import React from "react";
//import moment from "moment";
//import $ from 'jquery';
//import api from "./../../../Api.js";
import {Row,Col,Card,CardHeader,Progress,Button,UncontrolledTooltip,CardBody,Modal} from "reactstrap";
import { connect } from 'react-redux';
import ModalLBUnit from "./../modal/ModalLBUnit.jsx";
import RelatorioUnit from "./../modal/RelatorioUnit.jsx";
import api from "./../../../Api.js";

class ListUnits extends React.Component {

	constructor(props){

		super(props);
		this.state = {};
		this.verifyProgress = this.verifyProgress.bind(this);

		window.listunits = this;

	}

	//Para verificar o progresso de uma unit
	verifyProgress(unit,force){

		return this.props.verifyProgressUnit(unit,force);

	}
 
	render(){

		//return(<React.Fragment>teste</React.Fragment>);
		var imgSrc = "";

		return(<React.Fragment>


			{

				(this.state.modalUnitRelatorio === true) &&
				<Modal className="modal-dialog-centered fastShow dialogfastuserchart" toggle={() => { this.setState({modalUnitRelatorio:false}); }} style={{maxWidth:'600px'}} isOpen={true} >
					<div className="modal-body p-0">
						<Card className="bg-secondary border-0 mb-0">
							<div className="modal-header">
								<div style={{display:'flex',alignItems:'center'}}>
									<div style={{display:'flex',alignItems:'center',gap:'10px'}}>
										<h6 className="modal-title" id="modal-title-default" style={{margin:"auto"}}>Relatório da Unit</h6>

										<button onClick={() => {

											api.report_unit_xlsx(window.classroom.state.classroom.id,this.state.unitrelatorio,window.app.state.user.id).then((response) => {
																					
												window.open(response.data.url);

											}).catch((error) => {

												console.log('erro');
												console.error(error);
												window.swal.fire('Ops!','Não foi possível carregar o relatório.','error');

											});

										}} className="btn btn-sm btn-success"><i className="far fa-file-excel"></i> Exportar planilha</button>
									</div>
									<div style={{marginLeft:'10px'}} >
										<select style={{padding:'0px',height:'23px'}} value={this.state.unitrelatorio} onChange={(event) => {

											this.setState({unitrelatorio:event.target.value});

										}} className="form-control">
											<option></option>
											{

												this.props.classroomState.units.map((unit) => {
													
													return <option value={unit.id}>{unit.name}</option>

												})

											}
										</select>
									</div>
								</div>
							</div>
							<CardBody className="" id="abacoddsa">
								<RelatorioUnit classroom={window.classroom.state.classroom.id} name={this.props.name} userId={window.app.state.user.id} unit={this.state.unitrelatorio} />
							</CardBody>
						</Card>
					</div>
				</Modal>

			}

			{

				(this.state.openModalUnit === true) &&
				<ModalLBUnit state={this.state} unit={this.state.unit} unitname={this.state.unitname} close={() => this.setState({openModalUnit:false})} />

			}

			<Row className=" ">
			<Col className="card-wrapper"  >
				<Card style={{marginBottom:'0px',backgroundColor:'#fff0'}}>
					<CardHeader>
						<ul className="list my--3 list-group list-group-flush" style={{width:'100%'}}>
				 
							{
								this.props.classroomState.units.map((value,index) => {

									//Para verificar unit bloqueada
									var lock = false;
									for(let index = 0; index < this.props.classroom_option.length; index++) {
									 
										if(this.props.classroom_option[index].name === "lock_unit" && parseInt(this.props.classroom_option[index].value) === parseInt(value.id)

										){ lock = true; }
										
									}


									var superunlock = false;
									var ishidden = false;
									for(let index = 0; index < this.props.classroom_option.length; index++) {
									 
										if(this.props.classroom_option[index].name === "superunlock_unit" && parseInt(this.props.classroom_option[index].value) === parseInt(value.id)
										){superunlock = true; }

										if(this.props.classroom_option[index].name === "hidden_unit" && parseInt(this.props.classroom_option[index].value) === parseInt(value.id)
										){ishidden = true; }
										
									}


									if(value.icon_file === undefined){ imgSrc = ""; }else{ imgSrc = sessionStorage.getItem('centerBackend')+value.icon_file.url; }

		                      		var tooltip1 = "tt1_"+value.id;
									var tooltip2 = "tt2_"+value.id;
									var access = false;
									var progress = 0; 

									//Caso for o primeiro, libera o primeiro botão
									if(index === 0){

										access = true;

									}else{

										//Verifica se a unit anterior esta 100% completa
										var progressAnt = this.verifyProgress(this.props.classroomState.units[(index - 1)].id);
										if(progressAnt === 100){ access = true; }else{ access = false; }

									}

									progress = this.verifyProgress(value.id);

									//Caso tenha a permissão libera...
									if(this.props.permissions_force.indexOf(20) !== -1){ access = true; }


									if(access === true){ }else{ }

									if(superunlock === true){ access = true; }

									var hiddenLi = false;
									if(ishidden === true && this.props.permissions_force.indexOf(42) === -1){

										hiddenLi = true;

									}


									//find lb
									var cl = window.admin.state.classrooms.find(x => x.id === parseInt(this.props.match.params.classrom));
									console.log('cl');
									console.log(cl);
									console.log('cl');

									if(cl !== undefined){

										console.log('cl.unloks');
										console.log(cl.unloks);

										console.log('this.props.user.id: '+this.props.user.id);
										console.log('value.id: '+value.id);

										var clbl = cl.unloks.find(x => (x.user == this.props.user.id && x.unit === value.id));
										console.log('clbl');
										console.log(clbl);
										if(clbl !== undefined){
											access = true;
										}


									}
									

									return(
									<React.Fragment key={value.id}>
									<li hidden={hiddenLi} idc={value.id} className={"px-0 list-group-item "}>
										{

											(ishidden === true) &&
											<div style={{position:'absolute',height:'100%',width:'100%',fontSize: '14px',top:'5px',color:'red'}}>Unit ocultada, os alunos não conseguirão ver.</div>

										}
										{

											(value.status === 0) &&
											<div className="block_unit">
												<div className="block_unit_aa" style={{paddingTop:'17px'}}>
													<center>
														<img alt="..." style={{height:'55px'}} src="/images/lock.png" />
														<font>Essa unit ainda não esta disponível.</font>
													</center>
												</div>
											</div>

										}
										<div className={"align-items-center row "+(ishidden === true ? 'ishidden_unit_c':'')}>
											<div className="col-auto col">
												<font className="" style={{cursor:'pointer'}} onClick={ () => { if(access === true){ this.props.history.push('/myclassroom/'+this.props.match.params.classrom+'/'+value.id); } }} >
													<img alt="..." src={imgSrc} style={{maxWidth:'70px'}}/>
												</font>
											</div>
											<div className="col">
										        <div className="progress-wrapper">
													<div style={{position:'absolute',right:'45px',top:'0px'}}> 
														{

															(this.props.permissions_force.indexOf(35) !== -1) &&

															<React.Fragment>
																<React.Fragment>

																	<Button onClick={() => { 

																		window.classroom.setState({openModalEvaluation:{open:true,unit:value.id,unitname:value.name}});

																	}} id={tooltip1+'aa234522'}  style={{}} className="animated fadeIn" color="secondary" size="sm" type="button"><i className="fas fa-star"></i> Avaliação</Button>
																	<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa234522'}>Avaliar o desempenho dos alunos na unit.</UncontrolledTooltip>

																</React.Fragment>
																<React.Fragment>

																	<Button onClick={() => { 

																		this.setState({openModalUnit:true,unit:value.id,unitname:value.name});

																	}} id={tooltip1+'aa2345'}  style={{}} className="animated fadeIn" color="secondary" size="sm" type="button"><i className="fas fa-lock-open"></i> LB individual</Button>
																	<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa2345'}>Libera UNIT individual por aluno.</UncontrolledTooltip>

																</React.Fragment>

																{

																	(superunlock === false && value.status !== 0) &&
																	<React.Fragment>
																		
																		<Button onClick={ () => this.props.insertOption(value.id,"superunlock_unit") } id={tooltip1+'aa23'}  style={{}} className="animated fadeIn" color="secondary" size="sm" type="button">SuperUnlock <strong style={{color:'red'}}>(OFF)</strong></Button>
																		<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa23'}>Libera todas as lessons, mesmo sem ter acesso.<strong style={{color:'RED'}}>(OFF)</strong></UncontrolledTooltip>

																	</React.Fragment>

																}

																{

																	(superunlock === true && value.status !== 0) &&
																	<React.Fragment>

																		<Button onClick={ () => this.props.deleteOption(value.id,"superunlock_unit") } id={tooltip1+'aa234'}  style={{}} className="animated fadeIn" color="danger" size="sm" type="button">SuperUnlock <strong style={{color:'#fff'}}>(ON)</strong></Button>
																		<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa234'}>Libera todas as lessons, mesmo sem ter acesso. <strong style={{color:'green'}}>(ON)</strong></UncontrolledTooltip>

																	</React.Fragment>

																}

																{

																	(lock === true && value.status !== 0) &&
																	<React.Fragment>

																		<Button onClick={ () => this.props.deleteOption(value.id,"lock_unit") } id={tooltip1+'aa'}  style={{}} className="animated fadeIn" color="danger" size="sm" type="button"><i className="fas fa-lock"></i></Button>
																		<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa'}>Desbloquear UNIT</UncontrolledTooltip>

																	</React.Fragment>

																}
																{

																	(lock === false && value.status !== 0) &&
																	<React.Fragment>

																		<Button onClick={ () => this.props.insertOption(value.id,"lock_unit") } id={tooltip1+'aa2'} style={{}} className="animated fadeIn" color="secondary" size="sm" type="button"><i className="fas fa-lock-open"></i></Button>
																		<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa2'}>Bloquear UNIT</UncontrolledTooltip>

																	</React.Fragment>

																}

																 

															</React.Fragment>

														}

														 
														{

															(this.props.permissions_force.indexOf(41) !== -1) &&
															<React.Fragment>

																{

																	(ishidden === false) &&
																	<React.Fragment>
																		<Button onClick={ () => this.props.insertOption(value.id,"hidden_unit") } id={tooltip1+'aa2hh'} style={{}} className="animated fadeIn" color="secondary" size="sm" type="button"><i className="fa fa-eye"></i></Button>
																		<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa2hh'}>Ocultar UNIT</UncontrolledTooltip>
																	</React.Fragment>

																}
																{

																	(ishidden === true) &&
																	<React.Fragment>
																		<Button onClick={ () => this.props.deleteOption(value.id,"hidden_unit") } id={tooltip1+'aa2hh'} style={{}} className="animated fadeIn" color="danger" size="sm" type="button"><i className="fa fa-eye-slash"></i></Button>
																		<UncontrolledTooltip placement="left" delay={0} target={tooltip1+'aa2hh'}>Desocultar UNIT</UncontrolledTooltip>
																	</React.Fragment>

																}
																 

															</React.Fragment>

														}
														
													</div>
												 	
													 
													<div className="progress-info">
														<div className=" ">
															<span>{value.name}</span>
														</div>
														<div className="progress-percentage">
															<span>{progress.toFixed(0)}%</span>
														</div>
													</div>
													<Progress max="100" value={progress.toFixed(0)} color="success" />
										        </div>
											</div>
											<div className="col-auto col">
												{

												 
													<React.Fragment>
														
														<button onClick={() => {

															this.setState({modalUnitRelatorio:true,unitrelatorio:value.id});

														}} className="btn btn-secondary" type="button" id="teassaasd"><i className="fas fa-chart-pie"></i> </button>
														<UncontrolledTooltip placement="left" delay={0} target={'teassaasd'}>Relatório na UNIT</UncontrolledTooltip>

														{

															(access === true) &&
															<React.Fragment>
																<Button onClick={ () => this.props.history.push('/myclassroom/'+this.props.match.params.classrom+'/'+value.id) } color="default" type="button" id={tooltip1}><i className="far fa-hand-pointer"></i> {this.props.translate['atividades']}</Button>
																<UncontrolledTooltip placement="left" delay={0} target={tooltip1}>{this.props.translate['ver_atividades']}</UncontrolledTooltip>
															</React.Fragment>

														}
														{
															//onClick={ () => this.props.history.push('/myclassroom/'+this.props.match.params.classrom+'/'+value.id) }
															(access === false || access === undefined) &&
															<React.Fragment>
																<Button style={{cursor:'no-drop'}} color="secondary" type="button" id={tooltip2}><i className="fas fa-lock"></i></Button>
																<UncontrolledTooltip placement="left" delay={0} target={tooltip2}>{this.props.translate['voce_ainda_nao_tem_acesso_a_essa_unit']}</UncontrolledTooltip>
															</React.Fragment>

														}	

													</React.Fragment> 

												}{

													/*(this.props.classroomState.lessons[value.id] === undefined) &&
													<React.Fragment>
														<center><ClipLoader sizeUnit={"px"} size={15} color={'#adb5bd'} loading={true} /></center>
													</React.Fragment>*/

												}
											</div>
										</div>
									</li>
									</React.Fragment>)
								})
							}
							</ul>
						</CardHeader>
					</Card>
				</Col>
 
			</Row>
			
			  

		</React.Fragment>)

	}

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  classroom_option: state.classroom_option,

}))(ListUnits);