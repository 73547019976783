import React from "react";
import api from "./../../Api.js";
import moment from "moment";
import classnames from "classnames";
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import {Container,Row,Card,CardHeader,Col,Button,Table,UncontrolledTooltip,Modal,CardBody,Form,Alert,FormGroup,InputGroupAddon,InputGroupText,Input,InputGroup} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
//import paginationFactory from "react-bootstrap-table2-paginator";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//import ReactToPrint from "react-to-print";
//import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
//import { dataTable } from "variables/general";
import ReactDatetime from "react-datetime";
import {BounceLoader,ClipLoader} from 'react-spinners';

import ImportXlsxClassRoom from './modal/ImportXlsxClassRoom.jsx';
import ModalHookClassRoom from "./modal/ModalHookClassRoom.jsx";

import TransferClassroom from "./listClssroom/TransferClassroom.jsx";

import SelectSearch from 'react-select-search';

import $ from 'jquery';

require('moment/locale/pt-br');
require('moment/locale/en-au');
require('moment/locale/es');

class ListClassRoom extends React.Component {

  constructor(props){

      super(props);
       
      this.state = {
        openModalImportClassRoom:false,
        classRoomError:"",
        loadClassroom:false,
        dialog:{},
        textDialog:"",
        classRooms:[],
        course:[],
        loadCourse:false,
        addUserClassRoomModal:false,
        searchUser:[],
        inputSearchUser:"",
        loadingSearchUsers:false,
        classroomusers:[],
        company:this.props.user.preference.selectcompany,
        usersCompanyProfessor:[]
      };
      
      this.getAllClassRoom = this.getAllClassRoom.bind(this);
      this.newClassRoom = this.newClassRoom.bind(this);
      this.filterClassRoom = this.filterClassRoom.bind(this);
      this.addUserClassRoom = this.addUserClassRoom.bind(this); 
      this.searchUserClassRoom = this.searchUserClassRoom.bind(this);
      this.searchUserClassRoomAll = this.searchUserClassRoomAll.bind(this);
      this.searchUserClassRoomTerm = this.searchUserClassRoomTerm.bind(this);
      //this.getUsersClassRoom = this.getUsersClassRoom.bind(this);
      this.deleteClassRoom = this.deleteClassRoom.bind(this);
      this.removeClassRoomApi = this.removeClassRoomApi.bind(this);
      this.addUserClassRoomApi = this.addUserClassRoomApi.bind(this);
      this.newImport = this.newImport.bind(this);
      this.getClassRooms = this.getClassRooms.bind(this);
      this.postClassRoom = this.postClassRoom.bind(this);
      this.editClassRoom = this.editClassRoom.bind(this);
      this.generateName = this.generateName.bind(this);

      window.listClassRoom = this;

  }

  newImport(){

    this.setState({openModalImportClassRoom:true});
    
  }

  generateName(){

    var course = this.state.course.map(function(x) {return x.id; }).indexOf(parseInt(this.state.dialog.course.id));
    var course2 = this.state.course.map(function(x) {return x.id; }).indexOf(parseInt(this.state.dialog.course));

    if(course !== -1){ var dataCourse = this.state.course[course]; }
    if(course2 !== -1){ var dataCourse = this.state.course[course2]; }
    
    if(dataCourse !== undefined){

      var name = dataCourse.name;

    }else{ name = ""; }
    
    //LEVEL 0 - SAB 10H - 11-01-20 - VMAR

    var dias = [];
    $(".dia_aula_turma:checked").each(function(){ 
      
      dias.push($(this).val());
    
    });

    for (let index = 0; index < dias.length; index++) {
       
      var dia = window.diasSemanas[dias[index]].name;
      dia = dia.substring(0,3);
      dia = dia.toUpperCase();
      
      if(index === 0){
        
        name = name+" - "+dia;

      }else{

        name = name+" "+dia;

      }
  
    }

    try{
        
      var time = moment(this.state.dialog.classTime,'HH:mm:ss').format('HH');
      name = name+' '+time+'H';

    }catch(error){}

    try{
      
      var openingDate = moment(this.state.dialog.openingDate,'YYYY-MM-DD').format('DD-MM-YY');
      name = name+' - '+openingDate;

    }catch(error){}

    var dialog = this.state.dialog;
    dialog.name = name;
    console.log(name);
    this.setState({dialog});

  }

  //para buscar todos os usuarios via api
  async getAllClassRoom(){
    
//    console.log(this.props.user.preference.selectcompany);
    
    await api.getAllClassRoomCompany(this.props.user.preference.selectcompany).then((res) => {

        //?this.setState({classRooms:res.data});
        res.data.reverse();
        this.filterClassRoom(res.data);

        console.log(res.data);
        //this.editClassRoom(18);
       // setTimeout(() => { this.setState({openModalHook:true}); }, 200);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        if(error.response !== undefined){

          if(error.response.status !== undefined){

            if(error.response.status === 401){

              let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
              this.refs.notificationAlert.notificationAlert(options);

            }

          }

        }

      });
    
  }

  //para buscar todos os usuarios via api
  async myclassroom(){

      await api.getMyClassRooms().then((res) => {

        //?this.setState({classRooms:res.data});
        res.data.reverse();
        this.filterClassRoom(res.data);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        if(error.response !== undefined){

          if(error.response.status !== undefined){

            if(error.response.status === 401){

              let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['ops']}!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
              this.refs.notificationAlert.notificationAlert(options);

            }

          }

        }

      });
    
  }
 

  //para buscar todos os cursos
  async getAllcourses(){
  
      await api.getAllcourses().then((res) => {

        this.setState({course:res.data,loadCourse:true})

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });
    
  }

  async filterClassRoom(array){

    for(var i = 0; i < array.length; i++){
        
      for(var i2 = 0; i2 < this.state.course.length; i2++){ if(this.state.course[i2].id === array[i].course){ array[i].courseName = this.state.course[i2].name; }

        //array[i].openingDate2 = moment(array[i].openingDate,'YYYY-MM-DD').format('DD/MM/YYYY');
        //array[i].closingDate2 = moment(array[i].closingDate,'YYYY-MM-DD').format('DD/MM/YYYY');

      }
        

    }

    await this.setState({classRooms:array,loadClassroom:true});

    /*for(i2 = 0; i2 < array.length; i2++){

      //console.log('p'+i2);
      await this.getUsersClassRoom(array[i2].id);

    }*/
 

  }
    
  //para criar uma turma
  newClassRoom(){

    this.setState({classRoomError:"",openDialogClassRoomCreate:true,editClassRoom:"",textDialogClassRoom:this.props.translate['cadastrar_turma'],textButtonDialogClassRoom:this.props.translate['cadastrar']});
    this.setState({dialog:{hook:[],name:'',course:'',idOnline:'',openingDate:moment().format('YYYY-MM-DD'),closingDate:moment().format('YYYY-MM-DD')}});

  }

  //Para editar uma turma
  editClassRoom(id){

      this.setState({classRoomError:"",openDialogClassRoomCreate:true,editClassRoom:id,textDialogClassRoom:this.props.translate['editar_turma'],textButtonDialogClassRoom:this.props.translate['salvar_alteracoes']});

      //pega o curso
      this.state.classRooms.forEach(function(item,index,array){

        if(parseInt(item.id) === parseInt(id)){

          this.setState({dialog:{hook:item.hook,publicAccess:item.publicAccess ,responsibleTeacher:item.responsibleTeacher,classTime:item.classTime,name:item.name,course:item.course,idOnline:item.idOnline,openingDate:item.openingDate,closingDate:item.closingDate}});

          for(let index = 0; index < item.dayOfTheWeek.length; index++){
            
            setTimeout(function(){
               
              $('.dia_aula_turma[value='+item.dayOfTheWeek[index]+']').prop('checked', true);

            }, 100);
             
          }

        }

      }.bind(this));

  }

  //quando clicar no botão
  saveClassRoom(e){
      
      e.preventDefault();
      if(this.state.editClassRoom === ""){ this.postClassRoom(); }else{ this.putClassRoom(); }

  }
  
  //Parar cadastrar uma turma
  async postClassRoom(importxlx,data){

      if(data === undefined){

        data = this.state.dialog;

      }
      
      data.company = this.props.user.preference.selectcompany;

      data.dayOfTheWeek = this.pegaDiasAulas();

      var post = await api.postClassRoom(data).then(async (res) => {
     
        console.log(res.data);
        if(importxlx === undefined){
        
          //Caso tenha cadastrado a turma
          var classRooms = this.state.classRooms;
          classRooms.unshift(res.data.classroom);
          
          //await this.getUsersClassRoom(res.data.classroom.id);
          await this.setState({openDialogClassRoomCreate:false,classRooms:classRooms});
           
          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}.</span><span data-notify="message">A turma foi cadastrada com sucesso.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }

        return res.data;

      }).catch(error => {
        
          console.log(error.data);
          console.log(error);
          console.log(error.response);

          this.setState({loading:false});

          if(error.response.status === 400){

            //let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            //this.refs.notificationAlert.notificationAlert(options);
            this.setState({classRoomError:error.response.data.msg});

          }

          return false;

      });

      return post;

  }

  //Para salvar uma turma
  async putClassRoom(){

      var data = JSON.parse(JSON.stringify(this.state.dialog));
      delete data.course;

      data.dayOfTheWeek = this.pegaDiasAulas();

      await api.putClassRoom(this.state.editClassRoom,data).then((res) => {
        
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

        this.state.classRooms.forEach(function(item,index,array){

          if(parseInt(item.id) === parseInt(this.state.editClassRoom)){

            this.state.classRooms[index] = res.data.classroom
            this.filterClassRoom(this.state.classRooms);

          }

        }.bind(this));

        this.setState({openDialogClassRoomCreate:false});

      }).catch(error => {
        
          console.log(error.data);
          console.log(error);
          console.log(error.response);

          if(error.response.status === 400){

            //let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            //this.refs.notificationAlert.notificationAlert(options);

            this.setState({classRoomError:error.response.data.msg});

          }

      });

  }
  
  //para enviar os dados do usuario para atualização
  async removeUserApi(){

      this.setState({openDialogRemoveUser:false});
      await api.deleteUser(this.state.userDeletion).then((res) => {
          
        this.getAllUsers();
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O usuário foi removido.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

        if(error.response.status === 400){

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }

      });

  }

  //Para adicionar usuarios no classroom
  async addUserClassRoom(id){ console.log(id); await this.setState({addUserClassRoomModal:true,editClassRoom:id,inputSearchUser:""}); this.searchUserClassRoomAll(); }

  async componentDidUpdate(){
 
    if(this.state.company !== this.props.user.preference.selectcompany){ 
      
      await this.getClassRooms();
      this.getUsersCompanyByAccessLevel();
    
    }

  }

  async getClassRooms(){
 
    
    await this.setState({company:this.props.user.preference.selectcompany});

    //Tenho permissão para ver todas as turmas
    if(this.props.permission.indexOf(19) !== -1){
      
      await this.getAllClassRoom();

    }else{

      //Verificar se posso ver pelo menos as turmas que eu tenho acesso
      if(this.props.permission.indexOf(24) !== -1){

        await this.myclassroom();

      }

    }
    
    this.setState({loadComplete:true});

  }

  //Para pegar os professores dessa turma
  async getUsersCompanyByAccessLevel(){
 
      await api.getUsersByAccessLevel(3).then((res) => {
          
        console.log(res.data);
        this.setState({usersCompanyProfessor:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        //this.setState({loading:false});

       // if(error.response.status === 400){

         // let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
         // this.refs.notificationAlert.notificationAlert(options);

        //}

      });

  }

  //para criar um novo usuario no sistem,
  async componentDidMount(){
    
    await this.getAllcourses();

    await this.getClassRooms();
    this.getUsersCompanyByAccessLevel();
    
    this.setState({loadComplete:true});

  }

  //Para pequisar o usuário
  async searchUserClassRoom(){

    if(this.state.inputSearchUser === ""){ this.searchUserClassRoomAll(); }else{ this.searchUserClassRoomTerm(); }

  }

  //Para buscar os alunos ja cadastrados na turma
  async searchUserClassRoomAll(){
      
     // console.log('searchUserClassRoomAll');
      this.setState({loadingSearchUsers:true});

      await api.searchUserClassRoom(this.state.editClassRoom,this.state.inputSearchUser).then((res) => {

        this.setState({loadingSearchUsers:false,searchUser:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        this.setState({loadingSearchUsers:false});
     
      });

  }

  //Para pesquisar alunos
  async searchUserClassRoomTerm(){
      
     // console.log('searchUserClassRoomTerm');
      this.setState({loadingSearchUsers:true});

      await api.searchUserClassRoomTerm(this.state.editClassRoom,this.state.inputSearchUser).then((res) => {

        this.setState({loadingSearchUsers:false,searchUser:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        this.setState({loadingSearchUsers:false});
     
      });

  }
   
  //Para cadastrar o usuario na turma
  async addUserClassRoomApi(user,classroom,type){

      await api.addUserClassRoom({user:user,classroom:classroom,type:type}).then((res) => {

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O usuário foi cadastrado na turma.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);
        this.searchUserClassRoom();
        //this.getUsersClassRoom(classroom);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        if(error.response.status === 400){

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível adicionar o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }
 
     
      });

  }

  //Para remover o usuário da turma
  async removeUserClassRoomApi(user,classroom){

      await api.deleteUserClassRoomApi(classroom,user).then((res) => {

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O usuário foi removido da turma.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);
        this.searchUserClassRoom();
        //this.getUsersClassRoom(classroom);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        if(error.response.status === 400){

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }
     
      });

  }

  //Para pesquisar quais alunos estão nessa turma
  /*async getUsersClassRoom(id){

      var a = await api.searchUserClassRoom(id).then((res) => {

        var classroomusers = this.state.classroomusers;
        classroomusers[id] = res.data;
        this.setState({classroomusers:classroomusers});
 
        return true; 

      }).catch(error => { 

        console.log(error.data);
        console.log(error);
        console.log(error.response); 
        return false; 

      });

      return a;

  }*/

  //Parar remover uma turma no banco
  async removeClassRoomApi(){

      this.setState({openDialogClassRoomRemove:false});

      var a = await api.deleteClassRoom(this.state.deleteClassRoomId).then((res) => {

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A turma foi removida.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

        var turmas = this.state.classRooms;
        turmas.forEach(function(item,index,array){ if(parseInt(item.id) === parseInt(this.state.deleteClassRoomId)){ turmas.splice(index,1); } }.bind(this));
        this.setState({classRooms:turmas});

      }).catch(error => { 

        console.log(error.data);
        console.log(error);
        console.log(error.response); 
        return false; 

      });

      return a;

  }


  //para abrir o modal para remover o turma
  deleteClassRoom(id){

    this.setState({openDialogClassRoomRemove:true,deleteClassRoomId:id});

  }


  pegaDiasAulas(){

    var dias = [];
    $(".dia_aula_turma:checked").each(function(){ dias.push($(this).val()); });
    return dias;

  }

  pesquisar(val){

    if(val === ""){

      $('.tableList').fadeIn();

    }else{

      for (let index = 0; index < this.state.classRooms.length; index++) {
        
        var tem = this.state.classRooms[index].name.toLowerCase().includes(val.toLowerCase());
        if(tem === true){ $('.tableList[idc="'+this.state.classRooms[index].id+'"]').fadeIn(); }else{ $('.tableList[idc="'+this.state.classRooms[index].id+'"]').fadeOut(); }

      }

    }

  }


  returnKeys(obj){

    var obj2 = JSON.parse(JSON.stringify(obj));

    for (let index = 0; index < obj.length; index++) {
      
      obj2[index].key = obj[index].id;
      obj2[index].name = obj[index].name+' '+obj[index].lastName;
      obj2[index].value = obj[index].id;

    }

    console.log(obj2[0]);
    return obj2;

  }



  render(){

    const handleChange = name => event => {
        var dialog = this.state.dialog;
        dialog[name] = event.target.value;
        this.setState({dialog:dialog});
    }

    return(
      <React.Fragment>

        

        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert"/></div>
        <SimpleHeader name={this.props.translate['turmas']} way={[{name:this.props.translate['turmas'],url:"/classroom"}]} buttons={<React.Fragment></React.Fragment>} />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6"><h3 className="mb-0">{this.props.translate['turmas']}</h3></Col>
                    <Col className="text-right" xs="6">
                      {

                        (this.props.permission.indexOf(14) !== -1 && this.props.user.preference.selectcompany !== "") &&
                        <React.Fragment>
                          <Button className=" btn-round btn-icon" color="success" onClick={() => this.newImport()} size="sm">
                            <span className="btn-inner--icon mr-1"><i className="far fa-file-excel"></i></span>
                            <span className="btn-inner--text">{this.props.translate['importar_planilha']}</span>
                          </Button>
                          <Button className="btn-neutral btn-round btn-icon" color="default" id="tooltip969372949" onClick={() => this.newClassRoom()} size="sm">
                            <span className="btn-inner--icon mr-1"><i className="fas fa-plus"></i></span>
                            <span className="btn-inner--text">{this.props.translate['criar_turma']}</span>
                          </Button>
                        </React.Fragment>

                      }
                    </Col>
                  </Row>
                </CardHeader>
                <div >
                  <div style={{marginTop:'10px'}} id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                    <label>{this.props.translate['procurar']}:<input type="text" className="form-control form-control-sm" onChange={ (event) => this.pesquisar(event.target.value) }/></label>
                  </div>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <div><input checked={this.state.showRest === true ? true:false} onChange={(e) => {
                      
                      if(e.target.checked === true){ this.setState({showRest:true}); }else{ this.setState({showRest:false}); }

                    }} type="checkbox" className="form-control"/></div>
                    <div style={{marginLeft:'10px'}}>Mostrar outras informações</div>
                  </div>  
                </div>
                

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>id</th>
                      <th>{this.props.translate['nome']}</th>
                      <th>{this.props.translate['curso']}</th>
                      <th hidden={this.state.showRest !== true ? true:false}>{this.props.translate['id_online']}</th>
                      <th hidden={this.state.showRest !== true ? true:false}><center>{this.props.translate['data_de_abertura']}</center></th>
                      <th hidden={this.state.showRest !== true ? true:false}><center>{this.props.translate['data_de_fechamento']}</center></th>
                      <th><center>{this.props.translate['participantes']}</center></th>
                      <th>{this.props.translate['acoes']}</th> 
                    </tr>
                  </thead>
                  <tbody>
                  {

                    this.state.classRooms.map((value,index) => {

                      var permits;

                      //pode ver todas as turmas
                      if(this.props.permission.indexOf(19) === -1){

                        if(value.inclassroom === undefined){
                          
                          console.log('ud1');
                          return <React.Fragment key={value.id}></React.Fragment>

                        }else{

                          console.log('ud1');
                          if(value.inclassroom.type === "" || value.inclassroom.type === undefined){ return <React.Fragment key={value.id}></React.Fragment>; }

                        }
                          
                        var perm = window.app.state.permitions.map(function(x) {return x.id; }).indexOf(value.inclassroom.type);
                        if(perm === -1){ return <React.Fragment key={value.id}></React.Fragment>; }

                        permits = window.app.state.permitions[perm].permissions; 
                     
                      }else{

                        permits = this.props.permission;

                      }



                      var tooltip1 = "tt1_"+value.id;
                      var tooltip2 = "tt2_"+value.id;
                      var tooltip3 = "tt3_"+value.id;
                      var tooltip4 = "tt4_"+value.id;

                      var courseName = "";
                      for(var i2 = 0; i2 < this.state.course.length; i2++){ if(this.state.course[i2].id === value.course){ courseName = this.state.course[i2].name; }}

                      return(<tr key={value.id} idc={value.id} className="tableList">
                          <td>{value.id}</td>
                          <td style={{cursor:"pointer"}} onClick={ () => this.props.history.push('/myclassroom/'+value.id)}><div style={{width:'135px',overflowX:'hidden'}}>{value.name}</div></td>
                          <td><div style={{width:'135px',overflowX:'hidden'}}>{courseName}</div></td>
                          <td hidden={this.state.showRest !== true ? true:false}>{value.idOnline}</td>
                          <td hidden={this.state.showRest !== true ? true:false} ><center>{ moment(value.openingDate,'YYYY-MM-DD').format('DD/MM/YYYY') }</center></td>
                          <td hidden={this.state.showRest !== true ? true:false} ><center>{ moment(value.closingDate,'YYYY-MM-DD').format('DD/MM/YYYY') }</center></td>
                          <td><center style={{fontWeight:'800'}} className="animated fadeIn">{value.countParticipants}</center></td>
                          <td>
                            <React.Fragment>
                              {

                                (permits.indexOf(18) !== -1) &&
                                <React.Fragment>
                                  <Link to={'/classroom/dashboard/'+value.id+'/none'} id={tooltip1} idc={value.id} className="table-action"><i className="fas fa-chart-pie" /></Link>
                                  <UncontrolledTooltip delay={0} target={tooltip1}>{this.props.translate['progresso_da_turma']}</UncontrolledTooltip>
                                </React.Fragment>

                              }
                              {

                                (permits.indexOf(17) !== -1) &&
                                <React.Fragment>
                                  <a id={tooltip2} href="#marlon" onClick={ () => this.addUserClassRoom(value.id) } className="table-action"><i className="fas fa-users" /></a>
                                  <UncontrolledTooltip delay={0} target={tooltip2}>{this.props.translate['participantes']}</UncontrolledTooltip>

                                  <a id={tooltip2+'_dd'} href="#marlon" onClick={ () => { this.setState({openModalTransfer:true,transferID:value.id}) } } className="table-action"><i className="fas fa-exchange-alt"></i></a>
                                  <UncontrolledTooltip delay={0} target={tooltip2+'_dd'}>Transferir participantes</UncontrolledTooltip>

                                   
                                </React.Fragment>

                              }
                              {

                                (permits.indexOf(15) !== -1) &&
                                <React.Fragment>
                                  <a id={tooltip3} href="#marlon" onClick={ () => this.editClassRoom(value.id)} className="table-action" ><i className="fas fa-pencil-alt" /></a>
                                  <UncontrolledTooltip delay={0} target={tooltip3}>{this.props.translate['editar_turma']}</UncontrolledTooltip>
                                </React.Fragment>
                              }

                              
                              {

                                (permits.indexOf(16) !== -1) &&
                                <React.Fragment>
                                  <a id={tooltip4} href="#marlon" onClick={ () => this.deleteClassRoom(value.id)} className="table-action" ><i className="far fa-trash-alt" /></a>
                                  <UncontrolledTooltip delay={0} target={tooltip4}>{this.props.translate['deletar_turma']}</UncontrolledTooltip>
                                </React.Fragment>

                              }
                            </React.Fragment>
                          </td>
                        </tr>)

                      })}
                  </tbody>
                </Table> 
                {

                  (this.state.classRooms.length === 0 && this.props.user.preference.selectcompany !== "" && this.state.loadClassroom === true) &&
                  <center><h3 style={{margin:'30px 0px'}}>{this.props.translate['nenhuma_turma_foi_encontrada']}</h3></center>

                }
                {

                  (this.props.user.preference.selectcompany === "" && this.state.classRooms.length === 0 && this.state.loadClassroom === true) &&
                  <center><h3 style={{margin:'30px 0px'}}>{this.props.translate['voce_nao_tem_acesso_a_nenhum_unidade']}</h3></center>

                }

                {

                  (this.state.loadClassroom === false) &&
                  <div className="col-md-12" style={{marginTop:"40px",marginBottom:"30px"}}>
                    <center><ClipLoader sizeUnit={"px"} size={60} color={'#adb5bd'} loading={true} /></center>
                  </div>

                }

              </Card>
            </div>
          </Row>
        </Container>
        <Modal className="modal-dialog-centered" isOpen={this.state.openDialogClassRoomCreate} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.textDialogClassRoom}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openDialogClassRoomCreate:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form autoComplete="off" role="form" type="post" onSubmit={ (event) => this.saveClassRoom(event) }>
                  {
                    (this.state.classRoomError) &&
                    <Alert color="danger" className="animated tada"><strong>{this.props.translate['ops']}!</strong> {this.state.classRoomError}</Alert>
                  }
                  <Row>
                      <Col md={12}>
                        <h5>{this.props.translate['nome']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                          <button onClick={ () => this.generateName() } style={{marginTop:'5px'}} type="button" class="btn btn-secondary btn-sm">Gerar nome</button>
                        </FormGroup>
                      </Col>
                      {
                        (this.state.editClassRoom === "") &&
                        <Col md={12}>
                          <h5>{this.props.translate['curso']}</h5>
                          <FormGroup className={classnames("mb-3",{focused:this.state.focusedCourse})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                              <Input type="select" onChange={handleChange('course')} value={ this.state.dialog.course } onFocus={() => this.setState({ focusedCourse: true }) } onBlur={() => this.setState({ focusedCourse: false }) } >
                                <option></option>
                                {

                                  this.state.course.map((value,index) => {

                                    return(<option value={value.id}>{value.name}</option>)

                                  })

                                }
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      }
                      <Col md={6}>
                        <h5>{this.props.translate['data_de_abertura']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedopeningDate})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <ReactDatetime autoComplete="off" locale="pt-br" onChange={ (event) => {
                              
                              var dialog = this.state.dialog; 
                              try {

                                dialog.openingDate = event.format('YYYY-MM-DD');

                              }catch(error){
                                
                              }
                              this.setState({dialog:dialog}); 
                              
                              }}  value={ moment(this.state.dialog.openingDate,'YYYY-MM-DD').format('DD/MM/YYYY') } dateFormat="DD/MM/YYYY" inputProps={{placeholder:""}} timeFormat={false} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <h5>{this.props.translate['data_de_fechamento']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedClosingDate})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <ReactDatetime autoComplete="off" locale="pt-br" onChange={ (event) => {
                              
                              var dialog = this.state.dialog; 

                              try {
                                dialog.closingDate = event.format('YYYY-MM-DD'); 
                              } catch (error) {
                                
                              }
                              
                              this.setState({dialog:dialog}); 
                              
                            } }  value={ moment(this.state.dialog.closingDate,'YYYY-MM-DD').format('DD/MM/YYYY') } dateFormat="DD/MM/YYYY" inputProps={{placeholder:""}} timeFormat={false} />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12} style={{marginBottom:'10px'}}>
                        <h5>Dia da semana:</h5>
                        <div className="row">
                          {

                            window.diasSemanas.map((value,index) => {

                              return(<React.Fragment key={"dia_semana_"+value.id}>
                                <div className="col-md-6">
                                  <div className="custom-control custom-checkbox">
                                    <input className="custom-control-input dia_aula_turma" value={value.id} id={'dia_semana_c'+value.id} type="checkbox" />
                                    <label className="custom-control-label" htmlFor={'dia_semana_c'+value.id}>
                                    {value.name}
                                    </label>
                                  </div>
                                </div>
                              </React.Fragment>)

                            })

                          }
                        </div>
                      </Col> 

                      <Col md={12}>
                        <h5>Horario da aula:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedidOnline2})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="far fa-clock"></i></InputGroupText></InputGroupAddon>
                            <InputMask value={this.state.dialog.classTime} onChange={handleChange('classTime')} className="form-control" mask="99:99:99" maskChar=" "  />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      {

                        (this.state.openDialogClassRoomCreate === true) && 
                        <Col md={12}>
                          <h5>Professor responsável:</h5>
                          <FormGroup className={classnames("mb-3",{focused:this.state.focusedidOnline2})} >
                            <style>{' .select_professor_bb{  } '}</style>
                            <InputGroup className="input-group-merge input-group-alternative select_professor_bb">
                      
                              <SelectSearch options={this.returnKeys(this.state.usersCompanyProfessor)  } onChange={ (value) => {

                                console.log(value);
                                var dialog = this.state.dialog;
                                dialog.responsibleTeacher = value.value;
                                this.setState({dialog:dialog});

                              }  }  value={this.state.dialog.responsibleTeacher}  name="responsibleTeacher" placeholder="Escolha um professor" />

                            </InputGroup>
                          </FormGroup>
                        </Col>
                        
                      }
                      

                      <Col md={12}>
                        <h5>Permitir acesso público:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <select className="form-control" type="text" onChange={handleChange('publicAccess')} value={ this.state.dialog.publicAccess } >
                              <option value=""></option>
                              <option value="0">Não</option>
                              <option value="1">Sim</option>
                            </select>
                          </InputGroup>
                        </FormGroup>
                      </Col>


                      <Col md={12}>
                        <h5>{this.props.translate['indetificador_online']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedidOnline})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-email-83" /></InputGroupText></InputGroupAddon>
                            <Input autoComplete="nope" onChange={handleChange('idOnline')} value={ this.state.dialog.idOnline } type="text" onFocus={() => this.setState({ focusedidOnline: true }) } onBlur={() => this.setState({ focusedidOnline: false }) } />
                          </InputGroup>
                        </FormGroup>
                        <h5>{this.props.translate['os_alunos_poderam_acessar_a_turma_pela_url']}:</h5>
                        <h6>{window.location.origin}/classenter/<font style={{color:"green"}}>{this.state.dialog.idOnline}</font></h6>
                      </Col>
                      
                      {

                        (this.state.dialog.publicAccess === 1 || this.state.dialog.publicAccess === "1") &&
                        <Col md={12}>
                          <h5>Link de acesso público:</h5>
                          <h6>{window.location.origin}/publicclassenter/<font style={{color:"green"}}>{this.state.dialog.idOnline}</font></h6>
                          <font onClick={() => { this.setState({openModalHook:true}); }} style={{color:'#5e72e4',position: 'absolute',fontSize:'10px',bottom:'-8px',cursor:'pointer'}}>Configurar hook</font>
                        </Col>


                      }
                      

                  </Row>
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openDialogClassRoomCreate:false})}>{this.props.translate['cancelar']}</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.textButtonDialogClassRoom}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogClassRoomRemove} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">{this.props.translate['sua_atencao_e_requirida']}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" >
              <span aria-hidden={true} onClick={ () => this.setState({openDialogClassRoomRemove:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">{this.props.translate['voce_deve_ler_isto']}!</h4>
              <p dangerouslySetInnerHTML={{__html: this.props.translate['atencao_remover_turma']}} ></p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeClassRoomApi() }>{this.props.translate['ok_remova']}</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogClassRoomRemove:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered" isOpen={this.state.addUserClassRoomModal} >
          <div className="modal-header bg-secondary">
            <h5 className="modal-title" id="exampleModalLabel">Adicionar ou remover participantes</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({addUserClassRoomModal:false})} >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body bg-secondary">
              <FormGroup>
              <h5>{this.props.translate['pesquise_o_nome_email_ou_usario_abaixo']}</h5>
                <InputGroup className="input-group-alternative mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="ni ni-zoom-split-in" /></InputGroupText>
                  </InputGroupAddon>
                  <Input className="form-control-alternative" placeholder={this.props.translate['procurar']} type="text" onChange={ async (event)  =>  { await this.setState({inputSearchUser:event.target.value}); this.searchUserClassRoom(); }} value={this.state.inputSearchUser} />
                </InputGroup>
              </FormGroup>
              <div className="listUserAddClassRoom">
                <center hidden>
                 <BounceLoader hidden sizeUnit={"px"} size={50} color={'#172b4d'} loading={this.state.loadingSearchUsers} />
                </center>
                {
                <React.Fragment>
                  {

                    this.state.searchUser.map((value,index) => {

                      var inclassRoom = value.inclassroom;
                      var namePerm = "";
                      if(inclassRoom !== undefined){

                        namePerm = <strong>Tipo: <font style={{color:"red"}}>Não definido.</font></strong>;
                        
                        var index3 = window.app.state.permitions.map(function(x) {return x.id; }).indexOf(value.inclassroom.type);
                        if(index3 !== -1){

                          namePerm = <strong>Tipo: <font style={{color:"#2b78fb"}}>{window.app.state.permitions[index3].name}</font></strong>;

                        }

                      }

                      return(<div key={'aaq_'+value.id} className="d-flex align-items-center card-header">
                              <div className="d-flex align-items-center">
                                <img style={{backgroundColor:'#adb5bd00'}} alt="..." className="avatar" src={value.photo} />
                                <div className="mx-3">
                                  <font className="text-dark font-weight-600 text-sm">{value.name} {value.lastName} (id:{value.id})</font>
                                  <small className="d-block text-muted">{value.user}</small>
                                </div>
                              </div>
                              <div className="text-right ml-auto">
                                {
                                  (value.inclassroom === undefined) &&
                                  <React.Fragment>

                                   
                                  <div style={{marginTop:'7px',textAlign:'center'}}>

                                    <font>Adicionar como:</font>
                                    <div>
                                      <select onChange={ (event) => {

                                          var searchUser = this.state.searchUser;
                                          searchUser[index].type = event.target.value;
                                          this.setState({searchUser});


                                      }} className="form-control" style={{height:"25px",padding:'0px'}}>
                                      <option value=""></option>
                                        {

                                          window.app.state.permitions.map((value2,index2) => {

                                            return(<option value={value2.id}>{value2.name}</option>)

                                          })

                                        }
                                      </select>
                                    </div>
                                  </div>
                                  
                                  {

                                    (value.type !== "" && value.type !== undefined) &&
                                    <button style={{marginTop:'5px'}} onClick={ () => this.addUserClassRoomApi(value.id,this.state.editClassRoom,value.type) } type="button" className="btn-icon btn btn-primary btn-sm">
                                      <span className="btn-inner--icon"><i className="ni ni-fat-add"></i></span>
                                      <span className="btn-inner--text">{this.props.translate['adicionar']}</span>
                                    </button>

                                  }

                                  </React.Fragment>
                                }
                                {
                                  (value.inclassroom !== undefined) &&
                                  <React.Fragment>
                                    <button onClick={ () => this.removeUserClassRoomApi(value.id,this.state.editClassRoom) } type="button" className="btn-icon btn btn-danger btn-sm">
                                      <span className="btn-inner--icon"><i className="ni ni-fat-remove"></i></span>
                                      <span className="btn-inner--text">{this.props.translate['remover']}</span>
                                    </button>

                                    <div style={{textAlign:'center',marginTop:'4px',fontSize:'13px'}}>{namePerm}</div>
                                    
                                  </React.Fragment>
                                  
                                }
                              </div>
                            </div>)

                    })
                  }
                  { 

                    (this.state.searchUser.length === 0) &&
                    <center>{this.props.translate['nenhum_usuario_foi_encontrado']}</center>

                  }
                </React.Fragment>    
              }
              </div>
          </div>
          <div className="modal-footer bg-secondary">
            <Button color="default" data-dismiss="modal" type="button" onClick={() => this.setState({addUserClassRoomModal:false})}>{this.props.translate['fechar']}</Button>
          </div>
        </Modal>







        <Modal className="modal-dialog-centered" isOpen={this.state.openModalTransfer} >
          <div className="modal-header bg-secondary">
            <h5 className="modal-title" id="exampleModalLabel">Transferir participantes</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalTransfer:false})} >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body bg-secondary">

                {

                  (this.state.openModalTransfer === true) &&
                  <React.Fragment><TransferClassroom success={ () => {

                    this.getClassRooms();
                    this.setState({openModalTransfer:false});
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}.</span><span data-notify="message">Os participantes selecionados foram transferidos.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);

                  }} getClassRooms={ () => this.getClassRooms() } classroom={this.state.transferID} state={this.state} setState={ (data) => this.setState(data) }/></React.Fragment> 

                }
          </div>
        </Modal>
        
        {

          (this.state.openDialogClassRoomCreate === true) &&
          <ModalHookClassRoom hook={this.state.dialog} open={this.state.openModalHook} close={() => this.setState({openModalHook:false})} />

        }
        
        <ImportXlsxClassRoom getAllClassRoom={ () => this.getAllClassRoom() } postClassRoom={this.postClassRoom} state={this.state} setState={(data) => this.setState(data) } />

      </React.Fragment>
    );
  }
}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,

}))(ListClassRoom);