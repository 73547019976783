import React from "react";
import { connect } from 'react-redux';

class SwitDash extends React.Component {

  constructor(props){

    super(props);
    this.state = {openMenu:false};
    this.changeSelect = this.changeSelect.bind(this);
    
    window.switdash = this;

  }

  changeSelect(valor){
 
    this.props.setState({viewDashBoard:valor});

  }

  componentWillUnmount(){
 
  }

  render(){

    return (<React.Fragment>

        <button className="switLado" onClick={() => this.setState({openMenu:!this.state.openMenu})}><i className="fas fa-chart-pie"></i></button>

        {

            (this.state.openMenu === true) &&
            <React.Fragment>

                <div className="switLadoOpen">

                    <center className="font_center_switdash">Escolha uma dashboard</center>

                    <center>
                        <select value={this.props.state.viewDashBoard} onChange={ (event) => this.changeSelect(event.target.value) } className="selectDashBoard">
                            
                            <option value="Professor">Professor</option>
                            <option value="Estudante">Estudante</option>
                            

                        </select>
                    </center>

                </div>

            </React.Fragment>
                
        }


    </React.Fragment>)

  }
 
}

export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    unit: state.unit,
    useronline: state.useronline
  
}))(SwitDash);