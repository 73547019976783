import React from "react";
import { Button,Card,CardHeader,CardBody,CardImg,CardTitle,FormGroup,Form,Input,ListGroupItem,ListGroup,Progress,Container,Row,Col} from "reactstrap";
import {InputGroupText,Alert,Modal,InputGroupAddon,InputGroup,Badge,CardFooter,DropdownMenu,DropdownItem,DropdownToggle,UncontrolledDropdown,Media,Pagination,PaginationItem,PaginationLink,UncontrolledTooltip} from "reactstrap";
import InputMask from 'react-input-mask';
import api from "./../../../Api.js";
import ReactStars from "react-rating-stars-component";

import $ from 'jquery';
import { SortablePane, Pane } from 'react-sortable-pane';
import classnames from "classnames";
import { RingLoader } from 'react-spinners';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import moment from "moment";

//spinner
import { ClipLoader } from "react-spinners";


class ModalEvaluation extends React.Component {

	constructor(props){

		super(props);
		this.state = {

            students:[],
            evaluation:[],
            evaluationlist:[]

        };

        this.getStudents = this.getStudents.bind(this);
        this.getEvaluation = this.getEvaluation.bind(this);

		window.modalEvaluation = this;

	}

    getStudents(){

        api.evaluationlist_unit_user_simple(this.props.classroom).then((response)=> {

            this.setState({load1:true,students:response.data}); 

        }).catch((error) => {
            
            console.log('erro');
            console.error(error);
            window.swal.fire('Ops!','Não foi possível carregar as lista de usuários.','error');


        });

    }

    getEvaluation(){

        api.evaluationlist_unit().then((response)=> {

            this.setState({load2:true,evaluation:response.data});

        }).catch((error) => {
            
            console.log('erro');
            console.error(error);
            window.swal.fire('Ops!','Não foi possível carregar a lista de avaliação','error');


        });

    }

    list_evaluationlist_unit(){

        api.list_evaluationlist_unit(this.props.classroom,this.props.data.unit).then((response)=> {

            this.setState({load3:true,evaluationlist:response.data});

        }).catch((error) => {
            
            console.log('erro');
            console.error(error);
            window.swal.fire('Ops!','Não foi possível carregar a lista de avaliação dos usuários','error');


        });

    }

     

    componentDidMount(){

        this.getStudents();
        this.getEvaluation();
        this.list_evaluationlist_unit();

    }

    findEvaluation(user,evaluation){

        var find = this.state.evaluationlist.find(x => x.user === user && x.evaluation === evaluation);

        if(find){

            return find.score;

        }else{

            return 0;

        }

    }

	render(){

	 
		return(
		<React.Fragment>
	        <Modal className="modal-dialog-centered" isOpen={true} style={{minWidth:'1640px'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">Avaliação da unit </h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.close()}><span aria-hidden={true}>×</span> </button>
                        </div>
                        <CardBody className=" ">
                            <table className="align-items-center table-flush table" id="my-table">
                                <thead class="thead-light">
                                    <tr> 
                                        <th>Estudante</th>
                                        {

                                            this.state.evaluation.map((evaluation) => {

                                                return( <th>{evaluation.name}</th>)

                                            })

                                        }
                                    </tr>
                                </thead>
                                {

                                    (this.state.load1 === true && this.state.load2 === true && this.state.load3 === true) &&
                                    <tbody>
                                        {

                                            this.state.students.map((student) => {

                                                return(

                                                    <tr>
                                                        <td>{student.name} {student.lastName}</td>
                                                        {

                                                            this.state.evaluation.map((evaluation) => {

                                                                var val = {
                                                                    size: 20,
                                                                    count: 5,
                                                                    isHalf: false,
                                                                    value: this.findEvaluation(student.id,evaluation.id),
                                                                    color: "#959595",
                                                                    activeColor: "#3833ff",
                                                                    onChange: newValue => {
                                                                        
                                                                    console.log('student:'+student.id+' evaluation: '+evaluation.id+' score:'+newValue);

                                                                    api.post_evaluationlist_unit({
                                                                        user:student.id,
                                                                        evaluation:evaluation.id,
                                                                        classroom:this.props.classroom,
                                                                        unit:this.props.data.unit,
                                                                        score:newValue
                                                                    }).then((response)=> {



                                                                    }).catch((error) => {

                                                                        console.log('erro');
                                                                        
                                                                    });
                                                                    
                                                                    }
                                                                }
                                                                return(

                                                                    <td>
                                                                        <ReactStars {...val} />
                                                                    </td>

                                                                )

                                                            })

                                                        }
                                                    </tr>)

                                            })

                                        }
                                    </tbody>

                                } 
                                
                            </table>
                            {

                                (this.state.load1 !== true || this.state.load2 !== true || this.state.load3 !== true) &&
                                <center style={{marginTop:'25px'}}> <ClipLoader color={'#333'} loading={true} /> </center>

                            }
                        

                          
                        </CardBody>
                    </Card>
                </div>
	        </Modal>

     
            
        </React.Fragment>

		)

	}

}

export default ModalEvaluation;