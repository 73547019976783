import React from "react";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import {Col,Button,Row,Progress} from "reactstrap";
import $ from 'jquery';
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import api from "./../../Api.js";
import {TwitterPicker} from 'react-color';
import { connect } from 'react-redux';

class Appearance extends React.Component {

	constructor(props) {
    
    	super(props);

    	this.state = {fileInput:[{progress:""},{progress:""},{progress:""},{progress:""}]};
    	this.uploadImg = this.uploadImg.bind(this);
    	this.setProgress = this.setProgress.bind(this);
    	this.changeFileInput = this.changeFileInput.bind(this);

	}		

	//Para fazer o upload do arquivo no input file
	async uploadImg(inputName){

		let formData = new FormData();
		var fileInput = this.state.fileInput;
		var row = $('.section_file[name="'+inputName+'"]');
		var input = row.find('input[type="file"]');
		var n = row.attr('n');
		var file = input[0].files;

		//seta o erro caso nenhum arquivo tenha sido informado
 		if(file.length === 0){  
 			fileInput[n].erro = "";
 			this.setState({fileInput:fileInput});
 			setTimeout(function(){ fileInput[n].erro = this.props.translate['voce_deve_escolher_um_arquivo']; this.setState({fileInput:fileInput}); }.bind(this), 1);
 			return false;
 		}

 		//removo o erro caso tenha
 		fileInput[n].erro = "";
 		this.setState({fileInput:fileInput});

 		formData.append('files', file[0]); 
		formData.append('name', file[0].name);
		formData.append('type',inputName);

		this.setProgress(0,n);

		axios.post(sessionStorage.getItem('backend')+'/upload/system',formData,{headers:{'Content-Type': 'multipart/form-data',session: sessionStorage.getItem('session')},onUploadProgress:progressEvent => {

			this.setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total),n)

	    }}).then(function(res){

	    	this.setProgress('',n);
	    	var state = window.app.state.confs;
	    	state[inputName] = res.data.url;
	    	window.app.setState({confs:state});

	  	}.bind(this)).catch(function(res){

	    	//console.log('FAILURE!!');
	    	//console.log(res.data);
	    	//console.log(res.response.data);
	    	this.setProgress('',n);

	  	}.bind(this));

	}

	//Para setar o progresso da barra
	setProgress(progress,n){

		var fileInput = this.state.fileInput;
		fileInput[n].progress = progress;
		this.setState({fileInput:fileInput});
		//console.log(progress);

	}

	//Quando mudar o arquivo no input, muda o texto no label
	changeFileInput(event,htmlFor){

		//console.log(event);
		//console.log(event.target.value);
		$("label[for='"+htmlFor+"']").html(event.target.value);
		//console.log("label[for='"+htmlFor+"']");

	}

	async saveConfiguration(event){

		event.preventDefault();

		var obj = {};
		obj.systemName = window.$("#systemName").val();
		obj.slogan = window.$("#slogan").val();
		obj.customcss = window.$("#customcss").val();
		
		obj.headerDegrade1 = window.app.state.confs.headerDegrade1;
		obj.headerDegrade2 = window.app.state.confs.headerDegrade2;

		await api.saveConfiguration(obj).then((res) => {
        	
        	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}!</span><span data-notify="message">{this.props.translate['as_configuracoes_foram_salvas']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        	this.refs.notificationAlert.notificationAlert(options);

			//console.log(res.data);


		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

		});


	}

	changeColor(event,name){

		//console.log(event);
		var state = window.app.state.confs;
		state[name] = event.hex;
		window.app.setState({confs:state});

	}

	render(){

		return(<React.Fragment>
			<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
			<SimpleHeader name={this.props.translate['aparencia']} way={[{name:this.props.translate['configuracao'],url:"/configuration"},{name:this.props.translate['aparencia'],url:"/configuration/appearance"}]} buttons={<React.Fragment></React.Fragment>} />
			<div className="mt--6 container-fluid">
				<div className="justify-content-center row">
					<div className="card-wrapper col-xs-12 col-sm-12 col-md-12">
						<form type="post" onSubmit={ (event) => this.saveConfiguration(event) } >
							<div className="card">
								<div className="card-header">
									<h3 className="mb-0">{this.props.translate['sistema']}</h3>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-md-6" style={{marginTop:'10px'}}>
							                <div className="security" style={{borderbottom:'1px solid #fff'}}>
						                        <div className="col-md-12 ">
						                            <h4>{this.props.translate['nome_da_escola']}:</h4> 
						                        	<input id="systemName" type="text" className="form-control" defaultValue={window.app.state.confs.systemName} />
						                        </div>
							                </div>
							                <br/>
							                <div className="security" style={{borderbottom:'1px solid #fff'}}>
						                        <div className="col-md-12 ">
						                            <h4>{this.props.translate['slogan']}:</h4> 
						                        	<input id="slogan" type="text" className="form-control" defaultValue={window.app.state.confs.slogan} />
						                        </div>
							                </div>
											<br/>
											<div className="security" style={{borderbottom:'1px solid #fff'}}>
						                        <div className="col-md-12 ">
						                            <h4>Custom CSS:</h4> 
						                        	<textarea id="customcss" type="text" className="form-control" >{window.app.state.confs.customcss}</textarea>
						                        </div>
							                </div>
											
							            </div>
							            <div className="col-md-6" style={{marginTop:'10px'}}>
						                	<div className="col-md-12">
						                		<h4 style={{marginBottom:'20px'}}>{this.props.translate['cor_degrade_1']}:</h4> 
							            		<TwitterPicker color={window.app.state.confs.headerDegrade1} onChange={ (event) => this.changeColor(event,'headerDegrade1') }/>		                		
						                	</div><br/>
						                	<div className="col-md-12">
						                		<h4 style={{marginBottom:'20px'}}>{this.props.translate['cor_degrade_2']}:</h4> 
							            		<TwitterPicker color={window.app.state.confs.headerDegrade2} onChange={ (event) => this.changeColor(event,'headerDegrade2') }/>						                		
						                	</div>
							            </div>
							            <Col md={12}>
								            <center>
								            	<Button color="secondary" type="submit" style={{marginTop:'20px'}}>{this.props.translate['salvar_alteracoes']}</Button>
								            </center>
							            </Col>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>		
			</div>
			<div className="container-fluid">
				<div className="justify-content-center row">
					<div className="card-wrapper col-xs-12 col-sm-12 col-md-12">
						<div className="card">
							<div className="card-header">
								<h3 className="mb-0">{this.props.translate['logos_e_background']}</h3>
							</div>
							<div className="card-body">
						            <div className="col-md-12" style={{marginTop:'10px'}}>
			                      		<div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">{this.props.translate['background_da_tela_de_login']}</h6>
				                      		<Row className="section_file" name="background" n="0">
					                      		<Col md={12} style={{padding:'34px'}}>
						                      		<Row>
						                      			<Col md="4"><center><img alt="..." className="imgup img-responsive" src={sessionStorage.getItem('backend')+window.app.state.confs.background} /></center></Col>
						                      			<Col md="8">
						                      				{
						                      					(this.state.fileInput[0].progress === "") &&
						                      					<div style={{display:"flex"}}>
								                      				<div className="custom-file form-control-sm">
												                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_0');this.uploadImg('background'); } } className="custom-file-input" id="custom_file_0" type="file" />
												                        <label className="custom-file-label " htmlFor="custom_file_0">{this.props.translate['selecionar_arquivo']}</label>
											                      	</div>
										                      		<Button onClick={ () => this.uploadImg('background') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> {this.props.translate['enviar']}</Button>
								                          		</div>
						                      				}
							                          		{
							                          			(this.state.fileInput[0].erro !== "") &&
							                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[0].erro}</h6>
							                          		}
							                          		{
							                          			(this.state.fileInput[0].progress !== "") &&
							                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
														          <div className="progress-info">
														            <div className="progress-label"><span>{this.props.translate['enviando_arquivo']}</span></div>
														            <div className="progress-percentage"><span>{this.state.fileInput[0].progress}%</span></div>
														          </div>
														          <Progress max="100" value={this.state.fileInput[0].progress} color="default" />
														        </div>
							                          		}
							                          	</Col>
						                          	</Row>
				                          		</Col>
				                      		</Row>
			                      		</div>
						            </div>
						            <div className="col-md-12">
			                      		<div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">{this.props.translate['logo_externa']}</h6>
				                      		<Row className="section_file" name="logo" n="1">
					                      		<Col md={12} style={{padding:'34px'}}>
						                      		<Row>
						                      			<Col md="4" style={{padding:'0px',background:'url('+sessionStorage.getItem('backend')+window.app.state.confs.background+')',"backgroundSize":"cover"}} >
						                      				
						                      				<div style={{"position":"absolute","width":"100%","height":"100%","background":"rgba(0, 0, 0, 0.46)"}}></div>
						                      				<center><img alt="..."  style={{margin:"50px",maxHeight:'60px',position:"relative"}} className="imgup img-responsive" src={sessionStorage.getItem('backend')+window.app.state.confs.logo}/></center>
						                      			
						                      			</Col>
						                      			<Col md="8">
						                      				{
						                      					(this.state.fileInput[1].progress === "") &&
						                      					<div style={{display:"flex"}}>
								                      				<div className="custom-file form-control-sm">
												                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_1');this.uploadImg('logo'); } } className="custom-file-input" id="custom_file_1" type="file" />
												                        <label className="custom-file-label " htmlFor="custom_file_1">{this.props.translate['selecionar_arquivo']}</label>
											                      	</div>
										                      		<Button onClick={ () => this.uploadImg('logo') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> {this.props.translate['enviar']}</Button>
								                          		</div>
						                      				}
							                          		{
							                          			(this.state.fileInput[1].erro !== "") &&
							                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[1].erro}</h6>
							                          		}
							                          		{
							                          			(this.state.fileInput[1].progress !== "") &&
							                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
														          <div className="progress-info">
														            <div className="progress-label"><span>{this.props.translate['enviando_arquivo']}</span></div>
														            <div className="progress-percentage"><span>{this.state.fileInput[1].progress}%</span></div>
														          </div>
														          <Progress max="100" value={this.state.fileInput[1].progress} color="default" />
														        </div>
							                          		}
							                          	</Col>
						                          	</Row>
				                          		</Col>
				                      		</Row>
			                      		</div>
						            </div>
						            <div className="col-md-12">
			                      		<div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">{this.props.translate['logo_interna']}</h6>
				                      		<Row className="section_file" name="internal_logo" n="2">
					                      		<Col md={12} style={{padding:'34px'}}>
						                      		<Row>
						                      			<Col md="4">
						                      				<center><img alt="..."  style={{margin:"50px",maxHeight:'60px',position:"relative"}} className="imgup img-responsive" src={sessionStorage.getItem('backend')+window.app.state.confs.internal_logo}/></center>
						                      			</Col>
						                      			<Col md="8">
						                      				{
						                      					(this.state.fileInput[2].progress === "") &&
						                      					<div style={{display:"flex"}}>
								                      				<div className="custom-file form-control-sm">
												                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_2');this.uploadImg('internal_logo'); } } className="custom-file-input" id="custom_file_1" type="file" />
												                        <label className="custom-file-label " htmlFor="custom_file_2">{this.props.translate['selecionar_arquivo']}</label>
											                      	</div>
										                      		<Button onClick={ () => this.uploadImg('internal_logo') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> {this.props.translate['enviar']}</Button>
								                          		</div>
						                      				}
							                          		{
							                          			(this.state.fileInput[2].erro !== "") &&
							                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[2].erro}</h6>
							                          		}
							                          		{
							                          			(this.state.fileInput[2].progress !== "") &&
							                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
														          <div className="progress-info">
														            <div className="progress-label"><span>{this.props.translate['enviando_arquivo']}</span></div>
														            <div className="progress-percentage"><span>{this.state.fileInput[2].progress}%</span></div>
														          </div>
														          <Progress max="100" value={this.state.fileInput[2].progress} color="default" />
														        </div>
							                          		}
							                          	</Col>
						                          	</Row>
				                          		</Col>
				                      		</Row>
			                      		</div>
						            </div>
						            <div className="col-md-12">
			                      		<div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">{this.props.translate['favicon']}</h6>
				                      		<Row className="section_file" name="favicon" n="3">
					                      		<Col md={12} style={{padding:'34px'}}>
						                      		<Row>
						                      			<Col md="4">
						                      				<center><img alt="..." style={{margin:"50px",maxHeight:'60px',position:"relative"}} className="imgup img-responsive" src={sessionStorage.getItem('backend')+window.app.state.confs.favicon}/></center>
						                      			</Col>
						                      			<Col md="8">
						                      				{
						                      					(this.state.fileInput[3].progress === "") &&
						                      					<div style={{display:"flex"}}>
								                      				<div className="custom-file form-control-sm">
												                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_3');this.uploadImg('favicon'); } } className="custom-file-input" id="custom_file_1" type="file" />
												                        <label className="custom-file-label " htmlFor="custom_file_3">{this.props.translate['selecionar_arquivo']}</label>
											                      	</div>
										                      		<Button onClick={ () => this.uploadImg('favicon') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> {this.props.translate['enviar']}</Button>
								                          		</div>
						                      				}
							                          		{
							                          			(this.state.fileInput[3].erro !== "") &&
							                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[3].erro}</h6>
							                          		}
							                          		{
							                          			(this.state.fileInput[3].progress !== "") &&
							                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
														          <div className="progress-info">
														            <div className="progress-label"><span>{this.props.translate['enviando_arquivo']}</span></div>
														            <div className="progress-percentage"><span>{this.state.fileInput[3].progress}%</span></div>
														          </div>
														          <Progress max="100" value={this.state.fileInput[3].progress} color="default" />
														        </div>
							                          		}
							                          	</Col>
						                          	</Row>
				                          		</Col>
				                      		</Row>
			                      		</div>
						            </div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</React.Fragment>);

	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	unit: state.unit,
  
  }))(Appearance);