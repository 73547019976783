import React from "react";
import { connect } from 'react-redux';
 
class Text extends React.Component {

	constructor(props){

		super(props);

		window.txtClassRoom = this;

	}
	
	onEnded(){
 

	}

	render(){  

		var txt = this.props.state.lesson.html;

		try {
		
			txt = window.variableCourse(txt,this.props);

		}catch(error){

			console.log(error);
		
		}

		 

		return(<React.Fragment> 

			<div className="textViwerHtml" dangerouslySetInnerHTML={{__html:txt}} ></div>

		</React.Fragment>);

	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	magictools: state.magictools
  
}))(Text);