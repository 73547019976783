import React from "react";
import {Modal,Card,CardBody,Button,UncontrolledTooltip} from "reactstrap";

import { connect } from 'react-redux';
import api from "./../../../Api.js";
import reduxHelper from "./../../../helper/index.js";
import $ from "jquery";

class MagicTools extends React.Component {

	constructor(props){

		super(props);  
		this.state = {users:[],userPosition:[],fixaTela:false};
		this.getUsersClassRoom = this.getUsersClassRoom.bind(this);
		this.fixaTela = this.fixaTela.bind(this);
		this.puxaTodos = this.puxaTodos.bind(this);
		this.ativaFixador = this.ativaFixador.bind(this);
		this.lockPage = this.lockPage.bind(this);
		this.changeLockPage = this.changeLockPage.bind(this);
		this.helper = new reduxHelper(this.props);

		//Para receber as posições dos usuarios

		 

			window.socket.on('infoPosition', function (data){
					
				var userPosition = this.state.userPosition;
				if(userPosition[data.user] === undefined){

					userPosition[data.user] = {};
					userPosition[data.user].pathname = data.pathname;
					userPosition[data.user].lockPage = false;
					userPosition[data.user].lockPagePath = "";
					this.setState({userPosition:userPosition});

				}else{

					//Verificar se o location é diferentente para evitar ficar fazendo render 
					if(userPosition[data.user].pathname !== data.pathname){

						userPosition[data.user].pathname = data.pathname;
						this.setState({userPosition:userPosition});

					}

				}
				
			}.bind(this)); 
			
	 

		window.magictools = this;

	}
	
	//Para fixar todos os usuarios numa tela
	ativaFixador(e){

		console.log(e.target.checked);
		if(e.target.checked === true){

			this.props.dispatch({type:"ON_FIXA_TELA"})
			this.puxaTodos(1,true);

		}else{

			this.props.dispatch({type:"OFF_FIXA_TELA"})
			this.puxaTodos(0,true);
			setTimeout(function(){ this.puxaTodos(0); }.bind(this), 200);

		}


	}

	//Par mudar a pagina em que o usuario vai ficar travado
	changeLockPage(event,usuarioID){

		var userPosition = this.state.userPosition;
		userPosition[usuarioID].lockPagePath = event.target.value;
		this.setState({userPosition:userPosition});

		window.socket.emit('puxaUser',{user:usuarioID,pathname:'/myclassroom/'+this.props.classRom+'/'+this.props.unit+'/'+$("#select_lesson_magic_"+usuarioID).val(),blockTop:0});

	}

	//Para fixar numa tela especifica
	lockPage(chave,usuarioID){

		console.log('deu lock');
		var userPosition;
		
		if(chave.target.checked === true){

			var valueSelect = $("#select_lesson_magic_"+usuarioID).val();

			userPosition = this.state.userPosition;
			userPosition[ usuarioID ].lockPage = true;
			userPosition[ usuarioID ].lockPagePath = valueSelect;

			this.setState({userPosition:userPosition});

			window.socket.emit('puxaUser',{user:usuarioID,pathname:'/myclassroom/'+this.props.classRom+'/'+this.props.unit+'/'+$("#select_lesson_magic_"+usuarioID).val(),blockTop:1});

		}else{

			userPosition = this.state.userPosition;
			userPosition[ usuarioID ].lockPage = false;
			this.setState({userPosition:userPosition});

			window.socket.emit('puxaUser',{user:usuarioID,pathname:'/myclassroom/'+this.props.classRom+'/'+this.props.unit+'/'+$("#select_lesson_magic_"+usuarioID).val(),blockTop:0});

		}

	}

	onEnded(){}

	//Para pegar todos os alunos dessa turma
	async getUsersClassRoom(){
 
		await api.searchUserClassRoom(this.props.classRom).then((res) => {
	          

	        //deleta o meu usuario
	        var elementPos = res.data.map(function(x) {return x.id; }).indexOf(this.props.user.id);
	        res.data.splice(elementPos,1);

	        var userPosition = {}
	        for (var i = 0; i < res.data.length; i++) {
	        	userPosition[res.data[i].id] = {lockPage:false,pathname:""};
	        }
 
	        this.setState({userPosition:userPosition,users:res.data});

	  	}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response.status === 404){ this.props.history.push('/'); }

	  	});

	}

	componentDidMount(){
			
		window.magicTools = true;
		this.getUsersClassRoom();
		this.fixaTela();
		
	}

	componentWillUnmount(){

		window.magicTools = false;
		this.props.dispatch({type:"OFF_FIXA_TELA"});
		window.socket.off('infoPosition');

	}

	//Para puxar o usuario para a tela atual
	puxaUser(user){

		window.socket.emit('puxaUser',{user:user,pathname:window.location.pathname});

	}

	//Para manter todos na tela
	fixaTela(){

		console.log('fixaTela '+window.magicTools);
		if(window.magicTools === false){ return false; }
  
		//if(this.props.magictools.fixedTela === true){}else{}
		
//		console.log('fixaTela');

		var todos = this.props.magictools.fixedTela;
		this.puxaTodos(1,todos);
		
		setTimeout(function(){ this.fixaTela(); }.bind(this), 1500);

	}

	//Para puxar todos apenas uma vez
	puxaTodos(blockTop,todos){

		if(blockTop === 1){ blockTop = 1; }else{ blockTop = 0; }

		for(var i = 0; i < this.state.users.length; i++){

			//console.log('em5');

			if(parseInt(this.state.users[i].id) !== parseInt(this.props.user.id)){

				//Verifiva se o lockpag esta ativado
				//console.log('em4');

				if(this.state.userPosition[this.state.users[i].id].lockPage === true){

					//console.log('em1');
					window.socket.emit('puxaUser',{user:this.state.users[i].id,pathname:'/myclassroom/'+this.props.classRom+'/'+this.props.unit+'/'+this.state.userPosition[this.state.users[i].id].lockPagePath,blockTop:1});

				}else{

					//console.log('em3');

					if(todos === true){

						console.log('em2');
						window.socket.emit('puxaUser',{user:this.state.users[i].id,pathname:window.location.pathname,blockTop:blockTop});

					}
					
				
				}

									

			}

		}

	}

	render(){  

		//console.log(this.props.magictools); 
		
		return(
			<React.Fragment> 

				<div hidden className="menu-button"><i className="fas fa-hat-wizard"></i>
					<button><i className="fas fa-chart-pie"></i></button>
					<button onClick={ () => this.props.dispatch({type:"OPEN_MODAL_CONFS_CLASSROOM"}) } ><i class="fas fa-cogs"></i></button>
					<button onClick={ () => this.props.dispatch({type:"OPEN_MODAL_TODOS_TELA"}) }><i className="fas fa-magnet"></i></button>
				</div>

				{this.props.magictools.openModalTodosTela}
			{ /*this.props.magictools.openModalTodosTela */ }
				<React.Fragment>
					<Modal className="modal-dialog-centered" style={{maxWidth:'780px'}} isOpen={this.props.magictools.openModalTodosTela} >
			          <div className="modal-body p-0">
			            <Card className=" border-0 mb-0">
			            <div className="modal-header">
			            <h6 className="modal-title" id="modal-title-default"><i className="fas fa-magnet"></i> {this.props.translate['todos_na_tela']}</h6>
			            <button onClick={ () => this.props.dispatch({type:"CLOSE_MODAL_TODOS_TELA"}) } aria-label="Close" className="close" data-dismiss="modal" type="button" ><span aria-hidden={true}>×</span> </button>
			          </div>
			              <CardBody className="px-lg-5">
			               	<div>
								
								<center>
									<font style={{textAlingn:"center",fontSize:'15px'}} dangerouslySetInnerHTML={{__html: this.props.translate['descricao_puxar_todos']}} ></font>
								</center>

								<br/>

								<center id="puxatodos"><Button onClick={ () => this.puxaTodos(this.props.magictools.blockTop,true) } color="primary" type="button" size="sm"><i className="fas fa-magnet"></i> Puxar todos</Button></center>
								<UncontrolledTooltip delay={0} target={"puxatodos"} dangerouslySetInnerHTML={{__html: this.props.translate['direcionar_todos']}}></UncontrolledTooltip>

								<center>
						        <div style={{marginTop:'20px'}} id="trava_todo">
									<font style={{position:'relative',top:'-7px'}}>{this.props.translate['puxar_e_manter_todos']}</font>
									<label className="custom-toggle" style={{marginLeft:'5px'}}>

										{ 

											(this.props.magictools.fixedTela === true) &&
											<input checked type="checkbox" onChange={ (e) => this.ativaFixador(e) } />

										}

										{

											(this.props.magictools.fixedTela === false) &&
											<input type="checkbox" onChange={ (e) => this.ativaFixador(e) } />

										}

							          
							          <span className="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On" />
							        </label>
						        </div>

						        <UncontrolledTooltip delay={0} target={"trava_todo"}>{this.props.translate['todos_os_alunos_exceto_os_que']}</UncontrolledTooltip>
								
						        <div style={{inlineFlex:'flex',marginTop:'10px'}}>

						        	<font style={{position:'relative',top:'-7px'}}>{this.props.translate['navegar_par_lesson']}</font>	

						        	{

						        		(this.props.state.lesson !== undefined) &&
							        	<select value={this.props.state.lesson.id} onChange={ (event) => this.props.history.push('/myclassroom/'+this.props.classRom+'/'+this.props.unit+'/'+event.target.value) } className="form-control" style={{width:'300px',height:'25px',padding:'0px'}}>
							        		{

							        			(this.props.state.lessons !== undefined) &&
							        			<React.Fragment>
							        				{
							        					this.props.state.lessons.map((value2,index2) => {
							        																 
								        					return(<option key={value2.id} value={value2.id}>{value2.name}</option>)
							        				
							        					})
							        				}
							        			</React.Fragment>
							        			
							        		}
							        	</select>

						        	}

						        </div>

						        </center>
						        <div style={{marginTop:'10px'}}> 
							        <center>
							        	<h5>Legendas</h5>
							        	<br/>
								        <font>Offline:<img alt="..." src="/images/error_min.png" style={{height:'20px'}} />  </font>  

								        <font id={"nao_esta_pagina"} style={{marginRight:'5px',cursor:'help'}}>{this.props.translate['nao_esta_na_lesson']}<img alt="..." src="/images/traco.png" style={{height:'20px'}} /> </font>
										<UncontrolledTooltip delay={0} target={"nao_esta_pagina"}>{this.props.translate['o_estudante_nao_esta_na_mesma_lesson']}</UncontrolledTooltip>

								        <font id="esta_pagina" style={{cursor:'help'}}>{this.props.translate['esta_na_lesson']}<img alt="..." src="/images/correct_min.png" style={{height:'20px'}} /> </font> 
								        <UncontrolledTooltip delay={0} target={"esta_pagina"}>{this.props.translate['o_estudante_esta_na_lesson_que_voce_esta']}</UncontrolledTooltip>

								        <font id="esta_fixado" style={{marginRight:'5px',cursor:'help'}}>{this.props.translate['travado_na_lesson']}:<img alt="..." src="/images/fixed_icon.png" style={{height:'20px'}} /></font>
								        <UncontrolledTooltip delay={0} target={"esta_fixado"}>{this.props.translate['o_estudante_ficara_travado']}</UncontrolledTooltip>

							        </center>  
						        </div>
								<table className="align-items-center table-flush table" style={{marginTop:'10px'}}>
								   <thead className="thead-light">
								      <tr>
								         <th style={{padding:'0px 0px 0px 45px',height:'5px'}}>{this.props.translate['nome']}</th>
								         <th style={{padding:'0px 0px 0px 45px',height:'5px'}}><center>{this.props.translate['travar_na_lesson']}</center></th>
								         <th style={{paddingLeft:'65px',padding:'3px',height:'5px'}}><center>{this.props.translate['lesson']}</center></th>
								         <th style={{paddingLeft:'65px',padding:'3px',height:'5px'}}><center>{this.props.translate['status']}</center></th>
								         <th style={{paddingLeft:'65px',padding:'3px',height:'5px'}}><center>{this.props.translate['acoes']}</center></th>
								      </tr>
								   </thead>
								   <tbody>
							 
									{

										(this.state.users !== undefined) &&
										<React.Fragment>

											{

												this.state.users.map((value,index) => {

													var img = <img alt="..." src={"/images/error_min.png"} style={{height:'20px'}} />
													

														if(this.state.userPosition[value.id].pathname === window.location.pathname){

															img = <img alt="..." src="/images/correct_min.png" style={{height:'20px'}} />

														}else{

															img = <img alt="..." src="/images/traco.png" style={{height:'20px'}} />;

														}

														//Caso esteja disconectado
														if(this.helper.chat().isOnline(this.props.useronline,value.id) === false){ img = <img alt="..." src={"/images/error_min.png"} style={{height:'20px'}} />; }

														//Caso seja usuário bloqueado e fixado em um link
														if(this.state.userPosition[value.id].lockPage === true){ img = <img alt="..." src="/images/fixed_icon.png" style={{height:'20px'}} />; }

													

													//var selectDisabled;
													//if(this.state.userPosition[value.id].lockPage === true){ selectDisabled=false; }else{ selectDisabled=true; } 

													return(<tr key={value.id}>
														<td className="table-user" style={{height:'5px',padding:'7px'}}>
															<img alt="..." style={{height:'25px',width:'25px'}} className="avatar rounded-circle mr-3" src={value.photo} />
															<b><input readOnly style={{border:'none',fontWeight:'600',color:'#59636f'}} value={value.name+' '+value.lastName} /></b>
														</td>
														<td>
															<center>
																<label className="custom-toggle" style={{marginLeft:'40px'}}>

																	{

																		(this.state.userPosition[ value.id ].lockPage === false) &&
																		<input type="checkbox" onClick={ (e) => this.lockPage(e,value.id) } />

																	}

																	{

																		(this.state.userPosition[ value.id ].lockPage === true) &&
																		<input checked type="checkbox" onClick={ (e) => this.lockPage(e,value.id) } />

																	}

																	
																	<span className="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On" />
																</label>
															</center>
														</td>

														<td>
															<select id={'select_lesson_magic_'+value.id} onChange={ (e) => this.changeLockPage(e,value.id) } className="form-control" style={{padding:'0px',height:'24px'}}>
																{

																	(this.props.state.lessons !== undefined) &&
																	<React.Fragment>

																		{

																			this.props.state.lessons.map((value2,index2) => {
																			
																				var path = "";
																				var sl;
																				path = "/myclassroom/"+this.props.classRom+"/"+value2.unit+"/"+value2.id;

																				//verifica o pacth
																				if(this.state.userPosition[ value.id ].pathname === path){ sl = true; }else{ sl = false; }

																				//caso esteja com pach fixo
																				if(this.state.userPosition[ value.id ].lockPage === true){

																					if(parseInt(this.state.userPosition[ value.id ].lockPagePath) === parseInt(value2.id)){ sl = true; }else{ sl = false; }

																				}

																				return(<option selected={sl} key={value2.id} value={value2.id}>{value2.name}</option>)

																			})

																		}

																	</React.Fragment>
																	
																}
															</select>
														</td>


														<td style={{height:'5px',padding:'7px'}}><center><span className="text-muted">{img}</span></center></td>
														<td className="table-actions" style={{height:'5px',padding:'7px'}}>
															<center><i onClick={ () => this.puxaUser(value.id)} style={{cursor:'pointer'}} className="fas fa-magnet"></i></center>
														</td>
													</tr>)

												})

											}

										</React.Fragment>

									} 
									
								   </tbody>
								</table>

						        {

						        	(this.state.users.length === 0) &&
						        	<center><h5 style={{marginTop:'15px'}}>{this.props.translate['nenhum_usuario_foi_encontrado']}</h5></center>

						        }


			               	</div>

			              </CardBody>
			            </Card>
			          </div>
			        </Modal>


					{
						//openModalConfsClassroom
					}

					<Modal className="modal-dialog-centered" isOpen={this.props.magictools.openModalConfsClassroom} >
						<div className="modal-body p-0">
							<Card className=" border-0 mb-0">
								<div className="modal-header">
									<h6 className="modal-title" id="modal-title-default"><i class="fas fa-cogs"></i> Configurações da turma</h6>
									<button onClick={ () => this.props.dispatch({type:"CLOSE_MODAL_CONFS_CLASSROOM"}) } aria-label="Close" className="close" data-dismiss="modal" type="button" ><span aria-hidden={true}>×</span> </button>
								</div>
								<CardBody className="px-lg-5">

									<div>

										<div className="div_custom_classroom">
											<div className="div_cinclss">
												<label className="custom-toggle">
													<input  onChange={ (e) => {

														if(e.target.checked === true){

															console.log('inseriu');
															this.props.insertOption('1',"hidden_chat");

														}else{

															console.log('removu');
															this.props.deleteOption('1',"hidden_chat");

														}
														


													}} checked={((this.props.classroom_option.map(function(x){ return x.name; }).indexOf('hidden_chat') === -1) ? false : true  )} defaultChecked type="checkbox" id="dbb" />
													<span className="custom-toggle-slider rounded-circle" />
												</label>
											</div>
											<div className="frsc" for="dbb">Desativar chat</div>  
										</div> 

									</div>

								</CardBody>
							</Card>
						</div>
					</Modal>

		        </React.Fragment>






			</React.Fragment>);

	}

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  magictools: state.magictools,
  useronline: state.useronline,
  classroom_option: state.classroom_option,

}))(MagicTools);