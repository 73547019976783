import React from 'react';
import PropTypes from 'prop-types';
import QuestionList from './QuestionList';

const Quiz = ({ translate,step, questions, totalQuestions, score, handleAnswerClick, handleEnterPress,topState }) => {
   

  return (
    <div className="wrapper wrapperQuiz">
      <header>
        <div className="question-count">
          <h2>{translate.pontuacao}</h2>
          <div className="question-number">{step}</div>
          <div className="description">{translate.de} <span>{totalQuestions}</span></div>
        </div>
        <h1 hidden>{topState.quiz.quizTitle}</h1>
        <div className="score-container">
          <h2>{translate.pontuacao}</h2>
          <div className="score">{score}</div>
          <div className="description">{translate.pontos}</div>
        </div>
      </header>

      <div className="questions">
        <QuestionList step={step} questions={questions} handleAnswerClick={handleAnswerClick} handleEnterPress={handleEnterPress} />
      </div>
    </div>
  );

}

Quiz.propTypes = {
  step: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
  handleEnterPress: PropTypes.func.isRequired,
};

export default Quiz;
