import React from "react";
import { connect } from 'react-redux';

// nodejs library that concatenates classes
import $ from 'jquery';
import api from './../../../Api.js';
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {UncontrolledAlert,Button,CardHeader,Form} from "reactstrap";
 
import QrReader from 'react-qr-reader';

class Login extends React.Component {

  constructor(props){
      
    super(props);
    this.state = {erroClassRoom:"",users:[],email:"",password:"",erroResetcode:"",showForm:true,loginErro:"",logando:false,qrcode:false,user:false,erroQRcode:"",esqueciSenha:false,sendResquestEmail:false};
    this.handleQrScan = this.handleQrScan.bind(this);
    this.getClassRoomByIDonline = this.getClassRoomByIDonline.bind(this);

    this.setAccess = this.setAccess.bind(this);
    this.reset = this.reset.bind(this);
    this.login = this.login.bind(this);

    window.loginClassRoom = this;

  }

  async login(event){

      event.preventDefault();
      this.setState({logando:true});
      api.login({email:this.state.dataUser.username,password:$("#password").val()}).then((res) => {

        this.props.loginSuccess(res.data.session,res.data.user);

        res.data.user.session = res.data.session
        this.props.dispatch({type:"SET_DATA_USER",data:res.data.user});
        this.props.history.push('/myclassroom/'+this.state.classroom.id); 

      }).catch(error => {

          //console.log(error.data);
          //console.log(error);
          //console.log(error.response);
          if(error.response.status === 400){ this.setState({loginErro:""}); this.setState({loginErro:error.response.data[0].msg}); }
          this.setState({logando:false});

      });

  }

  getClassRoomByIDonline(){

    api.getClassRoomAccessOnline(this.props.match.params.classroom).then((res) => {

        this.setState({users:res.data.users,classroom:res.data.classroom});
        console.log({users:res.data.users,classroom:res.data.classroom});

    }).catch(error => {  
        
        if(error.response.status === 400){
          console.log(error.response);
        }

        this.setState({erroClassRoom:"Não foi possível acessar a turma, verifique o link informado."})
         
    });

  }

  handleQrScan(password){

      if(password === null || this.state.logando === true){ return false; }

      this.setState({logando:true});

      api.loginQRcode({id:this.state.dataUser.id,password:password}).then((res) => {

        console.log(res.data);

        this.props.loginSuccess(res.data.session,res.data.user);
        this.props.dispatch({type:"SET_DATA_USER",data:res.data.user});
        
        this.props.history.push('/myclassroom/'+this.state.classroom.id); 
    
      }).catch(async error => {
        
          //console.log(error.data);
          //console.log(error);
          //console.log(error.response);
          if(error.response.status === 400){ 

            await this.setState({erroQRcode:""}); 
            await this.setState({erroQRcode:error.response.data.msg}); 
            //console.log(error.response.msg);

          }

          this.setState({logando:false});
      });

  }

  componentDidMount(){

    var url = window.location.pathname;
    url = url.split('/');
    if(url[1] === "recoverpassword" & url[2] !== undefined){

      console.log('esta tentando recuperar a senha');

      this.verifyCodeRecover(url[2]);
      this.setState({showForm:false});

    }else{

      this.setState({showForm:true});

    }

    this.getClassRoomByIDonline();
    
    window.$("#superBg").css('background','url('+sessionStorage.getItem('backend')+''+window.app.state.confs.background+')');
    window.$("#superBg").fadeIn(0);
    window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.17)');
    window.$("#superBg").css('background-size','cover');

  }

  componentWillUnmount(){

    window.$("#superBg").css('background','');
    window.$("#superBg").fadeOut(0);
    window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.60)');
    window.$("#superBg").css('background-size','cover');

  }

  setAccess(dataUser){

    this.setState({dataUser:dataUser});

  }

  reset(){

    this.setState({dataUser:undefined,typeAccess:undefined});

  }

  render() {

    var translate = this.props.translate;
    //console.log('deu render login');
    
    return (
      <React.Fragment>
      <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <div className=" ">
         
          <div className="loginRight" style={{width:"100%",display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#f7fafc !important',padding:'60px 60px 0px'}}>
        {
            (this.state.qrcode === false && this.state.esqueciSenha === false && this.state.showForm === true) &&
            <React.Fragment>
              <Form style={{width:"800px",padding:'35px',boxShadow:'0px 2px 5px -4px #000'}} className="pageLoginForm bg-secondary" role="form" type="POST" onSubmit={ (event) => this.login(event) } >
                {
                  (this.state.user !== false) &&
                  <div style={{height:'120px'}}>
                    <div className="justify-content-center row">
                      <div className="order-lg-2 col-lg-3">
                        <div className="card-profile-image">
                          <img alt="..." className="rounded-circle border-secondary" src={this.state.user.photo}/>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mb-4" style={{marginTop:'75px'}}><h3>{this.props.translate['bem_vindo']}! {this.state.user.name}</h3></div>
                  </div>
                }
                {
                  (this.state.erroClassRoom !== "") &&
                  <div className="container">
                    <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.erroClassRoom}</span></UncontrolledAlert>
                  </div>} 

                { (this.state.classroom !== undefined) &&

                <React.Fragment>

                <div className="text-center mb-4"><h2> {this.state.classroom.name} </h2><h5 style={{fontWeight:'400'}}> </h5></div>

                { (this.state.dataUser !== undefined) && 

                    <React.Fragment>
                        <div className="col-md-12" style={{marginTop:'35px'}} id="terceira_div">

                            <div className="" style={{marginLeft:'0px'}}>
                              <center>
                                  <div className="col-md-12" style={{textalign:'center'}}> 
                                      <img alt="..." style={{height:'120px',width:'120px'}} id="foto_user_entrar" src={this.state.dataUser.photo} />
                                  </div>
                                  <div className="col-md-12" style={{textalign:'center'}}>
                                      <font style={{marginTop:'15px'}}>
                                      <br/><font>{this.props.translate['ola']}, <strong id="nume_user">{this.state.dataUser.name} {this.state.dataUser.lastName}</strong> 
                                      
                                      {

                                       // (this.state.data.)

                                      }

                                      .</font> <br/>  
                                      
                                      </font>
                                  </div>
                              </center>
                            </div>
                            {
                              (this.state.typeAccess === undefined) &&
                              <React.Fragment>
                                <center>
                                  <font id="como_gostaria">{this.props.translate['como_gostaria_de_fazer_o_acesso']}</font>
                                </center>
                                <div className="col-4 mx-auto bts_escolhe_acesso" id="" style={{display:'flex',marginTop:'20px',paddingLeft:'25px'}}>
                                    <div onClick={ () => this.setState({typeAccess:"senha"}) } className="card card-checkboxes card-hover-effect c1" >
                                        <i className="fas fa-key" style={{fontSize:'20px'}}></i>
                                        <p>{this.props.translate['senha']}</p>
                                    </div>
                                    <div onClick={ () => this.setState({typeAccess:"qrcode"}) } className="card card-checkboxes card-hover-effect c1" >
                                        <i className="fas fa-qrcode" style={{fontSize:'20px'}}></i>
                                        <p>QR code</p>
                                    </div>
                                </div>
                                <center className="bts_escolhe_acesso">
                                  <font onClick={ () => this.reset() } style={{cursor:'pointer'}}><i className="fas fa-arrow-left"></i> {this.props.translate['voltar']}</font> 
                                </center>
                              </React.Fragment>

                            }
                            {

                            (this.state.typeAccess === "senha") &&
                            <React.Fragment>
                              
                              <center>


                              {
                                (this.state.loginErro !== "") &&
                                <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.loginErro}</span></UncontrolledAlert>
                              } 


                              <div style={{marginTop:'5px',marginBottom:'5px'}}>{this.props.translate['informe_sua_senha_abaixo']}</div>
                              <div style={{width:'260px',marginBottom:'10px'}}>
                                <div style={{}} class="input-group-merge input-group-alternative input-group">
                                  <div class="input-group-prepend"><span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span></div>
                                  <input placeholder="Senha" id="password" type="password" class="form-control" />
                                </div>
                              </div>

                              <button type="submit" class="btn btn-primary" style={{marginBottom:'20px',marginTop:'0px',width:'260px',marginRight:'0px'}}>Entrar <i class="fas fa-key"></i></button>

                              <center className="bts_escolhe_acesso">
                                <font onClick={ () => this.setState({erroQRcode:"",typeAccess:undefined}) } style={{cursor:'pointer'}}><i className="fas fa-arrow-left"></i> {this.props.translate['voltar']}</font> 
                              </center>
                              </center>

                            </React.Fragment>

                            }
                            {

                              (this.state.typeAccess === "qrcode") &&
                              <React.Fragment>
                                <center><font>{this.props.translate['mire_o_qr_code_do_seu_cartao_de_acesso_na_camera']}</font></center>
                                <form className="" style={{marginTop:'20px'}}>
                                  <div className="form-group">
                                    <center>
                                    <QrReader delay={100} onError={this.props.handleQrError} onScan={this.handleQrScan} showViewFinder={false} style={{boxShadow:'0px 2px 8px -4px #000', width: '128px' }} />
                                    </center>
                                  </div>
                                </form>

                                {

                                  (this.state.erroQRcode !== "") &&
                                  <center style={{fontSize:'15px',marginBottom:'10px',color:'red'}}><strong>{this.state.erroQRcode} <br/></strong></center>

                                }
                                
                                <center className="bts_escolhe_acesso">
                                  <font onClick={ () => this.setState({erroQRcode:"",typeAccess:undefined}) } style={{cursor:'pointer'}}><i className="fas fa-arrow-left"></i> {this.props.translate['voltar']}</font> 
                                </center>

                              </React.Fragment>

                            }
                          </div>
                    </React.Fragment>

                }

                { (this.state.dataUser === undefined) && 

                    <React.Fragment>

                        

                        {

                        (this.state.users.length === 0) &&
                        <font style={{fontSize:"15px"}}><center>{this.props.translate['nenhum_usuario_foi_encontrado']}</center></font>

                        }

                        {

                        (this.state.users.length !== 0) &&
                        <font style={{fontSize:"15px"}}><center>{this.props.translate['por_favor_escola_sua_conta_na_lista_abaixo']}</center></font>

                        }

                        <div className="text-center"></div>

                        {

                            this.state.users.map((value2,index2) => {

                                return(<div key={value2.id} onClick={ () => this.setAccess(value2) } className=" " style={{cursor:'pointer',position:'relative',display:'block'}}>
                                    
                                    <div className="row access_class_room_login">
                                        
                                        <img alt="..." src={value2.photo} style={{height:"50px",width:"50px"}} />
                                        <div className="name_login_qr_code_access">{value2.name+' '+value2.lastName}</div>

                                    </div>
                                    
                                    <Button  color="default" className="btn-sm buttton_access_class">{this.props.translate['entrar']} <i className="fas fa-sign-in-alt"></i></Button>
                                    
                                </div>);

                            })

                        }

                        

                    </React.Fragment>
                
                }


                </React.Fragment>

                }

                
                
                <CardHeader className="bg-transparent" style={{padding:'0rem 0rem',marginTop:'22px',paddingTop:'0px',marginBottom:'0px',paddingBottom:'0px'}}>
                  <div className="text-muted text-center mt-2 mb-3"><small>{translate['escolha_uma_linguagem']}</small></div>
                  <div className="btn-wrapper text-center">
                    {
                      this.props.languages.map((value,key)=>{
                        var img = "/flags/"+value.img;
                        return(
                          <Button key={key+'_language'} onClick={ () => window.app.getLanguageTexts(value.file) } style={{marginBottom:'15px'}} className="btn-neutral btn-icon" color="default" >
                            <span className="btn-inner--icon mr-1"><img alt="..." src={img} /></span>
                            <span className="btn-inner--text">{value.name}</span>
                          </Button>)
                      })
                    }
                  </div>
                </CardHeader>
              </Form>
            </React.Fragment>
          }
          </div>
        </div>
      </React.Fragment>);
  }
}

export default connect(state =>({

  languages: state.language,
  translate: state.translate

}))(Login);