import React from "react";
import {Card,CardBody,FormGroup,Form,Input} from "reactstrap";
import {InputGroupText,Alert,Modal,InputGroupAddon,InputGroup} from "reactstrap";
import InputMask from 'react-input-mask';
import api from "./../../../Api.js";
import { connect } from 'react-redux';
import classnames from "classnames"; 
import moment from "moment";

class ModalMountLesson extends React.Component {

	constructor(props){

        super(props);
        
        this.state = {dialog:{type:"0",lesson:""}};

        this.deleteCalendar = this.deleteCalendar.bind(this);
        this.save = this.save.bind(this);

		window.modalagendalesson = this;

    }
    
    //Para salvar o calendario
    async save(event){

        event.preventDefault();

        var obj = {};
         
        obj.description = "";
        obj.lesson = this.props.state.dialogAgendaLesson.lesson;
        obj.classroom = this.props.propsTop.match.params.classrom;
        
        obj.date = this.props.state.dialogAgendaLesson.date;
        obj.date = moment(obj.date,'DD/MM/YYYY').format('YYYY-MM-DD');

        obj.time = this.props.state.dialogAgendaLesson.time;
        obj.color = "bg-info";

        //Verifica se o calendario ja existe 
        var index  = this.props.propsTop.classroomState.calendar.map(function(x) {return x.lesson; }).indexOf(parseInt(obj.lesson));

        //Caso não existe cadastra
        if(index === -1){

            await api.postCalendar(obj).then((res) => {

                console.log(res.data);
                this.props.getCalendarClassroom();
                window.socket.emit('reloadClassRoomOption',{classroom:this.props.propsTop.match.params.classrom});

            }).catch(error => {

                console.log(error);
                if(error.response !== undefined){ 
                    
                    this.props.setState({erroAgendaLesson:error.response.data.msg});
                    console.log(error.response);

                }

            });

        }
        
        //Caso exista edita
        if(index !== -1){

            var data = this.props.propsTop.classroomState.calendar[index];
            delete obj.description;

            await api.putCalendar(data.id,obj).then((res) => {

                console.log(res.data);
                this.props.getCalendarClassroom();
                window.socket.emit('reloadClassRoomOption',{classroom:this.props.propsTop.match.params.classrom});

            }).catch(error => {

                console.log(error);
                if(error.response !== undefined){ 
                    
                    this.props.setState({erroAgendaLesson:error.response.data.msg});
                    console.log(error.response);

                }

            });

        }
         
    }

    //Para deletar o calendario
    async deleteCalendar(){

        var index  = this.props.propsTop.classroomState.calendar.map(function(x) {return x.lesson; }).indexOf(parseInt(this.props.state.dialogAgendaLesson.lesson));
        var data = this.props.propsTop.classroomState.calendar[index];

        await api.deleteCalendar(data.id).then((res) => {

            console.log(res.data);
            this.props.getCalendarClassroom();
        
        }).catch(error => {

            console.log(error);
            if(error.response !== undefined){ 
                
                this.props.setState({erroAgendaLesson:error.response.data.msg});
                console.log(error.response);

            }

        });


    }

	render(){

        const handleChange = name => event => {

            var dialogAgendaLesson = this.props.state.dialogAgendaLesson;
            dialogAgendaLesson[name] = event.target.value;
            this.props.setState({dialogAgendaLesson:dialogAgendaLesson});
            
        }
        
        var index  = this.props.propsTop.classroomState.calendar.map(function(x) {return x.lesson; }).indexOf(parseInt(this.props.state.dialogAgendaLesson.lesson));
        var bt;

        if(index === -1){ bt = <React.Fragment></React.Fragment>; }else{

            bt = <button onClick={ () => this.deleteCalendar() } className="my-4 btn btn-danger"><i className="far fa-save"></i> Remover agendamento</button>

        }

		return(
		<React.Fragment>
	        <Modal className="modal-dialog-centered" isOpen={this.props.state.openModalAgenda} >
	          <div className="modal-body p-0">
	            <Card className="bg-secondary border-0 mb-0">
	            <div className="modal-header">
	            <h6 className="modal-title" id="modal-title-default">Agendar atividade</h6>
	            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.props.setState({openModalAgenda:false})}><span aria-hidden={true}>×</span> </button>
	          </div>
	              <CardBody className="px-lg-5">
	                <Form role="form" type="post" onSubmit={ (event) => this.save(event) }>
                        
	                  {
	                    (this.props.state.erroAgendaLesson !== "") &&
	                    <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.props.state.erroAgendaLesson}</Alert>
	                  }  


                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedCourse})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                              <Input type="select" onChange={handleChange('lesson')} value={this.props.state.dialogAgendaLesson.lesson} onFocus={() => this.setState({focusedCourse:true}) } onBlur={() => this.setState({focusedCourse:false}) } >
                                <option value=""></option>
                                {

                                  this.props.state.unit.lessons.map((value,index) => {

                                    return(<option key={'arms_'+value.id} value={value.id}>{value.name}</option>)

                                  })

                                }
                              </Input>
                            </InputGroup>
                            </FormGroup>

                            
                            {

                                (this.props.state.dialogAgendaLesson.lesson !== "") &&
                                <React.Fragment>

                                    <div className="row">

                                        <div className="col-md-6">
                                            
                                            <h5>{this.props.translate['data']}:</h5>
                                            <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento})} >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-calendar-grid-58" /></InputGroupText></InputGroupAddon> 
                                                <InputMask onChange={handleChange('date')} value={ this.props.state.dialogAgendaLesson.date } className="form-control" mask="99/99/9999" maskChar=" " onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
                                            </InputGroup>
                                            </FormGroup>

                                        </div>
                                        <div className="col-md-6">

                                            <h5>{this.props.translate['hora']}:</h5>
                                            <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento2})} >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-calendar-grid-58" /></InputGroupText></InputGroupAddon> 
                                                <InputMask onChange={handleChange('time')} value={ this.props.state.dialogAgendaLesson.time } className="form-control" mask="99:99:99" maskChar=" " onFocus={() => this.setState({ focusedNascimento2: true }) } onBlur={() => this.setState({ focusedNascimento2: false }) }/>
                                            </InputGroup>
                                            </FormGroup>


                                        </div>

                                    </div>

                                </React.Fragment>

                            }
                            {

                                (this.props.state.dialogAgendaLesson.lesson !== "") &&
                                <center style={{display:'flex'}}>
                                    {bt}
                                    <button style={{margin:'0 auto'}} type="submit" className="my-4 btn btn-primary"><i className="far fa-save"></i> Salvar alteração</button>
                                </center>

                            }
	                </Form>
	              </CardBody>
	            </Card>
	          </div>
	        </Modal>
	        
        </React.Fragment>

		)

	}

}
 
export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    classroom_option: state.classroom_option,
  
  }))(ModalMountLesson);