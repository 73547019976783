import React from 'react';
import api from "./../../Api.js";

class Engine extends React.Component {

  	constructor(props){
    
        super(props);
        this.state = {windowWidth:0,windowHeight:0,link:""};
        window.engine = this;

    }
    
    componentDidMount(){

        window.addEventListener('resize', this.updateSize);
        this.getAccessLink();
        this.updateSize();

    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateSize);

    }
 

    updateSize = () => {
        
        this.setState({
            windowHeight: (window.innerHeight - 0),
            windowWidth: window.innerWidth
        });

        console.log('aa');

    }

    async getAccessLink(){

        await api.getAccessEngine().then((res) => {
	        
            var link = res.data.urlEngine;
            link = link+'?session='+res.data.session+'&user='+res.data.user.id;
            this.setState({link});
             
	  	}).catch(error => {

	        console.log(error.data);
	        console.log(error);
			console.log(error.response);
			

	  	});

    }

	render(){	
        
     	return(<React.Fragment>
            
            <div className="iframeEngine" style={{height:this.state.windowHeight+'px',width:"100%",backgroundColor:"#1b191b"}}></div>

            {
            
                (this.state.link !== "") &&
                <iframe className="iframeEngine" title="open engine" frameBorder="0" src={this.state.link} width="100%" height={this.state.windowHeight}> </iframe>

            }

        </React.Fragment>)
        
	}

}

export default Engine;