import React from "react";
import {Container,Row,Col,Card,CardHeader} from "reactstrap";
import { connect } from 'react-redux';
import api from "./../../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
 
class ListStudents extends React.Component {

    constructor(props){

        super(props);
        this.state = {loadUsers:false,users:[]};

        this.searchUserClassRoomAll = this.searchUserClassRoomAll.bind(this);

        window.listStudents = this;
    }

    componentDidMount(){

        this.searchUserClassRoomAll();

    }

    async searchUserClassRoomAll(){
   
   
        await api.searchUserClassRoom(this.props.classRoom,'').then((res) => {
           
           var u = res.data; 
           this.setState({loadUsers:true,users:u});
           //users:u
  
        }).catch(error => {
  
          console.log(error.data);
          console.log(error);
          console.log(error.response);
          //this.setState({loadUsers:false});
       
        });
  
   }
   
    render(){

        return(<React.Fragment>
            <Card  >
                <CardHeader>
					<Row>

                        <div className="col-md-12">

                            <table className="align-items-center table-flush table">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Nome</th>
                                        <th>Usuários</th>
                                        <th>E-mail</th>
                                        <th><center>Acesso bloqueado</center></th> 
                                        <th><center>QR code bloqueado</center></th>
                                        <th hidden="">Data de nascimento</th>
                                      
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {

                                        this.state.users.map((value,index) => {

                                            var accessLevel,b,c,key = "user_key_"+value.id;
                                            if(parseInt(value.blocked) === 1){ 

                                                b=<strong style={{color:"red"}}>{this.props.translate['sim']}</strong>; 

                                            }else{ 

                                                b=<strong style={{color:"green"}}>{this.props.translate['nao']}</strong>; 

                                            }

                                            if(parseInt(value.blockedQRcode) === 1){ 

                                                c=<strong style={{color:"red"}}>{this.props.translate['sim']}</strong>; 

                                            }else{ 

                                                c=<strong style={{color:"green"}}>{this.props.translate['nao']}</strong>; 

                                            }

                                            return(<tr>
                                                <td class="table-user"><img alt="..." class="avatar rounded-circle mr-3 photo_user" idc="0" src={value.photo} /><b>{value.name} {value.lastName}</b></td>
                                                <td>{value.username}</td>
                                                <td>{value.email}</td>
                                                <td><center>{b}</center></td>
                                                <td><center>{c}</center></td>
                                                <td><center>{moment(value.birthDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</center></td>
                                            
                                            </tr>)

                                        })

                                    }
                                </tbody>
                            </table>
                            
                            {

                                (this.state.loadUsers === false) &&
                                <React.Fragment>
                                    <div style={{width:"100%",marginTop:"25px"}}>
                                        <center> <ClipLoader size={50}  color={"#adb5bd"} loading={true} /></center>
                                    </div>
                                </React.Fragment>

                            }

                            {

                                (this.state.loadUsers === true && this.state.users.length === 0) &&
                                <React.Fragment>
                                    <center><div style={{marginTop:'10px',fontSize:'15px',fontWeight:'600'}}>Nenhum participante foi encontrado.</div></center>
                                </React.Fragment>

                            }
                            
                        </div>
                         
                    </Row>
                </CardHeader>
            </Card>
        </React.Fragment>)

    }


}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	unit: state.unit,
  
}))(ListStudents);