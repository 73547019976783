export default function Translate(state = {},action){

	if(action.type === "SET_DATA_TRANSLATE"){

		state = action.data;

	}

	return state;

}