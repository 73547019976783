import React from "react";
//import {Modal,Card,CardBody,Col,Button} from "reactstrap";
import Chart from 'react-apexcharts'
import reduxHelper from "./../../../helper/index.js";
import { connect } from 'react-redux';
//import moment from 'moment';
//import MultiSelect from "@khanacademy/react-multi-select";

 

class Desempenho_no_quiz extends React.Component { 

	constructor(props){

        super(props);
        
        this.helper = new reduxHelper(this.props);
        this.state = {

            series1:[],
            idUser:this.props.idUser
            
        };

        window.chartuserquiz = this;

    }
      
    async componentDidUpdate(){

        if(this.state.idUser !== this.props.idUser){

            await this.setState({idUser:this.props.idUser});
            this.getDataChart1();

        }

    }


    componentDidMount(){

        this.getDataChart1();

    }

    componentWillUnmount(){}

 
    chart1(){
            
        return {labels: [this.props.translate['acertou_na_primeira_chart'],this.props.translate['acertou_na_segunda_chart'],this.props.translate['acertou_na_terceira_chart'],this.props.translate['acertou_na_quarta_chart']],
            responsive:[{
                breakpoint:480,
                options:{
                    chart:{width:10},
                    legend:{position: 'bottom'}
                }
            }],
            legend:{horizontalAlign:'right'},
            chart: {
                toolbar: {
                  show: true, 
                },
            }
        };
        
    }

   

    async getDataChart1(){

        var data = await this.helper.chart().chartQuizperFormance(this.props.idUser);

        if(data !== false){

            if(data.status === 200){

                this.setState({series1:data.data,load:true});
                return data.data;

            }

        }

        return [];
        
    }


 
//<Chart options={this.chart1()} series={this.state.series1} type="pie" width='100%'/>
    

  	render(){

        console.log('this.state.series1');
        console.log(this.state.series1);
        
  		return(
        <React.Fragment>
            {

                (this.state.load === true) &&
                <Chart options={this.chart1()} series={this.state.series1} type="pie" width='100%'/>

            }
           
  		</React.Fragment>);

  	}


}


export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
}))(Desempenho_no_quiz);