export default function User(state = {},action){

	if(action.type === "SET_DATA_USER"){ state = action.data; }

	if(action.type === "OPEN_MODAL_TODOS_TELA"){ 
		
		//state.openModalTodosTela = true;
	
	}

	if(action.type === "CLOSE_MODAL_TODOS_TELA"){ state.openModalTodosTela = false; }

	if(action.type === "SET_DATA_SET_PREFERENCE_LANGUAGE"){ 

		state = JSON.parse(JSON.stringify(state));
		if(state.preference !== undefined){

			state.preference.language = action.data;

		}
		
	}

	if(action.type === "SET_DATA_SET_PREFERENCE_MODAL_BASIC_CHART_USER"){ 

		state = JSON.parse(JSON.stringify(state));
		if(state.preference !== undefined){

			state.preference.modalbasicchartuser = action.data;

		}
		
	}


	if(action.type === "SET_DATA_SET_PREFERENCE_COMPANY_SELECT"){ 

		state = JSON.parse(JSON.stringify(state));
		if(state.preference !== undefined){

			state.preference.selectcompany = action.data;

		}
		
	}

	return state;

}