import api from './../../Api.js';

function Chat_helper(props){
		
	this.props = props; 

}

Chat_helper.prototype.getUsersOnline = async function(user){

    //for (var i = 0; i < this.props.units.length; i++) {//}
    var req = await api.getUsersOnline().then(async (res) => {
        
        return {status:200,data:res.data};
       
      }).catch(error => {
  
        if(error.response.status === 400){
  
          return {status:400,data:error.response};
  
        }
  
        return false;
  
      });
  
    return req;
  
}

Chat_helper.prototype.isOnline = function(reduxUserOnline,user){
  
  //isOnline
  for(let index = 0; index < reduxUserOnline.length; index++) {
    
    if(parseInt(reduxUserOnline[index].id) === parseInt(user)){

      return reduxUserOnline[index];

    }
    
  }

  return false;

}


export default Chat_helper;