import React from 'react';
import PropTypes from 'prop-types';
 
const tally = arr => {
  return arr.map(item => {
    return item.tries;
  }).reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {});
};

 

const Results = ({ translate,userAnswers, score, restartQuiz }) => {

  const triesTotal = tally(userAnswers);
  const oneTry = triesTotal[1] && <div>{translate['acertou_na_primeira_chart']}: <strong>{triesTotal[1]}</strong></div>;
  const twoTries = triesTotal[2] && <div>{translate['acertou_na_segunda_chart']}: <strong>{triesTotal[2]}</strong></div>;
  const threeTries = triesTotal[3] && <div>{translate['acertou_na_terceira_chart']}: <strong>{triesTotal[3]}</strong></div>;
  const fourTries = triesTotal[4] && <div>{translate['acertou_na_quarta_chart']}: <strong>{triesTotal[4]}</strong></div>;
  
  return (
    <React.Fragment>
      <div className="results-container">
        <h2>{translate.resultado_do_quiz}</h2>
        <div>{translate.voce_respondeu_todas_as_perguntas}</div>
        {oneTry}
        {twoTries} 
        {threeTries}
        {fourTries}
        <div className="results-total">{translate.sua_pontuacao_total_e_de} <strong>{score}</strong> {translate.pontos}.</div>
          
          <center> 
            <div>
            
                <button style={{marginTop:'22px'}} onClick={ restartQuiz } type="button" className="btn btn-secondary btn-md"  >

                  <i className="fas fa-redo"></i> Refazer quiz

                </button>
                <br/>

                <font style={{fontSize:'16px',fontWeight:'200'}}>Caso queira fazer, a nota não será salva.</font>

            </div>
          </center>


      </div>
    </React.Fragment>
  );
}

Results.propTypes = {
  userAnswers: PropTypes.array.isRequired,
  score: PropTypes.number.isRequired,
  restartQuiz: PropTypes.func.isRequired
};

export default Results;