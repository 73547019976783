import React from "react";
import { connect } from 'react-redux';
import $ from "jquery";
//import ScrollContainer from 'react-indiana-drag-scroll'
import reduxHelper from "./../../../helper/index.js";
import moment from "moment";
//import ApexCharts from 'apexcharts';
//import Chart from 'react-apexcharts';
import {Link} from "react-router-dom";
import {ClipLoader} from 'react-spinners';
// reactstrap components
import {Button,Card,CardHeader,CardBody,CardImg,CardTitle,Container,Row,Col,CardText,UncontrolledTooltip} from "reactstrap";

import DesempenhoNoQuiz from './../chartlist/Desempenho_quiz.jsx';
import TempoInvestido from './../chartlist/Tempo_investido.jsx';

class Student extends React.Component {

  constructor(props){

    super(props);
    
    this.checkTimeCalendar = this.checkTimeCalendar;
    this.state = {calendarIndex:null,calendar:[], calendarDetails:{name:"",description:"",icon:"",number:""},totalTimeAll:'00:00:00'};

    this.helper = new reduxHelper(this.props);
    this.detailsEvent = this.detailsEvent.bind(this);

		window.student_dashboard = this;

  }

  //Para pegar o tempo investido total

  async getTotalTime(){

    var time = await this.helper.chart().getTotalTimeUser(this.props.user.id);
    if(time.status === 200){

      this.setState({totalTimeAll:time.data});

    }
    console.log(time);

  }
  
  //Para mover o calendario para esquerda
  leftCalendarScrool(){

    var scrool = $(".calendarStudend").scrollLeft();
    scrool = scrool - 200;
    if(scrool < 0){ scrool = 0; }
    $(".calendarStudend").scrollLeft(scrool);

  }

  //Par mover o calendario para direita
  rightCalendarScrool(){

    var scrool = $(".calendarStudend").scrollLeft();
    scrool = scrool + 200;
    if(scrool < 0){ scrool = 0; }
    $(".calendarStudend").scrollLeft(scrool);

  }
  
  //Para mostrar os detalhe do evento do calendario
  detailsEvent(index){

    var event = this.state.calendar[index];
    var calendarDetails = {};

    calendarDetails = {name:event.lesson_data.name,description:event.lesson_data.description,icon:"",number:(index+1)};

    if(event.unit_data.icon_file !== undefined){

      calendarDetails.icon = sessionStorage.getItem('centerBackend')+event.unit_data.icon_file.url;

    }
    
     var a = {calendarDetails:calendarDetails,calendarDetailsComplete:event};

     return a;

  }

  //Para pegar todos os calendarios
  async getCalendar(){

    var events = await this.helper.calendar().getMyCalendar();
    if(events.status === 200){  await this.setState({calendar:events.data}); if(events.data.length !== 0){ this.detailsEvent(0); } }

  }
  
 

  checkTimeCalendar(){

      //console.log('tick');
      this.setState({a:'a'});

  }

  async componentDidMount(){

    await this.getCalendar();
    await this.getTotalTime();
    this.timerID = setInterval(() => this.checkTimeCalendar(),1000);

  }


  componentWillUnmount(){ clearInterval(this.timerID); }

  dragador(e,type){
    
    if(type === 'start'){

      window.startTouch = e.touches[0].clientX;
      window.scroolCalendar = $(".calendarStudend").scrollLeft();
      return false;

    }

    if(type === "move"){

      var calc = window.startTouch - e.touches[0].clientX;

    }

    $(".calendarStudend").scrollLeft(window.scroolCalendar+calc);
 
  }
 
  render(){

    var time = this.state.totalTimeAll;
    time = time.split(':');
  
    //Parar pegar o calendario
    if(this.state.calendarIndex !== null){

      var dataCalendar = this.detailsEvent(this.state.calendarIndex);
      
      var agenda = moment(dataCalendar.calendarDetailsComplete.date+' '+dataCalendar.calendarDetailsComplete.time,'YYYY-MM-DD HH:mm:ss');
      var agora = moment();
    
      var stb = {display:"none"};

      if(agenda <= agora){ 
      
        stb = {position:'absolute',left:'calc(100% - 160px)'};
        
      }else{


      }



    }else{

      if(this.state.calendar.length !== 0){ this.setState({calendarIndex:0}); }

    }

    return (
      <>

        <style>{".navbar-top{ background:#0000 !important; } .navbar-top{ position: absolute; width: 100%; z-index:2; } "}</style>

        <div className="header d-flex align-items-center"
          style={{ minHeight: "390px", backgroundImage: 'url("/images/background.jpg")', backgroundSize: "cover"    }} >
          <span className="mask opacity-8" style={{background:'linear-gradient(-45deg,'+window.app.state.confs.headerDegrade1+','+window.app.state.confs.headerDegrade2+')'}} />
          <Container className="d-flex align-items-center" fluid>
              <Row style={{width:'100%'}}>
                <Col lg="4">
                  <h1 className="display-2 text-white" style={{fontSize:'26px'}}>{this.props.translate['ola']} {this.props.user.name}</h1>
                  <p className="text-white mt-0">
                      {this.props.translate['este_e_seu_painel_de_controle']}
                      <br/>
                      {this.props.translate['logo_abaixo_você_encontra']}
                  </p>
                </Col> 
                <Col lg="8" style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                  <Card className="bg-gradient-success border-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle className="text-uppercase text-muted mb-0 text-white" tag="h5" >
                          {this.props.translate['meus_projetos_na_game_engine']}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 text-white">0</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="fas fa-gamepad"></i>
                          </div>
                        </Col>
                      </Row>
                      <p className="mb-0 text-sm" style={{marginTop:'10px',marginBottom:'0px'}}>
                        <span className="text-white mr-2">
                          <Button onClick={ () => this.props.history().push('/engine') } color="secondary" size="sm" type="button">{this.props.translate['acessar_engine']}</Button>
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                  <Card className="bg-gradient-danger border-0" tag="h5" style={{marginLeft:'10px'}}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle className="text-uppercase text-muted mb-0 text-white">
                          {this.props.translate['tempo_investido']}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 text-white">
                           {time[0]} {this.props.translate['horas']} e {time[1]} {this.props.translate['minutos']}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                            <i className="ni ni-spaceship" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-white mr-2">
                          {
                              
                            (parseInt((time[0]/24).toFixed(0)) === 0 || parseInt((time[0]/24).toFixed(0)) === 1) &&
                            <font>{ this.props.translate['mais_de_x_dia_de_aulas'].replace("[number]", (time[0]/24).toFixed(0)) }</font>

                          }
                          {

                            (parseInt((time[0]/24).toFixed(0)) >= 1) &&
                            <font>{ this.props.translate['mais_de_x_dias_de_aulas'].replace("[number]", (time[0]/24).toFixed(0)) }</font>

                          }
                        </span> 
                      </p>
                    </CardBody>
                  </Card>
                </Col>
  
              </Row> 
          </Container>
        </div>
        
        <Container className="mt--6" fluid>

          <Row>
            <Col className="order-xl-2" xl="4">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">{this.props.translate['desempenho_no_quiz']}</h5>
                </CardHeader>
                <CardBody style={{padding:'5px'}}>
                <div id="chart_desempenho" style={{padding:'0px 0px 0px 40px'}}>
                    {

                      // (this.state.chart1 === true) &&
                      // <Chart options={this.state.options} series={this.state.series} type="pie" width='100%' height='350' />
                        //<Tempo_investido idUser={this.props.idUser}/>
                    }
                    
                    <DesempenhoNoQuiz idUser={this.props.user.id} />


                </div>
                </CardBody>
              </Card>
              <Card >
                <CardHeader><h5 className="h3 mb-0">{this.props.translate['tempo_investido']}</h5></CardHeader>
                <div id="tempo_investido" style={{padding:'5px'}}>
                 
                  <TempoInvestido idUser={this.props.user.id}/>

                </div>
              </Card>
            </Col>
            
            <Col className="order-xl-1" xl="8">
              <Row>

                <Col lg="12" onTouchMove={ (e) => this.dragador(e,'move') } onTouchStart={ (e) => this.dragador(e,'start') }>

                  <div onClick={ () => this.leftCalendarScrool() } className="divIconLeftCalendarDash">
                    <i className="fas fa-chevron-left"></i>
                  </div>

                  <div onClick={ () => this.rightCalendarScrool() } className="divIconRightCalendarDash">
                      <i className="fas fa-chevron-right"></i>
                  </div>

                  <div id="scroolLessons" className="calendarStudend" style={{backgroundColor:"#fff",width:'100%',borderRadius:'5px',height:"90px"}}>
                    {

                      this.state.calendar.map((value,index) => {

                        var agenda = moment(value.date+' '+value.time,'YYYY-MM-DD HH:mm:ss');
                        var agora = moment();
                        var img;
                        
                        if(agenda <= agora){ 
                        
                          img = "/images/unlocked.svg";
                          
                        }else{

                          img = "/images/locked.svg";

                        }

                        return(<div onMouseEnter={ () => { this.setState({calendarIndex:index}); } } key={'calendar_'+value.id} className="calendarStudendLesson hvr-grow">

                          <div className="divCenterCalendarStudent lesson"><center>Lição {(index+1)}</center></div>
                          <div className="divCenterCalendarStudent descr_lesson "><center>{value.lesson_data.name}</center></div>
                          <div className="divCenterCalendarStudent lock_desc_lesson"><center><img alt="..." className="imgDivCenterCalendar" src={img}/></center></div>

                        </div>)

                      })

                    }
                    {

                      (this.state.calendar.length === 0) &&
                      <div style={{width:'100%'}}>
                        <center><h3 style={{marginTop:'30px'}}>{this.props.translate['voce_nao_tem_nenhuma_atividade_agendada']}</h3></center>
                      </div>

                    }
                  </div>
                </Col>

                <Col lg="12">
                <div className="card">
                        <div className="border-0 card-header">
                            <h3 className="mb-0">{this.props.translate['minhas_turmas']}</h3>
                        </div>
                        <div className="table-responsive">
                            <table className="align-items-center table-flush table">
                                <thead className="thead-light">
                                    <tr>
                                        <th>{this.props.translate['nome']}</th>
                                        <th>{this.props.translate['curso']}</th> 
                                        <th><center>{this.props.translate['data_de_fechamento']}</center></th>
                                    </tr>
                                </thead>
                                <style>{' .th_coursedd:hover{  } '}</style>
                                <tbody>
                                    {

                                        window.admin.state.classrooms.map((value,index) => {

                                            var course = this.props.getCourseState(value.course);
                                            var img;

                                            if(course.icon_file !== undefined){

                                                img = sessionStorage.getItem('centerBackend')+course.icon_file.url;

                                            }else{ img = ""; }

                                            if(value.inclassroom === undefined){

                                                return <React.Fragment key={value.id}></React.Fragment>
                        
                                              }else{
                        
                                                if(value.inclassroom.type === "" || value.inclassroom.type === undefined){ return <React.Fragment key={value.id}></React.Fragment>; }
                        
                                              }
                                                
                                              
                                              


                                            var permits;

                                            //pode ver todas as turmas
                                            if(this.props.permission.indexOf(19) === -1){

                                                if(value.inclassroom === undefined){
                                                
                                                console.log('ud1');
                                                return <React.Fragment key={value.id}></React.Fragment>

                                                }else{

                                                console.log('ud1');
                                                if(value.inclassroom.type === "" || value.inclassroom.type === undefined){ return <React.Fragment key={value.id}></React.Fragment>; }

                                                }
                                                
                                                var perm = window.app.state.permitions.map(function(x) {return x.id; }).indexOf(value.inclassroom.type);
                                                if(perm === -1){ console.log('ud99'); return <React.Fragment key={value.id}></React.Fragment>; }

                                                permits = window.app.state.permitions[perm].permissions; 
                                            
                                            }else{

                                                permits = this.props.permission;

                                            }

                                            return(
                                            <tr className="th_coursedd hvr-fade" style={{cursor:'pointer',display:'table-row'}} onClick={() => window.admin.props.history.push('/myclassroom/'+value.id) } key={'arm_'+value.id}>
                                                <th scope="row">
                                                    <div className="align-items-center media">
                                                        <div style={{display:'inline-flex',alignItems:'center',justifyContent:'center',fontSize:'1rem',borderRadius:'0.375rem',height:'48px',width:'48px'}}>
                                                          <img style={{height:'48px',width:'48px'}} alt="..." src={img} />
                                                        </div>
                                                        <div className="media">
                                                          <span className="name mb-0 text-sm" style={{marginLeft:"5px"}}>{value.name}</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="budget">{course.name}</td>
                                                <td>
                                                  <center>
                                                    <div>{ moment(value.closingDate,'YYYY-MM-DD').format('DD/MM/YYYY') }</div>
                                                    {

                                                      (moment().isAfter(value.closingDate, 'year')) &&
                                                      <React.Fragment><div style={{color:'red'}}>(fechada)</div></React.Fragment>

                                                    }
                                                  </center>
                                                </td> 
                                            </tr>)

                                        })

                                    }
                                     
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
                
                { (this.state.calendarIndex !== null) &&
                <Col lg="12">
                  <Card style={{height:'185px'}}>
                    <Col lg="12">
                      <Row>
                          <Col lg="3" md="3" style={{height:'185px'}}>  
                            <center style={{}}>
                              <img alt="..." className="imgCenterCalendarA1 img-fluid" style={{maxHeight:'100px'}} src={dataCalendar.calendarDetails.icon} />
                            </center>
                          </Col>
                          <Col lg="9" md="9" style={{height:'185px'}}>
                            <div className="progress-wrapper">
                              <div className="progress-info">
                                <div className="progress-label"><span style={{fontSize:'10px',fontWeight:'700'}}>Aula {dataCalendar.calendarDetails.number}</span></div>
                              </div>
                            </div>
                            <h2>{dataCalendar.calendarDetails.name}</h2>
                            <div style={{height:'45px'}}>
                              <h5>{dataCalendar.calendarDetails.description}</h5>
                            </div>
                            <button hidden type="button" className="btn btn-secondary btn-sm"><i className="fas fa-list-ul"></i> Plano de aula</button>
                            <button hidden type="button" className="btn btn-secondary btn-sm"><i className="far fa-hand-point-right"></i> Acessar aula</button>
                            <div style={{display:'flex'}}>
                              <div>
                                <font>Data da aula: <strong>{moment(dataCalendar.calendarDetailsComplete.date,'YYYY-MM-DD').format('DD/MM/YYYY')}</strong>  </font> 
                                <br/>
                                <font>Horario da aula: <strong>{moment(dataCalendar.calendarDetailsComplete.time,'HH:mm:ss').format('HH:mm')}</strong></font>
                              </div>
                              <div id="enterClassRoom" style={stb}>
                                <Button onClick={ () => this.props.history().push('/myclassroom/'+dataCalendar.calendarDetailsComplete.classroom+'?redirectLesson='+dataCalendar.calendarDetailsComplete.lesson+'') } color="primary" size="sm" type="button"><i className="far fa-hand-pointer"></i> Acessar atividade</Button>
                              </div>
                            </div>
                          </Col>
                      </Row>
                    </Col>
                  </Card>
                </Col>

                }

                 
                <Col lg="12" hidden>
                  <Card >
                    <CardHeader>
                        <h5 className="h3 mb-0">Meu desempenho nas Units</h5>
                    </CardHeader>
                    <CardBody id="chart_student_nota" style={{padding:'0px'}}>
                      
                    </CardBody>
                    </Card>
                  </Col>
                <Card hidden>
                    <Col lg="12">
                        <center> <h1 style={{marginTop:'20px',marginBottom:'20px',fontWeight:'400'}}>Turmas que você participa</h1> </center>
                        {           
                            this.props.getMainState().classrooms.map((value,index) => {

                                var dataCourse = this.props.getCourseState(value.course);
                                var capa = "";
                                if(dataCourse === false){ return <React.Fragment></React.Fragment> }
                                if(dataCourse.cover_file === undefined){ 

                                    capa = '';

                                }else{

                                    capa = sessionStorage.getItem('centerBackend')+dataCourse.cover_file.url
                                        
                                }

                                return(<React.Fragment key={value.id} >

                                        <Col onClick={ () => this.props.history().push('/myclassroom/'+value.id) } md="4" className="hvr-grow" style={{cursor:'pointer'}}>
                                            <Card>
                                                <CardImg alt="..." src={capa} top />
                                                <CardBody>
                                                <CardTitle>{dataCourse.name}</CardTitle>
                                                <CardText>{dataCourse.description}</CardText>
                                                <CardText><small className="text-muted">{value.name}</small></CardText>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </React.Fragment>)

                            })

                        }
                    </Col>
                </Card>
              </Row>
              <Card>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    unit: state.unit,
  
  }))(Student);