import React from "react";
import moment from "moment";
import { connect } from 'react-redux';
import {ClipLoader} from 'react-spinners';
//import $ from 'jquery';

//import {UncontrolledTooltip} from "reactstrap";
//import {} from "reactstrap";
import ScrollContainer from 'react-indiana-drag-scroll';

class Minimal extends React.Component {

	constructor(props){

		super(props);
		this.altDefault = 55;
		this.altMultiplayer = 26;

		this.state = {
			colors:{
				cinzaClaro:'whitesmoke',
				verdeEscuro:'#4cad00',
				vermelho:'red',
				preto:'#000',
				quiz100:window.colorQuiz.a,
				quiz75:window.colorQuiz.b,
				quiz45:window.colorQuiz.c,
				quiz44:window.colorQuiz.d,
			}
		}

		/*
			CINZA CLARO = PROGRESSO ZERADO
			BRANCO BORDA PRETA = SEM CONTEUDO
			PRETO = PROGRESSO ZERADO E CONTEUDO ATRASADO
			VERMELHO = ABAIXO DO TEMPO ESTIMADO

			QUIZ = ACERTOU DE PRIMEIRA 100% = VERDE ESCURO
			QUIZ = ACERTOU DE SEGUNDA 75% = VERDE MEDIO
			QUIZ = ACERTOU DE TERCEIRA 45% = VERDE CLARO
			QUIZ = ACERTOU DE QUARTA  ABAIXO 45% = LARANJA

			AZUL = ESTA NO CONTEUDO
		*/

	}

	async componentDidMount(){
		
		//window.$('#over_mouse_t').unbind();
		//setTimeout(function(){ 

			//window.$('#over_mouse_t').dragscrollable({dragSelector:'div',acceptPropagatedEvent:false});

		//}, 100);

	 

	}

	//Para somar todos os times
	sumAllTime(times){

		var total = 0;

		for(var i = 0; i < times.length; i++) {

			var hms = times[i].time;   // your input string
			var a = hms.split(':'); // split it at the colons

			// minutes are worth 60 seconds. Hours are worth 60 minutes.
			var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);  
			total += seconds;

		}
		
		var t = moment('00:00:00', 'HH:mm:ss').add(total, 'seconds').format('HH:mm:ss');
		return t;

	}

	componentDidUpdate(){

		
  

	}

	render(){
  
		return(<React.Fragment>
			  <div className="row">
			  	{

					(this.props.state.loadProgres !== true) &&
					<React.Fragment>
						<div className="col-md-12" style={{marginTop:"40px",marginBottom:"30px"}}>
							<center><ClipLoader sizeUnit={"px"} size={60} color={'#adb5bd'} loading={true} /></center>
						</div>
					</React.Fragment>

				}
	            <div className="col-md-3 col-xs-4 col-sm-4" style={{boxShadow:'8px 0px 14px -20px #000',paddingRight:'0px'}}>
	         	{

	            	(this.props.state.loadProgres === true) &&
	            	<React.Fragment>

	            		<div className="dashboard_class_user" style={{height:'150px'}}>

	            			<div style={{position:'relative',top:'-20px',height:'0px'}}><center>{this.props.translate['leganda']}:</center></div> 
	            	 
		            			<div className="legendaRelatorioTurma" style={{paddingTop:'10px'}}>
 
		            					<div className="legenda_minimal_description">
		            					<img alt="..." id="tot1" className="lengd" style={{height:'20px'}} src="/images/point.png" />
										<font delay={0} target={"tot1"}>{this.props.translate['esta_na_lesson']}</font>
										</div>

										<div className="legenda_minimal_description">
			            				<div id="tot2" className="lengd" style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:this.state.colors.cinzaClaro,boxShadow:'0px 1px 4px -2px #000'}}></div>
			            				<font delay={0} target={"tot2"}>{this.props.translate['sem_progresso']}</font>
			            				</div>

			            				<div className="legenda_minimal_description">
			            				<div id="tot3" className="lengd" style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:this.state.colors.preto,boxShadow:'0px 1px 4px -2px #000'}}></div>
										<font delay={0} target={"tot3"}>{this.props.translate['lesson_sem_conteudo']}</font>
										</div>

										<div className="legenda_minimal_description">
										<div id="tot4" className="lengd" style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:this.state.colors.vermelho,boxShadow:'0px 1px 4px -2px #000'}}></div>
		         						<font delay={0} target={"tot4"}>{this.props.translate['abaixo_do_tempo_esperado']}</font>
		         						</div>

		         						<div className="legenda_minimal_description">
			         					<div id="tot5" className="lengd" style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:this.state.colors.quiz100,boxShadow:'0px 1px 4px -2px #000'}}></div>
			         					<font delay={0} target={"tot5"}>{this.props.translate['acertou_100_quiz']}</font>
			         					</div>

			         					<div className="legenda_minimal_description">
			         					<div id="tot6" className="lengd" style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:this.state.colors.quiz75,boxShadow:'0px 1px 4px -2px #000'}}></div>
			         					<font delay={0} target={"tot6"}>{this.props.translate['acertou_entre_76_e_99']}</font>
			         					</div>

			         					<div className="legenda_minimal_description">
			         					<div id="tot7" className="lengd" style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:this.state.colors.quiz45,boxShadow:'0px 1px 4px -2px #000'}}></div>
			         					<font delay={0} target={"tot7"}>{this.props.translate['acertou_entre_46_e_99']}</font>
			         					</div>

			         					<div className="legenda_minimal_description">
			         					<div id="tot8" className="lengd" style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:this.state.colors.quiz44,boxShadow:'0px 1px 4px -2px #000'}}></div>
			         					<font delay={0} target={"tot8"}>{this.props.translate['acertou_ate_45_do_quiz']}</font>
			         					</div>
									

		            			</div> 
	            		

	            			


	            		</div>


	            			{
	            				this.props.state.progress.users.map((value,index) => {

	            					var tot = this.props.maxArrayReturn(value.id);
			    					//console.log(tot);

			    					var height = (tot * this.altMultiplayer);
			    					if(height === 0 || height < 55){ height = this.altDefault+height; }
			    					var sty = {height:height+'px'};

			    					if(this.props.state.openTimes[value.id] === true){ 

			    						sty = {height:height+'px'};

			    					}else{ 
			    						
			    						sty = {height:this.altDefault+'px'};

									}
									

								 
									if(value.userInClass === false){ sty.opacity = "0.5"; }

	            					return( <React.Fragment key={'d_'+index}> 
												<div className="dashboard_class_user dashboard_class_user_2" style={sty}>
													<div className="name_user">
														<div onClick={ () => this.props.openChartUser(value) } style={{cursor:'pointer',display:'flex'}} >
			            									<img alt="..." className="avatar rounded-circle mr-3" src={value.photo} /> 
			            									<input style={{width:'130px',cursor:'pointer'}} readOnly className="inputViwerName_classroom inputViwerName_classroom " value={''+value.name+' '+value.lastName} />
			        									</div>
		        									</div>
												</div>
	            							</React.Fragment>)

	            				})

	            			}
	            		
	            		
	            	</React.Fragment>

	            }
	            </div>

	            {

	            	(this.props.state.loadProgres === true) &&

	             
	            <div className="col-md-9 col-xs-8 col-sm-8" style={{paddingLeft:'0px'}}>
					 
	            	<ScrollContainer id="over_mouse_t" className="scroll-container" style={{overflow:'auto'}}>
					<div className="listLessonsTop" style={{borderRadius:'0px',boxShadow:'0px 11px 8px -16px #000',backgroundColor:"#fff",height:'150px',paddingTop:'90px',paddingLeft:'0px',marginBottom:'0px'}}>
		            	

		            	{
		            		this.props.state.progress.lessons.map((value,index) => {
 
								var left = 27;
								
							 

		            			return(
		            				<React.Fragment key={'a_'+index}>
		            					<div className="lessonTop lessonTop_minshadown txtLessonTop" style={{ left:left+'px'}}>
		            						<font>

		            							<i style={{transform:'rotate(52deg)',fontWeight:'600'}} className={'nameUnitMinDashBoardClassroom_icon '+window.iconsLesson[value.icon]}></i> 
		            							<div className="nameUnitMinDashBoardClassroom">{value.name}</div>

	            							</font>
		            					</div>
		            				</React.Fragment>)

		            		})
		            	}
		            </div>


		            {
	    				this.props.state.progress.users.map((value,index) => {

	    					var tot = this.props.maxArrayReturn(value.id);
	    					var sty;
	    					//console.log(tot);

	    					var height = (tot * this.altMultiplayer);
	    					if(height === 0 || height < 55){ height = this.altDefault+height; }


	    					if(this.props.state.openTimes[value.id] === true){ 

	    						sty = {height:height+'px'};

	    					}else{} 
	    						
	    						

	    					

							sty = {height:this.altDefault+'px','backgroundColor':"#fff",paddingBottom:'0px'};
	    					
	    					sty.borderTop = '1px solid #e9ecef';
	    					sty.paddingLeft = '0px';
	    					return(<React.Fragment key={'b_'+index}> 
										<div className="dashboard_class_userList_user" style={sty}>
											<React.Fragment>
												{

													this.props.state.progress.lessons.map((value2,index2) => {

														var cor = "whitesmoke";

														//verifico o tipo da lesson e se tem conteudo
														if(parseInt(value2.type) === 1){

															//if(value2.html.length < 20){ cor = "#000"; }
															//if(value2.html !== null){

																//if(value2.html.length < 20){ cor = this.state.colors.preto; }

															//}

															if(value2.html === null){ cor = this.state.colors.preto; }

														}

														
														//Verifico se o usuário tem progresso
														if(value.progress[value2.id].length > 0){

															cor = this.state.colors.verdeEscuro;

															//Verifico se o progresso tem o tempo esperado;

															//Verifico se a lesson é do tipo quiz, caso for.... poe a cor de acordo com a nota
															//console.log(value);
															//console.log('lesson type: '+value.progress[value2.id][0].type)
															 
															if(parseInt(value2.type) === 3){

																console.log('é do tipo quiz');
																var points = parseInt(value.progress[value2.id][0].quizPoints);

																if(points === 100){ cor = this.state.colors.quiz100; }
																if(points <= 99 && points >= 76){ cor = this.state.colors.quiz75; }
																if(points <= 75 && points >= 46){ cor = this.state.colors.quiz45; }
																if(points <= 45){ cor = this.state.colors.quiz44; }

															}

														}
														
														//QUIZ = ACERTOU DE PRIMEIRA 100% = VERDE ESCURO
														//QUIZ = ACERTOU DE SEGUNDA 75% = VERDE MEDIO
														//QUIZ = ACERTOU DE TERCEIRA 45% = VERDE CLARO
														//QUIZ = ACERTOU DE QUARTA  ABAIXO 45% = LARANJA
														


														//quiz100:'#0b7b0b',
														//quiz75:'#0ba70b',
														//quiz45:'#0cbd0c',
														//quiz44:'orange',

														return(
															<div key={'c_'+index2} className="listTime_dashboard_class_room listTime_dashboard_class_room_min" style={{width:'50px'}}>
																<div user={value.id} lesson={value2.id} className="rrasta animated" style={{cursor:'grab',padding:'15px 0px 0px 0px',height:'35px'}}>
																	<center>
																		{

																			/* verificar se oo usuario esta nessa posicao */
																			(this.props.state.userPosition[value.id] === "/myclassroom/"+this.props.state.classRoom.id+"/"+this.props.unit+"/"+value2.id) &&
																			<img alt="..." className="animated fadeInDown" src="/images/point.png" style={{position:'absolute',height:'27px',left:'11px',top:'2px'}}/>
																		
																		}
																		<div style={{width:'20px',height:'21px',borderRadius:'21px',backgroundColor:cor,boxShadow:'0px 1px 4px -2px #000'}}>
																			<i hidden style={{color:'#fff',marginTop:'5px',marginLeft:'1px'}}  className="far fa-clock"></i></div>
																	</center>
																</div>
															</div>)

													})

												} 
											</React.Fragment>
										</div>
	    							</React.Fragment>)
	    				})
	    			}
	            </ScrollContainer>
	            </div>
	            }
	        </div>
		</React.Fragment>);

	}

}

/*
	
	CINZA CLARO = PROGRESSO ZERADO
	BRANCO BORDA PRETA = SEM CONTEUDO
	PRETO = PROGRESSO ZERADO E CONTEUDO ATRASADO
	VERMELHO = ABAIXO DO TEMPO ESTIMADO


	QUIZ = ACERTOU DE PRIMEIRA 100% = VERDE ESCURO
	QUIZ = ACERTOU DE SEGUNDA 75% = VERDE MEDIO
	QUIZ = ACERTOU DE TERCEIRA 45% = VERDE CLARO
	QUIZ = ACERTOU DE QUARTA  ABAIXO 45% = LARANJA

	AZUL = ESTA NO CONTEUDO

*/

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
}))(Minimal);