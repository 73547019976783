export default function Language(state = [],action){

	if(action.type === "ADD_UNIT"){

		state = action.data;

	}

	return state;

}