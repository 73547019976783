export default function AccessLevel(state = [],action){

	if(action.type === "SET_LEVEL_ACCESS"){

		state = action.data;

	}

	//console.log(state);
	//console.log('teste');
	return state;

}