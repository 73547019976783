import React from "react";
import { Button,Card,CardHeader,CardBody,CardImg,CardTitle,FormGroup,Form,Input,ListGroupItem,ListGroup,Progress,Container,Row,Col} from "reactstrap";
import {InputGroupText,Alert,Modal,InputGroupAddon,InputGroup,Badge,CardFooter,DropdownMenu,DropdownItem,DropdownToggle,UncontrolledDropdown,Media,Pagination,PaginationItem,PaginationLink,UncontrolledTooltip} from "reactstrap";
import InputMask from 'react-input-mask';
import api from "./../../../Api.js";
 
import $ from 'jquery';
import { SortablePane, Pane } from 'react-sortable-pane';
import classnames from "classnames";
import { RingLoader } from 'react-spinners';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import moment from "moment";

//spinner
import { ClipLoader } from "react-spinners";


class ModalPresence extends React.Component {

	constructor(props){

		super(props);
		this.state = {

            lists:[],
            load:false,
            load2:false, 
            dialog:{},
            openRegisterModal:false,

        };

        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.getlists = this.getlists.bind(this);
        this.save = this.save.bind(this);
        this.setPresence = this.setPresence.bind(this);
        this.remove = this.remove.bind(this);

		window.modalPresence = this;

	}

    remove(){

        api.deleteListPresenca(this.state.dialog.id).then((response) => {

            this.setState({openDialogRemove:false,load2:false});
            this.getlists();
            window.swal.fire('Sucesso!','A lista foi removida.','success');

        }).catch((error) => {

            window.swal.fire('Ops!',error.response.data.msg,'error');

        });

    }

	post(){

        this.state.dialog.class_room = this.props.classroom;

        var obj = {};
        obj.class_room = this.props.classroom;
        obj.date = moment(this.state.dialog.date,'DD/MM/YYYY').format('YYYY-MM-DD');
        obj.unit = this.state.dialog.unit;

        api.postListPresenca(obj).then((response) => {

            this.setState({openRegisterModal:false,load2:false});
            this.getlists();
            window.swal.fire('Sucesso!','A lista foi cadastrada','success');

        }).catch((error) => {

            window.swal.fire('Ops!',error.response.data.msg,'error');

        });

    }

    put(){

        var obj = {};
        obj.class_room = this.props.classroom;
        obj.date = moment(this.state.dialog.date,'DD/MM/YYYY').format('YYYY-MM-DD');
        obj.unit = this.state.dialog.unit;

        api.putListPresenca(this.state.dialog.id,obj).then((response) => {

            this.setState({openRegisterModal:false,load2:false});
            this.getlists();
            window.swal.fire('Sucesso!','As alterações foram salvas.','success');

        }).catch((error) => {

            window.swal.fire('Ops!',error.response.data.msg,'error');

        });

    }

    getlists(){

        api.listPresencaClass(this.props.classroom).then((response) => {

            this.setState({lists:response.data,load:true});

        }).catch((error) => {});

    }

    save(e){

        e.preventDefault();

        if(this.state.dialog.id === undefined){

            this.post();

        }else{

            this.put();
        
        }

    }

    componentDidMount(){

        this.getlists();

    }

    setPresence(user,type,unit){

        api.setPresenca(this.state.dialog.id,user,type,unit).then((response) => {}).catch((error) => {

            window.swal.fire('Ops!',error.response.data.msg,'error');

        });

    }

	render(){

        if(Array.isArray(this.props.units) === false){

            this.props.units = [];

        }

        var showSaveAll = false;
        if(Array.isArray(this.state.dialog.students)){

            for(let index = 0; index < this.state.dialog.students.length; index++){

                if(this.state.dialog.students[index].nosave === true){

                    showSaveAll = true;
                    break;

                }

            }

        }
        

		return(
		<React.Fragment>
	        <Modal className="modal-dialog-centered" isOpen={true} style={{minWidth:'950px'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">Listas de presença</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.close()}><span aria-hidden={true}>×</span> </button>
                        </div>
                        <CardBody className=" ">
                            <table className="align-items-center table-flush table" id="my-table">
                                <thead class="thead-light">
                                    <tr> 
                                        <th>Data</th> 
                                        <th><center>Presenças</center></th>
                                        <th><center>Faltas</center></th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {

                                    this.state.lists.map((item, index) => {
 
                                        return <tr>
                                            <td>{moment(item.date,'YYYY-MM-DD').format('DD/MM/YYYY')}</td> 
                                            <td><center>{item.totalPresenca}</center></td>
                                            <td><center>{item.totalFalta}</center></td>
                                            <td>
                                                <button className="btn btn-sm btn-secondary" onClick={() => {
 
                                                    this.setState({openList:true,dialog:item});

                                                }}><i className="fas fa-list-ol"></i> Lista</button>
                                                <button onClick={() => {

                                                    var dd = JSON.parse(JSON.stringify(item));
                                                    dd.date = moment(item.date,'YYYY-MM-DD').format('DD/MM/YYYY');

                                                    this.setState({openRegisterModal:true,dialog:dd});

                                                }} className="btn btn-sm btn-secondary"><i className="fas fa-pencil-alt"></i> Editar</button>
                                                <button onClick={(e) => {

                                                    this.setState({openDialogRemove:true,dialog:item});

                                                }} className="btn btn-sm btn-danger"><i className="fas fa-trash"></i></button>
                                            </td>
                                        </tr>

                                    })

                                }
                                </tbody>
                                {

                                    (this.state.lists.length === 0 && this.state.load === true) && <tr><td colSpan="5"><center>Nenhuma lista cadastrada.</center></td></tr>

                                }
                            </table>
                            {

                                (this.state.load === false) && <center style={{marginTop:'20px'}}><ClipLoader color={'#000'} loading={true} /></center>

                            }

                            <center style={{marginTop:'20px'}}>
                                <button onClick={() => {

                                    this.setState({openRegisterModal:true,dialog:{date:moment().format('DD/MM/YYYY')}});

                                }} className="btn btn-sm btn-secondary"><i className="fas fa-plus"></i> Nova lista</button>
                            </center>
                        </CardBody>
                    </Card>
                </div>
	        </Modal>

        {

            (this.state.openRegisterModal === true) &&
            <Modal className="modal-dialog-centered" isOpen={true} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">Nova lista</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openRegisterModal:false}) }><span aria-hidden={true}>×</span> </button>
                        </div>
                        <CardBody className="px-lg-5">
                            <Form role="form" type="post" onSubmit={ (event) => this.save(event) }> 

                            <div class="col-md-12">
                                    <h5>Data da aula:</h5>
                                    <div class="mb-3 form-group">
                                        <div class="input-group-merge input-group-alternative input-group">
                                        <InputMask value={ this.state.dialog.date } className="form-control" mask="99/99/9999" maskChar=" "  onChange={(e) => { 

                                            this.setState({dialog:{...this.state.dialog,date:e.target.value}});
                                            
                                        }} /></div>
                                    </div>
                                </div> 
                                <center>
                                    <button style={{width:"100%"}} className="btn btn-lg btn-primary" type="submit">{this.state.dialog.id === undefined ? 'Cadastrar list':'Salvar alterações'}</button>
                                </center>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>

        }
	     
        {

            (this.state.openDialogRemove === true) &&
            <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemove} >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
                    <span aria-hidden={true} onClick={ () => this.setState({openDialogRemove:false}) }>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                    <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
                    <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
                    <p>Se você prosseguir, todos os dados da lista serão removidos</p>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button className="btn-white" color="default" type="button" onClick={ () => this.remove() }>Ok, Remova</Button>
                    <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemove:false}) }>
                    Cancelar e fechar
                    </Button>
                </div>
            </Modal>


        }

        {

            (this.state.openList === true) &&
            <Modal className="modal-dialog-centered" isOpen={true} style={{minWidth:'950px'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">Listas de presença da data {moment(this.state.dialog.date).format('DD/MM/YYYY') }</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => { this.getlists(); this.setState({openList:false}); }}><span aria-hidden={true}>×</span> </button>
                        </div>
                        <CardBody className=" ">
                            <table className="align-items-center table-flush table" id="my-table">
                                <thead class="thead-light">
                                    <tr> 
                                        <th>Estudante</th>
                                        <th style={{display:'flex',alignItems:'center'}}>
                                            <div>Unit</div>
                                            <div style={{marginLeft:'5px'}}>
                                                <select onChange={(e) => {

                                                    if(e.target.value !== ""){

                                                        for(let index = 0; index < this.state.dialog.students.length; index++){
                                                            
                                                            this.state.dialog.students[index].unit = e.target.value;
                                                            this.state.dialog.students[index].nosave = true;
                                                            
                                                        }
                                                        
                                                        this.setState({dialog:JSON.parse(JSON.stringify(this.state.dialog))}); 

                                                    }
                                                    

                                                }} className="form-control" style={{padding:'0px',height:"25px"}}>
                                                    <option value="">Mudar todos para unit</option>
                                                    {

                                                        this.props.units.map((unit) => {
                                                            
                                                            return <option value={unit.id}>{unit.name}</option>

                                                        })

                                                    }
                                                </select>
                                            </div>
                                        </th>
                                        <th>Status</th>
                                        <th>
                                            {

                                                (showSaveAll === true) &&
                                                <button onClick={() => {

                                                    for(let index = 0; index < this.state.dialog.students.length; index++){

                                                        if(this.state.dialog.students[index].nosave === true && this.state.dialog.students[index].unit !== undefined && this.state.dialog.students[index].unit !== null && (this.state.dialog.students[index].presence === "presente" || this.state.dialog.students[index].presence === "falta")){

                                                            this.state.dialog.students[index].nosave = false;
                                                            this.setPresence(this.state.dialog.students[index].id,this.state.dialog.students[index].presence,this.state.dialog.students[index].unit);

                                                        }

                                                    }

                                                    this.setState({dialog:JSON.parse(JSON.stringify(this.state.dialog))});

                                                }} class="btn btn-sm btn-primary"> <i class="fas fa-save"></i> Salvar todos</button>

                                            }
                                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {

                                    this.state.dialog.students.map((item, index) => {
                                        
                                        return <tr>
                                            <td>{item.name} {item.lastName}</td>
                                            <td>
                                                <select value={item.unit} onChange={(e) => {

                                                    item.unit = e.target.value;
                                                    item.nosave = true;
                                                    this.setState({dialog:JSON.parse(JSON.stringify(this.state.dialog))});

                                                }} className="form-control" style={{padding:'0px',height:"25px"}}>
                                                    <option value=""></option>
                                                    {

                                                        this.props.units.map((unit) => {

                                                            return <option value={unit.id}>{unit.name}</option>

                                                        })

                                                    }
                                                </select>
                                            </td>
                                            <td>
                                            {

                                                (item.presence === 'presente') && 
                                                <Badge style={{cursor:'pointer',userSelect:'none'}} color="success" onClick={() => {

                                                    item.presence = 'falta';
                                                    item.nosave = true;
                                                    //this.setPresence(item.id,'falta')
                                                    this.setState({dialog:JSON.parse(JSON.stringify(this.state.dialog))});

                                                }}>Presente</Badge> 

                                            }
                                            {

                                                (item.presence === 'falta') && 
                                                <Badge style={{cursor:'pointer',userSelect:'none'}} color="danger" onClick={() => {

                                                    item.presence = 'presente';
                                                    item.nosave = true;
                                                    //this.setPresence(item.id,'presente')
                                                    this.setState({dialog:JSON.parse(JSON.stringify(this.state.dialog))});

                                                }}>Falta</Badge> 

                                            }
                                            {

                                                (item.presence === null) && 
                                                <Badge style={{cursor:'pointer',userSelect:'none'}} color="default" onClick={() => {

                                                    item.presence = 'presente';
                                                    item.nosave = true;
                                                    //this.setPresence(item.id,'presente')
                                                    this.setState({dialog:JSON.parse(JSON.stringify(this.state.dialog))});

                                                }}>Não definido</Badge> 

                                            }
                                            </td>
                                            <td>
                                                {

                                                    (item.nosave === true && item.unit !== undefined && item.unit !== null && (item.presence === "presente" || item.presence === "falta")) && <button onClick={() => {

                                                        item.nosave = false;
                                                        this.setPresence(item.id,item.presence,item.unit);
                                                        this.setState({dialog:JSON.parse(JSON.stringify(this.state.dialog))});

                                                    }} className="btn btn-sm btn-primary" > <i className="fas fa-save"></i> Salvar</button>

                                                }
                                            </td>
                                        </tr>

                                    })

                                }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
	        </Modal>

        }
            
        </React.Fragment>

		)

	}

}

export default ModalPresence;