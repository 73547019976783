import React from "react";
//import moment from "moment";
//import $ from 'jquery';
import {Button} from "reactstrap";
//import ReactPlayer from 'react-player';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';

class Congratulations extends React.Component {

	constructor(props){

		super(props); 
		this.state = {};
		window.congratulations = this;

	}
 

	render(){  

		return(<React.Fragment> 
			<Confetti gravity={0.03} wind={0.01} initialVelocityX={4} initialVelocityY={0.6} numberOfPieces={20} width={this.props.state.width+'px'} height={this.props.state.height+'px'} />
			<div className="congratulations" style={{zIndex:"9999",position:"relative"}}>
				<center>
					<img alt="..." src="/images/trophy.png" />
					<h1>{this.props.translate['parabens']}!</h1>
					<font>{this.props.translate['voce_finalizou_essa_unit']}</font>
					<br/><br/>
					<Button onClick={ () => this.props.history.push('/myclassroom/'+this.props.classroom) } color="success" size="sm" type="button"><i class="fas fa-undo"></i> {this.props.translate['voltar_nas_units']}</Button>
				</center>
			</div>
			
		</React.Fragment>);

	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	magictools: state.magictools
  
  }))(Congratulations);