import api from './../../Api.js';

function ClassRom_helper(props){
		
	this.props = props; 

}

ClassRom_helper.prototype.listClassRoom = async function(user){

  //for (var i = 0; i < this.props.units.length; i++) {//}
  var req = await api.getAllClassRoom(user).then(async (res) => {
      
      return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

        return {status:400,data:error.response};

      }

      return false;

    });

  return req;

}
 
ClassRom_helper.prototype.classRoomCheckXlsx = async function(data){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.classRoomCheckXlsx(data).then(async (res) => {
    	
    	return {status:200,data:res.data};
       
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para pegar as turmas que eu tenho acesso
ClassRom_helper.prototype.getMyClassRooms = async function(){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getMyClassRooms().then(async (res) => {
    	
    	return {status:200,data:res.data};
       
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para verificar se a lesson esta disponível para acesso
ClassRom_helper.prototype.lessonIsAccessible = async function(classroom,lesson){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.lessonIsAccessible(classroom,lesson).then(async (res) => {
    	
    	return {status:200,data:res.data};
       
    }).catch(error => {

      console.log(error);
      
      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}



 

 
 

export default ClassRom_helper;