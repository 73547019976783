import React from 'react';
import { connect } from 'react-redux';
import api from "./../Api.js";

class Main extends React.Component {

  	constructor(props){

        super(props);
		window.userAPI = this;

  	}
 
 	componentDidMount(){}
    
    //Para pegar algum dado do usuário
    async getData(name){

        //getUserData
        var a = await api.getUserData(name).then(async (res) => {
      
            return res.data;
      
        }).catch(error => {
            
            console.log(error.data);
            console.log(error);
            console.log(error.response);
            
            if(error.response !== undefined){ return error.response.data; }

            return false;
        });
          
        return a;


    }

    //Para salvar algum dado no usuario
    async saveData(name,data2){

        var data = {};
        data.name = name;
        data.data = data2;

        //postUserSaveData
        var a = await api.postUserSaveData(data).then(async (res) => {
      
            console.log(res.data);
            return res.data;

        }).catch(error => {
            
            console.log(error.data);
            console.log(error);
            console.log(error.response);

            if(error.response !== undefined){ return error.response.data; }
            return false;

        });
          
        return a;

    }
	 
	render(){
 
  		return (<React.Fragment></React.Fragment>);

	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
  }))(Main);