import React from "react";
import {Card,CardBody,Table} from "reactstrap";
import {Modal} from "reactstrap";

class ModalHookClassRoom extends React.Component {

	constructor(props){

        super(props);
        
        this.state = {};

        this.addHook = this.addHook.bind(this);
        this.addParam = this.addParam.bind(this);
        this.update = this.update.bind(this);


		window.ModalHookClassRoom = this;

    }

    addHook(){

        this.props.hook.hook.push({url:"",type:"",params:[]});
        this.update();

    }

    addParam(){

        this.update();

    }

    update(){

        this.setState({a:''});

    }
    
	render(){

        if(this.props.hook === undefined){ return <React.Fragment></React.Fragment>; }

        var hook = this.props.hook.hook;
        if(hook === undefined){  return <React.Fragment></React.Fragment>; }

        if(hook.length === 0){

            //this.addHook();

        }

        //console.log(hook);

		return(
		<React.Fragment>
	        <Modal className="modal-dialog-centered" isOpen={this.props.open} >
	          <div className="modal-body p-0">
	            <Card className="bg-secondary border-0 mb-0">
	            <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">Gerenciar hook</h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.close()}><span aria-hidden={true}>×</span> </button>
                </div> 
	            <CardBody className="px-lg-5" style={{paddingTop:'0px'}}>
                    <div style={{marginBottom:"15px"}}>

                        <h5>Lista de variáveis:</h5>
                        [[user_name]] [[user_lastName]] [[user_email]] [[user_username]] [[user_birthDateBR]] [[user_birthDateDB]] [[user_genre]] [[user_city]] [[user_state]] [[user_tel]]
                        <br/><br/>
                        [[classroom_name]] [[classroom_courseName]] [[classroom_courseId]]

                    </div> 
                    {

                        (hook.length === 0) &&
                        <React.Fragment>
                            <center style={{marginBottom:'25px',fontSize:'17px',fontWeight:'100'}}>Nenhum hook foi configurado.</center>
                        </React.Fragment>

                    }
                    {

                        hook.map((value,index) => {

                            return(<React.Fragment>
                                <div style={{marginBottom:'15px',position:'relative'}}>

                                    <div style={{position:'absolute',left:'-20px',cursor:'pointer',zIndex:'999'}} onClick={() => { hook.splice(index,1); this.update(); }}><i className="far fa-trash-alt"></i></div>

                                    <div className="row">
                                        <div className="col-md-8">
                                            <h5>URL:</h5>
                                            <div className="input-group-merge input-group-alternative input-group"><input style={{height:'30px'}} className="form-control" value={value.url} onChange={(e) => { value.url = e.target.value;  this.update(); }} /> </div>
                                        </div>
                                        <div className="col-md-4">
                                            <h5>Tipo:</h5>
                                            <div className="input-group-merge input-group-alternative input-group">
                                                <select style={{height:'30px',padding:'0px'}} className="form-control" value={value.type} onChange={(e) => { value.type = e.target.value;  this.update(); }}>
                                                    <option></option>
                                                    <option value="get">GET</option>
                                                    <option value="post">POST</option>
                                                    <option value="put">PUT</option>
                                                    <option value="put">DELETE</option>
                                                </select>
                                             </div>
                                        </div>
                                    </div>
                                     

                                    <Table className="align-items-center" responsive style={{marginTop:'15px'}}>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" style={{padding:'0px',borderTop:'0px solid #e9ecef'}}>Nome</th>
                                                <th scope="col" style={{padding:'0px',borderTop:'0px solid #e9ecef'}}>Local</th>
                                                <th scope="col" style={{padding:'0px',borderTop:'0px solid #e9ecef'}}>Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {

                                            value.params.map((value2,index2) => {

                                                return(<React.Fragment>
                                                    <tr>
                                                        <td style={{padding:'0px',borderTop:'0px solid #e9ecef'}}><input style={{height:'30px',borderRadius:'0px'}} className={"form-control"} value={value2.name} onChange={(e) => { value2.name = e.target.value; this.update(); }} /></td>
                                                        <td style={{padding:'0px',borderTop:'0px solid #e9ecef'}}>
                                                            <select style={{height:'30px',padding:'0px',borderRadius:'0px'}} className={"form-control"} onChange={(e) => { value2.location = e.target.value; this.update(); }} value={value2.location}>
                                                                <option></option>
                                                                <option value="header">Header</option>
                                                                <option value="body">Body</option>
                                                            </select>
                                                        </td>
                                                        <td style={{padding:'0px',position:'relative',display:'flex',borderTop:'0px solid #e9ecef'}}>
                                                            <input style={{height:'30px',borderRadius:'0px'}} className={"form-control"} value={value2.value} onChange={(e) => { value2.value = e.target.value; this.update(); }} />
                                                            <div onClick={() => { value.params.splice(index2,1); this.update(); }} style={{marginLeft:'10px',marginTop:'5px',cursor:'pointer'}}><i className="far fa-trash-alt"></i></div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>)

                                            })

                                        }
                                        </tbody>
                                    </Table>
                                     
                                    {

                                        (value.params.length === 0) &&
                                        <center style={{marginTop:'15px',fontSize:'17px',fontWeight:'100'}}>Nenhum parâmetro foi configurado.</center>

                                    }

                                    <center><button style={{marginTop:'20px'}} onClick={() => { value.params.push({name:"",location:"",value:""}); this.update(); }} class="btn-secondary btn-round btn-icon btn  btn-sm"><i class="fas fa-plus"></i> Adicionar parâmetro</button></center>

                                </div>
                            </React.Fragment>)

                        })

                    }

                    <center><button style={{marginTop:'20px'}} onClick={() => { this.addHook(); }} class="btn-default btn-round btn-icon btn  btn-sm"><i class="fas fa-plus"></i> Criar hook</button></center>
	              
                  </CardBody>
	            </Card>
	          </div>
	        </Modal>
	        
        </React.Fragment>)

	}

}

export default ModalHookClassRoom;