import React from "react";
import {Modal,Card,CardBody,Button,UncontrolledTooltip} from "reactstrap";

import { connect } from 'react-redux';
import api from "./../../../Api.js";
import reduxHelper from "./../../../helper/index.js";
import $ from "jquery";
import Iframe from 'react-iframe'; 

import ClipLoader from "react-spinners/ClipLoader";

class VideoChat extends React.Component {

    constructor(props){

        super(props);
        this.state = {};
      
        window.videoChat = this;

    }

    render(){

        return(<React.Fragment>
 
          
             

            <Iframe frameBorder={0} allow="camera; microphone" url={'https://videoroom.supergeeks.io/'+this.props.classroom} width={this.props.width+"px"} height={this.props.height+"px"} id="myIdIframe" className="myClassname" display="initial" position="relative"/>

        
        </React.Fragment>)
 
             
    }

}

export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    magictools: state.magictools,
    useronline: state.useronline,
    classroom_option: state.classroom_option,
  
  }))(VideoChat);