import React from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';

class Socket extends React.Component {

	constructor(props){

		super(props);
		this.ioInfoPosition = this.ioInfoPosition.bind(this);
		window.sock = this;

	}

	//Para informar a posição a todos
	ioInfoPosition(init){

		//Caso o init seja 1 ele seta a variavel para deixar o loop rodando
		if(init === 1){ window.ioInfoPosition = true; }

		if(window.ioInfoPosition === false){ return false; }

		setTimeout(function(){

			window.socket.emit('infoPosition',{user:this.props.user.id,pathname:window.location.pathname});
			if(window.ioInfoPosition !== true){ return false; }
			this.ioInfoPosition();

		}.bind(this), 1000);

	}

	componentDidMount(){ 

		if(window.location.origin === "http://192.168.15.200:3000"){

			window.socket = io(sessionStorage.getItem('backend'));

		}else{

			window.socket = io('/socket.io');

		}
		
		window.socket.on('connect',function(){ /*console.log('io connect');*/ });
		window.socket.on('event',function(data){ /*console.log('io event');*/ });
		window.socket.on('disconnect',function(){ /*console.log('io disconnect');*/ });
		window.socket.on('update',function(e){ /*console.log(e);*/ });

		window.socket.emit('join', this.props.user.id);
 
		//para iniciar o loop 
		this.ioInfoPosition(1);

		//Quando receber comendo para uxar o usuario
		window.socket.on('puxaUser',function(data){

			if(window.ioInfoPosition === false){ return false; }
			 
			//Verifica se sou o usuairo solicitado
			if(parseInt(this.props.user.id) === parseInt(data.user)){

				//Verifico se estou na localização informada.
				if(window.location.pathname !== data.pathname){ 

					//console.log('direicionou usuairo');
					this.props.history.push(data.pathname); 
					window.socket.emit('infoPosition',{user:this.props.user.id,pathname:window.location.pathname});

				}
				
				//Caso seja para bloquear a barra de cima
				if(parseInt(data.blockTop) === 1 && this.props.magictools.blockTop === false){ this.props.dispatch({type:"ON_BLOCK_TOP"}) }
				if(parseInt(data.blockTop) === 0 && this.props.magictools.blockTop === true){ this.props.dispatch({type:"OFF_BLOCK_TOP"}) }
			
			}

		}.bind(this));

		window.socket.on('disconnectUser',function(data){ 
			
			this.props.dispatch({type:"REMOVE_USER_ONLINE",data:data});

		}.bind(this)); 

		window.socket.on('userEnter',function(data){ 
			 
			this.props.dispatch({type:"ADD_USER_ONLINE",data:data});

		}.bind(this));

		window.socket.on('updateUserOnline',function(data){ 
			
			this.props.dispatch({type:"UPDATE_USER_ONLINE",data:data});

		}.bind(this)); 

 
	}

	componentWillUnmount(){

		window.ioInfoPosition = false;
		window.socket.emit('disconnect');
		
		window.socket.off('connect');
		window.socket.off('event');
		window.socket.off('disconnect');
		window.socket.off('update');
		window.socket.off('puxaUser');
		window.socket.disconnect();
		
		console.log(sessionStorage.getItem('backend')+" DELIGANDO SOCKET")
		
	}

	render(){

		return(<React.Fragment></React.Fragment>)

	}

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  magictools: state.magictools

}))(Socket);