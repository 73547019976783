import api from './../../Api.js';

function Preference_helper(props){
		
	this.props = props;

}

//Para salvar a prefernecia do usuario
Preference_helper.prototype.savePreference = async function(obj){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.savePreference(obj).then(async (res) => {
    	
    	return {status:200,data:res.data};
      
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}



export default Preference_helper;