export default function User(state = [],action){

	if(action.type === "CLEAR_CLASSROOM_OPTION"){ 
        
        state = JSON.parse(JSON.stringify(state));
        state = []; 
    
    }

	if(action.type === "SET_CLASSROOM_OPTION"){ 
        
        state = JSON.parse(JSON.stringify(state));
        state = action.data; 

    }

	return state;

}