import React from "react";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";

class License extends React.Component {

	//constructor(props) {
    
    	//super(props);

	//}

	render(){

		return(<React.Fragment>

			<SimpleHeader name="Usuários" way={[{name:"Configuração",url:"/configuration"},{name:"Licença",url:"/configuration/license"}]} buttons={<React.Fragment></React.Fragment>} />
 
			<div className="mt--6 container-fluid">
				<div className="justify-content-center row">
					<div className="card-wrapper col-lg-12">
						<div className="card">
							<div className="card-header">
								<h3 className="mb-0">Detalhes da licença</h3>
							</div>
							<div className="card-body">
								<div className="row-example row">
									<div className="col-md-12 pbbuscarn1 resto_pagina_admin">
						                <div className="security" style={{borderbottom:'1px solid #fff'}}>

						                		<div className="pl-lg-4">
						                        <div className="col-md-12 ">
						                        	<h4>Versão do sistema:</h4> 
						                        	<div className="download" style={{border:'1px solid #eee',borderRadius:'10px',padding:'20px'}}>
						                            	<div className="row">
							                                <div className="col-sm-3 text-center">
							                                    <font className="version" style={{fontSize:'2em'}} >V<font id="latestVersion">1.0.0</font></font><br/>
							                                    <font className="rel-date" style={{fontSize:'13px'}} ><font id="latestVersionDate">16 de Julho de 2019</font></font>
							                                </div>
							                                <div className="col-sm-4">
							                                    <ul>
							                                       <li><a rel="noopener noreferrer" href="https://www.google.com.br/" target="_blank">Anúncios de lançamentos</a></li>
							                                       <li><a rel="noopener noreferrer" href="https://www.google.com.br/" target="_blank" id="latestVersionReleaseNotes">Notas da versão</a></li>  
							                                    </ul>
							                                </div>
							                                <div className="col-sm-5">
							                                        <center>
								                                        <button type="button" className="btn btn-secondary" style={{marginTop:'0px',cursor:'not-allowed'}}>
								                                        <i className="fas fa-sync"></i> Checar atualizações</button><br/><br/> 
								                                        <div>
								                                        	<img alt="..." style={{height:"30px",marginRight:'10px'}} src="https://image.flaticon.com/icons/svg/148/148767.svg"/>
									                                        <font>Você ja possui a versão atualizada.</font>
								                                       </div>
							                                        </center>
							                                </div>
						                         		</div>
						                    		</div>
						                    		<br/>
						                            <h4>Sua licença:</h4> <input type="text" className="form-control" defaultValue="W269N-WFGWX-YVC9B-4J6C9-T83GX" readOnly />
						                            <br/>
						                            <h4>Detalhes da licença:</h4>
						                            <table className="align-items-center table-flush table">
						                                <thead className="thead-light">
						                                  <tr>
						                                    <th>Descrição</th>
						                                    <th>Valor</th>
						                                  </tr>
						                                </thead>
						                                <tbody>

						                                  <tr>
						                                    <td>Máximo de usuários</td>
						                                    <td>300</td>
						                                  </tr>
						                                  <tr>
						                                    <td>Válidade da licença</td>
						                                    <td>01/01/2020</td>
						                                  </tr>
						                                  <tr>
						                                    <td>Cursos disponíveis</td>
						                                    <td>30</td>
						                                  </tr>
						 
						                                </tbody>
						                              </table>
						                        </div>

						                        </div>
						                </div>
						            </div>

								</div>
							</div>
						</div>
					</div>
				</div>		
			</div>

		</React.Fragment>);

	}

}


export default License;