import api from './../../Api.js';

function Company_helper(props){
		
	this.props = props; 
	//console.log('executou helper');

}

//Para pegar as lessons de uma unit
Company_helper.prototype.getListCompany = async function(){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getListCompany().then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para pegar as lessons de uma unit
Company_helper.prototype.postCompany = async function(obj){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.postCompany(obj).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para pegar as lessons de uma unit
Company_helper.prototype.putCompany = async function(id,obj){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.putCompany(id,obj).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para pegar as lessons de uma unit
Company_helper.prototype.deleteCompany = async function(id){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.deleteCompany(id).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}




//Para pegar as turmas de uma unidade
Company_helper.prototype.getUsersCompany = async function(id){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getUsersCompany(id).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}


//Para pegar os usuarios de uma unidade
Company_helper.prototype.getClassRoomCompany = async function(id){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getClassRoomCompany(id).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}



//Para adicionar o usuario na companhia
Company_helper.prototype.addUserCompany = async function(company,user){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.addUserCompany(company,user).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para adicionar o usuario na companhia
Company_helper.prototype.addUserCompany = async function(company,user){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.addUserCompany(company,user).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para adicionar o usuario na companhia
Company_helper.prototype.deleteUserCompany = async function(company,user){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.deleteUserCompany(company,user).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}



//Para pegar as companias que eu tenho acesso
Company_helper.prototype.getMyCompanys = async function(){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getMyCompanys().then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

		console.log(error);
		
		console.log(error.response);

		if(error.response.status === 400){

		return {status:400,data:error.response};

		}

      return false;

    });

	return req;

}



//Para pegar as companias que eu tenho acesso
Company_helper.prototype.searchManagersCompanyAll = async function(company){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.searchManagersCompanyAll(company).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}


 

export default Company_helper;