import React from "react";
import classnames from "classnames";
import { connect } from 'react-redux';
import {Table} from "reactstrap";
import $ from "jquery";

import api from "./../../../Api.js";

import ClipLoader from "react-spinners/ClipLoader";

class TransferClassroom extends React.Component {

    constructor(props){
  
        super(props);
        
        this.state = {loadInsert:false,loadUsers:false,users:[],all:false,selectclassroom:"",checknodelete:false};
        this.todosMarcado = this.todosMarcado.bind(this);
        this.marcaTodos = this.marcaTodos.bind(this);
        this.temPeloMenosUm = this.temPeloMenosUm.bind(this);
        this.getDataClassAtual = this.getDataClassAtual.bind(this);
        this.addUserClassRoomApi = this.addUserClassRoomApi.bind(this);
        this.removeUserClassRoomApi = this.removeUserClassRoomApi.bind(this);

        this.initTransfer = this.initTransfer.bind(this);

        window.transferClassroom = this;
  
    }

    async initTransfer(){

        this.setState({loadInsert:true});
 
        var u =  this.state.users;

        for(let index = 0; index < u.length; index++){

            if(u[index].checked === true){

                console.log(u[index].inclassroom.type);

                if(this.state.checknodelete === false){

                    console.log(''+u[index].id+' ,'+this.state.selectclassroom+'');
                    await this.removeUserClassRoomApi(u[index].id,this.props.classroom);
                }

                await this.addUserClassRoomApi(u[index].id,this.state.selectclassroom,u[index].inclassroom.type);

            }
            
        }

      
        this.props.success();

    }

    componentDidMount(){

        this.searchUserClassRoomAll();

    }

    async searchUserClassRoomAll(){
   
   
         await api.searchUserClassRoom(this.props.classroom,'').then((res) => {
            
            var u = res.data;
            for(let index = 0; index < u.length; index++) {
                
                u[index].checked = false;
                
            }

            this.setState({loadUsers:true,users:u});
   
         }).catch(error => {
   
           console.log(error.data);
           console.log(error);
           console.log(error.response);
           this.setState({loadUsers:false});
        
         });
   
    }

    todosMarcado(){

        var s = true;
        for(let index = 0; index < this.state.users.length; index++){
            
            if(this.state.users[index].checked === false){ s = false; }
            
        }

        return s;

    }

    temPeloMenosUm(){

        var t = false;

        for(let index = 0; index < this.state.users.length; index++) {
            
            if(this.state.users[index].checked === true){ 
                
                t = true;
 
            }
            
        }

        return t;

    }

    marcaTodos(como){

        var users = this.state.users;

        for(let index = 0; index < users.length; index++){
            
            users[index].checked = como;
            console.log(users[index].checked);
            
        }

        this.setState({users})

    }

    getDataClassAtual(){

        var index = this.props.state.classRooms.map(function(x) {return x.id; }).indexOf(parseInt(this.state.selectclassroom));
        
        if(index !== -1){
        
            var data = this.props.state.classRooms[index];
        
            return <React.Fragment>{data.name}</React.Fragment>;

        }else{ return <React.Fragment></React.Fragment>; }
        

    }


    async addUserClassRoomApi(user,classroom,type){

        await api.addUserClassRoom({user:user,classroom:classroom,type:type}).then((res) => {
  
           
  
        }).catch(error => {
  
          console.log(error.data);
          console.log(error);
          console.log(error.response);
  
          if(error.response.status === 400){
  
            //let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível adicionar o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            //this.refs.notificationAlert.notificationAlert(options);
  
          }
   
       
        });
  
    }

    //Para remover o usuário da turma
  async removeUserClassRoomApi(user,classroom){

    await api.deleteUserClassRoomApi(classroom,user).then((res) => {

      

    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

      if(error.response.status === 400){

        //let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
        //this.refs.notificationAlert.notificationAlert(options);

      }
   
    });

}

    render(){

        return(<React.Fragment>
            {

                (this.state.loadUsers === false) &&
                <center><ClipLoader size={60} color={"#adb5bd"} loading={true} /></center>
            
            }

            {

                (this.state.loadUsers === true && this.state.users.length === 0) &&
                <React.Fragment><center>Nenhum participante foi encontrado.</center></React.Fragment>

            }

            {

                (this.state.loadUsers === true && this.state.users.length !== 0) &&
                <React.Fragment>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th>
                                    <div className="custom-control custom-checkbox" style={{width:'30px'}}>
                                        <input checked={this.todosMarcado()} onChange={ (e) => { 

                                            if(e.target.checked === false){

                                                this.marcaTodos(false);

                                            }else{

                                                this.marcaTodos(true);

                                            }
                                            //this.setState({all:e.target.checked})

                                        }} className="custom-control-input user_check_all" id={'_check_utr'} type="checkbox" />
                                        <label className="custom-control-label" for={'_check_utr'}></label>
                                    </div>
                                </th>
                                <th>Participantes</th>
                                <th>Tipo</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {

                                this.state.users.map((value,index) => {

                                    var namePerm = "";
                                    var index3 = window.app.state.permitions.map(function(x) {return x.id; }).indexOf(value.inclassroom.type);
                                    if(index3 !== -1){

                                        namePerm = <strong><font>{window.app.state.permitions[index3].name}</font></strong>;

                                    }

                                    return(
                                    <tr key={value.id} idc={value.id} className="tableList hvr-fade" style={{display:'table-row'}}>
                                        <td style={{width:'60px'}} >
                                            <div className="custom-control custom-checkbox" style={{width:'30px'}}>
                                                <input onChange={ (e) => { 

                                                    var users = this.state.users; 
                                                    users[index].checked = e.target.checked; 
                                                    this.setState({users}); 
                                            
                                                }} checked={value.checked} className="custom-control-input user_check_all" id={value.id+'_check_utr'} type="checkbox" />
                                                <label className="custom-control-label" for={value.id+'_check_utr'}></label>
                                            </div>
                                        </td>
                                        <td style={{cursor:'pointer'}} onClick={ () => { var users = this.state.users; users[index].checked = !users[index].checked; this.setState({users}); }}>
                                            <img style={{height:'35px',width:'35px'}} alt="..." className="avatar rounded-circle mr-3 photo_user" src={value.photo} />
                                            <font>{value.name} {value.lastName}</font>
                                        </td>
                                        <td style={{cursor:'pointer'}} onClick={ () => { var users = this.state.users; users[index].checked = !users[index].checked; this.setState({users}); }}>{namePerm}</td>
                                    </tr>)

                                })

                            }
                            

                        </tbody>
                    </Table>

                    <div className="row">
                        <div className="col-md-6">
                            <div style={{marginBottom:'3px'}}>Escolha uma turma:</div>
                            <div className="input-group-merge input-group-alternative input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="ni ni-caps-small"></i></span></div>
                                    <select id="escolhe_turma" onChange={ (e) => { this.setState({selectclassroom:e.target.value}) }} value={this.state.selectclassroom} className="form-control">
                                        <option value=""></option>
                                        {

                                            this.props.state.classRooms.map((value,index) => {

                                                return(<option key={'asd_c_'+value.id} value={value.id}>{value.name}</option>)

                                            })
                                            
                                        }
                                    </select>
                                </div>
                        </div>
                        <div className="col-md-6">
                             <div className="custom-control custom-checkbox" style={{marginTop:'20px'}}>
                                <input checked={this.state.checknodelete} onChange={(e) => this.setState({checknodelete:e.target.checked})} className="custom-control-input" id="check_no_delete_atual" type="checkbox" /><label className="custom-control-label" for="check_no_delete_atual">Transferir e também manter na turma atual.</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12" style={{marginTop:'15px',textAlign:"center"}}>
                            
                            {

                                (this.state.selectclassroom !== "" && this.temPeloMenosUm()) &&
                                <font style={{textAlign:'center',fontSize:'15px'}}>

                                    {
                                    
                                        (this.state.checknodelete === false) &&
                                        <React.Fragment>Transferindo </React.Fragment>

                                    }{
                                    
                                        (this.state.checknodelete === true) &&
                                        <React.Fragment>Copiando </React.Fragment>

                                    }
                                    de <strong>{this.getDataClassAtual()}</strong> para <strong>{$('#escolhe_turma').find('option:selected').html()}</strong>

                                </font>
                            }
                            
                                    
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12" style={{marginTop:'15px'}}>
                        {

                            (this.state.selectclassroom !== "" && this.temPeloMenosUm() && this.state.loadInsert === false) &&
                            <button onClick={ () => this.initTransfer() } style={{width:'100%'}} className="btn btn-primary">
                                <i className="fas fa-exchange-alt"></i> 

                                {
                                    
                                    (this.state.checknodelete === false) &&
                                    <React.Fragment> Transferir </React.Fragment>

                                }{
                                
                                    (this.state.checknodelete === true) &&
                                    <React.Fragment> Copiar </React.Fragment>

                                }
                                
                                participantes</button>

                        }
                        {

                            (this.state.selectclassroom !== "" && this.temPeloMenosUm() && this.state.loadInsert === true) &&
                            <React.Fragment> <center><ClipLoader size={30} color={"#adb5bd"} loading={true} /></center></React.Fragment>

                        }
                        </div>
                    </div>
                </React.Fragment>

            }
            
        </React.Fragment>)

    }

}

export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
  
  }))(TransferClassroom);