import React from "react";
// nodejs library to set properties for components
// reactstrap components
import {Breadcrumb,BreadcrumbItem,Container,Row,Col} from "reactstrap";

import { Link } from 'react-router-dom';

class TimelineHeader extends React.Component {

  constructor(props){

    super(props);

    window.simpleHead = this;

  }

  render() {

    var className;
    if(this.props.cl !== undefined){

      className = this.props.cl;
      
    }else{

      className = "header header-dark  pb-6 content__title content__title--calendar";

    }

    return (
      <React.Fragment>
        <div cltop="1" className={className} style={{background:"linear-gradient(-45deg,"+window.app.state.confs.headerDegrade1+","+window.app.state.confs.headerDegrade2+")"}}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">{this.props.name}</h6>
                  <Breadcrumb className="d-none d-md-inline-block ml-lg-4" listClassName="breadcrumb-links breadcrumb-dark">
                    <BreadcrumbItem><Link to="/"><i className="fas fa-home" /></Link></BreadcrumbItem>
                    { this.props.way.map((value,index)=>{ return(<BreadcrumbItem key={index}><Link to={value.url} >{value.name}</Link></BreadcrumbItem>) }) }
                  </Breadcrumb>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">{this.props.buttons}</Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TimelineHeader;
