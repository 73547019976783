export default function Language(state = [],action){

	if(action.type === "SET_LIST_LANGUAGE"){

		state = action.data;

	}

	return state;

}