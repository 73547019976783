import Unit_helper from './redux/Unit_helper.js';
import Lesson_helper from './redux/Lesson_helper.js';
import Calendar_helper from './redux/Calendar_helper.js';
import Chart_helper from './redux/Chart_helper.js';
import Preference_helper from "./redux/Preference_helper.js";
import ClassRom_helper from "./redux/ClassRom_helper.js";
import User_helper from "./redux/User_helper.js";
import Company_helper from "./redux/Company_helper.js";
import Widget_helper from "./redux/Widget_helper.js";
import Chat_helper from "./redux/Chat_helper.js";
 
function ReduxHelper(props){
		
	this.props = props;

}

ReduxHelper.prototype.user = function(){

	const unit = new User_helper(this.props);
	return unit;

};

ReduxHelper.prototype.company = function(){

	const company = new Company_helper(this.props);
	return company;

};

ReduxHelper.prototype.classroom = function(){

	const unit = new ClassRom_helper(this.props);
	return unit;

};

ReduxHelper.prototype.unit = function(){

	const unit = new Unit_helper(this.props);
	return unit;

};

ReduxHelper.prototype.lesson = function(){

	const lesson = new Lesson_helper(this.props);
	return lesson;

};

ReduxHelper.prototype.calendar = function(){

	const calendar = new Calendar_helper(this.props);
	return calendar;

};

ReduxHelper.prototype.chart = function(){

	const chart = new Chart_helper(this.props);
	return chart;

};

ReduxHelper.prototype.preference = function(){

	const preference = new Preference_helper(this.props);
	return preference;

};

ReduxHelper.prototype.widget = function(){

	const widget = new Widget_helper(this.props);
	return widget;

};

ReduxHelper.prototype.chat = function(){

	const chat = new Chat_helper(this.props);
	return chat;

};

export default ReduxHelper;