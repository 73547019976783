
import React from "react";
import {connect} from 'react-redux';

import api from "./../../Api.js";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import {ClipLoader} from 'react-spinners';

class ClassRoom extends React.Component {

    constructor(props){

        super(props);
        this.state = {load:false,companyes:[],classroomSelect:[],loadReq:false};

        this.postClassRoom = this.postClassRoom.bind(this);
        this.todosMarco = this.todosMarco.bind(this);
        this.addTodos = this.addTodos.bind(this);
        this.removeTodos = this.removeTodos.bind(this);

    }

    componentDidMount(){
 
        this.getAllClassRoom();

    }

    //Para pegar todas as turmas
    async getAllClassRoom(){

        await api.getAllClassRoomCompanies().then(async (res) => {
 
            this.setState({companyes:res.data});

        }).catch(error => { 

            console.log(error.data);
            console.log(error);
            console.log(error.response); 
            
             

        });

    }

    //Para gerar xlsx
    async postClassRoom(){

        await this.setState({loadReq:true});

        await api.generateGrandProgress({classroom:this.state.classroomSelect}).then(async (res) => {

            await this.setState({loadReq:false});

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatório.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

        }).catch(async error => { 
            
            console.log(error.data);
            console.log(error);
            console.log(error.response); 

            if(error.response.status === 400){

                let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
                this.refs.notificationAlert.notificationAlert(options);

            }

            await this.setState({loadReq:false});
            
        });

    }

    todosMarco(data){

        var s = true;
        
        var classroomSelect = this.state.classroomSelect;
        for(let index = 0; index < data.length; index++) {
            
            var sd = classroomSelect.indexOf(data[index].id);
            if(sd === -1){ s = false; }
            
        }

        return s;

    }

    addTodos(data){

        var classroomSelect = this.state.classroomSelect;

         

        for(let index = 0; index < data.length; index++) {
            
            if(classroomSelect.indexOf(data[index].id) === -1){ classroomSelect.push(data[index].id); }
            
        }

        this.setState({classroomSelect});

    }

    removeTodos(data){ 
        
        var classroomSelect = this.state.classroomSelect;
        for(let index = 0; index < data.length; index++) {
            
            var sd = classroomSelect.indexOf(data[index].id);
            if(sd !== -1){ classroomSelect.splice(sd,1); }
            
        }

        this.setState({classroomSelect});
 
    }

    render(){

        return(<React.Fragment>
            
            <SimpleHeader name={this.props.translate['usuarios']} way={[{name:this.props.translate['usuarios'],url:"/users"}]} buttons={<React.Fragment></React.Fragment>} />
            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

            <div className="mt--6 container-fluid">
                <div className="justify-content-center row">
                    <div className="card-wrapper ct-example col-lg-4">
                    <div className="card"><div class="card-header">
                        <h3 className="mb-0">Selecione as turmas abaixo</h3></div>
                            <div className="card-body">
                                <div>
                                    {

                                        this.state.companyes.map((value,index) => {

                                            return(<React.Fragment>

                                                <div style={{marginBottom:'10px'}} key={'key_class_'+value.id}>
                                                    
                                                     

                                                         
                                                        <div style={{display:'flex'}}>

                                                            <h4>{value.name}</h4>

                                                            {

                                                                (value.classroom.length !== 0) &&

                                                                <div onChange={ (e) => {

                                                                    if(e.target.checked === true){

                                                                        this.addTodos(value.classroom);

                                                                    }else{

                                                                        this.removeTodos(value.classroom);

                                                                    }

                                                                }} style={{marginLeft:'10px'}} className="custom-control custom-checkbox">
                                                                    <input checked={this.todosMarco(value.classroom)} className="custom-control-input" id={'vvv_customCheck1dd'+value.id} type="checkbox" />
                                                                    <label className="custom-control-label" htmlFor={'vvv_customCheck1dd'+value.id}></label>
                                                                </div>
                                                                
                                                            }

                                                        </div>

                                                    
                                                    

                                                              
                                                    <div style={{textAlign:'center'}}>
                                                        {

                                                            (value.classroom.length === 0) &&
                                                            <div>Nenhuma turma foi encontrada nessa unidade.</div>

                                                        }
                                                        {

                                                            value.classroom.map((value2,index2) => {

                                                                return(<div className="custom-control custom-checkbox">
                                                                <input onChange={(e) =>{

                                                                    var classroomSelect = this.state.classroomSelect;
                                                                    if(e.target.checked === true){
                                                                        
                                                                        classroomSelect.push(value2.id);
                                                                        this.setState({classroomSelect});

                                                                    }else{

                                                                        classroomSelect.splice(classroomSelect.indexOf(value2.id),1);
                                                                        this.setState({classroomSelect});

                                                                    }

                                                                }} checked={ (this.state.classroomSelect.indexOf(value2.id) !== -1) ? true : false } className="custom-control-input" id={'vvv_customCheck1'+value2.id} type="checkbox" />
                                                                <label className="custom-control-label" htmlFor={'vvv_customCheck1'+value2.id}>{value2.name}</label>
                                                              </div>)

                                                            })

                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>)
                                        }) 
                                    }
                                </div>

                                
                                {
                                    

                                    (this.state.classroomSelect.length !== 0) &&
                                    <center>

                                    {

                                        (this.state.loadReq === false) &&
                                        <React.Fragment>
                                            <br/><br/>
                                            <button hidden type="button" className="btn btn-primary"><i className="far fa-eye"></i> Gerar visualização</button>
                                            <button onClick={ () => this.postClassRoom() } type="button" className="btn btn-primary"><i className="far fa-file-excel"></i> Gerar planilha</button>
                                        </React.Fragment>

                                    }

                                    {

                                        (this.state.loadReq === true) &&
                                        <div style={{paddingTop:'20px'}}>
                                            <ClipLoader sizeUnit={"px"} size={30} color={'#adb5bd'} loading={true} />
                                        </div>

                                    }
                                     
                                </center>

                                }
 
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>);

    }

}

export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    
}))(ClassRoom);