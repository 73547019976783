import React from "react";
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,Col,Row} from "reactstrap";
//import NotificationAlert from "react-notification-alert";
import classnames from "classnames";
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';

class EditUser extends React.Component {

	constructor(props){

    	super(props);
  		this.setState = this.props.setState;
      this.state = {};

  	}

  	render(){


    const handleChange = name => event => {
	        var dialog = this.props.state.dialog;
	        dialog[name] = event.target.value;
	        this.setState({dialog:dialog});
    }

      var stblock;
	    if(parseInt(this.props.state.dialog.blocked) === 1){ stblock={}; }else{ stblock={display:"none"}; }

  		return(<React.Fragment><Modal className="modal-dialog-centered" isOpen={this.props.state.openDialogUserCreate} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.props.state.textDialog}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openDialogUserCreate:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.props.saveUser(event) }>
                  {
                    (this.props.state.userError) &&
                    <Alert color="danger" className="animated tada"><strong>{this.props.translate['ops']}!</strong> {this.props.state.userError}</Alert>
                  }
                  <Row>
                      <Col md={6}>
                        <h5>{this.props.translate['nome']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.props.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <h5>{this.props.translate['sobrenome']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLastName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('lastName')} value={ this.props.state.dialog.lastName } onFocus={() => this.setState({ focusedLastName: true }) } onBlur={() => this.setState({ focusedLastName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col md={6}>
                        <h5>{this.props.translate['email']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-email-83" /></InputGroupText></InputGroupAddon>
                            <Input onChange={handleChange('email')} value={ this.props.state.dialog.email } type="email" onFocus={() => this.setState({ focusedEmail: true }) } onBlur={() => this.setState({ focusedEmail: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <h5>{this.props.translate['usuario']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedUsername})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="fas fa-user"></i></InputGroupText></InputGroupAddon> 
                            <Input onChange={handleChange('username')} value={ this.props.state.dialog.username } className="form-control" onFocus={() => this.setState({ focusedUsername: true }) } onBlur={() => this.setState({ focusedUsername: false }) }/>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col md={6}>
                        <h5>{this.props.translate['data_de_nascimento']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-calendar-grid-58" /></InputGroupText></InputGroupAddon> 
                            <InputMask onChange={handleChange('birthDate')} value={ this.props.state.dialog.birthDate } className="form-control" mask="99/99/9999" maskChar=" " onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                      <FormGroup >
                        <h5>{this.props.translate['nivel_de_acesso']}:</h5>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-badge" /></InputGroupText></InputGroupAddon>
                          <Input id="nivelAcesso" type="select" className="" value={this.props.state.dialog.accessLevel} onChange={handleChange('accessLevel')}>
                            {
                              this.props.state.levelAcess.map((value,index) => {return(<option key={index} value={value.id}>{value.name}</option>)})
                            }
                          </Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={5}>
                        <h5>{this.props.translate['sexo']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-calendar-grid-58" /></InputGroupText></InputGroupAddon> 
                            <Input id="sexo" type="select" className="" value={this.props.state.dialog.genre} onChange={handleChange('genre')}>

                              <option value="0">{this.props.translate['masculino']}</option>
                              <option value="1">{this.props.translate['feminino']}</option>
                            
                            </Input>
                          </InputGroup>
                        </FormGroup>
                    </Col>  
                    <Col md={7}>
                      <FormGroup>
                        <h5>{this.props.translate['bloquear_acesso_via_QR']}:</h5>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText></InputGroupAddon>
                          <Input id="bloquearAcessoQRcode" type="select" className="" value={this.props.state.dialog.blockedQRcode} onChange={handleChange('blockedQRcode')}>
                              <option value="0" selected>{this.props.translate['nao']}</option>
                              <option value="1">{this.props.translate['sim']}</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                    </Col> 
                  </Row>

                  <Row>

                    <Col md={6}>
                        <h5>Estado:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="text" className="" value={this.props.state.dialog.state} onChange={handleChange('state')}></Input>
                          </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <h5>Cidade:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="text" className="" value={this.props.state.dialog.city} onChange={handleChange('city')}></Input>
                          </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <h5>Telefone:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            <InputMask onChange={handleChange('tel')} value={ this.props.state.dialog.tel } className="form-control" mask="(99) 9999-9999999" maskChar=" " />
                          </InputGroup>
                        </FormGroup>
                    </Col>


                  </Row>

                  <FormGroup>
                    <h5>{this.props.translate['bloquear_acesso_total']}:</h5>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText></InputGroupAddon>
                      <Input id="bloquearAcesso" type="select" className="" value={this.props.state.dialog.blocked} onChange={handleChange('blocked')}>
                          <option value="0" selected>{this.props.translate['nao']}</option>
                          <option value="1">{this.props.translate['sim']}</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})+" "} style={stblock}>
                    <h5>{this.props.translate['motivo_do_bloqueio']}</h5>
                    <Input value={this.props.state.dialog.blockedReason} onChange={handleChange('blockedReason')} className="form-control-alternative" placeholder={this.props.translate['motivo_bloqueio_login']} rows="3" type="textarea" />
                  </FormGroup>
                  <FormGroup className={classnames({focused: this.props.state.focusedPassword})}>
                    <h5>{this.props.translate['senha_de_acesso']}:</h5>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                      </InputGroupAddon>
                      <Input className="form-control-alternative" onChange={handleChange('password')} placeholder={this.props.translate['senha']} type="password" onFocus={() => this.setState({ focusedPassword: true }) } onBlur={() => this.setState({ focusedPassword: false }) } />
                    </InputGroup>
                    {
                      (this.props.state.FormHelperTextPassword !== "") &&
                      <h5>{this.props.state.FormHelperTextPassword}</h5>
                    }
                  </FormGroup>

                  <FormGroup>
                    <h5>Conta verificada:</h5>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText></InputGroupAddon>
                      <Input id="bloquearAcesso" type="select" className="" value={this.props.state.dialog.verify} onChange={handleChange('verify')}>
                          <option value="0" selected>{this.props.translate['nao']}</option>
                          <option value="1">{this.props.translate['sim']}</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openDialogUserCreate:false}) } >{this.props.translate['cancelar']}</Button>
                    <Button className="my-4" color="primary" type="submit">{this.props.state.textDialog}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal></React.Fragment>);

  	}

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,

}))(EditUser);