export default function User(state = [],action){

    var index;

    if(action.type === "SET_USERS_ONLINE"){ state = action.data; }

    //Para adicionar um usuario que acabou de entrar
    if(action.type === "ADD_USER_ONLINE"){ 
        
        state = JSON.parse(JSON.stringify(state)); 
        index = state.map(function(x) {return x.id; }).indexOf(action.data.id); 
        if(index === -1){

            state.push(action.data);

        }
   
    }
    
    //Para remover um usuario que saiu
    if(action.type === "REMOVE_USER_ONLINE"){ 
        
        state = JSON.parse(JSON.stringify(state));
        index  = state.map(function(x) {return x.id; }).indexOf(action.data);
        state.splice(index,1);
           
    }

    //Para atualizar
    if(action.type === "UPDATE_USER_ONLINE"){ 
        
        state = JSON.parse(JSON.stringify(state));
        index  = state.map(function(x) {return x.id; }).indexOf(action.data.id);
        if(index === -1){

            state.push(action.data);

        }else{

            state[index] = action.data;

        }
           
    }

	return state;

}