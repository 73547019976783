export default function Magictools(state = {fixedTela:false,blockTop:false,openModalConfsClassroom:false},action){

	if(action.type === "OPEN_MODAL_TODOS_TELA"){

		state = JSON.parse(JSON.stringify(state));
		state.openModalTodosTela = true;

	}

	if(action.type === "CLOSE_MODAL_TODOS_TELA"){ 

		state = JSON.parse(JSON.stringify(state));
		state.openModalTodosTela = false;  

	}
	
	if(action.type === "OPEN_MODAL_CONFS_CLASSROOM"){

		state = JSON.parse(JSON.stringify(state));
		state.openModalConfsClassroom = true;

	}

	if(action.type === "CLOSE_MODAL_CONFS_CLASSROOM"){ 

		state = JSON.parse(JSON.stringify(state));
		state.openModalConfsClassroom = false;  

	}




	if(action.type === "ON_FIXA_TELA"){ 

		state = JSON.parse(JSON.stringify(state));
		state.fixedTela = true;  

	}

	if(action.type === "OFF_FIXA_TELA"){ 

		state = JSON.parse(JSON.stringify(state));
		state.fixedTela = false;  

	}

	if(action.type === "ON_BLOCK_TOP"){ 

		state = JSON.parse(JSON.stringify(state));
		state.blockTop = true;  

	}

	if(action.type === "OFF_BLOCK_TOP"){ 

		state = JSON.parse(JSON.stringify(state));
		state.blockTop = false;  

	}

	return state;

}