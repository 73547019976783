import React from "react";
import { connect } from 'react-redux';
//import moment from "moment";
//import $ from 'jquery';
//import {Button} from "reactstrap";
//import ReactPlayer from 'react-player';
//import Confetti from 'react-confetti'
import Iframe from 'react-iframe'; 

class Iframev extends React.Component {

	constructor(props){

		super(props); 
		this.state = {};
		window.iframev = this;

	}
 

	render(){

		var url = this.props.state.lesson.iframeURL;
		//url = "http://127.0.0.1/testblock.html";

		if(url !== null && url !== ""){

			url = window.variableCourse(url,this.props);
			
			/*url = url.replace("[[url_engine_backend]]", window.app.state.confs.url_engine_backend);
			url = url.replace("[[url_engine_frontend]]", window.app.state.confs.url_engine_frontend);
			url = url.replace("[[url_front_reactengine]]", window.app.state.confs.url_front_reactengine);
			url = url.replace("[[url_back_reactengine]]", window.app.state.confs.url_back_reactengine);
			url = url.replace("[[url_scratch]]", window.app.state.confs.url_scratch);
			url = url.replace("[[license]]", window.app.state.confs.license);
			url = url.replace("[[course_id]]", this.props.propsextend.course);
			url = url.replace("[[id_classRoom]]", this.props.propsextend.classroom);
			url = url.replace("[[id_unit]]", this.props.propsextend.unit);
			url = url.replace("[[id_lesson]]", this.props.propsextend.lessom);
			url = url.replace("[[id_user]]", this.props.user.id);
			url = url.replace("[[user_name]]", this.props.user.name);
			url = url.replace("[[user_lastName]]", this.props.user.lastName);
			url = url.replace("[[user_username]]", this.props.user.username);
			url = url.replace("[[user_birthDate_type_datedb]]", this.props.user.birthDate);
			url = url.replace("[[user_birthDate_type_default]]", this.props.user.birthDate);
			url = url.replace("[[user_email]]", this.props.user.email);*/
			

		}

		return(<React.Fragment> 
			<style>{' #lessonView{ overflow:hidden !important; } '}</style>
			<Iframe frameBorder={0} allow="camera; microphone"  url={url} width={this.props.state.width+"px"} height={this.props.state.height+"px"} id="myIdIframe" className="myClassname" display="initial" position="relative"/>
		</React.Fragment>);

	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
  }))(Iframev);