export default function User(state = {lastMessages:[],open:false,conversation:null,users:[],loadUsers:false},action){

  

    if(action.type === "LAST_MESSAGES_CHAT"){

        state = JSON.parse(JSON.stringify(state));
        state.lastMessages = action.data

    }

    //Para abrir o chat
	if(action.type === "OPEN_CHAT"){ 
        
        state = JSON.parse(JSON.stringify(state));
        state.open = true; 
    
    }

    //Para fechar o chat
	if(action.type === "CLOSE_CHAT"){ 
        
        state = JSON.parse(JSON.stringify(state));
        state.open = false; 

    }

    //Para listar meus contatos
    if(action.type === "LIST_FRIENDS"){ 
        
        state = JSON.parse(JSON.stringify(state));
        state.conversation = null; 

    }

    //Para iniciar uma conversa com alguem

    if(action.type === "INIT_CONVERSATION"){ 
        
        state = JSON.parse(JSON.stringify(state));
        state.conversation = action.data.user; 
        state.open = true; 

    }

    //Para colocar todos os usuarios no chat
    if(action.type === "SET_CHAT_USERS"){ 
        
        state = JSON.parse(JSON.stringify(state));
        state.users = action.data;
        state.loadUsers = true; 

    }

    if(action.type === "EXIT_CHAT"){

        state = JSON.parse(JSON.stringify(state));
        state = {open:false,conversation:null,users:[],loadUsers:false};

    }

    if(action.type === "CLEAR_CHAT"){

        state = JSON.parse(JSON.stringify(state));
        state.conversation = null;

    }

 

	return state;

}