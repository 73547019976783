import React from 'react';
import api from './../Api.js';
import $ from 'jquery';
import { connect } from 'react-redux';
import reduxHelper from "./../helper/index.js";
 

import "./assets/custom.css";



import {BounceLoader} from 'react-spinners';

class App extends React.Component {

    constructor(props){

        super(props);

        this.state = {newMenssages:[],listLastMenssages:true,conversation:null,menssage:'',menssages:[],loadMyLastMessages:false};

        this.getMyContats = this.getMyContats.bind(this);
        this.listFriends = this.listFriends.bind(this);
        this.sendMenssage = this.sendMenssage.bind(this);
        this.setMenssage = this.setMenssage.bind(this);
        this.getLastMensages = this.getLastMensages.bind(this);
        this.receiverMsg = this.receiverMsg.bind(this);
        this.getLastMessages = this.getLastMessages.bind(this);
        this.listLastMessages = this.listLastMessages.bind(this);
        this.helper = new reduxHelper(this.props);
        this.props.dispatch({type:"CLEAR_CHAT",data:{}});

        window.chat = this;

    }

    //Para pegar as ultimas mensagens recebidas
    async getLastMessages(){

        await this.setState({loadMyLastMessages:false});

        await api.getMyLastMensagens().then(async (res) => {

            await this.props.dispatch({type:"LAST_MESSAGES_CHAT",data:res.data});
            await this.setState({loadMyLastMessages:true});

        }).catch(error => {


        });

    }

    //Para abrir e fechart o chat
    openChat(){
        if(this.props.chat.open === true){
            this.props.dispatch({type:"CLOSE_CHAT",data:{}});
        }else{
            
            this.props.dispatch({type:"OPEN_CHAT",data:{}});
            setTimeout(function(){ $("#chatMarlonContentMenssage").focus(); }, 100);
            
            this.getLastMessages();

        }
    }

    //Para pegar meus contatos
    async getMyContats(){

        await api.getUsersChat().then((res) => {

            this.props.dispatch({type:"SET_CHAT_USERS",data:res.data})

        }).catch(error => {});

    }

    //Para filtrar o campo de mensagem
    setMenssage(event){
 
        var StrippedString = event.target.value.replace(/(<([^>]+)>)/ig,"");
        this.setState({menssage:StrippedString});
        //this.setState({menssage:val});

    }

    //para descer o scrool
    scrooldes(){

        var d = $('.chatMarlonListMensagems');
        d.scrollTop(d.prop("scrollHeight"));

    }

    //Para enviar uma mensagem
    async sendMenssage(event){

        event.preventDefault();
        
        var obj = {};

        obj.from_user = this.props.user.id;
        obj.user = this.state.conversation.id;
        obj.message = this.state.menssage;

        this.setState({menssage:""});


        //adiciona a mensagem no array
        var menssages = this.state.menssages;
        menssages.push({from_user:obj.from_user,to_user:obj.user,message:obj.message});
        await this.setState({menssages});

        this.scrooldes();


        await api.chatSend(obj).then((res) => {

            console.log(res.data);

            window.socket.emit('chatSend',res.data.data);
            console.log(res);

        }).catch(error => {

            if(error.response !== undefined){

                console.log(error.response);

            }

        });   

    } 
    
    componentWillUnmount(){

       window.socket.off('chatSend');

    }

    componentDidMount(){

        this.getMyContats();
        this.getLastMessages();

        window.socket.on('chatSend',function(e){
            
            this.receiverMsg(e);
 
        }.bind(this));

    }

    playAudio(){

        var x = document.getElementById("chatMarlonAudio");
        x.play();

    }
    //Quando receber uma mensagem
    async receiverMsg(data){

        var newMenssages;

        console.log(data);
        if(data === null){ return false; }

        console.log(parseInt(data.to_user)+' === '+parseInt(this.props.user.id));
        if(parseInt(data.to_user) !== parseInt(this.props.user.id)){ 
            
            return false;
        
        }

        if(this.state.conversation !== null){
            
            //Caso ja esteja com o chat aberto
            if(parseInt(data.from_user) === parseInt(this.state.conversation.id) && this.props.chat.open === true){

                var menssages = this.state.menssages;
                menssages.push(data);
                await this.setState({menssages});
                this.scrooldes();
    
            }else{

                this.playAudio();

                newMenssages = this.state.newMenssages;
                newMenssages.push(data);
                this.setState({newMenssages});
                this.scrooldes();

            }
        
        //Caso não
        }else{

            this.playAudio();

            newMenssages = this.state.newMenssages;
            newMenssages.push(data);
            this.setState({newMenssages});
            this.scrooldes();

        }
       


    }

    async getLastMensages(destinary,pagination){
        
        await api.getChatMensages(destinary,pagination).then(async (res) => {

            console.log(res.data);
            await this.setState({menssages:res.data});

            //joga o scrool pra baixo
            setTimeout(function(){ this.scrooldes(); }.bind(this), 200);

        }).catch(error => {

            if(error.response !== undefined){

                console.log(error.response);

            }

        });   

    }

    //Para iniciar um conversa com alguem
    initConversation(user){

        this.props.dispatch({type:"INIT_CONVERSATION",data:{user:user}})
        this.setState({listLastMenssages:false,conversation:user});
        this.getLastMensages(user.id,0);

    }
 
    //Para listar minhas ultimas mensagens
    async listLastMessages(){

        await this.props.dispatch({type:"CLEAR_CHAT",data:{}})
        await this.setState({conversation:null,listLastMenssages:true});
        this.getLastMessages();

    }

    //Para pesquisar um usuário
    search(val){

        if(val === ""){
    
            $('.chatMarlonUser').fadeIn();
    
        }else{
         
            $(".chatMarlonUser").each(function(){

                var temuser = $(this).find('.name').html().toLowerCase().includes(val.toLowerCase());

                if(temuser === true ){ 
                
                    $(this).fadeIn();
            
                }else{ 
                
                    $(this).fadeOut(); 
                
                }

            });
    
        }
    
      }

    //Para listar os amigos
    async listFriends(){

        await this.setState({conversation:null});
        this.setState({menssages:[],listLastMenssages:false});

        await this.props.dispatch({type:"LIST_FRIENDS",data:{}});

    }


   
    render(){
        
        //Caso esteja com conversation
        if(this.props.chat.conversation !== null && this.state.conversation === null){

            for(let i = 0; i < this.props.chat.users.length; i++){
               
                if(this.state.conversation !== null){ break; }

                for(let i2 = 0; i2 < this.props.chat.users[i].users.length; i2++){

                    if(this.state.conversation !== null){ break; }
                   
                    if(parseInt(this.props.chat.users[i].users[i2].id) === parseInt(this.props.chat.conversation)){
                        
                        this.setState({conversation:this.props.chat.users[i].users[i2]});
                        break; 

                    }

                }
                
            }
            
        }

         

        return(<React.Fragment>

            {

                this.state.newMenssages.map((value,index) => {

                    var calc = (index * 70) + 10;
                    if(calc === 10){ calc = 20; }

                    var n = JSON.parse(JSON.stringify(value));

                    n.id = value.data_from_user.id;
                    n.name = value.data_from_user.name;
                    n.lastName = value.data_from_user.lastName;
                    n.photo = value.data_from_user.photo;

                    var time = 6000 * index;
                    if(time === 0){ time = 6000; }

                    setTimeout(function(){

                        $('.chatMarlonNotifi[idc='+value.id+']').removeClass('fadeInDown');
                        $('.chatMarlonNotifi[idc='+value.id+']').addClass('fadeOutDown');
                        setTimeout(function(){
                            
                            var newMenssages = this.state.newMenssages;
                            newMenssages.splice(index,1);
                            this.setState({newMenssages});

                        }.bind(this), time+1000);
                        
                    }.bind(this), time);

                    console.log(value.photo);
                    
                    return(<div key={index+'aaa_d'} idc={value.id} onClick={ () => this.initConversation(n) } className="chatMarlonNotifi animated fadeInDown" style={{top:calc+'px'}}>
                                <img alt="..." src={value.data_from_user.photo} />
                                <div className="chatMarlonNnN">
                                    <div className="marlonNname">{value.data_from_user.name+' '+value.data_from_user.lastName}</div>
                                    <div className="marlonNnamMsg"><input readOnly type="text" defaultValue={value.message} /></div>  
                                </div>
                            </div>)

                })

            }
            

            {/*Audio do chat*/}
            <audio id="chatMarlonAudio"> 
                <source src="/sound/chat.mp3" type="audio/mpeg"/>
                Your browser does not support the audio element.
            </audio>
            
            <div className="chatMarlon">
                <div className="buttonOpenChat" onClick={ () => this.openChat() }>
                    {

                        (this.props.chat.open === false) &&
                        <i className="far fa-comments iconOpenChatMarlon animated fadeIn"></i>

                    }
                    {

                        (this.props.chat.open === true) &&
                        <i className="fas fa-times iconCloseChatMarlon animated fadeIn"></i>

                    }
                </div>
            </div>
            
            {

                (this.props.chat.open === true) &&
                
                <div className="chatMarlonWindow animated bounceInRight">

                        
                    <div className="chatMarlonHeader">
                        {

                            (this.state.listLastMenssages === false && this.props.chat.conversation === null) &&
                            <React.Fragment>
                                

                                <i onClick={ () => this.listLastMessages() } className="fas fa-angle-left chatMarlon returnUsers"></i>

                                <i className="fas fa-child chatMarlonContats"></i>
                                <div className="chatMarlonHeader--team-name" style={{marginLeft:'5px'}}>Meus contatos</div>
                                <div onClick={ () => this.props.dispatch({type:"CLOSE_CHAT",data:{}})} className="chatMarlonHeader--close-button"><img src="https://kingofthestack.github.io/react-chat-window/close-icon.c30463a5.png" alt="" /></div>
                            
                            </React.Fragment>

                        }
                        {

                            (this.state.conversation !== null && this.props.chat.conversation !== null) &&
                            <React.Fragment>
                                {/* onClick={ () => this.listFriends() } */}
                                <i onClick={ () => this.listLastMessages() } className="fas fa-angle-left chatMarlon returnUsers"></i>
                                <img style={{height:'60px',width:'60px'}} className="chatMarlonHeader--img" src={this.state.conversation.photo} alt="" />
                                <div className="chatMarlonHeader--team-name">{this.state.conversation.name}</div>
                                <div onClick={ () => this.openChat() } className="chatMarlonHeader--close-button"><img src="https://kingofthestack.github.io/react-chat-window/close-icon.c30463a5.png" alt="" /></div>

                            </React.Fragment>

                        }
                        { 

                            (this.state.listLastMenssages === true && this.state.conversation === null) &&
                            <React.Fragment>

                                <i className="far fa-comment-dots chatMarlonContats"></i>
                                <div className="chatMarlonHeader--team-name" style={{marginLeft:'5px'}}>Últimas Mensagens</div>
                                <div onClick={ () => this.props.dispatch({type:"CLOSE_CHAT",data:{}})} className="chatMarlonHeader--close-button"><img src="https://kingofthestack.github.io/react-chat-window/close-icon.c30463a5.png" alt="" /></div>
                            
                            </React.Fragment>


                        }
                    </div>

                    {

                        (this.state.listLastMenssages !== true && this.props.chat.conversation !== null) &&
                        <React.Fragment>

                            <div className="chatMarlonMensagems">

                                <div className="chatMarlonListMensagems">

                                    {

                                        this.state.menssages.map((value,index) => {

                                            if(parseInt(value.from_user) === parseInt(this.props.user.id)){

                                                return(
                                                    <div key={'mma_'+index} className="chatMarlonMensagem">
                                                    <div className="chatMarlonMensagem--content sent">
                                                        <div hidden className="chatMarlonMensagem--avatar" ></div>
                                                        <div className="chatMarlonMensagem--text"><span className="Linkify">{value.message}</span></div>
                                                    </div>
                                                </div>)

                                            }

                                            if(parseInt(value.from_user) !== parseInt(this.props.user.id)){

                                                return(
                                                    <div key={'mma_'+index} className="chatMarlonMensagem">
                                                    <div className="chatMarlonMensagem--content received">
                                                        <div hidden className="chatMarlonMensagem--avatar" ></div>
                                                        <div className="chatMarlonMensagem--text"><span className="Linkify">{value.message}</span></div>
                                                    </div>
                                                </div>)

                                            }

                                            return(<React.Fragment key={'mma_'+index}></React.Fragment>)

                                        })

                                    }


                                    <div hidden className="chatMarlonMensagem">
                                        <div className="chatMarlonMensagem--content received">
                                            <div className="chatMarlonMensagem--avatar" ></div>
                                            <div className="chatMarlonMensagem--text"><span className="Linkify">Nossssssssss story?</span></div>
                                        </div>
                                    </div>
                                    
                                    <div hidden className="chatMarlonMensagem">
                                        <div className="chatMarlonMensagem--content sent">
                                            <div className="chatMarlonMensagem--avatar" ></div>
                                            <div className="chatMarlonMensagem--text"><span className="Linkify">Nossssssssssssssss forget the story. </span></div>
                                        </div>
                                    </div>
                                      
                                </div>

                                <form onSubmit={ (event) => this.sendMenssage(event) } className="chatMarlonForm" autoComplete="off">

                                    <input  autoFocus  ref={(input) => { this.nameInput = input; }}  name="txt" autoComplete="off" placeholder={"Escreva a mensagem..."} onChange={ (event) => this.setMenssage(event) } value={this.state.menssage} type="text" id="chatMarlonContentMenssage" />
                                    
                                </form>

                            </div>
                        </React.Fragment>

                    }
                     








































                    {

                        (this.state.listLastMenssages === true && this.props.chat.conversation === null) &&
                        <div className="chatMarlonUsers">

                        { 

                            (this.state.loadMyLastMessages === true) &&
                            <React.Fragment>

                                <div style={{display:'flex'}} hidden>
                                    <i className="fas fa-search iconMarlonChatSearch"></i>
                                    <input placeholder="Pesquisar..." onChange={ (event) => this.search(event.target.value) } type="text" className="inputMarlonChatSearch"/>
                                </div>
                                
                                <ul className="list list-group list-group-flush">
                                {
 
                                    this.props.chat.lastMessages.map((value,index) => {
                                        
                                        var online = this.helper.chat().isOnline(this.props.useronline,value.id);
                                        
                                        console.log(value);
                                        
                                        return(<li onClick={ () => this.initConversation(value) } key={index+'abb'} className="px-0 list-group-item chatMarlonUser" idc={value.id}>
                                            <div className="align-items-center row" style={{paddingLeft: '15px'}}>
                                                <div className="col-auto col">
                                                <font className="avatar rounded-circle"><img dd="dd" alt="..." src={value.photo} /></font></div>
                                                {

                                                    (online !== false) &&
                                                    <div className="chatViewUserOnlineMarlon"><span className="text-success">●</span><small>Online</small></div>

                                                }
                                                {

                                                    (online === false) &&
                                                    <div className="chatViewUserOnlineMarlon"><span className="text-danger">●</span><small>Offline</small></div>

                                                }
                                                
                                                <div className="col ml--2">
                                                    <h4 className="mb-0"><font className="name">{value.name+' '+value.lastName}</font></h4>

                                                    <input defaultValue={value.message} readOnly className="inputViewLastMessageMarlon" />
                                                    

                                                </div>
                                            </div>
                                        </li>)

                                    })

                                }
                                </ul>

                                {

                                    (this.props.chat.lastMessages.length === 0) &&
                                    <center><div className="semMsgMarlon">Você não tem nenhuma mensagem.</div></center>

                                }

                                <div onClick={ () => this.listFriends() } className="bottomMyContatsMarlon"><center><i className="fas fa-user-friends"></i> Meus contatos</center></div>

                            </React.Fragment>

                        }

                        { 

                            (this.props.chat.loadMyLastMessages === false) &&
                            <React.Fragment>

                                    <center style={{position:'absolute',top:'calc(100% - 250px)',left:'calc(52% - 32px)'}}>
                                      
                                        <BounceLoader hidden sizeUnit={"px"} size={50} color={'#4e8cff'} loading={this.state.loadingSearchUsers} />
                
                                    </center>
                  
                            </React.Fragment>

                        }

                        </div>

                    }








































                    
                    {

                        (this.state.listLastMenssages !== true && this.props.chat.conversation === null) &&
                        <div className="chatMarlonUsers">

                        { 

                            (this.props.chat.loadUsers === true) &&
                            <React.Fragment>

                                <div style={{display:'flex'}}>
                                    <i className="fas fa-search iconMarlonChatSearch"></i>
                                    <input placeholder="Pesquisar..." onChange={ (event) => this.search(event.target.value) } type="text" className="inputMarlonChatSearch"/>
                                </div>

                                {
 
                                    this.props.chat.users.map((value,index) => {

                                        return(<React.Fragment  key={index+'aa'}>

                                            <div className="chatMarlonGroupUser">
                                                
                                                <font>{value.name}</font>

                                                <div className="">

                                                    <ul className="list list-group list-group-flush">
                                                        {
                                                            
                                                            value.users.map((value2,index2) =>{
                                                                
                                                                var online = this.helper.chat().isOnline(this.props.useronline,value2.id);
                                                                
                                                                return(<li onClick={ () => this.initConversation(value2) } key={index2+'a'} className="px-0 list-group-item chatMarlonUser" idc={value2.id}>
                                                                <div className="align-items-center row" style={{paddingLeft: '15px'}}>
                                                                    <div className="col-auto col">
                                                                    <font className="avatar rounded-circle"><img ss="ss" alt="..." src={value2.photo} /></font></div>
                                                                    <div className="col ml--2">
                                                                        <h4 className="mb-0"><font className="name">{value2.name+' '+value2.lastName}</font></h4>
                                                                        {

                                                                            (online !== false) &&
                                                                            <React.Fragment><span className="text-success">●</span><small>Online</small></React.Fragment>

                                                                        }
                                                                        {

                                                                            (online === false) &&
                                                                            <React.Fragment><span className="text-danger">●</span><small>Offline</small></React.Fragment>

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>)

                                                            })
                                                        
                                                        }

                                                        
                                                    </ul>
                                                    
                                                    {

                                                        (value.users.length === 0) &&
                                                        <React.Fragment>
                                                            <center><br/>Nenhum contato foi encontrado.<br/><br/></center>
                                                        </React.Fragment>

                                                    }

                                                </div>

                                            </div>

                                        </React.Fragment>)

                                    })

                                }

                            </React.Fragment>

                        }

                        { 

                            (this.props.chat.loadUsers === false) &&
                            <React.Fragment>

                                    <center style={{position:'absolute',top:'calc(100% - 250px)',left:'calc(52% - 32px)'}}>
                                      
                                        <BounceLoader hidden sizeUnit={"px"} size={50} color={'#4e8cff'} loading={this.state.loadingSearchUsers} />
                
                                    </center>
                  
                            </React.Fragment>

                        }

                        </div>

                    }
                    
                    

                </div>

            }
            

        </React.Fragment>)

    }

}

export default connect(

    state =>({

        chat: state.chat,
        user: state.user,
        useronline: state.useronline
      
    })
    
)(App);