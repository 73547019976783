import React from "react";
import {ClipLoader} from 'react-spinners';
//import $ from 'jquery';

//import {UncontrolledTooltip} from "reactstrap";
//import {Progress} from "reactstrap";
import ScrollContainer from 'react-indiana-drag-scroll';

class ProgressUnit extends React.Component {

	constructor(props){

		super(props);
		this.altDefault = 55;
		this.altMultiplayer = 26;

		this.state = {};

		 window.progressunit = this;

	}

	async componentDidMount(){}

	componentDidUpdate(){}

	render(){
  
		if(this.props.state.loadProgres !== true){

			return(
				
				<div className="row">
					<React.Fragment>
						<div className="col-md-12" style={{marginTop:"40px",marginBottom:"30px"}}>
							<center><ClipLoader sizeUnit={"px"} size={60} color={'#adb5bd'} loading={true} /></center>
						</div>
					</React.Fragment>
				</div>

			)

		}

		return(<React.Fragment>

			<style> {'.dashboard_class_user{ height:55px; }'} </style>
			  <div className="row">
			   

	            <div className="col-md-2" style={{boxShadow:'8px 0px 14px -20px #000',paddingRight:'0px'}}>
	         	{

	            	(this.props.state.loadProgres === true && this.props.state.progress !== undefined) &&
	            	<React.Fragment>

	            		<div className="dashboard_class_user" style={{height:'28px'}}>

	            			<div style={{position:'relative',top:'-20px',height:'0px'}}> </div> 
 	            		
	            		</div>

	            			{
								
								(this.props.state.progress !== undefined) &&
								<React.Fragment>

								{
									this.props.state.progress.users.map((value,index) => {
										
										var css = { };
										if(value.userInClass === false){ css.opacity = "0.5"; }

										return( <React.Fragment key={'a_'+index}> 
													<div className="dashboard_class_user dashboard_class_user_2" style={css} idc={value.id}>
														<div className="name_user">
															<div onClick={ () => this.props.openChartUser(value) } style={{cursor:'pointer',display:'flex'}} >
																<img alt="..." className="avatar rounded-circle mr-3" src={value.photo} /> 
																<input style={{cursor:"pointer",width:'100%'}} readOnly className="inputViwerName_classroom" defaultValue={''+value.name+' '+value.lastName} />
															</div>
														</div>
													</div>
												</React.Fragment>)

									})

								}

								</React.Fragment>

							}         		
	            		
	            	</React.Fragment>

	            }
	            </div>

	            {

	            (this.props.state.loadProgres === true) &&
	             
	            <div className="col-md-10" style={{paddingLeft:'0px'}}>
	            	<ScrollContainer className="scroll-container" id="over_mouse_t" style={{overflow:'auto'}}>
					<div className="listLessonsTop" style={{backgroundColor:"#fff",borderRadius:'0px'}}>
		            	{
		            		this.props.state.units.map((value,index) => {

		            			return(
		            				<React.Fragment key={'d_'+index} >
										<div className="lessonTop"> 
										{

		            						(value.icon_file !== undefined) &&
		            						<img alt="..." className="icon_progressunit_1" src={sessionStorage.getItem('centerBackend')+value.icon_file.url} />

		            					}  
										<font style={{fontWeight:600}}>{value.name}</font> </div>		
		            				</React.Fragment>)

							})

		            	}
		            </div>
					{
					
						(this.props.state.progress !== undefined) &&
						<React.Fragment>

						 
							
							{

								this.props.state.progress.users.map((value,index) => {

									return(<React.Fragment key={'e_'+index}> 
												<div className="dashboard_class_userList_user" >
													<React.Fragment>
														{

															this.props.state.units.map((value2,index2) => {
																
																
																
																var progressUserIndex = this.props.state.progressAllUnits.map(function(x) {return x.user; }).indexOf(value.id);
																var progUnit = this.props.state.progressAllUnits[progressUserIndex].progress.map(function(x) {return x.unit; }).indexOf(parseInt(value2.id));
																
																var progress2 = this.props.state.progressAllUnits[progressUserIndex].progress[progUnit].progress;
																
																if(parseInt(value.id) === 304){

																	console.log(this.props.state.progressAllUnits[progressUserIndex].progress[progUnit]);

																}

																var color;
																var progress = progress2;

																if(progress <= 30){ color = "#f5365c"; }
																if(progress > 30 && progress < 70){ color = "#fb6340"; }
																if(progress > 70){ color = "#ff947b"; }
																if(progress >= 100){ color = "#2dce89"; }

																return(
																	<div key={'aabv_'+index2} className="listTime_dashboard_class_room listTime_dashboard_class_room_min">
																		
																		<div className="progress-wrapper" style={{padding:'5px 10px 0px 10px'}}>
																			<center><span style={{color:'#8898aa',fontSize:'.875rem',fontWeight:'600'}}>{progress}%</span></center>
																			<div className="progress">
																				<div className="progress-bar" style={{backgroundColor:color,width:progress+'%'}} role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" >
																				</div>
																			</div>
																		</div>

																	</div>)

															})

														} 
													</React.Fragment>
												</div>
											</React.Fragment>)
								})} 
						</React.Fragment>
	    				 
	    			}
	            </ScrollContainer>
	            </div>
	            }
	        </div>
		</React.Fragment>);

	}

}

 
export default ProgressUnit;