import React from "react";
import api from "./../../Api.js";
import moment from "moment";
import {Modal,Card,CardHeader,Row,Col,Button,Table,UncontrolledTooltip,Container} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import EditUserQRcode from './modal/EditUserQRcode.jsx';
import EditUser from './modal/EditUser.jsx';
import EditUserCardQRCode from './modal/EditUserCardQRCode.jsx';
import ImportXlsxUser from './modal/ImportXlsxUser.jsx';
import reduxHelper from "./../../helper/index.js";
import AlterUserPassword from "./modal/AlterUserPassword.jsx";
import $ from "jquery";
import {ClipLoader} from 'react-spinners';
import * as JSZip from 'jszip';

class Users extends React.Component {

  constructor(props){

      super(props);
      this.removerUser = this.removerUser.bind(this);
      this.saveUser = this.saveUser.bind(this);
      this.editUser = this.editUser.bind(this);
      this.putUser = this.putUser.bind(this);
      this.newUser = this.newUser.bind(this);
      this.postUser = this.postUser.bind(this);
      this.removeUserApi = this.removeUserApi.bind(this);
      this.editQRcodeUser = this.editQRcodeUser.bind(this);
      this.handleQrError = this.handleQrError.bind(this);
      this.handleQrScan = this.handleQrScan.bind(this);
      this.newImport = this.newImport.bind(this);
      this.helper = new reduxHelper(this.props);
      this.getAllUsers = this.getAllUsers.bind(this);
      this.checkAll = this.checkAll.bind(this);
      this.checkUser = this.checkUser.bind(this);
      this.generateQr = this.generateQr.bind(this);

      this.state = {loadUser:false,openModalalterUserPassword:false,openModalImport:false,loadComplete:false,openDialogUserCreate:false,openLanguageDialog:false,openDialogRemoveUser:false,showPassword:false,textDialog:"",descriptionDialog:"",FormHelperTextPassword:"",loading:false,userError:"",users:[],levelAcess:[],language:[],userEdit:"",
        dialog:{name:"",lastName:"",email:"",birthDate:"",blocked:"",blockedReason:"",genre:"1"},
        qrcode:{setar:false,saveSet:false},
        company:this.props.user.preference.selectcompany
      };

      window.listUsers = this;

  }

  //para buscar todos os usuarios via api
  async getAllUsers(){

    await this.setState({company:this.props.user.preference.selectcompany});

    await api.usersCompany(this.props.user.preference.selectcompany).then((res) => {

      this.setState({users:res.data,loadUser:true});

    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

    });
    
  }

  //par apegar todos os levels de acesso
  async getAllLevelAcess(){

      await api.levelAcess().then((res) => {
        
        this.setState({levelAcess:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

  //para buscar as linguagens disponíveis
  async listLanguages(){

      await api.listLanguages().then((res) => {
        
        this.setState({language:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

  //para atualizar os dados do usuario no sistema.
  async putUser(){
 
      var data = JSON.parse(JSON.stringify(this.state.dialog));
      //console.log(data);

      data.birthDate = moment(data.birthDate,'DD/MM/YYYY').format('YYYY-MM-DD');
      data.company = this.props.user.preference.selectcompany;
 
      data.genre = window.$("#sexo").val();
      data.accessLevel = window.$("#nivelAcesso").val();
      data.blocked = window.$("#bloquearAcesso").val();
      data.blockedQRcode = window.$("#bloquearAcessoQRcode").val();

      await api.updateUser(this.state.userEdit,data).then((res) => {
          
        this.setState({openDialogUserCreate:false,loading:false});

        var users = this.state.users;
        users.forEach(function(item,index,array){ if(parseInt(res.data.user.id) === parseInt(item.id)){ users[index] = res.data.user; }});
        this.setState({users:users});

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">As alterações foram salvas com sucesso.</span><span data-notify="message">Informe ao usuário para relogar no sistema.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

          if(error.response.status === 400){
          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

      });

  }

  //para cadastrar o usuario no banco de dados
  async postUser(importxlx,data){

      if(data === undefined){
        
        data = JSON.parse(JSON.stringify(this.state.dialog));
        data.birthDate = moment(data.birthDate,'DD/MM/YYYY').format('YYYY-MM-DD');
        
        data.accessLevel = window.$("#nivelAcesso").val();
        data.genre = window.$("#sexo").val();

        data.blocked = window.$("#bloquearAcesso").val();
        data.blockedQRcode = window.$("#bloquearAcessoQRcode").val();

        //console.log(data);

      }

      data.company = this.props.user.preference.selectcompany;
      
      
      var post = await api.postUser(data).then((res) => {
      
        if(importxlx === undefined){
        
          this.setState({openDialogUserCreate:false,loading:false});
        
          this.getAllUsers(); 

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">O usuário foi cadastrado com sucesso.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);
      
        }

        return res.data;

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

         if(error.response.status === 400){

          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

        return false;

      });

      return post;
  }

  //para editar os dados do usuario
  editUser(id){

      this.setState({userError:"",userEdit:id,openDialogUserCreate:true,textDialog:"Editar usuário",descriptionDialog:"",showPassword:false,FormHelperTextPassword:"Deixe em branco caso não queira alterar."});
      this.state.users.forEach(function(item,index,array){ 

        if(parseInt(item.id) === parseInt(id)){ 
        
        console.log(item);

        var item2 = JSON.stringify(item);
        item2 = JSON.parse(item2);

          delete item2.createDate;
          delete item2.updateDate;
          delete item2.lastAcess;
          delete item2.qrcodePassword;
          delete item2.id; 
          delete item2.preference;
          delete item2.photo;

          item2.birthDate = moment(item2.birthDate,'YYYY-MM-DD').format('DD/MM/YYYY');

          this.setState({dialog:item2});

        } 

      }.bind(this));

  }

    //Para cadastrar um usuário novo
  newUser(){

    this.setState({dialog:{verify:"1",state:"",city:"",tel:"",name:"",lastName:"",email:"",accessLevel:"",birthDate:"",blocked:"0",blockedReason:"",blockedQRcode:"0"},FormHelperTextPassword:"",userError:"",userEdit:"",openDialogUserCreate:true,textDialog:this.props.translate['cadastrar_usuario'],descriptionDialog:"",showPassword:false});
      
  }

  //quando clicar no botão
  saveUser(e){

      e.preventDefault();
      this.setState({loading:true});
      if(this.state.userEdit === ""){ this.postUser(); }else{ this.putUser(); }

  }
 
  //para remover um usuario do sistema
  removerUser(id){

    this.setState({openDialogRemoveUser:true,userDeletion:id});

  }

  //Para pegar todas turmdas do sistema
  async getAllclassRoom(){
 
    await api.getAllClassRoomCompany(this.props.user.preference.selectcompany).then((res) => {

      //?this.setState({classRooms:res.data});
      res.data.reverse(); 
      this.setState({classRoom:res.data});

    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

      if(error.response !== undefined){

        if(error.response.status !== undefined){

          if(error.response.status === 401){

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

          }

        }

      }

    });
  

  }

  //para enviar os dados do usuario para atualização
  async removeUserApi(){

      this.setState({openDialogRemoveUser:false});
      await api.deleteUser(this.state.userDeletion).then((res) => {
          
        this.getAllUsers();
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O usuário foi removido.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

        if(error.response.status === 400){

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }

      });

  }

  async componentDidUpdate(){
 
    if(this.state.company !== this.props.user.preference.selectcompany){ 

      await this.getAllUsers(); 
      await this.getAllclassRoom();

    }

  }

  //para criar um novo usuario no sistem,
  async componentDidMount(){
    
    if(this.props.user.preference.selectcompany !== ""){

      await this.getAllclassRoom();
      await this.listLanguages();
      await this.getAllUsers();
      await this.getAllLevelAcess();

    }

    this.setState({loadComplete:true});
    //console.log('deu did mount');

  }

  //nao uso mais
  selectLanguage(id){

    var dialog = this.state.dialog;
    dialog.language = id;
    this.setState({openLanguageDialog:false,dialog:dialog});

  }

  //para abrir o modal de qr code
  async editQRcodeUser(id,modal){
    
    if(modal === undefined){ modal = true; }

       var index = this.state.users.map(function(x) {return x.id;}).indexOf(parseInt(id));

        if(index === -1){ return false; }

        var user = this.state.users[index];
          
          delete window.resetQR;
          this.setState({openModalQRcode:modal,editUser:user.id,qrcodePassword:undefined});

          //Para pegar o QR code do usuário
          await api.getQrCodeUser(user.id).then(async (res) => {

            var qrcode = this.state.qrcode;
            qrcode.qrcodePassword = res.data.qrcodePassword;
            qrcode.name = user.name+' '+user.lastName;
            qrcode.username = user.username;

            await this.setState({qrcode});

            return true;

          }).catch(error => { return false; });

          //pega o codigo lá no banco
 
  }

  //Para fazer o download do codigo svg
  donwloadSVGQRCODE(){
    
    window.$("#figureSvg").find('svg').attr('xmlns','http://www.w3.org/2000/svg');
    var svgData = window.$("#figureSvg").html();
    var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
    var svgUrl = URL.createObjectURL(svgBlob);
  
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;downloadLink.download = this.state.qrcode.name+" QR CODE.svg";
    document.body.appendChild(downloadLink); 
    downloadLink.click(); 
    document.body.removeChild(downloadLink);
    

  }

  handleQrError(e){ 

    console.log(e); 

  }

  //Ao scaner o qr code na camera
  async handleQrScan(password){ 

    if(password === null || password === ""){ return false; }

    var obj = {qrcode:password};

    await api.setQRcode(this.state.editUser,obj).then((res) => {

        //console.log(res.data);
        var qr = this.state.qrcode;
        qr.setar = false;
        qr.qrcodePassword = password;
        this.setState({qrcode:qr});

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">O QR code foi atualizado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

        var users = this.state.users;
        for (var i = 0; i < users.length; i++) {
          if(parseInt(users[i].id) === parseInt(this.state.editUser)){

            users[i].qrcodePassword = password;

          }
        }

        this.setState({users:users});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        if(error.response.status === 400){
          
          if(window.$('.alert-text').length !== 0){ return false; }
          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{error.response.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }

      });

  }

  newImport(){

    this.setState({openModalImport:true});
    
  }

  checkAll(){

    var check = $('#_check').is(':checked');
    if(check === false){

      $(".user_check").prop('checked', false);

    }else{

      $(".user_check").prop('checked', true);

    }

    this.setState({a:'a'});

  }

  checkUser(){ 
    
    this.setState({a:'a'});

    if($('.user_check:checked').length === $('.user_check').length){ $("#_check").prop('checked', true); }else{ $("#_check").prop('checked', false); }

    if($('.user_check:checked').length === 0){ $("#_check").prop('checked', false); }

  }

  //Para baixar os qr codes que o usuario escolheu
  async generateQr(){

    if(this.state.generateQRCodes === true){ return false; }

    this.setState({generateQRCodes:true,qrcodeprogress:0});

    var zip = new JSZip();
        
    var img = zip.folder("Cards");
    var frente = await window.edituserqrcode.gera_card(true);
    
    frente.href = frente.href.replace("data:image/png;base64,", "");
    
    zip.file("Frete do cartao.png", frente.href, {base64: true});
    //img.file(frente.download, frente.href, {base64: true});
    
    var users = [];
    $(".user_check:checked").each(async function(){
      
      //this.editQRcodeUser($(this).attr('idc'),true);
      users.push($(this).attr('idc'));

    });


    for(let index = 0; index < users.length; index++) {
      
      console.log('Gerando: '+index+'/'+users.length+' = '+users[index]);

      //aguarda qr
      var gerou = await this.editQRcodeUser(users[index],false);
      console.log(users[index]);

      if(gerou === false){

        this.setState({generateQRCodes:false});
        alert('Não foi possível gerar o QR code do usuário '+users[index]+' ');
        return false;

      }

      var base = await window.edituserqrcode.gera_card_2(true);
      base.href = base.href.replace("data:image/png;base64,", "");
      img.file(base.download, base.href, {base64: true});

      var calc = ((index+1) * 100) / users.length;
      this.setState({qrcodeprogress:calc.toFixed(0)});

    }

    //Para baixar o arquivo
    zip.generateAsync({type:"blob"}).then(function(content) {
        
        var data = new Blob([content], {type: 'application/zip'});
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'QR codes.zip');
        tempLink.click();

    });
    
    this.setState({generateQRCodes:false});

  }
 
  pesquisar(val){

    if(val === ""){

      $('.tableList').fadeIn();

    }else{

      var user;

      for (let index = 0; index < this.state.users.length; index++) {
        
        user = this.state.users[index];
        var temname = user.name.toLowerCase().includes(val.toLowerCase());
        var temmail = user.email.toLowerCase().includes(val.toLowerCase());
        var temuser = user.username.toLowerCase().includes(val.toLowerCase());

        if(temname === true || temmail === true || temuser === true){ 
          
          $('.tableList[idc="'+this.state.users[index].id+'"]').fadeIn();
      
        }else{ 
          
          $('.tableList[idc="'+this.state.users[index].id+'"]').fadeOut(); 
        
        }

      }

    }

  }


  render(){

    var countChecks = $('.user_check:checked').length;
    var showButtosExtra;
    if(countChecks === 0){ showButtosExtra = false; }else{ showButtosExtra = true; }

    return(
      <React.Fragment>
        
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

         

        <SimpleHeader name={this.props.translate['usuarios']} way={[{name:this.props.translate['usuarios'],url:"/users"}]} buttons={<React.Fragment></React.Fragment>} />
        <Container className="mt--6" fluid>

          
          <Card>
 


            <CardHeader className="border-0">
              <Row>
                <Col xs="6"><h3 className="mb-0">{this.props.translate['lista_de_usuarios']}</h3></Col>
                {
                  (this.props.permission.indexOf(26) !== -1 && this.props.user.preference.selectcompany !== "") &&
                  <React.Fragment>
                  <Col className="text-right" xs="6">

                    {

                      (showButtosExtra === true) &&
                      <React.Fragment>

                        <Button className=" btn-round btn-icon" color="default" onClick={() => this.generateQr()} size="sm">
                          <span className="btn-inner--icon mr-1"><i className="fas fa-qrcode"></i></span>
                          <span className="btn-inner--text">Gerar QR code  
                          
                            {
                              
                              (this.state.generateQRCodes === true) &&
                              <font style={{marginLeft:"4px"}}><ClipLoader sizeUnit={"px"} size={10} color={'#ffffff'} loading={true} /> {this.state.qrcodeprogress}% </font>

                            }
                            
                          </span>
                        </Button>

                      </React.Fragment>

                    }
                    
                    {

                      (this.props.permission.indexOf(33) !== -1) &&
                      <React.Fragment>
                        <Button className=" btn-round btn-icon" color="success" onClick={() => this.newImport()} size="sm">
                          <span className="btn-inner--icon mr-1"><i className="far fa-file-excel"></i></span>
                          <span className="btn-inner--text">{this.props.translate['importar_planilha']}</span>
                        </Button>
                        <Button className="btn-neutral btn-round btn-icon" color="default" onClick={() => this.newUser()} size="sm">
                          <span className="btn-inner--icon mr-1"><i className="fas fa-user-plus"></i></span>
                          <span className="btn-inner--text">{this.props.translate['criar_usuario']}</span>
                        </Button>
                      </React.Fragment>
                     
                    }
                     
                  </Col>
                  </React.Fragment>
                }
              </Row>
            </CardHeader>

            <div style={{marginTop:'10px'}} id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                  <label>{this.props.translate['procurar']}:<input type="text" className="form-control form-control-sm" onChange={ (event) => this.pesquisar(event.target.value) }/></label>
            </div>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>

                  <th style={{"paddingLeft":"0px","paddingRight":"0px","width":"1px"}}>
                    <center>
                      <div className="custom-control custom-checkbox" style={{"width":"28px",paddingLeft:'58px',paddingRight:'0px'}}>
                        <input onChange={ () => this.checkAll() } className="custom-control-input user_check_all" id={"_check"} type="checkbox" />
                        <label className="custom-control-label" htmlFor={"_check"}></label>
                      </div>
                    </center>
                  </th>
                  <th>id</th>
                  <th>{this.props.translate['nome']}</th>
                  <th>{this.props.translate['usuarios']}</th>
                  <th>{this.props.translate['email']}</th>
                  <th><center>{this.props.translate['acesso_bloqueado']}</center></th>
                  <th><center>{this.props.translate['qr_code_bloqueado']}</center></th>
                  <th hidden>{this.props.translate['data_de_nascimento']}</th>
                  <th>{this.props.translate['nivel_de_acesso']}</th>
                  <th>{this.props.translate['acoes']}</th>

                </tr>
              </thead>
              <tbody>
              {

                this.state.users.map((value,index) => {

                  var accessLevel,b,c,key = "user_key_"+value.id;
                  var tooltip = "tooltip_user_key_"+value.id;
                  var tooltip2 = "tooltip2_user_key_"+value.id;

                  if(parseInt(value.blocked) === 1){ 

                    b=<strong style={{color:"red"}}>{this.props.translate['sim']}</strong>; 

                  }else{ 

                    b=<strong style={{color:"green"}}>{this.props.translate['nao']}</strong>; 

                  }

                  if(parseInt(value.blockedQRcode) === 1){ 

                    c=<strong style={{color:"red"}}>{this.props.translate['sim']}</strong>; 

                  }else{ 

                    c=<strong style={{color:"green"}}>{this.props.translate['nao']}</strong>; 

                  }
  
                  this.state.levelAcess.forEach(function(item,index,array){
                    if(parseInt(item.id) === parseInt(value.accessLevel)){accessLevel = item.name;}
                  });
              
                  return(<tr key={key} idc={value.id} className="tableList">

                      <td style={{"paddingLeft":"0px","paddingRight":"0px","width":"28px"}}>
                        <center>
                          <div className="custom-control custom-checkbox" style={{"width":"28px",paddingLeft:'58px',paddingRight:'0px'}}>
                            <input idc={value.id} onClick={ () => this.checkUser() } className="custom-control-input user_check" id={key+"user_check"} type="checkbox" />
                            <label className="custom-control-label" htmlFor={key+"user_check"}></label>
                          </div>
                        </center>
                      </td>
                      <td>{value.id}</td>
                      <td className="table-user"><img alt="..."className="avatar rounded-circle mr-3 photo_user" idc={value.id} src={value.photo}/><b>{value.name} {value.lastName}</b></td>
                      <td>{value.username}</td>
                      <td>{value.email}</td>
                      <td><center>{b}</center></td>
                      <td><center>{c}</center></td>
                      <td hidden>{moment(value.birthDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                      <td>{accessLevel}</td>
                      <td className="table-actions">
                        
                        {

                          (this.props.permission.indexOf(1) !== 33) &&
                          <React.Fragment>
                            <a onClick={ () => this.editUser(value.id)} className="table-action" href="#pablo" id={tooltip}  ><i className="fas fa-user-edit" /></a>
                            <UncontrolledTooltip delay={0} target={tooltip}>{this.props.translate['editar_usuario']}</UncontrolledTooltip>
                          </React.Fragment>

                        }
                         
                        
                        {

                          (this.props.permission.indexOf(32) !== -1) &&
                          <React.Fragment>
                            <a onClick={ () => this.editQRcodeUser(value.id)} className="table-action" href="#pablo" id={tooltip+"qr_code"}  ><i className="fas fa-qrcode" /></a>
                            <UncontrolledTooltip delay={0} target={tooltip+"qr_code"}>{this.props.translate['configurar_qr_code']}</UncontrolledTooltip>
                          </React.Fragment>
                        
                        }
                        {

                          (this.props.permission.indexOf(31) !== -1) &&
                          <React.Fragment>
                            <a onClick={ () => this.props.setState({userPhoto:value.id,openModalChangePhoto:true}) } className="table-action" href="#pablo" id={tooltip+"qr_photo"} ><i className="far fa-image"></i></a>
                            <UncontrolledTooltip delay={0} target={tooltip+"qr_photo"}>{this.props.translate['alterar_foto']}</UncontrolledTooltip>
                          </React.Fragment>

                        }      

                        {

                          (this.props.permission.indexOf(30) !== -1) &&
                          <React.Fragment>
                            <a onClick={ () => this.setState({alterUserPassword:value.id,openModalalterUserPassword:true}) } className="table-action" href="#pablo" id={tooltip+"qr_photoss"} ><i className="fas fa-key"></i></a>
                            <UncontrolledTooltip delay={0} target={tooltip+"qr_photoss"}>{this.props.translate['alterar_senha']}</UncontrolledTooltip>
                          </React.Fragment>

                        }

                        {

                          (parseInt(value.id) !== parseInt(window.app.state.user.id) && this.props.permission.indexOf(34) !== -1) &&
                          <React.Fragment>
                            <a onClick={ () => this.removerUser(value.id)} className="table-action table-action-delete" href="#pablo" id={tooltip2} ><i className="fas fa-trash" /></a>
                            <UncontrolledTooltip delay={0} target={tooltip2}>{this.props.translate['deletar_usuario']}</UncontrolledTooltip>
                          </React.Fragment>

                        }
                      </td>
                    </tr>)

                  })}

              </tbody>
            </Table>

            
          {

            (this.state.loadUser === false && this.props.user.preference.selectcompany !== "") &&
            <React.Fragment>
              <div className="col-md-12" style={{marginTop:"40px",marginBottom:"30px"}}>
                <center><ClipLoader sizeUnit={"px"} size={60} color={'#adb5bd'} loading={true} /></center>
              </div>
            </React.Fragment>

            }

            {

              (this.state.users.length === 0 && this.props.user.preference.selectcompany !== "" && this.state.loadUser === true) &&
              <React.Fragment>
                <center><h3 style={{margin:'30px 0px'}}>{this.props.translate['nenhum_usuario_foi_encontrado']}</h3></center>
              </React.Fragment>

            }
            {

              (this.props.user.preference.selectcompany === "") &&
              <React.Fragment>
                <center><h3 style={{margin:'30px 0px'}}>{this.props.translate['voce_nao_tem_acesso_a_nenhum_unidade']}</h3></center>
              </React.Fragment>

            }
          </Card>
        </Container>

        <EditUserQRcode state={this.state} setState={(e) => this.setState(e) } handleQrScan={this.handleQrScan} handleQrError={this.handleQrError} />
        <EditUser saveUser={ (e) => this.saveUser(e) } state={this.state} setState={(e) => this.setState(e) } />
        <EditUserCardQRCode state={this.state} setState={(e) => this.setState(e) } />
        <ImportXlsxUser getAllUsers={ this.getAllUsers } state={this.state} setState={(e) => this.setState(e) } postUser={ this.postUser } />
        <AlterUserPassword state={this.state} setState={ (data) => this.setState(data) } />
         
        {/* modal para remover o uisuario */}
        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveUser} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Se você prosseguir, todos os dados do usuário serão removidos, essa ação é irreversível. É recomendavel <strong style={{fontWeight:'700'}}>bloquear</strong> o usuário ao invés de remove-lo. </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeUserApi() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveUser:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,

}))(Users);