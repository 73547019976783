import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ translate,modal: { state, praise, points } }) => {
 

  return (

    <div className={'correct-modal animated fadeIn' + (state === 'show' ? ' modal-enter' : '')}>
      <div className="praise">{praise}</div>
      <div className="points">{points} {translate['pontos']}.</div>
    </div>

  );
};

Modal.propTypes = {
  modal: PropTypes.shape({
    state: PropTypes.string.isRequired,
    praise: PropTypes.string.isRequired,
    points: PropTypes.string.isRequired
  })
};

export default Modal;
