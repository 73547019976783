import React from "react";
import {Card,CardBody,FormGroup,Form,Input} from "reactstrap";
import {InputGroupText,Alert,Modal,InputGroupAddon,InputGroup} from "reactstrap";
import InputMask from 'react-input-mask';
import api from "./../../../Api.js";
import { connect } from 'react-redux';
import classnames from "classnames"; 
import moment from "moment";

class ModalLB extends React.Component {

	constructor(props){

        super(props); 
        this.state = {dialog:{type:"0",lesson:""}};

        this.unlock = this.unlock.bind(this);
        this.removeunlock = this.removeunlock.bind(this);

    }

    unlock(id){

        var objpost = {};
        objpost.unit = this.props.state.unit;
        objpost.user = id; 
        objpost.classroom = window.classroom.state.classroom.id;

        console.log('objpost');
        console.log(objpost);

        api.addUnlock(objpost).then((res) => {
            
            window.swal.fire('Sucesso!','O acesso foi liberado.','success');
 
            window.lb.unloks.push({user:id,unit:objpost.unit,classroom:objpost.classroom});

            this.setState({dd:moment().format('YYYY-MM-DD HH:mm:ss')})


        }).catch((err) => {
            
            window.swal.fire('Ops!',err.response.data.msg,'error');

        });


    }

    removeunlock(id){


        var objpost = {};
        objpost.unit = this.props.state.unit;
        objpost.user = id; 
        objpost.classroom = window.classroom.state.classroom.id;

        console.log('objpost');
        console.log(objpost);

         

        api.removeUnlock('?unit='+objpost.unit+'&user='+objpost.user+'&classroom='+objpost.classroom+'').then((res) => {

            window.swal.fire('Sucesso!','O acesso foi removido.','success');
            
            var find = window.lb.unloks.findIndex(u => (u.user == objpost.user && u.unit == objpost.unit && u.lesson == null && u.classroom == objpost.classroom));
            window.lb.unloks.splice(find,1);

            this.setState({dd:moment().format('YYYY-MM-DD HH:mm:ss')})

        }).catch((err) => {

            window.swal.fire('Ops!',err.response.data.msg,'error');

        });

    }

    
 
	render(){
 
        var users = [];
        var lb = window.admin.state.classrooms.find (c => c.id == window.classroom.state.classroom.id);
        window.lb = lb;

        console.log('lb');
        console.log(lb);

        if(lb != undefined){

            users = lb.userClass;

        }

		return(
		<React.Fragment>
	        <Modal className="modal-dialog-centered" isOpen={true} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">Liberar unit individual</h6>
                            
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.props.close()}><span aria-hidden={true}>×</span> </button>
                        </div>
                        <div style={{textAlign:'center'}}>
                            ID:{this.props.state.unit} - {this.props.state.unitname}                             
                        </div>
                        <CardBody className="px-lg-5">

                            {

                              users.map((value,index) => {

                                //verificar se ja esta liberado
                                var findlb = window.lb.unloks.find(u => (u.user == value.id && u.unit == this.props.state.unit));
                                var lock = false;

                                if(findlb !== undefined){

                                    lock = true;

                                }

                                console.log('valuevaluevalue');
                                console.log(value);

                                return <React.Fragment>
                                    <div style={{display:'flex',position:'relative',marginBottom:'20px'}}>
                                        <div style={{textAlign:'center'}}>
                                            {value.name}
                                        </div>
                                        <div style={{position:'absolute',right:'0px'}}>

                                            {

                                                (lock === true) &&
                                                <button className="btn btn-primary btn-sm" onClick={() => { this.removeunlock(value.id); }}>Remover acesso</button>

                                            }
                                            {

                                                (lock === false) &&
                                                <button className="btn btn-success btn-sm" onClick={() => { this.unlock(value.id) }}>Liberar</button>

                                            }
                                            

                                        </div>
                                    </div>
                                </React.Fragment>

                              })  

                            }

                        </CardBody>
                    </Card>
                </div>
	        </Modal>
        </React.Fragment>

		)

	}

}
 
export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    classroom_option: state.classroom_option,
  
  }))(ModalLB);