import api from './../../Api.js';

function Calendar_helper(props){
		
	this.props = props;

}

 

Calendar_helper.prototype.getEvents = async function(course){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getCalendar().then(async (res) => {
    	
    	return {status:200,data:res.data};
 
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

Calendar_helper.prototype.getMyCalendar = async function(course){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getMyCalendar().then(async (res) => {
    	
    	return {status:200,data:res.data};
 
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}
 


export default Calendar_helper;