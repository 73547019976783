import api from './../../Api.js';

function Lesson_helper(props){
		
	this.props = props; 
	//console.log('executou helper');

}

//Para pegar as lessons de uma unit
Lesson_helper.prototype.getLessonsUnit = async function(unit){

	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getLessonsUnit(unit).then(async (res) => {
    	
    	return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}

//Para pegar uma lesson especifica
Lesson_helper.prototype.getLesson = async function(lesson){

  //for (var i = 0; i < this.props.units.length; i++) {//}
  var req = await api.getLesson(lesson).then(async (res) => {
      
      return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

        return {status:400,data:error.response};

      }

      return false;

    });

  return req;

}
 

export default Lesson_helper;