import React from "react";
import { connect } from 'react-redux';

// nodejs library that concatenates classes
import classnames from "classnames";
import $ from 'jquery';
import api from './../../../Api.js';
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {UncontrolledAlert,Button,CardHeader,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Row,Col} from "reactstrap";
 
import QrReader from 'react-qr-reader';
import { RingLoader } from 'react-spinners';

class Login extends React.Component {

  constructor(props){
    
    super(props);
    
    this.state = {errorCode:"",recoverPass:"",email:"",password:"",erroResetcode:"",showForm:true,loginErro:"",logando:false,qrcode:false,user:false,erroQRcode:"",esqueciSenha:false,sendResquestEmail:false};
    this.verifyUser = this.verifyUser.bind(this);
    this.handleQrScan = this.handleQrScan.bind(this);
    this.requestRecoverPassword = this.requestRecoverPassword.bind(this);
    this.verifyCodeRecover = this.verifyCodeRecover.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.verifyCode = this.verifyCode.bind(this);

    window.loginPage = this;

  }

  async login(event){

      event.preventDefault();
      this.setState({logando:true});
      api.login({email:$("#email").val(),password:$("#password").val()}).then((res) => {

        this.props.loginSuccess(res.data.session,res.data.user);

        res.data.user.session = res.data.session
        this.props.dispatch({type:"SET_DATA_USER",data:res.data.user});

        setTimeout(function(){ this.setState({logando:false}); }.bind(this), 1200);
      }).catch(error => {
          //console.log(error.data);
          //console.log(error);
          //console.log(error.response);
          if(error.response.status === 400){ this.setState({loginErro:""}); this.setState({loginErro:error.response.data[0].msg}); }
          this.setState({logando:false});
      });

  }

  //para verificar se existe um usuario com este e-mail ou usuario
  async verifyUser(event){
 
    var a = await api.searchUserLogin(event.target.value).then((res) => {
    
      this.setState({user:res.data});

    }).catch(error => { 

      this.setState({user:false});
      //console.log(error.data);
      //console.log(error);
      //console.log(error.response); 
      return false; 
 
    });

    return a;
 
  }

  async verifyCode(code){

    await this.setState({loadConfirmCode:true});
    api.verifyCode(code).then(async (res) => { 

      var re = window.parseQueryString();
      //http://localhost:3000/confirm/3997e8ff-4451-459e-8b66-779982249e37?urlRedirect=/myclassroom/18

      

      //setTimeout(() => {}, 200);

      console.log(res.data);
      this.props.loginSuccess(res.data.session,res.data.user);

      if(re.urlRedirect !== undefined){

        this.props.history.push(re.urlRedirect);

      }else{

        this.props.history.push('/');

      }
      

    }).catch(async (error) => {

      await this.setState({loadConfirmCode:false});
      this.setState({errorCode:"O cófigo verificador não é válido."});

    });

  }

  handleQrScan(password){

      if(password === null || this.state.logando === true){ return false; }

      this.setState({logando:true});

      api.loginQRcode({id:this.state.user.id,password:password}).then((res) => {

        this.props.loginSuccess(res.data.session,res.data.user);
        this.props.dispatch({type:"SET_DATA_USER",data:res.data.user});
        
        setTimeout(function(){ 

          this.setState({logando:false}); 

        }.bind(this), 1200);

      }).catch(async error => {
        
          //console.log(error.data);
          //console.log(error);
          //console.log(error.response);
          if(error.response.status === 400){ 

            await this.setState({erroQRcode:""}); 
            await this.setState({erroQRcode:error.response.data.msg}); 
            //console.log(error.response.msg);

          }

          this.setState({logando:false});
      });

  }

  async requestRecoverPassword(){
      
    if(this.state.sendResquestEmail === true){ return false; }
    
      await this.setState({sendResquestEmail:true,recoverPass:""});

      api.sendRecoverPassWord($("#email").val()).then((res) => {

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">{this.props.translate['codigo_verificacao_enviado']}</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

        this.setState({sendResquestEmail:false});

      }).catch(async error => {

         // console.log(error.data);
         // console.log(error);
         // console.log(error.response);

          if(error.response.status === 400){ 

            await this.setState({recoverPass:""}); 
            await this.setState({recoverPass:error.response.data.msg}); 
            console.log(error.response.data.msg);

           // console.log(error.response.msg);

          }

         await this.setState({sendResquestEmail:false});

      });

  }

  //Para verificar o código de redefinição de senha
  async verifyCodeRecover(codigo){

    console.log('verificou codigo'+codigo);
    this.setState({coderecover:codigo});

    await api.verifyCodeRecover(codigo).then((res) => {
    
      this.setState({recoverPassuser:res.data});
      console.log(res.data);

    }).catch(error => { 
    
      if(error.response.status === 400){ 

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }


      this.setState({showForm:true});
      console.log(error.data);
      console.log(error);
      console.log(error.response); 
      return false; 
 
    });

  }

  //Para resetar a senha
  async resetPassword(event){

    event.preventDefault();
    
    var obj = {};

    obj.code = this.state.coderecover;
    obj.password = $("#newpass").val();
    obj.confirmpassword = $("#confirmnewpass").val();

    await api.resetPassword(obj).then((res) => {
    
      this.setState({recoverPassuser:undefined,showForm:true});
      let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">{this.props.translate['senha_redefinida']}</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
      this.refs.notificationAlert.notificationAlert(options);

    }).catch(async error => { 
     
      console.log(error.data);
      console.log(error);
      console.log(error.response); 
 
      if(error.response.status === 400){ 

        await this.setState({erroResetcode:""}); 
        await this.setState({erroResetcode:error.response.data.msg}); 

      }

      return false; 
 
    });

  }


  componentDidMount(){

    if(this.props.match.params.confirmCode !== undefined){

      this.verifyCode(this.props.match.params.confirmCode);

    }

    var url = window.location.pathname;
    url = url.split('/');
    if(url[1] === "recoverpassword" & url[2] !== undefined){

      console.log('esta tentando recuperar a senha');

      this.verifyCodeRecover(url[2]);
      this.setState({showForm:false});

    }else{

      this.setState({showForm:true});

    }
    
    window.$("#superBg").css('background','url('+sessionStorage.getItem('backend')+''+window.app.state.confs.background+')');
    window.$("#superBg").fadeIn(0);
    window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.17)');
    window.$("#superBg").css('background-size','cover');

  }

  componentWillUnmount(){

    window.$("#superBg").css('background','');
    window.$("#superBg").fadeOut(0);
    window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.60)');
    window.$("#superBg").css('background-size','cover');

  }

  render() {

    var translate = this.props.translate;
    //console.log('deu render login');
    
    console.log('this.state.esqueciSenha: '+this.state.esqueciSenha);
    console.log('this.state.showForm: '+this.state.showForm)

    return (
      <React.Fragment>
      <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <div className=" ">
          <div className="">
            <div className="page-brand-info">
              <div className="brand" style={{height:"160px"}}>
                
                <img alt="..." className="brand-img img-responsive logo_escola" src={sessionStorage.getItem('backend')+window.app.state.confs.logo} style={{height:'60px'}} />

                <img alt="..." className="login_mais brand-img img-responsive" src={'https://i.imgur.com/HAMtu3f.png'} />

                <a href="https://supergeeks.com.br" target="_blank" rel="noopener noreferrer"><img alt="..." className="logologin_super brand-img img-responsive" src={'https://i.imgur.com/5knV2ov.png'} /></a>

                <img alt="..." className="login_mais brand-img img-responsive" src={'https://i.imgur.com/HAMtu3f.png'} />

                <a href="https://cs.plus" target="_blank" rel="noopener noreferrer"><img alt="..." className="logologin_cs brand-img img-responsive" src={'https://i.imgur.com/JIUjHtN.png'} /></a>


              </div>
              <p className="font-size-20" style={{color:"#fff",marginTop:"20px"}} dangerouslySetInnerHTML={{__html:window.app.state.confs.slogan}} ></p>
            </div>
          </div>
          <div className="loginRight" style={{ display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#f7fafc !important',padding:'60px 60px 0px'}}>
          {
            (this.state.qrcode === true) &&
            <React.Fragment>
              <div className="justify-content-center" >
                <div className="card-profile bg-secondary mt-5 card">
                  <div className="justify-content-center row">
                    <div className="order-lg-2 col-lg-3">
                      <div className="card-profile-image"><img alt="" className="rounded-circle border-secondary" src={this.state.user.photo} /></div>
                    </div>           </div>
                  <div className="pt-7 px-5 card-body">
                    <div className="text-center mb-4"><h3>Olá {this.state.user.name}!</h3></div>
                    {
                      (this.state.erroQRcode !== "") &&
                      <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.erroQRcode}</span></UncontrolledAlert>
                    }
                    <font>Mire o QR code do seu cartão de acesso na camera.</font>
                    <form className="" style={{marginTop:'20px'}}>
                      <div className="form-group">
                        <center>
                        <QrReader delay={100} onError={this.props.handleQrError} onScan={this.handleQrScan} showViewFinder={false} style={{ width: '128px' }} />
                        </center>
                      </div>
                      <div className="text-center"><button onClick={ () => this.setState({qrcode:false}) } type="button" className="mt-2 btn btn-secondary">{this.props.translate['cancelar']}</button></div>
                    </form>
                  </div>
                </div> 
              </div>
            </React.Fragment>
          }{

            (this.state.recoverPassuser !== undefined) &&
            <React.Fragment>
              <div className="justify-content-center" >
                <div className="card-profile bg-secondary mt-5 card">
               
                  <div className="pt-5 px-5 card-body" style={{minWidth:'400px'}}>

                     

                    <div className="text-center mb-4"><h3>Olá {this.state.recoverPassuser.name}!</h3></div>

                    {

                      (this.state.erroResetcode !== "") &&
                      <div class="alert alert-danger fade show" role="alert"><strong>Ops!</strong> {this.state.erroResetcode}</div>

                    }
                    

                    <font>{this.props.translate['informe_nova_senha']}</font>
                    <form className="" onSubmit={ (event) => this.resetPassword(event) } style={{marginTop:'20px'}}>
                      
                      

                      <div className="form-group">
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="newpass" placeholder="Nova senha" type="password" onFocus={() => this.setState({focusedEmail:true})} onBlur={() => this.setState({ focusedEmail: false })} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className={classnames("mb-3",{focused:this.state.rs2})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="confirmnewpass" placeholder="Confirme a senha" type="password" onFocus={() => this.setState({rs2:true})} onBlur={() => this.setState({ rs2: false })} />
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className="text-center"><button style={{width:'100%'}} type="submit" className="mt-2 btn btn-primary">{this.props.translate['redefinir_senha']}!</button></div>
                    </form>
                  </div>
                </div> 
              </div>
            </React.Fragment>
          }
          {
            (this.state.esqueciSenha === true && this.state.showForm === true) &&
            <React.Fragment>
              <Form style={{padding:'35px',boxShadow:'0px 2px 5px -4px #000'}} className="pageLoginForm bg-secondary" role="form" type="POST" onSubmit={  (event) => this.login(event) } >
                <center>{this.props.translate['informe_email_recuperar_senha']}</center><br/>
                {
                  
                  (this.state.recoverPass !== "") &&
                  <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.recoverPass}</span></UncontrolledAlert>
                
                }
                <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.email} onChange={ (event) => this.setState({email:event.target.value}) } onKeyUp={ (event) => this.verifyUser(event) } placeholder="Email" type="email" id="email" onFocus={() => this.setState({focusedEmail:true})} onBlur={() => this.setState({ focusedEmail: false })} />
                  </InputGroup>
                </FormGroup>
                <Button onClick={ () => this.requestRecoverPassword() } color="default" type="button" style={{textTransform:"capitalize",marginTop:'10px',width:'100%'}}> 
                  {

                    (this.state.sendResquestEmail === true) &&
                    <center><RingLoader sizeUnit={"px"} size={20} color={'#fff'} loading={this.state.loading} /></center>

                  }{

                    (this.state.sendResquestEmail === false ) &&
                    <font>{this.props.translate['recuperar_senha']}</font>

                  }
                </Button>  
                <Col md={12}><center><Button onClick={ () => this.setState({esqueciSenha:false}) } style={{marginTop:'10px'}} color="secondary" size="sm" type="button"> <i className="fas fa-undo-alt"></i> {this.props.translate['voltar']} </Button></center></Col>
              </Form>
            </React.Fragment>
          }
          


          { // formulário de login
            (this.state.qrcode === false && this.state.esqueciSenha === false && this.state.showForm === true) &&
            <React.Fragment>
              <Form style={{padding:'35px',boxShadow:'0px 2px 5px -4px #000'}} className="pageLoginForm bg-secondary" role="form" type="POST" onSubmit={  (event) => this.login(event) } >
                {
                  (this.state.user !== false) &&
                  <div style={{height:'120px'}}>
                    <div className="justify-content-center row">
                      <div className="order-lg-2 col-lg-3">
                        <div className="card-profile-image">
                          <img alt="..." className="rounded-circle border-secondary" src={this.state.user.photo}/>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mb-4" style={{marginTop:'75px'}}><h3>{this.props.translate['bem_vindo']}! {this.state.user.name}</h3></div>
                  </div>
                }{
                  (this.state.loginErro !== "") &&
                  <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.loginErro}</span></UncontrolledAlert>
                }

                {

                  (this.props.match.params.confirmCode === undefined) &&
                  <React.Fragment>
                    {

                      (this.state.user === false) &&
                      <div className="text-center mb-4"><h3>{this.props.translate['area_de_login']}</h3></div>

                    }
                    <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="fas fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input value={this.state.email} onChange={ (event) => this.setState({email:event.target.value}) } onKeyUp={ (event) => this.verifyUser(event) } placeholder={this.props.translate['email_ou_usuario']} type="text" id="email" onFocus={() => this.setState({focusedEmail:true})} onBlur={() => this.setState({ focusedEmail: false })} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className={classnames({focused:this.state.focusedPassword})} >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={this.props.translate['senha']} type="password" id="password" onFocus={() => this.setState({focusedPassword:true})} onBlur={() => this.setState({ focusedPassword:false}) }/>
                      </InputGroup>
                    </FormGroup>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                          <center>
                            <Button color="primary" type="submit" style={{marginTop:'10px',width:'100%'}}>{translate['entrar']} <i className="fas fa-key"></i></Button>
                            <br/>
                            {
                              (this.state.user !== false) &&
                              <Button onClick={ () => this.setState({qrcode:true}) } color="default" type="button" style={{marginTop:'10px',width:'100%'}}>Entrar com QR code <i className="fas fa-qrcode"></i></Button>
                            }
                          </center>
                          <Col md={12}>
                            <center><Button onClick={ () => this.setState({esqueciSenha:true}) } style={{marginTop:'10px'}} color="secondary" size="sm" type="button">{translate['esqueci_minha_senha']}</Button></center>
                          </Col>
                        </Col>
                    </Row>
                  </React.Fragment>

                }
                
                {

                  (this.props.match.params.confirmCode !== undefined) &&
                  <React.Fragment>
                    {

                        (this.state.errorCode !== "") &&
                        <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.errorCode}</span></UncontrolledAlert>
                 
                    }
                  </React.Fragment>

                }



                 


                <div className="text-center"></div>
                <CardHeader className="bg-transparent" style={{padding:'0rem 0rem',marginTop:'22px',paddingTop:'0px',marginBottom:'0px',paddingBottom:'0px'}}>
                  <div className="text-muted text-center mt-2 mb-3"><small>{translate['escolha_uma_linguagem']}</small></div>
                  <div className="btn-wrapper text-center">
                    {
                      this.props.languages.map((value,key)=>{
                        var img = "/flags/"+value.img;
                        return(
                          <Button key={key+'_language'} onClick={ () => window.app.getLanguageTexts(value.file) } style={{marginBottom:'15px'}} className="btn-neutral btn-icon" color="default" >
                            <span className="btn-inner--icon mr-1"><img alt="..." src={img} /></span>
                            <span className="btn-inner--text">{value.name}</span>
                          </Button>)
                      })
                    }
                  </div>
                </CardHeader>
              </Form>
            </React.Fragment>
          }










          
          </div>
        </div>
      </React.Fragment>);
  }
}

export default connect(state =>({

  languages: state.language,
  translate: state.translate

}))(Login);