import React from 'react';
import {Button} from "reactstrap";


class ErrorPage extends React.Component {

  	//constructor(props){
		//super(props);
	//}

	render(){	

		var sty = {height:'20px',marginLeft:'5px'};
		return(<React.Fragment>
			<div style={{backgroundColor:'#fff',"position":"fixed","height":"100vh","width":"100vw","WebkitBoxPack":"center","justifyContent":"center","WebkitBoxAlign":"center","alignItems":"center","display":"flex"}}>
            <div>
            <div className="col-md-12">
              <center><img alt="..." src="/images/500.gif" /></center>
              <img alt="..." src="/images/card-logo.png" style={{height:'160px',top:'-59px',position:'absolute',left:'155px'}} />
            </div>
            <div>
            <center>
            	<h3>Ops... Algo errado!</h3>
        		<div className="">
					<h4>Verificação da linguagens: 
					{ 
						(this.props.errors.b === true) &&
						<img alt="..." style={sty} src="/images/correct.svg"/>
					}
					{
						(this.props.errors.b === false) &&
						<img alt="..." style={sty} src="/images/error.svg"/>
					}
					</h4>
             	 </div>
              <div className="">
              	<h4>Baixou o arquivo de linguagem:
          			{ 
		              	(this.props.errors.c === true) &&
		              	<img alt="..." style={sty} src="/images/correct.svg"/>
	              	}
	              	{
		              	(this.props.errors.c === false) &&
		              	<img alt="..." style={sty} src="/images/error.svg"/>
	              	}
              	</h4>
              </div>
              <div className="">
	              <h4>Verificação do brancked:
		              { 
		              	(this.props.errors.a === true) &&
		              	<img alt="..." style={sty} src="/images/correct.svg"/>
		              }
		              {
		              	(this.props.errors.a === false) &&
		              	<img alt="..." style={sty} src="/images/error.svg"/>
		              }
	              </h4>
              </div>
              <div className="">
              	<h4>Verificação da licença:
              		{ 
		              	(this.props.errors.d === true) &&
		              	<img alt="..." style={sty} src="/images/correct.svg"/>
	              	}
	              	{
		              	(this.props.errors.d === false) &&
		              	<img alt="..." style={sty} src="/images/error.svg"/>
	              	}
              	</h4>
              </div>
              <div className="">
              	<h4>Verificação das permissões:
              		{ 
	              		(this.props.errors.e === true) &&
	              		<img alt="..." style={sty} src="/images/correct.svg"/>
	              	}
	              	{
	              		(this.props.errors.e === false) &&
	              		<img alt="..." style={sty} src="/images/error.svg"/>
	              	}
              	</h4>
              </div>
              <Button onClick={ () => window.location.reload() } style={{marginTop:'10px'}} color="secondary" type="button"><i className="fas fa-sync-alt"></i> Tentar novamente</Button>
              </center>
            </div>
            </div>
          </div>
		</React.Fragment>)
	}

}

export default ErrorPage;