import React from "react";
import {Modal,Card,CardBody,Col,Button} from "reactstrap";
import { connect } from 'react-redux';
import reduxHelper from "./../../../helper/index.js";
import api from "./../../../Api.js";
import moment from "moment";
import Chart from 'react-apexcharts'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import XLSX from 'xlsx';

//spiner
import ClipLoader from "react-spinners/ClipLoader";

class ModalBasicChartUser extends React.Component{ 

    constructor(props){

        super(props);

        this.setStateTop = this.props.setState;
        this.helper = new reduxHelper(this.props);
  
        this.getAbaco = this.getAbaco.bind(this);
        this.chart1 = this.chart1.bind(this);

        this.state = {

            load: false,
            data: [],
            series: [],
            options: {
              chart: {
                width: 380,
                type: 'pie',
              },
              labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            }
            ,
            date:'hoje'
            
        };

        window.abaco = this;

    }

    chart1(){
        
        var obj = {
            labels: [],
            series:[],
            responsive:[{
                breakpoint:480,
                options:{
                    chart:{width:10},
                    legend:{position: 'bottom'}
                }
            }],
            legend:{horizontalAlign:'right'},
            chart: {
                toolbar: {
                  show: true, 
                },
            }
        };

        var labels = [];
        var notas = [];

        for(let index = 0; index < this.state.data.length; index++){

            const score = this.state.data[index].score;

            console.log('score');
            console.log(score);

            if(notas.indexOf(score) === -1){

                notas.push(score);

            }
            
        }

        console.log('notas');
        console.log(notas);

        //sort movs
        notas.sort(function(a, b){return b - a});
        
        console.log('notas sort');
        console.log(notas);

        for(let index = 0; index < notas.length; index++){

            labels.push('Nota: '+notas[index]);
            obj.labels.push('Nota: '+notas[index]);

            for(let index2 = 0; index2 < this.state.data.length; index2++){
                
                var nt = this.state.data[index2].score;

                if(notas[index] === nt){

                    if(obj.series[index] === undefined){

                        obj.series[index] = 0;

                    }

                    obj.series[index] = obj.series[index] + 1;

                }
                
                
            }



        }

        console.log('obj CHART')
        console.log(obj);

        return obj;
        
    }

    series1(){

        return [];

    }

    getAbaco(){

        this.setState({load:false});
        api.getAbaco(this.props.userId+'?'+this.state.date+'=1').then((response) => {

            this.setState({data: response.data, load: true});

        });

    };

    componentDidMount(){ 
        
        this.getAbaco();
    
    }

    geraXlsx(){

        var letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','BB'];
  
        var dados = {};
        var wb = JSON.parse('{"SheetNames":["Desafio"],"Sheets":{"Desafio":{} } }');
  
        var segura = 1;
        var segurasl = 0;
  
        for(let index = 0; index < this.state.data.length; index++){
          
            var inv = this.state.data[index];

            var sl = 0;
        
            segura++;
            var n = segura;
            
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'ID'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v: inv.id };
            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'Label'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.challenge};
            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'Tempo'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.time};
            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'Movimentos'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.movements};
            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'MinMov'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.minMovements};
            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'Nota'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.score};
            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'Unit'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.unit};

            if(inv.dataUnit !== undefined){
 
                wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.dataUnit.name};

            }

            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'Lesson'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.lesson};

            if(inv.dataLesson !== undefined){

                wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:inv.dataLesson.name};

            }
            
            sl++;
        
            wb.Sheets['Desafio'][''+letras[sl]+'1'] = {t:'s',v:'Data'};
            wb.Sheets['Desafio'][''+letras[sl]+''+n+''] = {t:'s',v:moment(inv.createDate).format('DD/MM/YYYY HH:mm:ss')};
            sl++;

          

             

             
        
            if(sl > segurasl){ segurasl = sl; }

            var ultimoIndex = n;
    
           

        }
  
       wb.Sheets['Desafio']['!ref'] = 'A1:'+letras[segurasl]+''+ultimoIndex;
       
       console.log(wb);
  
        XLSX.writeFile(wb,this.props.name+'.xlsx');
        var dd = moment().format('DD/MM/YYYY HH:mm:ss');
        
    }

    render(){

        return <React.Fragment>
            {

                (this.state.load === false) &&
                <center><ClipLoader /></center>

            }
            {

                (this.state.load === true) &&
                <React.Fragment>

               
                    <div style={{position:"absolute",width:'96%',top:'55px',display:'flex',justifyContent:'center',gap:"10px"}}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}} >
                            <div><input onChange={(e) => {
 
                                this.state.date = e.target.value;
                                this.setState({date:e.target.value});
                                this.getAbaco();

                            }} id="bdsa1" type="radio" name="dds" checked={(this.state.date === "todos" ? true:false)} value="todos"></input></div>
                            <label for="bdsa1">Todos</label>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}} >
                            <div><input onChange={(e) => {
 
                                this.state.date = e.target.value;
                                this.setState({date:e.target.value});
                                this.getAbaco();

                            }} id="bdsa2" type="radio" name="dds" checked={(this.state.date === "hoje" ? true:false)} value="hoje"></input></div>
                            <label for="bdsa2">Hoje</label>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}} >
                            <div><input onChange={(e) => {
 
                                this.state.date = e.target.value;
                                this.setState({date:e.target.value});
                                this.getAbaco();

                            }} id="bdsa2" type="radio" name="dds" checked={(this.state.date === "semana" ? true:false)} value="semana"></input></div>
                            <label for="bdsa2">Esta semana</label>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}} >
                            <div><input onChange={(e) => {
 
                                this.state.date = e.target.value;
                                this.setState({date:e.target.value});
                                this.getAbaco();

                            }} id="bdsa2" type="radio" name="dds" checked={(this.state.date === "mes" ? true:false)} value="mes"></input></div>
                            <label for="bdsa2">Esta mês</label>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}} >
                            <div><input onChange={(e) => {
 
                                this.state.date = e.target.value;
                                this.setState({date:e.target.value});
                                this.getAbaco();

                            }} id="bdsa2" type="radio" name="dds" checked={(this.state.date === "ano" ? true:false)} value="ano"></input></div>
                            <label for="bdsa2">Esta ano</label>
                        </div>
                    </div>

                    <div style={{display:'flex'}}>
                        <div>
                            <Chart
                                options={this.chart1()}
                                series={this.chart1().series}
                                type="pie"
                                width="320"
                            />
                        </div>
                        <div className="table-responsive" style={{
                                backgroundColor:'#fff',
                                boxShadow:'0px 3px 4px -4px #000',
                                borderRadius:'5px'
                        }}>
                            <table className="align-items-center table-flush table" id="my-table">
                                <thead class="thead-light">
                                    <tr>
                                        <th>ID</th>
                                        <th>Label</th>
                                        <th><center>Tempo</center></th>
                                        <th><center>Movimentos</center></th>
                                        <th><center>MinMov</center></th> 
                                        <th><center>Nota</center></th>
                                        <th>Unit</th>
                                        <th>Lesson</th>
                                        <th>Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        this.state.data.map((item, index) => {
 
                                            var unit = "";
                                            var lesson = "";

                                            if(item.dataUnit !== undefined){

                                                unit = item.dataUnit.name;

                                            }

                                            if(item.dataLesson !== undefined){

                                                lesson = item.dataLesson.name;

                                            }

                                            return <tr>
                                                <td>{item.id}</td>
                                                <td>{item.challenge}</td>
                                                <td><center>{item.time}</center></td>
                                                <td><center>{item.movements}</center></td>
                                                <td><center>{item.minMovements}</center></td>
                                                <td><center>{item.score}</center></td>
                                                <td>{unit}</td>
                                                <td>{lesson}</td>
                                                <td>{moment(item.createDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                                            </tr>

                                        })

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </React.Fragment>

            }
        </React.Fragment>

    }


}


export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    
}))(ModalBasicChartUser);