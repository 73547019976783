import React from "react";
import {Alert,Modal,FormGroup,InputGroupAddon,Input,InputGroupText,InputGroup,Button,Card,} from "reactstrap";
import api from "./../../../Api.js";
import NotificationAlert from "react-notification-alert";
import classnames from "classnames";
import { connect } from 'react-redux';

class ChangePassword extends React.Component {

	constructor(props){

    	super(props);
    	this.state = {password:"",confirmPassword:""};
    	this.changePassword = this.changePassword.bind(this);
    	window.ChangePassword = this;

  	}

	//Para salvar a foto do usuário
	async changePassword(event){

		event.preventDefault();

		var obj = {};
		obj.password = this.state.password;
		obj.confirmPassword = this.state.confirmPassword;

		//console.log(this.state.password);
		//console.log(obj);

		await api.changePassword(obj).then((res) => {

			this.setState({password:"",confirmPassword:""});
			this.props.setState({openModalChangePassword:false});

		 	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}!</span><span data-notify="message">{this.props.translate['a_sua_senha_foi_alterada']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        	this.refs.notificationAlert.notificationAlert(options);

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			if(error.response.status === 400){

				this.setState({errorChangePassword:""});
				this.setState({errorChangePassword:error.response.data.msg});

			}

		});

	}

  	render(){

  		return(<React.Fragment>
		<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
		<Modal className="modal-dialog modal-dialog-centered modal-sm " isOpen={this.props.state.openModalChangePassword} toggle={() => this.props.setState({openModalChangePassword:false})} >
          

          <div className="modal-body p-0 bg-secondary">
            <Card className="bg-secondary border-0 mb-0">
         
            </Card>
          </div>

           
         	
          <div className="px-lg-5 py-lg-5 card-body bg-secondary" style={{borderRadius:"20px"}}>
			{
                (this.state.errorChangePassword) &&
                <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.errorChangePassword}</Alert>
          	}
          	<form type="post" onSubmit={ (event) => this.changePassword(event) }>
          	<center><font>{this.props.translate['informe_a_nova_senha']}</font></center>
          	<FormGroup className={classnames("mb-3",{focused:this.state.focusedPassword})} >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText></InputGroupAddon>
                <Input value={this.state.password} onChange={ (event) => this.setState({password:event.target.value}) } type="password"  onFocus={() => this.setState({ focusedPassword: true }) } onBlur={() => this.setState({ focusedPassword: false }) } />
              </InputGroup>
            </FormGroup>

            <center><font>{this.props.translate['confirme_a_senha']}</font></center>
            <FormGroup className={classnames("mb-3",{focused:this.state.focusedConfirmPassword})} >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText></InputGroupAddon>
                <Input value={this.state.confirmPassword} onChange={ (event) => this.setState({confirmPassword:event.target.value}) } type="password" onFocus={() => this.setState({ focusedConfirmPassword: true }) } onBlur={() => this.setState({ focusedConfirmPassword: false }) } />
              </InputGroup>
            </FormGroup>

            <center>
            	<Button color="primary" type="submit">Alterar senha</Button>
            </center>
            </form>
          </div>
         	 


     

        </Modal>

  			 

		</React.Fragment>)
  	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
  }))(ChangePassword);