import React from "react";
//import {Modal,Card,CardBody,Col,Button} from "reactstrap";
import Chart from 'react-apexcharts'
import reduxHelper from "./../../../helper/index.js";
import { connect } from 'react-redux';
//import moment from 'moment'; 

class Nota_quiz_unit extends React.Component { 

	constructor(props){

        super(props);

        this.setStateTop = this.props.setState;
        this.helper = new reduxHelper(this.props);

        this.getDataChart2 = this.getDataChart2.bind(this);

        this.state = {
        
            series: [{
              name: 'Pontos não conquistados',
              data: []
            },{
              name: 'Pontos conquistados',
              data: []
            }],


            label:[]

          }

        window.chartuser = this;

    }
      
    async componentDidUpdate(){

        console.log(this.state.idUser+'!=='+this.props.idUser);
        if(this.state.idUser !== this.props.idUser){

            console.log('componentDidUpdate');
            await this.setState({idUser:this.props.idUser});
            //this.getDataChart1();
            this.getDataChart2();

        }

    }
 
    componentDidMount(){ this.getDataChart2(); }
  
    chart2(){


        return {
            chart: {
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            xaxis: {
              type: 'string',
              categories: this.state.label,
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1,
              colors: ['#00e396','#ff4560']
            }
          }; 

    }
 

    async getDataChart2(){
        //user,classroom,unit
        var data = await this.helper.chart().chartQuizUserUnit(this.props.idUser,this.props.classroom,this.props.unit);

        if(data !== false){

            if(data.status === 200){

                console.log(data.data);

               
                for (let index = 0; index < data.data.pointsEarned.length; index++) {
                    
                    console.log(data.data.pointsEarned[index]);

                    data.data.maximumPoints[index] = data.data.maximumPoints[index] -  data.data.pointsEarned[index];

                }

                var series = [{
                    name: 'Pontos conquistados',
                    data: data.data.pointsEarned,
                    colors: ['#F44336', '#E91E63', '#9C27B0'],
                    color: '#F44336'
                  },{
                    name: 'Pontos não conquistados',
                    data: data.data.maximumPoints
                }];

                this.setState({label:data.data.label,series:series,load:true});
                //return data.data;

            } 
        }  
    }
   
  	render(){
        
        var height,width;
        
        if(this.props.height === undefined){ height = "350"; }else{ height = this.props.height; }
        if(this.props.width === undefined){ width = "100%"; }else{ width = this.props.width; }

  		return(
        <React.Fragment>
          {

            (this.state.load === true) &&
            <Chart options={this.chart2()} series={this.state.series} type="bar" height={height} width={width} />

          }
          
  		</React.Fragment>);

  	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
}))(Nota_quiz_unit);