import React from "react";
//import {Modal,Card,CardBody,Col,Button} from "reactstrap";
import Chart from 'react-apexcharts'
import reduxHelper from "./../../../helper/index.js";
import { connect } from 'react-redux';
import moment from 'moment';
//import MultiSelect from "@khanacademy/react-multi-select";
//import Desempenho_no_quiz from './../chartlist/Desempenho_quiz.jsx';
 

class ModalBasicChartUser extends React.Component { 

	constructor(props){

        super(props);

        this.setStateTop = this.props.setState;
        this.helper = new reduxHelper(this.props);

        this.getDataChart2 = this.getDataChart2.bind(this);

        this.state = {

            series2:[],
            label2:[],
            idUser:this.props.idUser

        };

        window.chartuser = this;

    }
      
    async componentDidUpdate(){

        if(this.state.idUser !== this.props.idUser){

            await this.setState({idUser:this.props.idUser});
            //this.getDataChart1();
            this.getDataChart2();

        }

    }
 
    componentDidMount(){ this.getDataChart2(); }
  
    chart2(){

        return{
            stroke:{width:[0,4]}, 
            labels:this.state.label2,
            yaxis:[{title:{text: this.props.translate['tempo_em_minutos']}}]
        };

    }

    async getDataChart1(){

        var data = await this.helper.chart().chartQuizperFormance(this.props.idUser);

        if(data !== false){

            if(data.status === 200){

                this.setState({series1:data.data});
                console.log(data.data);
                return data.data;

            }

        }

        return [];
        
    }


    async getDataChart2(){

        var data = await this.helper.chart().chartTimeInvested(this.props.idUser);

        if(data !== false){

            if(data.status === 200){

                for(let index = 0; index < data.data.label.length; index++){

                    data.data.label[index] = moment(data.data.label[index],'YYYY-MM-DD').format('DD/MM/YYYY')
                    
                }

                var series2 = [{
                    name: this.props.translate['em_aula'],
                    type: 'column',
                    data: data.data.minutes
                }];
                
                this.setState({label2:data.data.label,series2:series2,load:true});
                return data.data;

            } 
        }  
    }

    

  	render(){
        
        var height,width;
        
        if(this.props.height === undefined){ height = ""; }else{ height = this.props.height; }
        if(this.props.width === undefined){ width = "100%"; }else{ width = this.props.width; }


  		return(
        <React.Fragment>
            {
                
                (this.state.load === true) &&
                <Chart options={this.chart2()} series={this.state.series2} height={height} type="bar" width={width} />

            }
  		</React.Fragment>);

  	}


}


export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
}))(ModalBasicChartUser);