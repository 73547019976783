import React from "react";
import ReactPlayer from 'react-player';

class Video extends React.Component {

	constructor(props){

		super(props); 
		this.state = {};

		this.onEnded = this.onEnded.bind(this);
		window.videoviwer = this;

	}
	
	onEnded(){

		//Caso o vídeo finalize sozinho
		if(parseInt(this.props.state.lesson.dataVideo.autoNext) === 1){

			this.props.next();

		}

	}

	render(){  

		return(<React.Fragment> 

			<ReactPlayer controls={true} onEnded={this.onEnded} ref="player_video" height={this.props.state.height} width={this.props.state.width} url={this.props.state.lesson.dataVideo.url} playing />

		</React.Fragment>);
		
	}

}

export default Video;