import React from 'react';
import PropTypes from 'prop-types';
import Question from './Question';

const QuestionList = ({ questions, handleAnswerClick,handleEnterPress,step}) => {
  return (
  
    <React.Fragment>
    
      <style>{'  .completar_continuar{ display:none; } .concluir_unit{ display:none; } .question:not([number=\''+step+'\']){ display:none; } '}</style>

      <ul className="question-list">
        {
          
          questions.map((question,index) => {

          
            return(
              <React.Fragment>
                <Question number={(index + 1 )} key={question.question} question={question.question} answers={question.answers} handleAnswerClick={handleAnswerClick} handleEnterPress={handleEnterPress} />
              </React.Fragment>);


          
        })}
      </ul>
    
    </React.Fragment>
    
  );
}

QuestionList.propTypes = {
  questions: PropTypes.array.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
  handleEnterPress: PropTypes.func.isRequired
};

export default QuestionList;
