import React from "react";
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
//import $ from "jquery";
//import ScrollContainer from 'react-indiana-drag-scroll'
import reduxHelper from "./../../../helper/index.js";
import moment from "moment";
//import ApexCharts from 'apexcharts';
//import Chart from 'react-apexcharts';
import {ClipLoader} from 'react-spinners';
import api from "./../../../Api.js";

// reactstrap components
import {Container,UncontrolledTooltip} from "reactstrap";
import Mystudents from "./../widget/Mystudents.jsx";

class Teacher extends React.Component {

    constructor(props){

        super(props);
        this.state = {
            classRooms:[],
            myStudents:[],
            classroomusers:[],
            classRoomProgress:[],
            loadClassRoom:false,
            loadMyStudents:false
        };

        this.helper = new reduxHelper(this.props);
        this.getMyStudents = this.getMyStudents.bind(this);
        this.getUsersClassRoom = this.getUsersClassRoom.bind(this);
        this.getProgressClassRoom = this.getProgressClassRoom.bind(this);

        window.teacher_dashboard = this;

    }

    //Pego os alunos que eu tenho acesso
    async getMyStudents(){

        var students = await this.helper.widget().getMyStudents();
        
        if(students.status === 200){

            this.setState({myStudents:students.data,loadMyStudents:true});

        }

    }

    //Para pegar o progresso completo da turma
    async getProgressClassRoom(classroom){

        await api.getProgressAllUnits(classroom).then(async (res) => {
            
            var classRoomProgress = this.state.classRoomProgress;
            
            classRoomProgress.push({classroom:classroom,progress:res.data.progressClassRoom});

            await this.setState({classRoomProgress:classRoomProgress});

	    }).catch(error => { 

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response); 
	        return false; 

	    });

    }
    
    //Pega as turmas que eu parcipo
    async getMyClassRooms(){

        var classrooms = await this.helper.classroom().getMyClassRooms(this.props.user.id);
        if(classrooms.status === 200){

            await this.setState({classRooms:classrooms.data,loadClassRoom:true});
            //console.log(classrooms.data.length);

            for (let index = 0; index < classrooms.data.length; index++) {
                
                await this.getUsersClassRoom(classrooms.data[index].id);
                await this.getProgressClassRoom(classrooms.data[index].id);
                
            }

        }

    }

    //Para pesquisar quais alunos estão nessa turma
    async getUsersClassRoom(id){

        var a = await api.searchUserClassRoom(id).then((res) => {
        
            
            var classRooms = this.state.classRooms;
            var index  = classRooms.map(function(x) {return x.id; }).indexOf(id);

            classRooms[index].students = res.data;
            this.setState({classRooms:classRooms});

            return true; 

        }).catch(error => { 

            console.log(error.data);
            console.log(error);
            console.log(error.response); 
            return false; 

        });

        return a;

    }
 

  async componentDidMount(){

    this.getMyStudents();
    this.getMyClassRooms();

  }

  componentWillUnmount(){}

  //Para pegar a diferença de dias
  calcProgressClassRoom(openingDate,closingDate){

    //console.log(openingDate+' '+closingDate);
   
    closingDate = moment(closingDate, "YYYY-MM-DD");
    openingDate = moment(openingDate, "YYYY-MM-DD");

    var hoje = moment().format("YYYY-MM-DD");
    var diferencaDiasTurma = closingDate.diff(openingDate, 'days'); // 12
    var diferencaDiasHoje = closingDate.diff(hoje, 'days'); // 12
    var resultado = ((diferencaDiasTurma - diferencaDiasHoje)*100) / diferencaDiasTurma;
    
    //console.log('(('+diferencaDiasTurma+' - '+diferencaDiasHoje+') *100)'+' / '+diferencaDiasTurma);
    //console.log(resultado);

    if(diferencaDiasTurma <= diferencaDiasHoje){ resultado = 0; }

    if(resultado === Infinity){ 
        
        resultado = 0; 
    
    
    }

    return resultado.toFixed(0);

  }
  
  render(){

    return (
      <>

        <style>{".navbar-top{ background:#0000 !important; } .navbar-top{ position: absolute; width: 100%; z-index:2; } "}</style>

        <div className="header d-flex align-items-center"
          style={{ minHeight: "200px", backgroundImage: 'url("/images/background.jpg")', backgroundSize: "cover"    }} >
          <span className="mask opacity-8" style={{background:'linear-gradient(-45deg,'+window.app.state.confs.headerDegrade1+','+window.app.state.confs.headerDegrade2+')'}} />
          <Container className="d-flex align-items-center" fluid>
          </Container>
        </div>
        
        <Container className="mt--6" fluid>
        <div className="row">
                <div className="col-md-6 col-xl-3">
                     <Mystudents users={this.state.myStudents} load={this.state.loadMyStudents} />
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="card-stats card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h5 className="text-uppercase text-muted mb-0 card-title">{this.props.translate['minhas_turmas']}</h5>
                                    <span className="h2 font-weight-bold mb-0">
                                    {

                                        (this.state.loadClassRoom === false) &&
                                        <font><ClipLoader sizeUnit={"px"} size={20} color={'#adb5bd'} loading={true} /></font>
                                    }
                                    {

                                        (this.state.loadClassRoom === true) &&
                                        <React.Fragment><font className="animated fadeIn">{this.state.classRooms.length}</font></React.Fragment>

                                    }
                                    </span>
                                </div>
                                <div className="col-auto col">
                                    <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow"><i className="fas fa-graduation-cap"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="card-stats card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h5 className="text-uppercase text-muted mb-0 card-title">Atividades agendadas</h5>
                                    <span className="h2 font-weight-bold mb-0">(Em breve)</span>
                                </div>
                                <div className="col-auto col">
                                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow"><i className="fas fa-chalkboard-teacher"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="card-stats card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h5 className="text-uppercase text-muted mb-0 card-title">Tarefas para corrigir</h5>
                                    <span className="h2 font-weight-bold mb-0">(Em breve)</span>
                                </div>
                                <div className="col-auto col">
                                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow"><i className="ni ni-active-40"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-9">
                    <div className="card">
                        <div className="border-0 card-header">
                            <h3 className="mb-0">{this.props.translate['minhas_turmas']}</h3>
                        </div>
                        <div className="table-responsive">
                            <table className="align-items-center table-flush table">
                                <thead className="thead-light">
                                    <tr>
                                        <th>{this.props.translate['nome']}</th>
                                        <th>{this.props.translate['curso']}</th> 
                                        <th><center>{this.props.translate['data_de_fechamento']}</center></th>
                                        <th><center>{this.props.translate['participantes']}</center></th>
                                        <th><center>{this.props.translate['progresso']}</center></th>
                                        <th>{this.props.translate['acoes']}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        this.state.classRooms.map((value,index) => {

                                            var course = this.props.getCourseState(value.course);
                                            var img;

                                            if(course.icon_file !== undefined){

                                                img = sessionStorage.getItem('centerBackend')+course.icon_file.url;

                                            }else{

                                                img = "";

                                            }





                                            if(value.inclassroom === undefined){

                                                return <React.Fragment key={value.id}></React.Fragment>
                        
                                              }else{
                        
                                                if(value.inclassroom.type === "" || value.inclassroom.type === undefined){ return <React.Fragment key={value.id}></React.Fragment>; }
                        
                                              }
                                                
                                              
                                              


                                            var permits;

                                            //pode ver todas as turmas
                                            if(this.props.permission.indexOf(19) === -1){

                                                if(value.inclassroom === undefined){
                                                
                                                console.log('ud1');
                                                return <React.Fragment key={value.id}></React.Fragment>

                                                }else{

                                                console.log('ud1');
                                                if(value.inclassroom.type === "" || value.inclassroom.type === undefined){ return <React.Fragment key={value.id}></React.Fragment>; }

                                                }
                                                
                                                var perm = window.app.state.permitions.map(function(x) {return x.id; }).indexOf(value.inclassroom.type);
                                                if(perm === -1){ console.log('ud99'); return <React.Fragment key={value.id}></React.Fragment>; }

                                                permits = window.app.state.permitions[perm].permissions; 
                                            
                                            }else{

                                                permits = this.props.permission;

                                            }

                                            return(
                                            <tr key={'arm_'+value.id}>
                                                <th scope="row">
                                                    <div className="align-items-center media">
                                                        <Link to={'/myclassroom/'+value.id} className="avatar" style={{backgroundColor:"#fff"}} >
                                                            <img style={{backgroundColor:"#fff"}} alt="..." src={img} />
                                                        </Link>
                                                        <div className="media">
                                                            <Link style={{color:'#525f7f'}} to={'/myclassroom/'+value.id}>
                                                                <span className="name mb-0 text-sm" style={{marginLeft:"5px"}}>{value.name}</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="budget"><input value={course.name} readOnly style={{border:'none'}} /> </td>
                                                <td>
                                                    <center>
                                                        { moment(value.closingDate,'YYYY-MM-DD').format('DD/MM/YYYY') }
                                                        {

                                                            (moment().isAfter(value.closingDate, 'year')) &&
                                                            <React.Fragment><div style={{color:'red'}}>(fechada)</div></React.Fragment>

                                                        }
                                                    </center>
                                                </td>
                                                <td>
                                                {

                                                    (this.state.classRooms[index].students === undefined) &&
                                                    <center><ClipLoader sizeUnit={"px"} size={15} color={'#adb5bd'} loading={true} /></center>

                                                }{

                                                    (this.state.classRooms[index].students !== undefined) &&
                                                    <center style={{fontWeight:'800'}} className="animated fadeIn">{this.state.classRooms[index].students.length}</center>

                                                }
                                                </td>
                                                <td>
                                                    {/*<div className="d-flex align-items-center"><span className="completion mr-2">{ calcProgressClassRoom+"%" }</span><div><div className="progress"><div className="progress-bar bg-warning" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width:calcProgressClassRoom+'%'}}></div></div></div></div>*/}
                                                <center>
                                                
                                                {
 
                                                    (this.state.classRoomProgress.map(function(x) {return x.classroom; }).indexOf(value.id) === -1) &&
                                                    <ClipLoader sizeUnit={"px"} size={15} color={'#adb5bd'} loading={true} />

                                                }

                                                {

                                                    (this.state.classRoomProgress.map(function(x) {return x.classroom; }).indexOf(value.id) !== -1) &&
                                                    <React.Fragment>
                                                    <font className="animated fadeIn">
                                                        { this.state.classRoomProgress[this.state.classRoomProgress.map(function(x) {return x.classroom; }).indexOf(value.id)].progress }%
                                                    </font>
                                                    </React.Fragment>

                                                }


                                                
                                                </center>
                                                </td>
                                                <td>
                                             
                                                        <React.Fragment>
                                                            <Link to={'/myclassroom/'+value.id} id={"tt_a"+value.id} idc={value.id} className="table-action"><i className="fas fa-sign-in-alt"></i></Link>
                                                            <UncontrolledTooltip delay={0} target={"tt_a"+value.id}>{this.props.translate['acessar_turma']}</UncontrolledTooltip>
                                                        </React.Fragment>

                                                    {

                                                        (permits.indexOf(18) !== -1) &&
                                                        <React.Fragment>
                                                            <Link to={'/classroom/dashboard/'+value.id+'/none'} id={"tt_"+value.id} idc={value.id} className="table-action"><i className="fas fa-chart-pie" /></Link>
                                                            <UncontrolledTooltip delay={0} target={"tt_"+value.id}>{this.props.translate['progresso_da_turma']}</UncontrolledTooltip>
                                                        </React.Fragment>

                                                    }
                                                    {
                                                        
                                                        (value.idOnline !== "" && value.idOnline !== null) &&
                                                        <React.Fragment>
                                                            <a rel="noopener noreferrer" target="_blank" href={window.location.origin+'/classenter/'+value.idOnline} id={"tta_"+value.id} idc={value.id} className="table-action"><i className="fas fa-external-link-alt"></i></a>
                                                            <UncontrolledTooltip delay={0} target={"tta_"+value.id}>{this.props.translate['link_acesso_online']}</UncontrolledTooltip>
                                                        </React.Fragment>

                                                    } 

                                                </td>
                                            </tr>)

                                        })

                                    }
                                     
                                </tbody>
                            </table>
                            {

                                (this.state.loadClassRoom === false) &&
                                <center style={{marginTop:'30px',marginBottom:'20px'}}><ClipLoader sizeUnit={"px"} size={30} color={'#adb5bd'} loading={true} /></center>
                            
                            }
                            {

                                (this.state.classRooms.length === 0 && this.state.loadClassRoom === true) &&
                                <center><h3 style={{margin:'30px 0px'}}>{this.props.translate['nenhuma_turma_foi_encontrada']}</h3></center>

                            }
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="mb-0">{this.props.translate['meus_alunos']}</h3>
                        </div>
                        <div className="card-body" style={{maxHeight:'400px',overflowY:'auto'}}>
                            <ul className="list my--3 list-group list-group-flush">
                                
                                {

                                    (this.state.loadMyStudents === false) &&
                                    <React.Fragment>

                                        <center style={{marginTop:'10px'}}><ClipLoader sizeUnit={"px"} size={30} color={'#adb5bd'} loading={true} /></center>

                                    </React.Fragment>

                                }
                                {
                                    
                                    (this.state.loadMyStudents === true) &&
                                    <React.Fragment>
                                    {
                                        
                                     

                                    this.state.myStudents.map((value,index) => {

                                        var online = this.helper.chat().isOnline(this.props.useronline,value.id);
                                        
                                        if(online === false){}
                                        
                                        if(online !== false){}
 
                                        if(parseInt(value.id) === parseInt(this.props.user.id)){

                                            

                                        }

                                        return(<li key={"teachera_"+value.id} className="px-0 list-group-item" useridc={value.id}>
                                                    <div className="align-items-center row">
                                                        <div className="col-auto col">
                                                            <font className="avatar rounded-circle">
                                                                <img alt="..." src={value.photo} />
                                                            </font>
                                                        </div>
                                                        <div className="col ml--2">
                                                            <h4 className="mb-0"><a href="#pablo">{value.name+' '+value.lastName}</a></h4>
                                                            
                                                            {

                                                                (online !== false) &&
                                                                <React.Fragment>
                                                                    <span className="text-success">●</span> 
                                                                    <small>Online</small> <Link to={online.location}>{online.location}</Link>
                                                                </React.Fragment>

                                                            }
                                                            {

                                                                (online === false) &&
                                                                <React.Fragment>
                                                                    <span className="text-danger">●</span> 
                                                                    <small>Offline</small>
                                                                </React.Fragment>

                                                            }

                                                        </div>
                                                        <div className="col-auto col">
                                                            <button hidden type="button" className="btn btn-primary btn-sm"><i className="far fa-comment-dots"></i> Chat</button>
                                                        </div>
                                                    </div>
                                                </li>)

                                    })
                                    
                                    }
                                    </React.Fragment>
                                }

                                

                            </ul>
                            {

                                (this.state.myStudents.length === 0 && this.state.loadMyStudents === true) &&
                                <center><h3 style={{margin:'30px 0px 0px 0px'}}>{this.props.translate['nenhum_usuario_foi_encontrado']}</h3></center>

                            }

                        </div>
                    </div>
                </div>
            </div>
        </Container>

      </>
    );
  }
}

export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    unit: state.unit,
    useronline: state.useronline
  
}))(Teacher);