import React from "react";
import {} from "reactstrap";
//import NotificationAlert from "react-notification-alert";
///import classnames from "classnames";
//import InputMask from 'react-input-mask';

var QRCode = require('qrcode.react');

class EditUserCardQRCode extends React.Component {

	constructor(props){

    	super(props);
  		this.state = this.props.state;
  		this.setState = this.props.setState;

  	}

  	render(){

  		return(<React.Fragment>
  			
  			<div className="row row_carduser" style={{marginTop:'10px'}}>

                        <div className="col-md-6">
                            
                            <div className="padrao_cartao" id="cartao_1" style={{"width":"600px","height":"340px"}}>

                                <img alt="..." className="img_principal" src="/images/card-front.png" style={{"height":"340px","width":"600px"}} />
                                <img alt="..." src="/images/card-logo.png" style={{"height":"165px","position":"absolute","top":"60px","left":"270px"}} />
                                <font style={{fontFamily:'Poppins',"position":"absolute","top":"240px","left":"172px","fontSize":"22px","fontWeight":"400",color:'#000'}}> Not just code. Computer Science </font>
 
                            </div>

                        </div>

                        <div className="col-md-6">
                            
                            <div className="padrao_cartao" id="cartao_2" style={{"width":"600px","height":"340px"}}>
                               
                                <img alt="..." className="img_principal" src="/images/card-verse.png" style={{"height":"340px","width":"600px"}} />
                                <img alt="..." src="/images/card-logo.png" style={{"height":"65px","position":"absolute","top":"29px","left":"14px"}} />

                                <font style={{"position":"absolute","top":"35px","left":"70px","fontSize":"22px","fontWeight":"400",color:'#000'}}> cs.plus</font>
                                <font style={{"position":"absolute","top":"67px","left":"70px","fontSize":"12px","fontWeight":"400",color:'#000'}}> Not just code. Computer Science </font>

								<div id="qr">
                                    { 

                                        (this.state.qrcode !== undefined) &&
                                        <React.Fragment>
                                            {

                                                (this.state.qrcode.qrcodePassword !== undefined) &&
                                                <React.Fragment><QRCode value={this.state.qrcode.qrcodePassword} size={170} renderAs="svg" /></React.Fragment>

                                            }
                                        </React.Fragment>

                                    }
								</div>

                                <div style={{"position":"absolute","top":"23px","left":"310px","width":"270px","textAlign":"center"}}>
                                    
                                    <font style={{"color":"#fff","fontSize":"24px","fontWeight":"700","textTransform":"uppercase","display":"none"}}>Malron Lima</font>
                                    <br/>
                                    <font style={{"color":"#fff","fontSize":"21px","fontWeight":"500","textTransform":"uppercase","display":"none"}}>admin</font>

                                </div>

                                    
                                <div className="p1" style={{"top":"145px","left":"35px"}}>

                                    <i className="far fa-user"></i>
                                    <font>{this.state.qrcode.name}</font>

                                </div>

                                <div className="p1" style={{"top":"190px","left":"35px"}}>

                                    <i className="fas fa-key"></i>
                                    <font>{this.state.qrcode.username}</font>

                                </div>

                                
                                <div hidden className="p1" style={{"top":"235px","left":"35px"}}>

                                    <i className="fas fa-sign-in-alt"></i>
                                    <font> seg9hfev19 seg14hfev19</font>

                                </div>

                                <div className="p1" style={{"top":"238px","left":"35px"}}>

                                    <i className="fas fa-link"></i>
                                    <font>{window.location.origin}</font>

                                </div>


                        </div>


                    </div>

                </div>

  		</React.Fragment>);

  	}


}


export default EditUserCardQRCode;