import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ClipLoader} from 'react-spinners';

class App extends Component {

    //constructor(props) {

        //super(props);

    //}

    render() {

        return (<React.Fragment>
            <div className="card-stats card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-uppercase text-muted mb-0 card-title">{this.props.translate['meus_alunos']}</h5>
                            <span className="h2 font-weight-bold mb-0">
                                {

                                    (this.props.load === false) &&
                                    <font><ClipLoader sizeUnit={"px"} size={20} color={'#adb5bd'} loading={true} /></font>
                                }
                                {

                                    (this.props.load === true) &&
                                    <React.Fragment><font className="animated fadeIn">{this.props.users.length}</font></React.Fragment>
                                    
                                }                            
                            </span>
                        </div>
                        <div className="col-auto col">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow"><i className="fas fa-users"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);

    }

}

export default connect(state =>({

    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
    unit: state.unit,
    useronline: state.useronline
  
}))(App);
