/*eslint-disable*/
import React from "react"; 
import {NavItem,NavLink,Nav,Input,Modal,Card,CardHeader,Row,Col,Button,Table,UncontrolledTooltip,Container,CardBody,Form,Alert,FormGroup,InputGroup,InputGroupAddon,InputGroupText} from "reactstrap";


// reactstrap components 

class Calendar extends React.Component {
  
  constructor(props){

    super(props);
    this.state = {openLog:false};
 
  }

  render(){
    
    return(


      <React.Fragment>
        <Container fluid  >
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a className="font-weight-bold ml-1" href="https://cs.plus/" target="_blank" >CS Plus</a>
                </div>
              </Col>
              <Col lg="6">
                <Nav className="nav-footer justify-content-center justify-content-lg-end">
                  <NavItem>
                    <NavLink href="https://cs.plus/" target="_blank">CS Plus</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://cs.plus/" target="_blank">Sobre nós</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#" onClick={() => this.setState({openLog:true}) } target="_blank" >Change Log</NavLink>
                  </NavItem> 
                </Nav>
              </Col>
            </Row>
          </footer>
        </Container>

        <Modal className="modal-dialog-centered" isOpen={this.state.openLog} >
          <div className="modal-body p-0">
            <Card className="  border-0 mb-0" style={{backgroundColor:'#fcfcfc'}}>
              
              <button style={{zIndex:'9999',position:'absolute',left:'calc(100% - 35px)',top:'15px'}} aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openLog:false})}><span aria-hidden={true}>×</span> </button>
              
              <div style={{position:'relative',position:'relative',height:'500px'}}>
                <iframe style={{height:'100%',width:"100%"}} scrolling="yes" frameborder="0" src="https://headway-widget.net/widgets/yEok87"></iframe>
              </div>
              
            </Card>
          </div>
        </Modal>


      </React.Fragment>);
  
  }

}

export default Calendar;
