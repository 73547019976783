export default function AccessLevel(state = [],action){
  
	if(action.type === "SET_PERMISSION"){

		state = action.data;

	}
 
	return state;

}