import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import store from './store';
import App from './App';

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.0.0";

import "./assets/css/animate.css";
import "./assets/css/hover.css";


//marlon styles
import "./assets/css/custom.css";
import "./assets/css/switdashboard.css";
import "./assets/css/custom_chard_studend.css";
import "./assets/css/login.css";
import "./assets/css/quiz.css";
import "./assets/css/selectUnidade.css";

window.iconsLesson = ['ni ni-caps-small','ni ni-button-play','ni ni-note-03','ni ni-paper-diploma','ni ni-controller'];
window.colorQuiz = {a:'#0247FE',b:'#44CC00',c:'#FF7E00',d:'#D3212D'};


var data2 = [];
data2.push({id:0,name:"Segunda-feira"});
data2.push({id:1,name:"Terça-feira"});
data2.push({id:2,name:"Quarta-feira"});
data2.push({id:3,name:"Quinta-feira"});
data2.push({id:4,name:"Sexta-feira"});
data2.push({id:5,name:"Sábado"});
data2.push({id:6,name:"Domingo"});
window.diasSemanas = data2;

 

ReactDOM.render(<Provider store={store}><App/></Provider>,document.getElementById("root"));