import React from 'react';
//Redirect
import{ BrowserRouter as Router, Route , Switch ,Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from "jquery";
import Login from "./pages/examples/Login.jsx";
import AcessClassRoom from "./pages/examples/AcessClassRoom.jsx";
import AcessPublicClassRoom from "./pages/examples/AcessPublicClassRoom.jsx";
import AdminLayout from "./../layouts/Admin.jsx"; 

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Main extends React.Component {

  	constructor(props){

    	super(props);
		this.state = {};
		
		this.loginSuccess = this.loginSuccess.bind(this);
		window.main = this;

  	}
  	
   	loginSuccess(session,user){ 

		console.log('session'+session);
		console.log('user');
		console.log(user);

  		sessionStorage.setItem('session',session);
		sessionStorage.setItem('userId',user.id);
		  
		cookies.set('session',session,{path:'/'});
		cookies.set('userId',user.id,{path:'/'});

		this.props.setAppState({session:session,userId:user.id,user:user});
		//console.log(user);
       
  	}

 	componentDidMount(){
		 
		$("head").append('<link rel="icon" href="'+sessionStorage.getItem('backend')+window.app.state.confs.favicon+'"></link>');

	}
	 
	render() {

		const loginSuccess = this.loginSuccess; 
		console.log('Deu rende main');


		console.log('this.props.getAppState().session: '+this.props.getAppState().session);

		console.log('window.app.state.user:');
		console.log(window.app.state.user);

		console.log('this.props.user.preference:');
		console.log(this.props.user.preference);

		if(window.app.state.user !== undefined){

			console.log('window.app.state.user.preference');
			console.log(window.app.state.user.preference);

		}
		

  		return ( 
  				<React.Fragment>
 
  					<title>{window.app.state.confs.systemName}</title>

	  				<Router>
						{
							//Caso tenha session o storage
							(this.props.getAppState().session !== "" && window.app.state.user !== undefined) &&
							<React.Fragment>

								{

									(window.app.state.user.preference !== undefined) &&
									<React.Fragment>
										{/**/} 
											<Switch>
												<Route exact={true} path="/classenter/:classroom" render={ (props) => <AcessClassRoom {...props} {...{ loginSuccess }} /> }/>
												<Route exact={true} path="/sala/:classroom" render={ (props) => <AcessClassRoom {...props} {...{ loginSuccess }} /> }/>
												<Route path="*" render={props => <AdminLayout {...props} />} />
											</Switch>
										
									</React.Fragment>

								}
								
							</React.Fragment>

						}{
							//Caso o session no app esteja em branco, exibe a tela de login
							(this.props.getAppState().session === "" && window.app.state.user === undefined) &&
							<React.Fragment>
																 
								{/*	
								<AcessClassRoom {...{ loginSuccess }} />
									<Login {...{ loginSuccess }} />
								*/}
								
								<Switch>
									
									<Route exact={true} path="/confirm/:confirmCode" render={ (props) => <Login {...props} {...{ loginSuccess }} /> } />
									<Route path={["/publicclassenter/:classroom/:extra","/publicclassenter/:classroom"]} render={ (props) => <AcessPublicClassRoom {...props} {...{ loginSuccess }} /> } />
									
									<Route exact={true} path="/classenter/:classroom" render={ (props) => <AcessClassRoom {...props} {...{ loginSuccess }} /> } />

									<Route exact={true} path="/classenter/:classroom" render={ (props) => <AcessClassRoom {...props} {...{ loginSuccess }} /> } />
									<Route exact={true} path="/sala/:classroom" render={ (props) => <AcessClassRoom {...props} {...{ loginSuccess }} /> } />
									
									<Route exact={true} path="/" render={ (props) => <Login {...props} {...{ loginSuccess }} /> }/>
									<Route exact={true} path="/recoverpassword/:id" render={ (props) => <Login {...props} {...{ loginSuccess }} /> } />
									
									<Redirect to="/" /> 

								</Switch>
							</React.Fragment>
						 	

						}
					</Router>

				</React.Fragment>);

	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
  }))(Main);