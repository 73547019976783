import React from "react";
//import moment from "moment";
//import $ from 'jquery';
import {Button} from "reactstrap";
import { connect } from 'react-redux';
import api from "./../../../../Api.js";
import QuizComp from './../QuizLib/QuizApp.js';

function shuffle(o) {
	for (var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
	return o;
}

class Quiz extends React.Component {

	constructor(props){

		super(props); 
		
		this.state = {loadQuiz:false,startQuiz:false,lesson:this.props.state.lesson};
	 	this.getQuiz = this.getQuiz.bind(this);
	 	this.props.setState({quizResult:{}});
		
		this.initQuiz = this.initQuiz.bind(this);

		window.quizviwer = this;

	}

	//Busca os dados do quiz
	async getQuiz(){

		await api.getQuizLesson(this.props.state.lesson.quiz).then((res) => {
			
			//mistura as respostas do quiz
			var quiz = res.data;
			for(let index = 0; index < quiz.questions.length; index++){
				 
				var correta_atual = quiz.questions[index].correctAnswer;

				//console.log('Correto: '+correta_atual);
				//console.log(quiz.questions[index]);

				var resposta_correta = quiz.questions[index].answers[correta_atual];
				//console.log('Resposta correta atual: '+resposta_correta);

				var mistura = shuffle(quiz.questions[index].answers);
				var nova_correta = quiz.questions[index].answers.indexOf(resposta_correta);
				//console.log('nova_correta: '+nova_correta);
				
				quiz.questions[index].correctAnswer = nova_correta;
				quiz.questions[index].answers = mistura;

				//var txt_correta = correta_atual

				//var novaMistura = shuffle(quiz)
				//console.log('');console.log('');console.log('');console.log('');console.log('');
				
			}

			this.setState({loadQuiz:true,quiz:res.data});

	  	}).catch(error => {

	        console.log(error.data);
	        console.log(error);
			console.log(error.response);
			

	  	});

	}

	componentDidUpdate(){
		//Para não bugar quando der next... e o próximo por um quiz também
		if(parseInt(this.state.idLesson) !== parseInt(this.props.state.lesson.id)){

			this.initQuiz();

		}

	}
	
	async componentDidMount(){

		this.initQuiz();

	}

	async initQuiz(){

		await this.setState({loadQuiz:false,startQuiz:false,lesson:this.props.state.lesson,idLesson:this.props.state.lesson.id});
		this.getQuiz();

		var quiz = this.state.lesson.quiz;
      	var classroom = this.props.propsextend.classroom;
      	var lesson = this.props.propsextend.lesson;

		var cache = await api.getProgressQuiz(classroom,quiz,lesson).then(async (res) => {

			this.setState({startQuiz:true});
			return res.data;
  
		}).catch(error => { 
  
			console.log(error.data);
			console.log(error);
			console.log(error.response); 
			return false; 
  
		});

		if(cache !== false){ 
			
		}

	}
 
	render(){

		return(<React.Fragment> 
			{
				(this.state.startQuiz === true && this.state.loadQuiz === true) &&
				<QuizComp quizId={this.state.lesson.quiz} propsextend={this.props.propsextend} state={this.state} />
			}
			{
				(this.state.startQuiz === false && this.state.loadQuiz === true) &&
		 		<React.Fragment> 
					<style>{'#lessonView{ display:flex; align-items: center; } .completar_continuar{ display:none; } .concluir_unit{ display:none; } '}</style>
					<div className="" style={{zIndex:"990",position:"relative",width:'100%'}}>
						<center>
							<img alt="..." style={{height:'120px'}} src="/images/Quiz-Games.png" />
							<h1 hidden>{this.state.quiz.quizTitle}</h1>
							<h5 style={{marginLeft:'100px',marginRight:'100px'}} dangerouslySetInnerHTML={{__html:this.state.quiz.quizSynopsis}}></h5>
							<Button style={{marginTop:'15px'}} onClick={ () => this.setState({startQuiz:true}) } color="secondary" type="button"><i className="fas fa-exclamation"></i> {this.props.translate['iniciar_quiz']}</Button>
						</center>
					</div>
				</React.Fragment>
			}
		</React.Fragment>);

	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	magictools: state.magictools
  
}))(Quiz);