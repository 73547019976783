import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

function remove_tags(html){

  html = html.replace(/<img/g, '||img||');
  html = '<p>'+html+'</p>';
  var text = $(html).text();
  text = text.replace(/\|\|img\|\|/g, '<img');

  console.log('texta antes: '+text);
  text = text.replaceAll('[[url_backend_center]]',sessionStorage.getItem('centerBackend'));
  console.log('text: '+text);
  
  return text;  

}



const Answer = ({answer,handleAnswerClick,handleEnterPress}) => {
  return (
    <li
      className="question-answer"
      tabIndex="0"
      onClick={handleAnswerClick}
      onKeyDown={handleEnterPress}
      dangerouslySetInnerHTML={{__html:remove_tags(answer)}} ></li>
  );
}

Answer.propTypes = {
  answer: PropTypes.element.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
  handleEnterPress: PropTypes.func.isRequired
};

export default Answer;
