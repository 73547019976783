import api from './../../Api.js';

function Chart_helper(props){
		
	this.props = props; 

}

Chart_helper.prototype.chartQuizperFormance = async function(user){

    //for (var i = 0; i < this.props.units.length; i++) {//}
    var req = await api.chartQuizperFormance(user).then(async (res) => {
        
        return {status:200,data:res.data};
       
      }).catch(error => {
  
        if(error.response.status === 400){
  
          return {status:400,data:error.response};
  
        }
  
        return false;
  
      });
  
    return req;
  
}

Chart_helper.prototype.chartTimeInvested = async function(user){

  //for (var i = 0; i < this.props.units.length; i++) {//}
  var req = await api.chartTimeInvested(user).then(async (res) => {
      
      return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

        return {status:400,data:error.response};

      }

      return false;

    });

  return req;

}


Chart_helper.prototype.chartTimeLessonsUnit = async function(user,classroom,unit){

  //for (var i = 0; i < this.props.units.length; i++) {//}
  var req = await api.chartTimeLessonsUnit(user,classroom,unit).then(async (res) => {
      
      return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

        return {status:400,data:error.response};

      }

      return false;

    });

  return req;

}

Chart_helper.prototype.chartTimeUnit = async function(user,classroom){

  //for (var i = 0; i < this.props.units.length; i++) {//}
  var req = await api.chartTimeUnit(user,classroom).then(async (res) => {
      
      return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

        return {status:400,data:error.response};

      }

      return false;

    });

  return req;

}

Chart_helper.prototype.chartQuizUserUnit = async function(user,classroom,unit){

  var req = await api.chartQuizUserUnit(user,classroom,unit).then(async (res) => {
      
      return {status:200,data:res.data};
     
    }).catch(error => {
  
      if(error.response.status === 400){

        return {status:400,data:error.response};

      }

      return false;

    });

  return req;

}


Chart_helper.prototype.getTotalTimeUser = async function(user){

  var req = await api.getTotalTimeUser(user).then(async (res) => {
      
      return {status:200,data:res.data};
     
    }).catch(error => {

      if(error.response.status === 400){

        return {status:400,data:error.response};

      }

      return false;

    });

  return req;

}

export default Chart_helper;