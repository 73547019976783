import React from "react";
import moment from "moment";
//import $ from 'jquery';
import {ClipLoader} from 'react-spinners';

import {} from "reactstrap";
import ScrollContainer from 'react-indiana-drag-scroll';

class Detailed extends React.Component {

	constructor(props){

		super(props);
		this.altDefault = 55;
		this.altMultiplayer = 26;
		window.dashdetailed = this;

	}

	async componentDidMount(){
		
	 
	}

	//Para somar todos os times
	sumAllTime(times){

		var total = 0;

		for(var i = 0; i < times.length; i++) {

			var hms = times[i].time;   // your input string
			var a = hms.split(':'); // split it at the colons

			// minutes are worth 60 seconds. Hours are worth 60 minutes.
			var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);  
			total += seconds
		}
		
		var t = moment('00:00:00', 'HH:mm:ss').add(total, 'seconds').format('HH:mm:ss');
		return t;

	}

	componentDidUpdate(){

		
  

	}

	render(){

		return(<React.Fragment>
			  <div className="row">
			  	{

					(this.props.state.loadProgres !== true) &&
					<React.Fragment>
						<div className="col-md-12" style={{marginTop:"40px",marginBottom:"30px"}}>
							<center><ClipLoader sizeUnit={"px"} size={60} color={'#adb5bd'} loading={true} /></center>
						</div>
					</React.Fragment>

				}

	            <div className="col-md-2 box_left_detailed" style={{boxShadow:'8px 0px 14px -20px #000'}}>
				
				
				

	         	{

	            	(this.props.state.loadProgres === true) &&
	            	<React.Fragment>

	            		<div className="dashboard_class_user" style={{borderTop:'1px solid #ffffff00',height:'28px'}}></div>
	            			{
	            				this.props.state.progress.users.map((value,index) => {

	            					var tot = this.props.maxArrayReturn(value.id);
			    					//console.log(tot);

			    					var height = (tot * this.altMultiplayer);
			    					if(height === 0 || height < 55){ height = this.altDefault+height; }
			    					var sty = {height:height+'px'};

			    					if(this.props.state.openTimes[value.id] === true){ 

			    						sty = {height:height+'px'};

			    					}else{ 
			    						
			    						sty = {height:this.altDefault+'px'};

									}
									
									var css = {cursor:'pointer',display:'flex'};
									if(value.userInClass === false){ css.opacity = "0.5"; }

	            					return( <React.Fragment key={value.id}> 
												<div className="dashboard_class_user dashboard_class_user_5" style={sty}>
													<div className="name_user" >
														<div onClick={ () => this.props.openChartUser(value) } style={css}>
			            									<img alt="..." className="avatar rounded-circle mr-3" src={value.photo} /> 
			            									<input style={{cursor:'pointer',width:'130px'}} readOnly className="inputViwerName_classroom " value={''+value.name+' '+value.lastName} />
			        									</div>
			        									{	

			        										(this.props.state.openTimes[value.id] !== true) &&
															<button onClick={ () => this.props.openUser(value.id) } type="button" className="buttonOpenDashBoard btn-icon-only rounded-circle btn btn-secondary"><span className="btn-inner--icon"><i className="fas fa-chevron-down"></i></span></button>
													
			        									}{	

			        										(this.props.state.openTimes[value.id] === true) &&
															<button onClick={ () => this.props.closeUser(value.id) } type="button" className="buttonOpenDashBoard btn-icon-only rounded-circle btn btn-secondary"><span className="btn-inner--icon"><i className="fas fa-chevron-up"></i></span></button>
													
			        									}
		        									</div>
												</div>
	            							</React.Fragment>)

	            				})

	            			}

							 
	            		
	            	</React.Fragment>

	            }
	            </div>

	            {

	            	(this.props.state.loadProgres === true) &&

	             
	            <div className="col-md-10" style={{paddingLeft:'0px'}}>
	            	<ScrollContainer id="over_mouse_t" className="scroll-container" style={{overflow:'auto'}}>
					<div className="listLessonsTop" style={{backgroundColor:"#fff",borderRadius:'0px',boxShadow:'0px 5px 8px -11px #000'}}>
		            	{
		            		this.props.state.progress.lessons.map((value,index) => {

		            			return(
		            				<React.Fragment key={value.id}>
		            					<div className="lessonTop"><i className={window.iconsLesson[value.icon]}></i> <font style={{fontWeight:'700'}}>{value.name}</font></div>		
		            				</React.Fragment>)

		            		})
		            	}
		            </div>


		            {
	    				this.props.state.progress.users.map((value,index) => {

	    					var tot = this.props.maxArrayReturn(value.id);
	    					var sty;
	    					//console.log(tot);

	    					var height = (tot * this.altMultiplayer);
	    					if(height === 0 || height < 55){ height = this.altDefault+height; }


	    					if(this.props.state.openTimes[value.id] === true){ 

	    						sty = {height:height+'px'};

	    					}else{ 
	    						
	    						sty = {height:this.altDefault+'px'};

	    					}

	    					if(index === 0){ sty.borderTop = '1px solid #e9ecef00'; }

	    					return(<React.Fragment key={index}> 
										<div className="dashboard_class_userList_user" style={sty}>
											<React.Fragment>
											{

												this.props.state.progress.lessons.map((value2,index2) => {

													var cl;

													if(this.props.state.openTimes[value.id] === true){ 

														cl = "resto_progresso";
														height = 20;
														sty = {};

													}else{

														cl = "resto_progresso hidden_progress";
														sty = {}; 

													}

													return(
														<div key={index2} className="listTime_dashboard_class_room" style={sty}>
															<div user={value.id} lesson={value2.id} className="rrasta animated" style={{cursor:'grab',padding:'15px 0px 0px 0px',height:'35px'}}>
																<font className="time_progress">{this.sumAllTime(value.progress[value2.id])}</font>
																<font className="tot_register">{value.progress[value2.id].length}</font>
															</div>
															<div className={cl}>
																{

																	value.progress[value2.id].map((value3,index3) => {

																		//console.log(value3);
																		var dd = moment(value3.createDate,'YYYY-MM-DD HH:mm:ss');

																		return(
																			<div key={index3} className="outras_tentativas">
																				<font className="date">{dd.format('DD/MM/YYYY')} as {dd.format('HH:mm')}</font> <font className="timer">{value3.time}</font>
																			</div>)

																	})

																}
															</div>
														</div>)
												})

											} 
											</React.Fragment>
										</div>
	    							</React.Fragment>)
	    				})
	    			}
	            </ScrollContainer>
	            </div>
	            }
	        </div>
			</React.Fragment>);

	}

}

export default Detailed;