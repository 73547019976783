import React from "react";
import {Link} from "react-router-dom";
import {Alert,Modal,CardBody,Form,Button,Card} from "reactstrap";
import InputMask from 'react-input-mask';
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import $ from "jquery";
import moment from "moment";
import reduxHelper from "./../../../helper/index.js";
//import api from "../../../Api.js";

class ImportXlsxClassRoom extends React.Component {

	constructor(props){

        super(props);
        
        this.setStateTop = this.props.setState;
        this.topState = this.props.topState;

        this.filterClassRoom = this.filterClassRoom.bind(this);
        
        this.state = {
            classRoomList:[],
            importError:"",
            erroXLSX:"",
            verificado:false
        };

        this.checkXlsx = this.checkXlsx.bind(this);
        this.reset = this.reset.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.checkXlsxServer = this.checkXlsxServer.bind(this);
        this.helper = new reduxHelper(this.props);
        this.manual = this.manual.bind(this);
        this.removeLine = this.removeLine.bind(this);
        this.addLine = this.addLine.bind(this);

        window.importxlsxclassroom = this;

    }

    //Para poder reenviar o arquivo xlsx
    reset(){

        this.setState({classRoomList:[],verificado:false});

    }

    //Para remover uma linha da planilha
    removeLine(index){

        var classRoomList = this.state.classRoomList;
        classRoomList.splice(index,1);
        this.setState({classRoomList:classRoomList});
        this.checkXlsxServer();

    }

    //Para adicionar uma linha na planilha
    addLine(){

        var classRoomList = this.state.classRoomList;
        classRoomList.push({name:"",course:"",openingDate:"",closingDate:"",idOnline:""});
        this.setState({classRoomList:classRoomList,verificado:false});

    }

    filterClassRoom(obj){

        var obj2 = JSON.parse(JSON.stringify(obj));
        for (let index = 0; index < obj2.length; index++) {
            
            obj2[index].openingDate = moment(obj2[index].openingDate,'DD/MM/YYYY').format('YYYY-MM-DD');
            obj2[index].closingDate = moment(obj2[index].closingDate,'DD/MM/YYYY').format('YYYY-MM-DD');
            obj2[index].company = this.props.user.preference.selectcompany;
            
        }

        return obj2;

    }

    //Para verificar os dados do xlsx no servidor
    async checkXlsxServer(){

            var data = await this.helper.classroom().classRoomCheckXlsx(this.filterClassRoom(this.state.classRoomList));
            if(parseInt(data.status) === 200){
    
                await this.setState({erroXLSX:'',verificado:true});
    
            }
    
            if(parseInt(data.status) === 400){
    
                //console.log(data.data.data.msg);
    
                await this.setState({erroXLSX:''});
                await this.setState({erroXLSX:data.data.data.msg});
                
    
            }
    
            //console.log(data);
    
    }


    async cadastrar(){

        var data = JSON.parse(JSON.stringify(this.filterClassRoom(this.state.classRoomList)));

        for(let index = 0; index < data.length; index++){
           
            await this.props.postClassRoom(true,data[index]);
           // console.log(cadastro);
            
        }

        this.props.setState({openModalImportClassRoom:false}); 
        this.reset();

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}!</span><span data-notify="message">{this.props.translate['a_importacao_foi_realizada']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);
        
        this.props.getAllClassRoom();

    }

    //Para verificar o arquivo enviado
    async checkXlsx(){

        await this.setState({importError:""});

		var input = $('#input_xlsx');
        var file = input[0].files;
        
        $('label[for="input_xlsx"]').html($('#input_xlsx')[0].files[0].name);

        var users = [];

        if(file.length === 0){ this.setState({importError:this.props.translate['voce_deve_selecionar_o_arquivo']}); return false; }
        
        //Tento abrir o arquivo XLSX
        try {
          await readXlsxFile(file[0]).then((rows) => {
            users = rows;
        })   
        } catch (error) {
            this.setState({importError:this.props.translate['nao_foi_possivel_abrir_o_arquivo']});
           // console.log('a1');
            return false;
        }
       
        //Se tiver usuario...
        if(users.length !== 0){

            //Caso não tenha linha de usuario
            if(users.length === 1){

                this.setState({importError:this.props.translate['o_arquivo_enviado_esta_vazio']});
               // console.log('a2');
                return false;

            }

            var classroomscheck = [];

            for(let index = 1; index < users.length; index++) {
                
                var classroomparse = {};
                classroomparse.name = users[index][0];
                
                classroomparse.course = users[index][1];
                classroomparse.idOnline = users[index][4];
                
                classroomparse.openingDate = await moment(users[index][2]).format('DD/MM/YYYY');
                classroomparse.closingDate = await moment(users[index][3]).format('DD/MM/YYYY');

                try {
                    
                    for(let index = 0; index < this.props.state.course.length; index++){
                             
                        if(this.props.state.course[index].name.toLowerCase() === classroomparse.course.toLowerCase()){ 
                            
                            classroomparse.course = this.props.state.course[index].id; 
                            //console.log('setou');

                        }
                                            
                    }

                }catch(error){}
                              
                classroomscheck.push(classroomparse);
                
            }

            await this.setState({classRoomList:classroomscheck,importError:""});
            this.checkXlsxServer();

        }else{

            this.setState({importError:this.props.translate['nao_foi_possivel_abrir_o_arquivo']});
            //console.log('a3');

        }

    }

    changeValue(index,value,position){

        var classRoomList = this.state.classRoomList;
        classRoomList[index][position] = value;
        this.setState({classRoomList:classRoomList,verificado:false});

    }
    
    //Para abrir o modo de cadastrar manualmente
    async manual(){

        await this.setState({classRoomList:[]});
        this.addLine();

    }

    //Para poder reenviar o arquivo xlsx
   	render(){

        var st = {};
        if(this.state.classRoomList.length !== 0){ st = {maxWidth:'98%'}; }

        return(<React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <Modal className="modal-dialog-centered" style={st} isOpen={this.props.state.openModalImportClassRoom} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">{this.props.translate['importar_por_planilha']}</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => { this.props.setState({openModalImportClassRoom:false}); this.reset(); } }><span aria-hidden={true}>×</span> </button>
            </div>
            <CardBody className=" " style={{}}>
                <Form role="form" type="post" onSubmit={ (event) => this.props.saveUser(event) }>
                    {
                        (this.state.importError !== "") &&
                        <Alert color="danger" className="animated tada"><strong>{this.props.translate['ops']}!</strong> {this.state.importError}</Alert>
                    }
                    {

                        (this.state.classRoomList.length === 0) &&
                        <React.Fragment>
                            <center><font>{this.props.translate['selecione_o_arquivo']}:</font></center>
                            <div style={{display:"flex"}}>
                                <div className="custom-file form-control-sm">
                                    <input onChange={ (event) => { this.checkXlsx() } } className="custom-file-input" id="input_xlsx" type="file" />
                                    <label className="custom-file-label " htmlFor="input_xlsx">{this.props.translate['selecionar_arquivo']}</label>
                                </div>
                                <Button onClick={() => this.checkXlsx() } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> {this.props.translate['enviar']}</Button>
                            </div>
                        </React.Fragment>

                    }{

                        (this.state.classRoomList.length !== 0) &&
                        <React.Fragment>

                            {

                                (this.state.erroXLSX !== "") &&
                                <div className="container">
                                    <div className="alert alert-danger animated tada" role="alert">
                                        <strong>{this.props.translate['ops']}!</strong> {this.state.erroXLSX}
                                    </div>
                                </div>

                            }
                            {

                                (this.state.erroXLSX === "" && this.state.verificado === true) &&
                                <div className="container">
                                    <div className="alert alert-success animated fadeIn" role="alert">
                                        <strong><i className="fas fa-check"></i></strong> {this.props.translate['tudo_pronto_para_cadastrar']}
                                    </div>
                                </div>

                            }

                            <div className="container table-responsive div_tab_importxlsx">
                                
                                <table className="align-items-center table-flush table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>#</th>
                                            <th>{this.props.translate['nome']}:</th>
                                            <th>{this.props.translate['curso']}:</th>
                                            <th>{this.props.translate['data_de_abertura']}:</th>
                                            <th>{this.props.translate['data_de_fechamento']}:</th>
                                            <th>{this.props.translate['indetificador_online']}:</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table_importXLXS">
                                        {
                                            this.state.classRoomList.map((value,index) => {

                                                return(<tr key={'input_xlsx_'+index}>
                                                    <td>{(index+1)}</td>
                                                    <td><input onChange={ (event) => this.changeValue(index,event.target.value,'name') } style={{width:'90px'}} value={this.state.classRoomList[index].name}/></td>
                                                    <td>
                                                        <select value={this.state.classRoomList[index].course} onChange={ (event) => this.changeValue(index,event.target.value,'course') }>
                                                            <option value=""></option>
                                                            {

                                                                this.props.state.course.map((value3,index3) => {

                                                                    return(<option value={value3.id} key={"i"+index+"iic"+index3}>{value3.name}</option>)

                                                                })

                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <InputMask onChange={ (event) => this.changeValue(index,event.target.value,'openingDate') } value={this.state.classRoomList[index].openingDate} mask="99/99/9999" maskChar="" onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
                                                    </td>
                                                    <td>
                                                        <InputMask onChange={ (event) => this.changeValue(index,event.target.value,'closingDate') } value={this.state.classRoomList[index].closingDate} mask="99/99/9999" maskChar="" />
                                                    </td>
                                                    <td>
                                                        <input onChange={ (event) => this.changeValue(index,event.target.value,'idOnline') } value={this.state.classRoomList[index].idOnline} />
                                                    </td>
                                                    <td><i style={{cursor:'pointer'}} onClick={ () => this.removeLine(index) } className="far fa-trash-alt"></i></td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>

                    }
                    <div className="text-center">
                        {   
                            (this.state.classRoomList.length !== 0) &&
                            <Button style={{marginLeft:'10px'}} className="my-4" color="danger" type="button" onClick={ () =>{ this.setStateTop({openModalImport:false}); this.reset(); } }><i className="far fa-times-circle"></i> {this.props.translate['cancelar']}</Button>
                        }
                        
                            <Link target="_blank" download to="/planilha/Template de importacao de turma.xlsx"><Button className="my-4" color="default" type="button" ><i className="fas fa-file-download"></i> {this.props.translate['baixar_template']} </Button></Link>
                        
                        {   
                            (this.state.classRoomList.length === 0) &&
                            <Button style={{marginLeft:'10px'}} className="my-4" color="secondary" type="button" onClick={ () =>{  this.manual(); } }><i className="fas fa-list-ol"></i> {this.props.translate['cadastrar_manualmente']}</Button>
                        }
                        {
                            (this.state.classRoomList.length !== 0) &&
                            <Button style={{marginLeft:'10px'}} className="my-4" color="secondary" type="button" onClick={ () =>{  this.addLine(); } }><i className="fas fa-plus"></i> {this.props.translate['adicionar_linha']}</Button>

                        }
                        {
                            (this.state.classRoomList.length !== 0) &&
                            <React.Fragment>
                                {

                                    (this.state.verificado === false) &&
                                    <Button style={{marginLeft:'5px'}} onClick={ () => this.checkXlsxServer() } className="my-4" color="secondary" type="button" ><i className="fas fa-clipboard-check"></i> {this.props.translate['verificar_dados']}</Button>
                 
                                }
                                {
                                    (this.state.erroXLSX === "" && this.state.verificado === true) &&
                                    <Button onClick={ () => this.cadastrar() } className="my-4" color="success" type="button" ><i className="fas fa-paper-plane"></i> {this.props.translate['cadastrar']}</Button>
                                }
                            </React.Fragment>
                        }
                    </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal></React.Fragment>);

  	}

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,

}))(ImportXlsxClassRoom);