import React from "react";
import {Button,Modal,Card} from "reactstrap";
import api from "./../../../Api.js";
import NotificationAlert from "react-notification-alert";
import $ from "jquery";
import { connect } from 'react-redux';

//var AvatarCropper = require(""); 
import Avatar from 'react-avatar-edit';

class ChangePhoto extends React.Component {

	constructor(props){

    	super(props);
 		
 	    this.state = {preview:null,src:''};
 		this.onCrop = this.onCrop.bind(this);
    	this.onClose = this.onClose.bind(this);
    	this.savePhoto = this.savePhoto.bind(this);

    	window.changephoto = this;

  	}

	onClose() {
	    this.setState({preview: null})
	}
	  
	onCrop(preview) {
	    this.setState({preview})
	}

	onBeforeFileLoad(elem){

		var el = elem.target.files[0];
		if(el.type !== "image/jpeg" && el.type !== "image/png"){ 
 			
 			elem.target.value = "";
			 
		} 
 
	}

	//Para salvar a foto do usuário
	async savePhoto(){

		//console.log('salvou photo');

		var a = await api.savePhoto(this.props.state.userPhoto,{photo:this.state.preview}).then((res) => {

			this.props.setState({openModalChangePhoto:false});
			$(".photo_user[idc="+res.data.user+"]").attr('src',res.data.photo);

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['foto_alterada_com_sucesso']}!</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        	this.refs.notificationAlert.notificationAlert(options);

	    }).catch(error => { 

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response); 
	        return false; 

	    });

	    return a;

	}

  	render(){

  		var c1;
  		//this.props.state.openModalChangePhot
  		if(this.state.preview === null){

  			c1 = "col-md-12";

  		}else{

  			c1 = "col-md-6";

  		}

  		return(<React.Fragment>
		<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
		<Modal className="modal-dialog-centered" isOpen={this.props.state.openModalChangePhoto} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">{this.props.translate['alterar_foto']}</h6>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.setState({openModalChangePhoto:false})}><span aria-hidden={true}>×</span> </button>
            </div>
            </Card>
          </div>
         
          <div className="modal-footer" style={{display:'block'}}>
          		
				<center>
					<div className="row">

						<div class={c1} >
							<Avatar width={160} height={160} onCrop={this.onCrop} onClose={this.onClose} src={this.state.src} label={this.props.translate['selecionar']} onBeforeFileLoad={this.onBeforeFileLoad} />
						</div>

						<div class={c1} >
					        {

					        	(this.state.preview !== null) &&
					        	<React.Fragment>
					        		<div>
						        		<center>Pré-Visualização</center>
						        		<img style={{width:'160px',height:'160px',boxShadow:'0px 7px 10px -8px #000',borderRadius:'100px'}} src={this.state.preview} alt="Preview" />
									</div>
						        	<div style={{marginTop:'10px'}}>	
							        	<Button onClick={ () => this.savePhoto() } color="primary" type="button">{this.props.translate['salvar']}</Button>
							        </div>
					        	</React.Fragment>

					        }
			       		</div>

		       		</div>
		        </center>

          </div>

        </Modal>

  			 

		</React.Fragment>)
  	}

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
  }))(ChangePhoto);