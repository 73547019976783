import React from "react";
import {Container,Row,Col,Card,CardHeader,Modal,Button} from "reactstrap";
import { connect } from 'react-redux';
import api from "./../../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import PostEditSchedule from "./schedule/PostEditSchedule.jsx";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import NotificationAlert from "react-notification-alert";

import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'


class Schedule extends React.Component {

    constructor(props){

        super(props);
        this.state = {loadSchedule:false,schedules:[],openModalPostSchedule:false,editSchedule:undefined};

        this.getSchedules = this.getSchedules.bind(this);
        this.editSchedule = this.editSchedule.bind(this);
        this.newSchedule = this.newSchedule.bind(this);

        window.schedule = this;

    }

    editSchedule(){ this.setState({openModalPostSchedule:true,editSchedule:undefined}); }
    newSchedule(id){ this.setState({openModalPostSchedule:true,editSchedule:id}); }

    componentDidMount(){

        this.getSchedules();

    }

    async getSchedules(){
   
        await api.getSchedulesClassroom(this.props.classRoom).then((res) => {

			this.setState({loadSchedule:true,schedules:res.data});
        
        }).catch(error => {

			console.log(error);
			if(error.response !== undefined){ console.log(error.response); }

        });
    
    }
   
    render(){

        return(<React.Fragment>

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert"/></div>

            <Card>
                <CardHeader>
					<Row>
                        <div className="col-md-12">

                        <center><button style={{marginBottom:'21px'}} onClick={() => { this.newSchedule(); }} type="button" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i> Cadastrar aula</button></center>

                            <VerticalTimeline layout={this.props.layout}>
                                
                                {
                        
                                    this.state.schedules.map((value,index) =>{
                        
                                        return (<VerticalTimelineElement key={'an_'+index}
                                            className="vertical-timeline-element--work hvr-grow"
                                            style={{width:'100%'}}
                                            contentStyle={{ background:'#fff',color:'#000',boxShadow:'0px 2px 6px -5px #000'}}
                                            contentArrowStyle={{ borderRight:'7px solid rgb(162, 162, 162)' }}
                                            date={<div style={{position:'relative',top:'-17px'}}><div style={{color:"#5a5a5a",marginBottom:'5px'}}>{moment(value.createDate).format('YYYY/MM/DD')} {value.start_time}</div> <button onClick={ () => this.editAnnotation(value.id) } type="button" className="btn btn-secondary btn-sm"><i className="far fa-edit"></i> Editar</button><button onClick={() => this.deleteAnnotation(value.id)} type="button" className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button></div>}
                                            iconStyle={{color: '#fff' }}
                                            icon={<React.Fragment>
                        
                                            {
                        
                                                (value.user !== null) &&
                                                <React.Fragment>
                                                    {
                                                    
                                                    //<div className={"show_name_annotation animated fadeIn"} style={{color:"#000"}}></div>
                                                    //<img alt="..." style={{width:"inherit" }} src={'value.user.photo'} id={'tooltip804044742_'+value.id}/>

                                                    }
                                                    <div style={{backgroundColor:'#5e72e4',position:'absolute',width:'100%',height:'100%',borderRadius:'42px',justifyContent:'center',textAlign:'center',paddingTop:'11px',fontSize:'25px'}}>{(this.state.schedules.length - index)}</div>
                                                
                                                    <div style={{position:'absolute',width:'110px',fontSize:'22px',height:'25px',top:'-35px',left:'-16px'}}>
                                                        <Rater total={5} rating={2} />
                                                    </div>

                                                </React.Fragment>
                        
                                            }
                        
                                            {
                        
                                                (value.user === null) &&
                                                <React.Fragment>
                                                
                                                </React.Fragment>
                        
                                            }
                                                
                        
                                            </React.Fragment>} >
                                            <h3 className="vertical-timeline-element-title">{value.name}</h3>
                                            <h4 hidden className="vertical-timeline-element-subtitle">Miami, FL</h4>
                                            <p>{value.description}</p>
                                        </VerticalTimelineElement>);
                        
                                    })
                        
                                }
     
                            </VerticalTimeline>

                            
                            {

                                (this.state.loadSchedule === false) &&
                                <React.Fragment>
                                    <div style={{width:"100%",marginTop:"25px"}}>
                                        <center> <ClipLoader size={50}  color={"#adb5bd"} loading={true} /></center>
                                    </div>
                                </React.Fragment>

                            }

                            {

                                (this.state.loadSchedule === true && this.state.schedules.length === 0) &&
                                <React.Fragment>
                                    <center><div style={{marginTop:'10px',fontSize:'15px',fontWeight:'600'}}>Nenhuma aula foi encontrada.</div></center>
                                </React.Fragment>

                            }


                        </div>
                         
                    </Row>
                </CardHeader>
            </Card>


            


        <Modal className="modal-dialog-centered" isOpen={this.state.openModalPostSchedule} >
            <div className="modal-header bg-secondary">
            <h5 className="modal-title" id="exampleModalLabel">
            {

                (this.state.editSchedule !== undefined)&&
                <font>Editar aula</font>

            }
            {

                (this.state.editSchedule === undefined)&&
                <font>Cadastrar aula</font>

            }</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalPostSchedule:false})} >
                <span aria-hidden={true}>×</span>
            </button>
            </div>
            <div className="modal-body bg-secondary">
            
                <PostEditSchedule 

                    successPost={() => {

                        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A aula foi cadastrada.</span></div>),type:'success',icon: "ni ni-check-bold",autoDismiss: 7};
                        this.refs.notificationAlert.notificationAlert(options);

                        this.setState({openModalPostSchedule:false});
                        this.getSchedules();

                    }}

                    successEdit={ () => {


                    }}

                    classRoom={this.props.classRoom}
                    close={() => this.setState({openModalPostSchedule:false}) }

                />

            </div>
           
        </Modal>


            

        </React.Fragment>)

    }


}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	unit: state.unit,
  
}))(Schedule);