import React from 'react';
import PropTypes from 'prop-types';
import Quiz from './Quiz.js';
import Modal from './Modal.js';
import Results from './Results.js';
import { connect } from 'react-redux';

import api from "./../../../../Api.js";

class QuizApp extends React.Component {

  constructor(props){

    super(props); 

    this.state = {quizReady:false};
    this.calculaNota = this.calculaNota.bind(this);
    this.saveQuizProgress = this.saveQuizProgress.bind(this);

    window.quizcomponent = this;

  }

  static propTypes = {
    totalQuestions: PropTypes.number.isRequired
  };

  calculaNota(){

    
    var questions = this.state.totalQuestions;
    var maxPoints = questions * 10;
    var nota = (this.state.score * 100) / maxPoints;

    return nota.toFixed(0);

  }

  async getInitialState(state) {


    //const QUESTIONS = [QUESTION_DATA[0],QUESTION_DATA[1]];
    //var totalQuestions = QUESTIONS.length;
 
    const QUESTIONS = state.quiz.questions;
    var totalQuestions = QUESTIONS.length;

    //console.log(QUESTIONS);
    //console.log(totalQuestions);
    //console.log(state.quiz.questions)

    var obj = {
      questions: QUESTIONS,
      totalQuestions: totalQuestions,
      userAnswers: QUESTIONS.map(() => {
        return {
          tries: 0
        }
      }),
      step: 1,
      score: 0,
      modal: {state:'hide',praise:'',points:''}};
    
      var quiz = this.props.quizId;
      var classroom = this.props.propsextend.classroom;
      var lesson = this.props.propsextend.lesson;

      //var obj = {quiz:quiz,classroom:classroom,lesson:lesson,state:JSON.stringify(this.state)};

      //faço o post la no servidor
      var cache = await api.getProgressQuiz(classroom,quiz,lesson).then(async (res) => {

          return res.data;

      }).catch(error => { 

          console.log(error.data);
          console.log(error);
          console.log(error.response); 
          return false; 

      });


      if(cache !== false && this.state.nosave === undefined){ 

        cache = JSON.parse(cache.state); 

        if(cache.step === undefined || cache.score === undefined || cache.userAnswers === undefined){

          console.log('OPS ERRO no cache EM');
          cache = false;

        }else{

          obj.step = cache.step;
          obj.score = cache.score;
          //obj.modal.state = cache.modal.state;
          obj.userAnswers = cache.userAnswers;

          console.log('entrou cache');

        }

        
        console.log(cache);
        console.log(obj);

      }else{ console.log('não tem cache'); }
     
      await this.setState(obj);

      //Salva pela primeira vez
      if(cache === false && this.state.nosave === undefined){ this.saveQuizProgress(); }

  }

  async componentDidMount(){

    await this.getInitialState(this.props.state);
    this.setState({quizReady:true});

  }

   handleAnswerClick =  (index)  => async (e) => {
     
    const { questions, step, userAnswers } = this.state;
    const isCorrect = questions[(step-1)].correctAnswer === index;
    //console.log(questions);

    const currentStep = step - 1;

    if(userAnswers[currentStep] === undefined){ 
      
      userAnswers[currentStep] = {};
      userAnswers[currentStep].tries = 0; 
    
    }

    const tries = userAnswers[currentStep].tries;
    if(tries === undefined){ userAnswers[currentStep] = 0; }

    if (isCorrect && e.target.nodeName === 'LI') {
      // Prevent other answers from being clicked after correct answer is clicked
      e.target.parentNode.style.pointerEvents = 'none';

      e.target.classList.add('right_quiz');

      userAnswers[currentStep] = {tries: tries + 1};

      await this.setState({userAnswers: userAnswers});

      setTimeout(() => this.showModal(tries), 1300);

      setTimeout(this.nextStep, 6000);
    
    }else if (e.target.nodeName === 'LI') {
    
      e.target.style.pointerEvents = 'none';
      e.target.classList.add('wrong');

      userAnswers[currentStep] = {
        tries: tries + 1
      };

      await this.setState({userAnswers: userAnswers});

    }

    this.saveQuizProgress();

  };

  handleEnterPress = (index) => (e) => {
    if (e.keyCode === 13) {
      this.handleAnswerClick(index)(e);
    }
  };

  showModal = (tries) => {
    
    let praise;
    let points;

    switch (tries) {
      case 0: {
        praise = this.props.translate['acertou_de_primeira'];
        points = '+10';
        break;
      }
      case 1: {
        praise = this.props.translate['acertou_de_segunda'];
        points = '+5';
        break;
      }
      case 2: {
        praise = this.props.translate['acertou_de_terceira'];
        points = '+2';
        break;
      }
      default: {
        praise = this.props.translate['acertou_de_ultima'];
        points = '+1';
      }
    }

    this.setState({ modal: { state: 'show',  praise, points } });
    
  };

  nextStep = async () => {

    const { userAnswers, step, score } = this.state;
   // const restOfQuestions = questions.slice(1);
    const currentStep = step - 1;
    const tries = userAnswers[currentStep].tries;

    await this.setState({
      step: step + 1,
      score: this.updateScore(tries, score),
       
      modal: {
        state: 'hide'
      }
    });
 
    //faço o post la no servidor
    this.saveQuizProgress();

  };

  async saveQuizProgress(){

    var quiz = this.props.quizId;
    var classroom = this.props.propsextend.classroom;
    var lesson = this.props.propsextend.lesson;

    var state = JSON.parse(JSON.stringify(this.state));
    delete state.questions;
    delete state.answers;
    delete state.modal;

    var obj = {quiz:quiz,classroom:classroom,lesson:lesson,state:JSON.stringify(state)};
    
    if(this.state.nosave === undefined){
      
      await api.postProgressQuiz(obj).then(async (res) => {

        //console.log(res);

      }).catch(error => { 

          console.log(error.data);
          console.log(error);
          console.log(error.response); 
          return false; 

      });
  
    }

  }

  updateScore(tries, score) {
    switch (tries) {
      case 1: return score + 10;
      case 2: return score + 5;
      case 3: return score + 2;
      default: return score + 1;
    }
  }

   restartQuiz = async () => {

    console.log('clicou');
    //...this.getInitialState(this.props.state)

    this.setState({nosave:true});
    await this.getInitialState(this.props.state);

  };

  render() {

    const{step,questions,userAnswers,totalQuestions,score,modal} = this.state;

    //caso esteja pronto para exibir o quiz
    if(this.state.quizReady === false){ return(<React.Fragment></React.Fragment>); }

    if (step >= totalQuestions + 1){


      return(<React.Fragment>
        
        <Results translate={this.props.translate} score={score} restartQuiz={this.restartQuiz} userAnswers={userAnswers}/>
        <input hidden id="scoreQuiz" value={this.calculaNota()} />

      </React.Fragment>);

    }else{ 

      return (
        <React.Fragment>

          <Quiz translate={this.props.translate} topState={this.props.state} step={step} questions={questions} totalQuestions={totalQuestions} score={score} handleAnswerClick={this.handleAnswerClick} handleEnterPress={this.handleEnterPress} />
          
          {modal.state === 'show' && <Modal translate={this.props.translate} modal={modal} />}
          <Modal translate={this.props.translate} modal={modal} />

        </React.Fragment>);

    }
  }
}

export default connect(state =>({
  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  magictools: state.magictools
}))(QuizApp);
