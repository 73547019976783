import React from "react";
import { connect } from 'react-redux';
import $ from 'jquery';
import api from './../../../Api.js';
import NotificationAlert from "react-notification-alert";
import {UncontrolledAlert,Button,CardHeader,Form,Row,Col,FormGroup,InputGroup,Input} from "reactstrap";
import InputMask from 'react-input-mask';
import moment from "moment";
import { load } from 'recaptcha-v3';
import QrReader from 'react-qr-reader';

import ClipLoader from "react-spinners/ClipLoader";

var recaptcha;


class Login extends React.Component {

    constructor(props){
        
        super(props);
        this.state = {showForm:true,error:"",
     
            name:"",
            lastName:"",
            email:"",
            password:"",
            username:"",
            birthDate:"",
            genre:"",
            city:"",
            state:"",
            tel:"",
            password:"",
            confirmPassword:"",
            classroom:{},
            showmail:false,
            loadPost:false
        
        };


        
       /* this.state.confirmPassword = "5550125";
        this.state.email = "marlon.233rj@gmail.com";
        this.state.error = "";
        this.state.genre = "";
        this.state.lastName ="Lima";
        this.state.name = "Marlon";
        this.state.password = "5550125";
        this.state.show = true;
        this.state.showForm = true;
        this.state.state = "Rio de janeiro";
        this.state.tel = "(15) 9330-01295";
        this.state.username = "serra9sdas";
        this.state.birthDate = "05/08/1996";
        this.state.city = "São Gonçalo";
        this.state.genre = "0";*/


        
        this.login = this.login.bind(this);

        this.getpublicclassroom = this.getpublicclassroom.bind(this);
        this.postUserpublicclassroom = this.postUserpublicclassroom.bind(this);

        window.publicclassenter = this;

    }

    async login(){
 
        this.setState({logando:true});
        api.login({email:this.state.username,password:$("#password").val()}).then((res) => {

            this.props.loginSuccess(res.data.session,res.data.user);

            res.data.user.session = res.data.session
            this.props.dispatch({type:"SET_DATA_USER",data:res.data.user});
            this.props.history.push('/myclassroom/'+this.state.classroom.id); 

        }).catch(error => {

            //console.log(error.data);
            //console.log(error);
            //console.log(error.response);
            if(error.response.status === 400){ this.setState({loginErro:""}); this.setState({loginErro:error.response.data[0].msg}); }
            this.setState({logando:false});

        });

    }

    getpublicclassroom(){

        api.getpublicclassroom(this.props.match.params.classroom).then((res) => {

            this.setState({show:true,classroom:res.data});

                console.log(res.data);
                
                if(res.data.course !== undefined){

                    if(res.data.course.background_file !== undefined){

                        console.log('res.data.course.background_file:'+res.data.course.background_file.url);
                        window.$('#superBg').css('background','url('+sessionStorage.getItem('centerBackend')+res.data.course.background_file.url+')');


                        window.$('#superBg').css('position','fixed');
                        window.$('#superBg').css('background-repeat','no-repeat');
                        window.$('#superBg').css('background-size','cover');
                    
                    }
 
                }
 
        }).catch(error => {  
            

            this.setState({error:"Não foi possível acessar a turma, verifique o link informado."})
                
        });


    }

    async postUserpublicclassroom(e){

        e.preventDefault();

        var obj = {
            name:this.state.name,
            lastName:this.state.lastName,
            email:this.state.email,
            password:this.state.password,
            username:this.state.username,
            birthDate:this.state.birthDate,
            genre:this.state.genre,
            city:this.state.city,
            state:this.state.state,
            tel:this.state.tel,
            genre:this.state.genre  
        };

        obj.username = obj.email;
        if(this.state.password !== this.state.confirmPassword){

            window.swal.fire('Ops!',"A senha informada é diferente da confirmação da senha.",'warning');
            return false;

        }

        obj.birthDate = moment(obj.birthDate,'DD/MM/YYYY').format('YYYY-MM-DD');
        obj.extraHook = {};

        var varUrls = window.parseQueryString();
        //pega coisas da url
        for(var index in varUrls){ 

            var attr = varUrls[index];
            obj.extraHook[index] = attr;

        }

        console.log(obj);
        
        await this.setState({loadPost:true});
        
        obj.token = await recaptcha.execute('register');
        
         
        await api.postUserpublicclassroom(this.props.match.params.classroom,obj).then((res) => {

            console.log('foi');
            //this.login();

            this.setState({showmail:true});
            this.props.history.push(window.location.pathname+'/obrigado');

        }).catch(error => {  

            this.setState({loadPost:false});

            if(error.response.status === 400){

                console.log(error.response.data.msg);
                window.swal.fire('Ops!',error.response.data.msg,'warning');

            }
 
                
        });

    }

 
   
    async componentDidMount(){

        this.getpublicclassroom();

       // if(window.app.state.confs.recaptcha_status === "1"){}

        recaptcha = await load('6LeJOg4bAAAAAK7ppIFpHSH85bgovKpokPp9sUJz');
      
        

        window.$("#superBg").css('background','url('+sessionStorage.getItem('backend')+''+window.app.state.confs.background+')');
        window.$("#superBg").fadeIn(0);
        window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.17)');
        window.$("#superBg").css('background-size','cover');

    }

    componentWillUnmount(){

        window.$("#superBg").css('background','');
        window.$("#superBg").fadeOut(0);
        window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.60)');
        window.$("#superBg").css('background-size','cover');

    }

  

  

    render() {

        var translate = this.props.translate;
        //console.log('deu render login');
        
        return (
        <React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
            <div className=" ">
            
            <div className="loginRight" style={{width:"100%",display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#f7fafc !important',padding:'60px 60px 0px'}}>
            {
                //(this.state.qrcode === false && this.state.esqueciSenha === false && this.state.showForm === true) &&
                <React.Fragment>
                <Form style={{width:"800px",padding:'35px',boxShadow:'0px 2px 5px -4px #000'}} className="pageLoginForm bg-secondary" role="form" type="POST" onSubmit={ (event) => this.postUserpublicclassroom(event) } >
                    
                    <div className="text-center mb-4">
                        <h3>Bem-vindo!</h3>
                        <font>Faça o seu cadastro abaixo para acessar o curso.</font>
                    </div>

                    {

                        (this.state.error !== "") &&
                        <div className="container">
                            <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.error}</span></UncontrolledAlert>
                        </div>
                    
                    } 

{/* 
    name
    lastName
    email
    password
    username
    birthDate
    genre
    city
    state
    tel*/}

                        <div className="row">

                            {

                                (this.state.showmail === false) &&
                                <React.Fragment>

                                    <div className="col-md-6">

                                        {

                                            (this.state.classroom !== undefined) &&
                                            <React.Fragment>

                                                {

                                                    (this.state.classroom.course !== undefined) &&
                                                    <React.Fragment>
                                                        <div style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                                                            {
                                                                
                                                                (this.state.classroom.course.icon_file !== undefined) &&
                                                                <React.Fragment>
                                                                    <img style={{maxWidth:'250px',maxHeight:'250px'}} src={sessionStorage.getItem('centerBackend')+this.state.classroom.course.icon_file.url} />
                                                                </React.Fragment>

                                                            }
                                                        </div>
                                                        <div style={{textAlign:'center',marginTop:'15px',fontSize:'16px',fontWeight:'100'}}>{this.state.classroom.course.name}</div>
                                                    </React.Fragment>

                                                }

                                                
                                                

                                            </React.Fragment>

                                        }
                                        <div className=""></div>

                                    </div>

                                    <div className="col-md-6">
                                        <div class="col-md-12">
                                            <h5>Nome:</h5>
                                            <div class="mb-3 form-group">
                                                <div class="input-group-merge input-group-alternative input-group"><input value={this.state.name} onChange={(e) => this.setState({name:e.target.value})} type="text" class="form-control" /></div>
                                            </div>
                                        </div> 
                                        <div class="col-md-12">
                                            <h5>Sobrenome:</h5>
                                            <div class="mb-3 form-group">
                                                <div class="input-group-merge input-group-alternative input-group"><input value={this.state.lastName} onChange={(e) => this.setState({lastName:e.target.value})} type="text" class="form-control" /></div>
                                            </div>
                                        </div> 
                                        <div   class="col-md-12">
                                            <h5>E-mail:</h5>
                                            <div class="mb-3 form-group">
                                                <div class="input-group-merge input-group-alternative input-group"><input value={this.state.email} onChange={(e) => this.setState({email:e.target.value})} type="text" class="form-control" /></div>
                                            </div>
                                        </div> 
                                        <div class="col-md-12">
                                            <h5>Gênero:</h5>
                                            <div class="mb-3 form-group">
                                                <div class="input-group-merge input-group-alternative input-group">
                                                    <select value={this.state.genre} onChange={(e) => this.setState({genre:e.target.value})} type="text" class="form-control" >
                                                        <option value=""></option>
                                                        <option value="0">Masculino</option>
                                                        <option value="1">Feminino</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> 
                                        <div hidden class="col-md-12">
                                            <h5>Usuário:</h5>
                                            <div class="mb-3 form-group">
                                                <div class="input-group-merge input-group-alternative input-group"><input value={this.state.username} onChange={(e) => this.setState({username:e.target.value})} type="text" class="form-control" /></div>
                                            </div>
                                        </div> 
                                        <div class="col-md-12">
                                            <h5>Data de nascimento:</h5>
                                            <div class="mb-3 form-group">
                                                <div class="input-group-merge input-group-alternative input-group">
                                                <InputMask value={ this.state.birthDate } className="form-control" mask="99/99/9999" maskChar=" "  onChange={(e) => { console.log(e.target.value); this.setState({birthDate:e.target.value}); }} /></div>
                                            </div>
                                        </div> 
                                        <Col md={12}>
                                            <h5>Estado:</h5>
                                            <FormGroup>
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <Input type="text" className="" value={this.state.state} onChange={(e) => { this.setState({state:e.target.value}); }}></Input>
                                            </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <h5>Cidade:</h5>
                                            <FormGroup>
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <Input type="text" className="" value={this.state.city} onChange={(e) => { this.setState({city:e.target.value}); }}></Input>
                                            </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <h5>Whatsapp:</h5>
                                            <FormGroup>
                                            <InputGroup className="input-group-merge input-group-alternative"> 
                                                <InputMask value={ this.state.tel } className="form-control" mask="(99) 99999-999999" maskChar=" "  onChange={(e) => { this.setState({tel:e.target.value}); }} />
                                            </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col md={12}>
                                            <h5>Senha:</h5>
                                            <FormGroup>
                                            <InputGroup className="input-group-merge input-group-alternative"> 
                                                <input type="password" id="password" value={ this.state.password } className="form-control" onChange={(e) => { this.setState({password:e.target.value}); }} />
                                            </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <h5>Confirme a senha:</h5>
                                            <FormGroup>
                                            <InputGroup className="input-group-merge input-group-alternative"> 
                                                <input type="password" value={ this.state.confirmPassword } className="form-control" onChange={(e) => { this.setState({confirmPassword:e.target.value}); }} />
                                            </InputGroup>
                                            </FormGroup>
                                        </Col>


                                        
                                        <div className="row" style={{justifyContent:'center'}}>
                                            <center>
                                                {

                                                    (this.state.loadPost === false) &&
                                                    <button type="submit" class="btn btn-primary" style={{marginTop:'10px',width:'100%'}}>Fazer cadastro</button> 

                                                }
                                                {

                                                    (this.state.loadPost === true) &&
                                                    <ClipLoader size={36} color={'#5e72e4'}/>

                                                }
                                            </center>
                                        </div>
                                    </div>
                                </React.Fragment>

                            }

                            {

                                (this.state.showmail === true) &&
                                <React.Fragment>
                                    <div style={{width:'100%',fontSize:'20px',textAlign:'center',color:'#32325d'}}>Enviamos um e-mail de confirmação para você.</div>
                                </React.Fragment>

                            }
                        </div>
                        <CardHeader className="bg-transparent" style={{padding:'0rem 0rem',marginTop:'22px',paddingTop:'0px',marginBottom:'0px',paddingBottom:'0px'}}>
                    </CardHeader>
                </Form>
                </React.Fragment>
            }
            </div>
        </div>
        </React.Fragment>);
    }

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate

}))(Login);