export default function Language(state = [],action){

	if(action.type === ""){

		state = action.data;

	}

	return state;

}