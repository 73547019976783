import React from "react";
import {Modal,Card,Button,UncontrolledDropdown,DropdownMenu,DropdownToggle,DropdownItem} from "reactstrap";
//import NotificationAlert from "react-notification-alert";
//import classnames from "classnames";
import QrReader from 'react-qr-reader';
import moment from "moment";

var QRCode = require('qrcode.react');
//var uuidv4 = require('uuid/v4');

class EditUserQRcode extends React.Component {

	constructor(props){

    	super(props);	
      	this.donwloadSVGQRCODE = this.donwloadSVGQRCODE.bind(this);
      	this.openSetQR = this.openSetQR.bind(this);
      	this.closeSetQR = this.closeSetQR.bind(this);
      	this.reset = this.reset.bind(this);
      	this.gera_card = this.gera_card.bind(this);
		this.gera_card_2 = this.gera_card_2.bind(this);
		this.gerar_os_dois = this.gerar_os_dois.bind(this);

		window.edituserqrcode = this;
		  
  	}

  	donwloadSVGQRCODE(){
    	
    	this.closeSetQR();

    	window.$("#figureSvg").find('svg').attr('xmlns','http://www.w3.org/2000/svg');
    	var svgData = window.$("#figureSvg").html();var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});var svgUrl = URL.createObjectURL(svgBlob);var downloadLink = document.createElement("a");downloadLink.href = svgUrl;downloadLink.download = this.props.state.qrcode.name+" QR CODE.svg";document.body.appendChild(downloadLink); downloadLink.click(); document.body.removeChild(downloadLink);

	}

	async openSetQR(){

    	var s = this.props.state.qrcode; 
    	s.setar = true; 
    	this.props.setState({qrcode:s});

    }

    async closeSetQR(){

    	var s = this.props.state.qrcode; 
    	s.setar = false; 
    	this.props.setState({qrcode:s});

    }

    //Para gerar o cartão 1
    async gera_card(returnBase64){

		var name = this.props.state.qrcode.name;
		var base64 = {};

		await window.html2canvas(document.getElementById('cartao_1'), {scale:1}).then(function(canvas) {

			var a = document.createElement('a');
			a.href = canvas.toDataURL("image/png");
			a.download = name+' cartão verso.png';

		  	base64.href = a.href;
			base64.download = a.download; 

			if(returnBase64 === true){ return false; }
		  	a.click();

		});  

		return base64;

	}
 	
 	//Para gerar o cartão dois
	async gera_card_2(returnBase64){
	 	
		var name = this.props.state.qrcode.name;
		var base64 = {};

	    await window.html2canvas(document.getElementById('cartao_2'), {scale:1}).then(function(canvas) {

			var a = document.createElement('a');
			a.href = canvas.toDataURL("image/png");
			a.download = name+' cartão frente.png';

			base64.href = a.href;
			base64.download = a.download; 

			if(returnBase64 === true){ return false; }
			a.click();

		}); 
 
		return base64;

	}

	//para gerar os dois
	gerar_os_dois(){

		this.gera_card();
		this.gera_card_2();

	}

    //Para gerar um novo qrcode para o usuario
    reset(){

    	var o = ['m','y','s','c','f'];
		for (var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
   		
   		var txt = "";
   		for (i = 0; i < o.length; i++) {
   			txt = txt+o[i];
   		}

   		var m = moment().format('YYYYMMDDhmmSSS');
   		var m2 = m.split('');

   		for (j, x, i = m2.length; i; j = Math.floor(Math.random() * i), x = m2[--i], m2[i] = m2[j], m2[j] = x);


		  var txt2 = "";
   		for (i = 0; i < m2.length; i++) {
   			txt2 = txt2+m2[i];
		   }
		   
		delete window.resetQR;
    	this.props.handleQrScan(txt+txt2);

		
		
    }

  	render(){
	   

		if(this.props.state.openModalQRcode === true){

			console.log(this.props.state.qrcode.qrcodePassword);

			if(this.props.state.qrcode.qrcodePassword === null || this.props.state.qrcode.qrcodePassword === ""){

				if(window.resetQR === undefined){
					this.reset();
					window.resetQR = true;
					console.log('resetou');
				}
				

			} 

		}

  		return(<React.Fragment>
		    <Modal className="modal-dialog-centered" isOpen={this.props.state.openModalQRcode} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">QR code</h6>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.setState({openModalQRcode:false})}><span aria-hidden={true}>×</span> </button>
            </div>
            </Card>
          </div>
          {
            (this.props.state.qrcode.setar === true) &&
            <React.Fragment>
              <center>
                <div style={{marginBottom:'15px'}}>Mostre o QR code do cartão para salvar.</div>
                <QrReader delay={100} onError={this.props.handleQrError} onScan={this.props.handleQrScan} showViewFinder={false} style={{ width: '128px' }} />
                <button onClick={ () => this.closeSetQR() } style={{marginTop:'10px'}} type="button" className="btn btn-secondary btn-sm"><i className="fas fa-times"></i> Cancelar</button>
              </center>
            </React.Fragment>
          }

		  {

			(this.props.state.qrcode.qrcodePassword !== undefined) &&
			<React.Fragment>
				
				{
					(this.props.state.qrcode.setar === false && this.props.state.qrcode.qrcodePassword !== null && this.props.state.qrcode.qrcodePassword !== "") &&
					<React.Fragment>
					<center id="figureSvg"><QRCode value={this.props.state.qrcode.qrcodePassword} renderAs="svg" /></center>
					</React.Fragment>
				}
			 
         
				<div className="modal-footer" style={{display:'block'}}>
					<center>
						<Button color="danger" type="button" onClick={ () => this.reset() }><i className="fas fa-retweet"></i> Resetar</Button>
						<Button color="warning" type="button" onClick={ () => this.openSetQR() }><i className="fas fa-pencil-alt"></i> Setar</Button>
						<Button hidden onClick={ () => this.donwloadSVGQRCODE() } className="btn-white" color="default" type="button"><i className="fas fa-file-download"></i> Baixar</Button>
						<UncontrolledDropdown>
								<DropdownToggle className="btn-white" role="button"><i className="fas fa-file-download"></i> Baixar</DropdownToggle>
								<DropdownMenu className="dropdown-menu-arrow" right>
									<DropdownItem style={{cursor:'pointer'}} onClick={ () => this.donwloadSVGQRCODE()}><i className="fas fa-qrcode"></i> Somente QR code</DropdownItem>
									<DropdownItem style={{cursor:'pointer'}} onClick={ () => this.gera_card() }><i className="far fa-address-card"></i> Frente do cartão</DropdownItem>
									<DropdownItem style={{cursor:'pointer'}} onClick={ () => this.gera_card_2()}><i className="far fa-address-card"></i> Verso do cartão</DropdownItem>
									<DropdownItem style={{cursor:'pointer'}} onClick={ () => this.gerar_os_dois()}><i className="far fa-address-card"></i> Cartão completo</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						<br/><br/>
						<Button className="text-white" color="default" data-dismiss="modal" type="button" onClick={ () => this.props.setState({openModalQRcode:false}) }>Fechar</Button>
					</center>
				</div>

			</React.Fragment>

		  }

        </Modal>
  		</React.Fragment>);

  	}

}

export default EditUserQRcode;