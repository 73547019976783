import React from 'react';
import PropTypes from 'prop-types';
import Answer from './Answer';
import $ from 'jquery';

function remove_tags(html){

  html = html.replace(/<img/g, '||img||');
  html = html.replace(/<p/g, '||p||');
  html = html.replace(/<br/g, '||br||');
 
  html = '<p>'+html+'</p>';
  var text = $(html).text();
  text = text.replace(/\|\|img\|\|/g, '<img');
  text = text.replace(/\|\|p\|\|/g, '<p');
  text = text.replace(/\|\|br\|\|/g, '<br');

  console.log('texta antes: '+text);
  text = text.replaceAll('[[url_backend_center]]',sessionStorage.getItem('centerBackend'));
  console.log('text: '+text);

  return text;  
  
}

const Question = ({ question, answers, handleAnswerClick, handleEnterPress,number }) => {

  return (

    <li className="question" number={number}>
      <p className="question-title" tabIndex="0" dangerouslySetInnerHTML={{__html:remove_tags(question)}}></p>
      <ul className="question-answers" tabIndex="-1">
        {answers.map((answer, index) => {
          return (
            <Answer
              key={answer}
              answer={answer}
              handleAnswerClick={handleAnswerClick(index)}
              handleEnterPress={handleEnterPress(index)}
            />
          );
        })}
      </ul>
    </li>
  );

}

Question.propTypes = {
  question: PropTypes.element.isRequired,
  answers: PropTypes.array.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
  handleEnterPress: PropTypes.func.isRequired
};

export default Question;
