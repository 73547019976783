import React from "react";
import {Link} from "react-router-dom";
import {Alert,Modal,CardBody,Form,Button,Card} from "reactstrap";
import InputMask from 'react-input-mask';
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import $ from "jquery";
import moment from "moment";
import reduxHelper from "./../../../helper/index.js";
import api from "../../../Api.js";
import Select from "react-dropdown-select";

class ImportXlsxUser extends React.Component {

	constructor(props){

        super(props);
        
        this.setStateTop = this.props.setState;
        this.checkXlsx = this.checkXlsx.bind(this);
        this.state = {usersList:[],importError:"",erroXLSX:"",verificado:false};
        this.changeValue = this.changeValue.bind(this);
        this.reset = this.reset.bind(this);
        this.checkXlsxServer = this.checkXlsxServer.bind(this);
        this.helper = new reduxHelper(this.props);
        this.cadastrar = this.cadastrar.bind(this);
        this.removeLine = this.removeLine.bind(this);
        this.addLine = this.addLine.bind(this);
        this.manual = this.manual.bind(this);
        

        window.importxlsx = this;

    }

    //Para abrir o modo de cadastrar manualmente
    async manual(){

        await this.setState({usersList:[],importError:""});
        this.addLine();

    }

    //Para remover uma linha da planilha
    removeLine(index){

        var usersList = this.state.usersList;
        usersList.splice(index,1);
        this.checkXlsxServer();
        this.setState({usersList:usersList});

    }

    //Para adicionar uma linha na planilha
    addLine(){

        var usersList = this.state.usersList;
        usersList.push({name:"",lastName:"",email:"",username:"",birthDate:"",accessLevel:"",blockedQRcode:"",blocked:"",classRoom:[],genre:"",classRooms:JSON.parse(JSON.stringify(this.props.state.classRoom))});
        this.setState({usersList:usersList,verificado:false});

    }
      
    //Para cadastrar os usuarios no banco de dados
    async cadastrar(){

        var data = JSON.parse(JSON.stringify( this.filterUsers(this.state.usersList)  ));
        var erro = false;
        var userInsert = [];

        for (let index = 0; index < data.length; index++) {
           
            var classRoom = JSON.parse(JSON.stringify(data[index].classRoom));
            delete data[index].classRoom;
            data[index].blockedReason = "";

            if(erro === false){

                var postUser = await this.props.postUser(1,data[index]);
                if(postUser !== false){

                    for(let index2 = 0; index2 < classRoom.length; index2++){
                        
                        await api.addUserClassRoom({classroom:classRoom[index2].id,user:postUser.user.id,type:classRoom[index2].type});
                        
                    } 
                    
                    userInsert.push(postUser.user.id);

                }else{ erro = true; }

            }
            

        }

        //Caso tenha dado agum erro.... remove o usuarios inseridos
        for (let index = 0; index < postUser.length; index++) {
            
            api.deleteUser(postUser[index]);

        }

        if(erro === false){

            this.setStateTop({openModalImport:false}); 
            this.reset();

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}!</span><span data-notify="message">{this.props.translate['a_importacao_foi_realizada']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.props.getAllUsers();
        }

    }

    //Para verificar os dados do xlsx no servidor
    async checkXlsxServer(){

        var data = await this.helper.user().userCheckXlsx(this.filterUsers(this.state.usersList));
        if(parseInt(data.status) === 200){

            await this.setState({erroXLSX:'',verificado:true});

        }

        if(parseInt(data.status) === 400){

//            console.log(data.data.data.msg);

            await this.setState({erroXLSX:''});
            await this.setState({erroXLSX:data.data.data.msg});
            

        }

        //console.log(data);

    }

    //Para verificar o arquivo enviado
    async checkXlsx(){

        await this.setState({importError:""});

        if($('#input_xlsx')[0].files[0] === undefined){ return false; }
        
        $('label[for="input_xlsx"]').html($('#input_xlsx')[0].files[0].name);

		var input = $('#input_xlsx');
        var file = input[0].files;
        
        var users = [];
        var userscheck = [];
        
        if(file.length === 0){ this.setState({importError:this.props.translate['voce_deve_selecionar_o_arquivo']}); return false; }

        //Tento abrir o arquivo XLSX
        try {
          await readXlsxFile(file[0]).then((rows) => {
            users = rows;
        })   
        } catch (error) {

            this.setState({importError:this.props.translate['nao_foi_possivel_abrir_o_arquivo']});
           // console.log('a1');
            return false;
        }

       
        //Se tiver usuario...
        if(users.length !== 0){
            //console.log(users);
            //Caso não tenha linha de usuario
            if(users.length === 1){

                this.setState({importError:this.props.translate['o_arquivo_enviado_esta_vazio']});
                return false;

            }

            for(let index = 1; index < users.length; index++) {
                

                if(users[index][0] === undefined || users[index][0] === null){ users[index][0] = ""; }
                if(users[index][1] === undefined || users[index][1] === null){ users[index][1] = ""; }
                if(users[index][2] === undefined || users[index][2] === null){ users[index][2] = ""; }
                if(users[index][3] === undefined || users[index][3] === null){ users[index][3] = ""; }
                if(users[index][4] === undefined || users[index][4] === null){ users[index][4] = ""; }
                if(users[index][5] === undefined || users[index][5] === null){ users[index][5] = ""; }
                if(users[index][6] === undefined || users[index][6] === null){ users[index][6] = ""; }
                if(users[index][7] === undefined || users[index][7] === null){ users[index][7] = ""; }
                if(users[index][8] === undefined || users[index][8] === null){ users[index][8] = ""; }
                if(users[index][9] === undefined || users[index][9] === null){ users[index][9] = ""; }
                if(users[index][9] === undefined || users[index][9] === null){ users[index][9] = ""; }

                try {
                    
                    
                    var userparse = {};
                    userparse.name = users[index][0];
                    userparse.lastName = users[index][1];
                    userparse.email = users[index][2];
                    userparse.username = users[index][3];
                    userparse.genre = users[index][4];

                    var genre = ['0','m','masculino','homem','male'];
                    
                    try{

                        userparse.genre= userparse.genre.toLowerCase();
                        if(genre.indexOf(userparse.genre) !== -1){ console.log('masculino'); userparse.genre = 0; }else{ console.log('feminino'); userparse.genre = 1; }
                        
                    }catch(error){ console.log(error); }

                    userparse.birthDate = users[index][5];
                    console.log(userparse.birthDate);
                    if(users[index][5] !== ""){ userparse.birthDate = await moment(users[index][5]).format('DD/MM/YYYY'); }
                    console.log(userparse.birthDate);

                    userparse.accessLevel = users[index][6];

                    try{

                        userparse.accessLevel = userparse.accessLevel.toLowerCase();

                    } catch (error) {}

                    for(let index = 0; index < this.props.state.levelAcess.length; index++){
                        
                        //console.log(this.props.state.levelAcess[index].name.toLowerCase()+' === '+userparse.accessLevel)
                        if(this.props.state.levelAcess[index].name.toLowerCase() === userparse.accessLevel){ 
                            
                            userparse.accessLevel = this.props.state.levelAcess[index].id; 
                            //console.log('setou');

                        }
                                            
                    }

                    userparse.blockedQRcode = users[index][7];
                    userparse.blocked = users[index][8];

                    var passBlockedQRcode = ['sim','yes','s','y','1'];
                     
                    try {
                        userparse.blockedQRcode = userparse.blockedQRcode.toLowerCase();
                    } catch (error) {
                       // console.log(error);
                    }

                    try {
                        userparse.blocked = userparse.blocked.toLowerCase();
                    } catch (error) {
                       // console.log(error);
                    }

                    if(passBlockedQRcode.indexOf(userparse.blockedQRcode) !== -1){ userparse.blockedQRcode = 1; }else{  userparse.blockedQRcode = 0; }
                    if(passBlockedQRcode.indexOf(userparse.blocked) !== -1){ userparse.blocked = 1; }else{  userparse.blocked = 0; }



                    //valida sexo
                    //var passBlockedQRcode = ['0','m','masculino','homem','male'];




                    userparse.password = users[index][9];
                    userparse.classRooms = JSON.parse(JSON.stringify(this.props.state.classRoom));

                    //Verifica a turma
                    userparse.classRoom = [];
                    var hasclass = false;

                    try{

                        for(let index3 = 0; index3 < this.props.state.classRoom.length; index3++){
                            

                            if(typeof users[index][10] === 'string'){

                                var resultado = users[index][10].split(",");
                                var tipagem = users[index][11].split(",");

                                for (let indexcc = 0; indexcc < resultado.length; indexcc++) {
                                    
                                    if(this.props.state.classRoom[index3].name.toLowerCase() === resultado[indexcc].toLowerCase()){

                                        var cls = this.props.state.classRoom[index3];
                                        cls.type = '4';
                                        userparse.classRooms[index3].type = '4';

                                        //Verifica o tipo informado
                                        if(tipagem[indexcc] !== undefined){

                                            var tpi = tipagem[indexcc].toLowerCase();
                                            for(let indextps = 0; indextps < window.app.state.permitions.length; indextps++){ 
                                                
                                                if(window.app.state.permitions[indextps].name.toLowerCase() === tpi){

                                                    cls.type = window.app.state.permitions[indextps].id;
                                                    userparse.classRooms[index3].type = window.app.state.permitions[indextps].id;

                                                }

                                            }


                                        }



                                        userparse.classRoom.push(cls);
                                        hasclass = true;
    
                                    }
                                    
                                }

                            }
                            

                            /* if(this.props.state.classRoom[index3].name.toLowerCase() === users[index][10].toLowerCase()){

                                userparse.classRoom = this.props.state.classRoom[index3].id;
                                hasclass = true;

                            }*/

                        }

                    } catch (error) {
                       // console.log(error);
                    }
                   

                    if(hasclass === false){   }
                    
                    userscheck.push(userparse);
                
                }catch (error){

                    this.setState({importError:this.props.translate['nao_foi_possivel_abrir_o_arquivo']});
                    //console.log('a3');
                    //console.log(error);

                }

                
                
            }

            if(this.state.importError === ""){ 
                
                await this.setState({usersList:userscheck,importError:""}); 
                this.checkXlsxServer(); 
            
            }
             
        }else{

            this.setState({importError:this.props.translate['nao_foi_possivel_abrir_o_arquivo']});
         //   console.log('a4');

        }

    }

    filterUsers(obj){

        var obj2 = JSON.parse(JSON.stringify(obj));
        for (let index = 0; index < obj2.length; index++) {
            
            obj2[index].birthDate = moment(obj2[index].birthDate,'DD/MM/YYYY').format('YYYY-MM-DD');
            obj2[index].company = this.props.user.preference.selectcompany;
            
        }

        return obj2;

    }

    changeValue(index,value,position){

        var usersList = this.state.usersList;
        usersList[index][position] = value;
        this.setState({usersList:usersList,verificado:false});

    }

    //Para poder reenviar o arquivo xlsx
    reset(){

        this.setState({usersList:[],verificado:false});

    }

  	render(){

        var st = {};
        if(this.state.usersList.length !== 0){ st = {maxWidth:'98%'}; }

        return(<React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <Modal className="modal-dialog-centered" style={st} isOpen={this.props.state.openModalImport} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">{this.props.translate['importar_por_planilha']}</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => { this.setStateTop({openModalImport:false}); this.reset(); } }><span aria-hidden={true}>×</span> </button>
            </div>
              <CardBody className=" " style={{}}>
                <Form role="form" type="post" onSubmit={ (event) => this.props.saveUser(event) }>
                    {
                        (this.state.importError !== "") &&
                        <Alert color="danger" className="animated tada"><strong>{this.props.translate['ops']}!</strong> {this.state.importError}</Alert>
                    }
                    {

                        (this.state.usersList.length === 0) &&
                        <React.Fragment>
                            <center><font>{this.props.translate['selecione_o_arquivo']}:</font></center>
                            <div style={{display:"flex"}}>
                                <div className="custom-file form-control-sm">
                                    <input onChange={ (event) => { this.checkXlsx() } } className="custom-file-input" id="input_xlsx" type="file" />
                                    <label className="custom-file-label " htmlFor="input_xlsx">{this.props.translate['selecionar_arquivo']}</label>
                                </div>
                                <Button onClick={() => this.checkXlsx() } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> {this.props.translate['enviar']}</Button>
                            </div>
                        </React.Fragment>

                    }{

                        (this.state.usersList.length !== 0) &&
                        <React.Fragment>

                            {

                                (this.state.erroXLSX !== "") &&
                                <div className="container">
                                    <div className="alert alert-danger animated tada" role="alert">
                                        <strong>{this.props.translate['ops']}!</strong> {this.state.erroXLSX}
                                    </div>
                                </div>

                            }
                            {

                                (this.state.erroXLSX === "" && this.state.verificado === true) &&
                                <div className="container">
                                    <div className="alert alert-success animated fadeIn" role="alert">
                                        <strong><i className="fas fa-check"></i></strong> {this.props.translate['tudo_pronto_para_cadastrar']}
                                    </div>
                                </div>

                            }

                            <div className="table-responsive div_tab_importxlsx">
                                
                                <table className="align-items-center table-flush table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>#</th>
                                            <th>{this.props.translate['nome']}:</th>
                                            <th>{this.props.translate['sobrenome']}:</th>
                                            <th>{this.props.translate['email']}:</th>
                                            <th>{this.props.translate['usuario']}:</th>
                                            <th>{this.props.translate['sexo']}:</th>
                                            <th>{this.props.translate['nascimento']}:</th>
                                            <th>{this.props.translate['tipo']}:</th>
                                            <th><center>{this.props.translate['bl_qr_code']}?</center></th>
                                            <th><center>{this.props.translate['bl_total']}?</center></th>
                                            <th>{this.props.translate['senha']}:</th>
                                            <th>{this.props.translate['turma']}:</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table_importXLXS">
                                        {
                                            this.state.usersList.map((value,index) => {

                                                return(<tr key={'input_xlsx_'+index}>
                                                    <td>{(index+1)}</td>
                                                    <td><input onChange={ (event) => this.changeValue(index,event.target.value,'name') } style={{width:'90px'}} value={this.state.usersList[index].name}/></td>
                                                    <td><input onChange={ (event) => this.changeValue(index,event.target.value,'lastName') } style={{width:'90px'}} value={this.state.usersList[index].lastName}/></td>
                                                    <td><input onChange={ (event) => this.changeValue(index,event.target.value,'email') } value={this.state.usersList[index].email}/></td>
                                                    <td><input onChange={ (event) => this.changeValue(index,event.target.value,'username') } style={{width:'90px'}} value={this.state.usersList[index].username}/></td>
                                                    <td>
                                                        <select value={this.state.usersList[index].genre} onChange={ (event) => this.changeValue(index,event.target.value,'genre') }>
                                                            <option value=""></option>
                                                            <option value="0">{this.props.translate['masculino']}</option>
                                                            <option value="1">{this.props.translate['feminino']}</option> 
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <InputMask onChange={ (event) => this.changeValue(index,event.target.value,'birthDate') } value={this.state.usersList[index].birthDate} mask="99/99/9999" maskChar="" onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
                                                    </td>
                                                    <td><select value={this.state.usersList[index].accessLevel} onChange={ (event) => this.changeValue(index,event.target.value,'accessLevel') }>
                                                    <option value=""></option>
                                                        {
                                                            this.props.state.levelAcess.map((value2,index2) => {

                                                                return(<option value={value2.id} key={"i"+index+"ii"+index2}>{value2.name}</option>)

                                                            })

                                                        }
                                                        </select>
                                                    </td>
                                                    <td> <center><select onChange={ (event) => this.changeValue(index,event.target.value,'blockedQRcode') } value={this.state.usersList[index].blockedQRcode+""}><option value=""></option><option value="1">Sim</option><option value="0">Não</option></select></center> </td>
                                                    <td> <center><select onChange={ (event) => this.changeValue(index,event.target.value,'blocked') } value={this.state.usersList[index].blocked+""}><option value=""></option><option value="1">Sim</option><option value="0">Não</option></select></center> </td>
                                                    <td> <input onChange={ (event) => this.changeValue(index,event.target.value,'password') } style={{width:'80px'}} value={this.state.usersList[index].password}/> </td>
                                                    
                                                    <td>
                                                        {/*
                                                        
                                                            <select value={this.state.usersList[index].classRoom} onChange={ (event) => this.changeValue(index,event.target.value,'classRoom') }>
                                                                <option value=""></option>
                                                                {

                                                                    this.props.state.classRoom.map((value3,index3) => {

                                                                        return(<option value={value3.id} key={"i"+index+"iic"+index3}>{value3.name}</option>)

                                                                    })

                                                                }
                                                            </select>

                                                        */}
                                                        <div className="input_select_custom_importa_usuario" >
                                                            <Select 
                                                            
                                                            placeholder={'Selecione as turmas'}

                                                            itemRenderer={({ item, methods }) => (
                                                                <div>
                                                                  {item.disabled ? (
                                                                     
                                                                    <div className="custom-control custom-checkbox"><input className="custom-control-input" type="checkbox" /> <label className="custom-control-label">{item.name} EEEE</label></div>
                                                                    
                                                                  ) : (
                                                                     
                                                                    <div style={{display:'flex'}}  className="custom-control custom-checkbox"><input checked={methods.isSelected(item)} className="custom-control-input" type="checkbox" /> 
                                                                        <label onClick={() => {  
                                                                            
                                                                            console.log(item.type);

                                                                            //var item = JSON.parse(JSON.stringify(item));
                                                                            if(item.type !== undefined && item.type !== ""){

                                                                                methods.addItem(item);
                                                                            
                                                                            }else{

                                                                                var a = '.select_tt_'+item.id+'_line_'+index;

                                                                                $(a).removeClass('flash');
                                                                                setTimeout(function(){ 
                                                                                    
                                                                                    $(a).addClass('animated');
                                                                                    $(a).addClass('flash');

                                                                                }, 100);
                                                                                

                                                                            }
                                                                        
                                                                        }} className="custom-control-label">{item.name}</label>
                                                                        <div style={{marginLeft:'10px'}}>
                                                                            Tipo:<select className={'select_tt_'+item.id+'_line_'+index} value={item.type} onChange={ (event) => {
                                                                            
                                                                            if(event.target.value === ""){ return false; }
                                                                            item.type = event.target.value; 
                                                                            this.setState({a:'1'}); 
 
                                                                            var index24 = this.state.usersList[index].classRoom.map(function(x) {return x.id; }).indexOf(item.id);
                                                                            if(index24 === -1){

                                                                                methods.addItem(item);

                                                                            }    

                                                                        }} style={{boxShadow:'0px 1px 3px -2px #000',borderRadius:'5px',marginLeft:'4px'}}>
                                                                            <option value=""></option>
                                                                            {
                                                                                
                                                                                window.app.state.permitions.map((val2,ind2) =>{

                                                                                    return(<option value={val2.id}>{val2.name}</option>)

                                                                                })
                                                                                
                                                                            }</select>
                                                                        </div>
                                                                    </div>

                                                                  )}
                                                                </div>
                                                              )}
                                                              
                                                            labelField={'name'} 
                                                            values={this.state.usersList[index].classRoom} 
                                                            onChange={ (event) => this.changeValue(index,event,'classRoom') } 
                                                            valueField={'id'} multi 
                                                            options={this.state.usersList[index].classRooms} 
                                                            
                                                            />
                                                        </div>


                                                    </td>
                                                    <td><i style={{cursor:'pointer'}} onClick={ () => this.removeLine(index) } className="far fa-trash-alt"></i></td>
                                                </tr>)

                                            })

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>

                    }
                    <div className="text-center">

                        {   
                            (this.state.usersList.length !== 0) &&
                            <Button style={{marginLeft:'10px'}} className="my-4" color="danger" type="button" onClick={ () =>{ this.setStateTop({openModalImport:false}); this.reset(); } }><i className="far fa-times-circle"></i> {this.props.translate['cancelar']}</Button>
                            
                        }

                        <Link target="_blank" download to="/planilha/Template de importacao de usuario.xlsx"><Button className="my-4" color="default" type="button" ><i className="fas fa-file-download"></i> {this.props.translate['baixar_template']} </Button></Link>
                        
                        {   
                            (this.state.usersList.length === 0) &&
                            <Button style={{marginLeft:'10px'}} className="my-4" color="secondary" type="button" onClick={ () =>{  this.manual(); } }><i className="fas fa-list-ol"></i> {this.props.translate['cadastrar_manualmente']}</Button>
                        }
                        {
                            (this.state.usersList.length !== 0) &&
                            <Button style={{marginLeft:'10px'}} className="my-4" color="secondary" type="button" onClick={ () =>{  this.addLine(); } }><i className="fas fa-plus"></i> {this.props.translate['adicionar_linha']}</Button>

                        }
                        
                        {

                            (this.state.usersList.length !== 0) &&
                            <React.Fragment>
                                
                                {

                                    (this.state.verificado === false) &&
                                    <Button style={{marginLeft:'5px'}} onClick={ () => this.checkXlsxServer() } className="my-4" color="secondary" type="button" ><i className="fas fa-user-check"></i> {this.props.translate['verificar_dados']}</Button>
                                
                                }
                                {

                                    (this.state.erroXLSX === "" && this.state.verificado === true) &&
                                    <Button onClick={ () => this.cadastrar() } className="my-4" color="success" type="button" ><i className="fas fa-paper-plane"></i> {this.props.translate['cadastrar']}</Button>

                                }
                                
                            </React.Fragment>

                        }
                    </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal></React.Fragment>);

  	}

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,

}))(ImportXlsxUser);