import React from "react";
import {Container,Row,Col,Card,CardHeader,Alert,Form,FormGroup,InputGroup,InputGroupAddon,Input,InputGroupText} from "reactstrap";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import { connect } from 'react-redux';
import api from "./../../../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import InputMask from 'react-input-mask';

class Schedule extends React.Component {

    constructor(props){

        super(props);
        this.state = {loadSchedule:false,schedules:[],dialog:{name:"",description:"",date:moment().format('YYYY-MM-DD'),start_time:""},erro:""};

        this.getSchedule = this.getSchedule.bind(this);
        this.putSchedule = this.putSchedule.bind(this);
        this.postSchedule = this.postSchedule.bind(this);
        this.saveSchedule = this.saveSchedule.bind(this);


        window.postschedule = this;

    }

    componentDidMount(){

        if(this.props.edit === undefined){}else{ this.getSchedule(); }

    }

    async getSchedule(){}

    async postSchedule(){

        this.state.dialog.classRoom = this.props.classRoom;
        console.log(this.state.dialog);

        await api.postSchedulesClassroom(this.state.dialog).then((res) => {

            console.log(res);

			if(this.props.successPost !== undefined){

                this.props.successPost({msg:"A aula foi cadastrada com sucesso.",data:res.data});

            }
        
        }).catch(error => {

			console.log(error);
            if(error.response !== undefined){ console.log(error.response); }
            if(error.response.data !== undefined){ this.setState({erro:error.response.data.msg}); }

        });

    }

    async putSchedule(){}
    
    async saveSchedule(e){

        e.preventDefault();
        this.setState({loading:true});
        if(this.props.edit === undefined){ this.postSchedule(); }else{ this.putSchedule(); }

    }
   
    render(){

        const handleChange = name => event => {
	        var dialog = this.state.dialog;
	        dialog[name] = event.target.value;
	        this.setState({dialog:dialog});
        }

        return(<React.Fragment>
            
            <Form role="form" type="post" onSubmit={ (event) => this.saveSchedule(event) }>
                {
                    (this.state.erro) &&
                    <Alert color="danger" className="animated tada"><strong>{this.props.translate['ops']}!</strong> {this.state.erro}</Alert>
                }
                <Row>
                    <Col md={12}>
                        <h5>{this.props.translate['nome']}:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <h5>Data da aula:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento5})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-calendar-grid-58" /></InputGroupText></InputGroupAddon> 


                            <ReactDatetime autoComplete="off" locale="pt-br" onChange={ (event) => {
                              
                              var dialog = this.state.dialog; 
                              try {

                                dialog.date = event.format('YYYY-MM-DD');

                              }catch(error){
                                
                              }
                              this.setState({dialog:dialog}); 
                              
                              }}  value={ moment(this.state.dialog.date,'YYYY-MM-DD').format('DD/MM/YYYY') } dateFormat="DD/MM/YYYY" inputProps={{placeholder:""}} timeFormat={false} />


                            { 
                            
                            //<InputMask onChange={handleChange('date')} value={ this.state.dialog.date } className="form-control" mask="99/99/9999" maskChar=" " onFocus={() => this.setState({focusedNascimento5:true}) } onBlur={() => this.setState({focusedNascimento5:false}) }/>
                          
                            
                            }  
                            
                          </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <h5>Horário da aula:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento4})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="far fa-clock" /></InputGroupText></InputGroupAddon> 
                            <InputMask onChange={handleChange('start_time')} value={ this.state.dialog.start_time } className="form-control" mask="99:99:99" maskChar=" " onFocus={() => this.setState({ focusedNascimento4: true }) } onBlur={() => this.setState({ focusedNascimento4: false }) }/>
                          </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <h5>Descrição:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento65})} >
                        <InputGroup className="input-group-merge input-group-alternative">
                            <textarea className="form-control" onFocus={() => this.setState({focusedNascimento65:true}) } onBlur={() => this.setState({focusedNascimento65:false}) }></textarea>
                        </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <center class="text-center">
                            <button onClick={ () => { this.props.close() } } type="button" class="btn btn-default">Cancelar</button>
                            <button type="submit" class="btn btn-primary">
                                {

                                    <font>Cadastrar aula</font>

                                }
                            </button>
                        </center>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>)

    }


}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	unit: state.unit,
  
}))(Schedule);